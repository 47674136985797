const ChevronIcon = () => {
  return (
    <svg
      className="slds-button__icon slds-button__icon_small"
      aria-hidden="true"
    >
      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevronright"></use>
    </svg>
  );
};

export default ChevronIcon;
