import { useEffect, useState, useRef } from "react";
import Card from "@salesforce/design-system-react/components/card";
import Button from "@salesforce/design-system-react/components/button";
import Tabs from "@salesforce/design-system-react/components/tabs";
import TabsPanel from "@salesforce/design-system-react/components/tabs/panel";

import "./PsPatternDetailedView.css";
import Event from "../../helpers/event.js";
import Record from "../../helpers/recordLayer.js";
import { shallowEqual, toastErrorMessage } from "../../helpers";
import PsPatternChart from "../ps-pattern-chart/PsPatternChart";
import PsPatternGrid from "../ps-pattern-grid/PsPatternGrid";
import PsCompositionGrid from "../ps-composition-grid/PsCompositionGrid";

function PsPatternDetailedView(props) {
  const [cmpState, setCmpState] = useState({
    mode: "view",
    processed: null,
    showAlternativeAxes: false,
    queryFilter: null, //default to null, so that we don't run getRecords until queryFilter is populated
    selectedTab: "Explore More",
  });

  const cmpWorking = useRef({});
  const detailsRef = useRef(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    if (props.pattern) {
      getPattern(cmp);
    }
  }, []); //runs on init and when search params are updated and/or when props are updated (incoming pattern)

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    handlePatternChange();
  }, [props.pattern]);

  const cmp = {
    get: (key) => {
      if (props[key]) return props[key];

      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        handleRefreshEvent();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },
  };

  const childToParent = (event) => {
    if (event) {
      handleEvent(event);
    }
  };

  const handleEvent = (event) => {
    let stopPropagation = false;

    // cmpWorking.current = { ...cmpState }; //TODO is this the right place

    if (event.type === "navigation") {
      handleNavigationEvent(event);
      stopPropagation = true;
    } else if (event.type === "dataCompEvent") {
      handleDataCompEvent(event);
      stopPropagation = true;
    }

    if (!stopPropagation) {
      //props.childToParent(event);
    }
  };

  const handleNavigationEvent = (event) => {
    props.childToParent(event);
  };

  const handleDataCompEvent = (event) => {
    props.childToParent(event);
  };

  const handlePatternChange = () => {
    // go back to the top of the page
    var scroller = detailsRef.current;
    if (scroller) {
      scroller.scrollTop = 0;
    }
    getPattern();
  };

  const handleCloseClick = () => {
    var event = Event.createEvent("dataCompEvent");
    event.data = { action: "close" };
    props.childToParent(event);
  };

  const handleRefreshEvent = () => {
    getPattern(true);
  };

  const parsePattern = (pattern) => {
    // pattern not yet available; don't change anything
    var patternId = pattern.id;
    var isSalesforceId = (patternId || "").length <= 18;
    var relatedToId = !isSalesforceId ? patternId : null;
    var keyId = pattern.keyId || (pattern.key || {}).id;
    var treeHash = pattern.treeHash;

    var queryFilter = relatedToId
      ? { relatedToId: relatedToId }
      : keyId
      ? { keyId: keyId }
      : null;

    // only update if changed to prevent unnecessary reloading
    var currentQueryFilter = cmp.get("queryFilter");
    if (
      !currentQueryFilter ||
      !shallowEqual(queryFilter, cmp.get("queryFilter"))
    ) {
      cmp.set("queryFilter", queryFilter);
    }
    cmp.set("treeHash", treeHash);
    cmp.set("showAlternativeAxes", Boolean(relatedToId));
  };

  const getPattern = (forceRefresh = false) => {
    cmp.set("mode", "view");
    var pattern = cmp.get("pattern") || {};
    var patternId = pattern.id;

    if (
      !patternId ||
      !patternId.length ||
      (pattern.inputs && pattern.parameters && pattern.treeHash)
    ) {
      // no way to get more pattern details without an id, so work with what is there
      if (forceRefresh) {
        //This will force PatternChart to reload
        pattern = { ...pattern };
      }
      cmp.set("processed", pattern);
      parsePattern(pattern);
    } else {
      getDeepSigmaPattern(patternId);
    }
  };

  const getDeepSigmaPattern = (patternId) => {
    try {
      var onSuccess = function (response) {
        if (!response || response.length === 0) {
          cmp.set("mode", "error");
          return;
        }
        var pattern = response[0];
        cmp.set("processed", pattern);
        parsePattern(pattern);
      };
      var onError = function (response) {
        if (response === "No current user") {
          props.childToParent({ type: "logout" });
        }

        props.setToastState({
          variant: "error",
          heading: "Error",
          details: toastErrorMessage(response),
        });
        cmp.set("mode", "error");
      };

      Record.getRecord(
        "relate",
        "pattern",
        patternId,
        {},
        "",
        "GET",
        onSuccess,
        onError
      );
    } catch (err) {
      cmp.set("mode", "error");
      console.error(err.stack);
    }
  };

  const handleTabClick = (selectedTab) => {
    cmp.set("selectedTab", selectedTab);
  };

  return (
    <div className="PsPatternDetailedView slds-scrollable" ref={detailsRef}>
      {/* <div className="slds-scrollable" id="details"> */}
      {cmpState.mode === "error" && (
        <Card
          id="recordGrid"
          style={{
            width: "90vw",
            marginBottom: "1rem",
          }}
          // headerActions={
          //   !isEmpty && (
          //     <Button label="New" onClick={handleDeleteAllItems} />
          //   )
          // }
          // heading={recordLabelPlural}
        >
          <div className="slds-is-relative">
            <div
              className="slds-var-p-around_medium slds-illustration slds-illustration_large"
              aria-hidden="true"
            >
              <img
                src="/assets/images/illustrations/Desert.svg"
                className="slds-illustration__svg"
                alt=""
              />
              <div className="slds-text-color_weak">
                <h4 className="slds-text-heading_medium">
                  Pattern could not be loaded
                </h4>
              </div>
            </div>
            <br />
            <div className="slds-align_absolute-center">
              <Button
                label="Return to Overview"
                title="Return to Overview"
                onClick={handleCloseClick}
              />
            </div>
          </div>
        </Card>
      )}

      {cmpState.mode === "view" && (
        <div className="detailed-view">
          <div className="chart-section slds-m-right_medium slds-m-bottom_medium">
            {/* {cmpState.processed &&
              <p>Id: {cmpState.processed.id}</p>
            } */}

            {/* TODO - should events go straight to parent or are there somethings that should be handled here? */}
            <PsPatternChart
              record={cmpState.processed}
              view="details"
              childToParent={props.childToParent}
            />

            {/* <c:PatternChart record="{!v.processed}" view="details"/> */}
          </div>
        </div>
      )}

      <div
        className="list-section slds-m-right_medium slds-m-bottom_medium"
        style={{
          width: cmpState.mode === "error" ? "90vw" : "",
        }}
      >
        <div className="slds-box">
          <Tabs id="tabs-example-default">
            <TabsPanel
              label={
                <div onClick={() => handleTabClick("Explore More")}>
                  Explore More
                </div>
              }
            >
              {cmpState.selectedTab === "Explore More" && (
                <PsCompositionGrid
                  view="grid"
                  queryFilter={cmpState.queryFilter}
                  showTitle={false}
                  maxRecords="6"
                  showFooter={false}
                  childToParent={childToParent}
                  setToastState={props.setToastState}
                  parentToChildEvent={props.parentToChildEvent}
                  parentCmp={props.parentCmp}
                />
              )}
            </TabsPanel>
            {cmpState.showAlternativeAxes && (
              <TabsPanel
                label={
                  <div onClick={() => handleTabClick("Alternative Axes")}>
                    Alternative Axes
                  </div>
                }
              >
                {cmpState.selectedTab === "Alternative Axes" && (
                  <PsPatternGrid
                    view="grid"
                    queryFilter={cmpState.queryFilter}
                    showTitle={false}
                    maxRecords="12"
                    showFooter={false}
                    childToParent={childToParent}
                    setToastState={props.setToastState}
                    parentToChildEvent={props.parentToChildEvent}
                    parentCmp={props.parentCmp}
                  />
                )}
              </TabsPanel>
            )}
          </Tabs>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default PsPatternDetailedView;
