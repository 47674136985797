import { Button, Input, Tooltip } from "@salesforce/design-system-react";

import ProgressBarComponent from "./ProgressBarComponent";

const FreeText = ({ cmpState }) => {
  return (
    <div>
      {cmpState.useInput === "FreeText" && (
        <div className="slds-form__item" role="listitem">
          <div className="slds-form-element slds-form-element_stacked">
            {cmpState.mode === "init" && <ProgressBarComponent />}

            {cmpState.mode === "view" && (
              <div className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                <span className="slds-form-element__label">Values</span>
                <div className="slds-form-element__control">
                  <div className="slds-form-element__static">
                    {cmpState.freeTextValues}
                  </div>
                  <div className="slds-button slds-button__icon slds-button__icon_hint">
                    <Button
                      auraId="EditRecord"
                      iconName="utility:edit"
                      variant="bare"
                      alternativeText="Edit"
                      //onClick={c.handleEdit}
                    />
                  </div>
                </div>
              </div>
            )}

            {(cmpState.mode === "new" || cmpState.mode === "edit") && (
              <Input
                auraId="checkField"
                name="freeTextValues"
                label="Values"
                autocomplete="off"
                value={cmpState.freeTextValues}
                fieldLevelHelpTooltip={
                  <Tooltip
                    id="field-level-help-tooltip"
                    align="top left"
                    content='Enter one or more values separated by commas. Enclose values with commas in double quotes. 
                    For example: orange, "pear, apple", banana'
                  />
                }
                // onChange={(e) => handleChangeFreeTextValues(e)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FreeText;
