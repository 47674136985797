import FormWrapper from "../../../components/ui/wrappers/FormWrapper";
import { useEffect, useMemo, useState } from "react";
import EditFieldWrapper from "../../../components/ui/wrappers/EditFieldWrapper";
import EditButtonIcon from "../../../components/ui/EditButtonIcon";
import CheckAndCloseIcons from "../../../components/ps-key/components/CheckAndCloseIcons";
import Toggle from "../../../components/ui/Toggle";
import FieldWrapper from "../../../components/ui/wrappers/FieldWrapper";
import Record from "../../../helpers/recordLayer";
import Combobox from "@salesforce/design-system-react/components/combobox";
import RecordConstants from "../../../constants/RecordConstants";

const initializeValues = {
  enableGenerativeAIExplain: false, //boolean
  enableGenerativeAISearch: false, //boolean
  generativeAI: "", // string
};

const AccountForm = ({
  //Required
  formValues,
  //Required
  onSave = (_formValues) => {
    return;
  },
  // Required  null or object
  readOnlyValues,
  loading,
  isEdit,
  setIsEdit,
}) => {
  // When isEditable is false, the user cannot update the account form.
  // To make the form editable, set isEditable to true or remove the isEditable condition entirely.
  const isEditable = false;

  const [values, setValues] = useState(initializeValues);

  // Use useMemo to ensure generativeAIOptions is created once per render cycle.
  // Alternatively, defining it outside the component will yield the same result since it doesn't depend on props or state.
  const generativeAIOptions = useMemo(
    () =>
      Object.values(RecordConstants.ACCOUNT_SETTINGS_GENERATIVE_AI).map(
        (opt) => ({
          label: opt.label,
          id: opt.value,
        })
      ),
    []
  );

  useEffect(() => {
    if (!!formValues) {
      setValues(formValues);
      return;
    }
    setValues(initializeValues);
  }, [formValues]);

  const GetIsEditableField = ({ children, value, name, isEdit }) => {
    return (
      <>
        {!isEdit && (
          <EditFieldWrapper
            name={name}
            value={value}
            children={
              isEditable ? (
                <EditButtonIcon handleEdit={() => setIsEdit(true)} />
              ) : null
            }
          />
        )}
        {isEdit && <>{children}</>}
      </>
    );
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <FormWrapper
      loading={loading}
      disableAllBtn={loading}
      title="Account"
      desc="Account details"
      hideDelete={true}
      isEdit={isEdit}
      isEditable={isEditable}
      onEdit={() => setIsEdit(true)}
      onCancel={() => setIsEdit(false)}
      onSave={() => onSave(values)}
    >
      <div className="slds-form__row">
        <EditFieldWrapper
          name={"Name"}
          value={readOnlyValues?.name || ""}
          children={null}
        />
        <EditFieldWrapper
          name={"Setup Status"}
          value={readOnlyValues?.setupStatus || ""}
          children={null}
        />
      </div>
      <div className="form-m-description">Settings</div>
      <div className="slds-form__row">
        <GetIsEditableField
          name={"Generative AI"}
          value={values?.generativeAI || ""}
          isEdit={isEdit}
        >
          <FieldWrapper>
            <Combobox
              id="Generative AI"
              labels={{
                name: "generativeAI",
                label: "Generative AI",
                placeholder: "--Please Select--",
              }}
              events={{
                onSelect: (_event, data) => {
                  if (data.selection.length === 0) return;
                  onChangeHandler({
                    target: {
                      name: "generativeAI",
                      value: data.selection[0].id,
                    }, // representing of input event so we can use the onChangeHandler function
                  });
                },
              }}
              menuPosition="relative"
              options={generativeAIOptions}
              selection={[
                generativeAIOptions.find(
                  (option) =>
                    `${option?.id}`.toLowerCase() ===
                    `${values?.generativeAI}`.toLowerCase()
                ),
              ]}
              value={values?.generativeAI || ""}
              variant="readonly"
            />
          </FieldWrapper>
        </GetIsEditableField>

        <GetIsEditableField
          name={"Enable Generative AI Explain"}
          value={
            <CheckAndCloseIcons
              selectedItem={values.enableGenerativeAIExplain}
            />
          }
          isEdit={isEdit}
        >
          <FieldWrapper>
            <Toggle
              active={values.enableGenerativeAIExplain}
              label="Enable Generative AI Explain"
              setActive={() => {
                onChangeHandler({
                  target: {
                    name: "enableGenerativeAIExplain",
                    value: !values.enableGenerativeAIExplain,
                  },
                });
              }}
            />
          </FieldWrapper>
        </GetIsEditableField>

        <GetIsEditableField
          name="Enable Generative AI Search"
          isEdit={isEdit}
          value={
            <CheckAndCloseIcons
              selectedItem={values.enableGenerativeAISearch}
            />
          }
        >
          <FieldWrapper>
            <Toggle
              active={values.enableGenerativeAISearch}
              label="Enable Generative AI Search"
              setActive={() =>
                onChangeHandler({
                  target: {
                    name: "enableGenerativeAISearch",
                    value: !values.enableGenerativeAISearch,
                  },
                })
              }
            />
          </FieldWrapper>
        </GetIsEditableField>
      </div>
      <div className="form-m-description">Usage</div>
      <div className="slds-form__row">
        <EditFieldWrapper
          name={"Compute Credit"}
          value={readOnlyValues?.computeCredit || ""}
          children={null}
        />
      </div>
    </FormWrapper>
  );
};

export default AccountForm;
