import { Button } from "@salesforce/design-system-react";

const Buttons = ({ cmpState, cmp }) => {
  return (
    <div className="slds-form__row slds-m-top_small">
      <div className="slds-form__item" role="listitem">
        <div className="slds-form-element slds-form-element_stacked">
          {/* <lightning:button disabled="{! v.loading || empty(v.dataTableRecords) }" label="Remove" title="Remove last Link" onclick="{!c.handleRemoveChainLink}"/> */}
          {/* <lightning:button disabled="{! v.loading || empty(v.leftKeyId) || empty(v.rightKeyId) }" label="Add" title="Add new Link" onclick="{!c.handleAddChainLink}" variant="brand"/> */}
          <Button
            disabled={
              cmpState.loading ||
              !cmpState.dataTableRecords ||
              cmpState.dataTableRecords.length === 0
            }
            label="Remove"
            title="Remove last Link"
            onClick={() => cmp.handleRemoveChainLink()}
          />
          <Button
            disabled={
              cmpState.loading || !cmpState.leftKey || !cmpState.rightKey
            }
            label="Add"
            title="Add new Link"
            onClick={() => cmp.handleAddChainLink()}
            variant="brand"
          />
        </div>
      </div>
    </div>
  );
};

export default Buttons;
