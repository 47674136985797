import Icon from "@salesforce/design-system-react/components/icon";
import PsPatternExplain from "../../ps-pattern-explain/PsPatternExplain";

const Description = ({ cmpState, toggleSection, handleEvent, parentCmp }) => {
  const { showSectionDescription } = cmpState;

  return (
    <div
      className="slds-col slds-m-horizontal_x-small"
      style={{ minWidth: "300px" }}
    >
      <div
        className={`slds-section ${
          showSectionDescription ? "slds-is-open" : "slds-is-close"
        }`}
        id="descriptionOptions"
      >
        <h3>
          <button
            aria-controls="contentDescriptionOptions"
            className="slds-button slds-section__title-action"
            onClick={() => toggleSection("showSectionDescription")}
          >
            <span id="sectionDescriptionOptions">
              <Icon
                assistiveText={{ label: "button icon" }}
                category="utility"
                name="switch"
                size="x-small"
                className="slds-section__title-action-icon slds-button__icon_left"
              />
            </span>
            <span className="slds-truncate" title="Plot Options">
              Description
            </span>
          </button>
        </h3>
        {showSectionDescription ? (
          <div className="slds-section__content" id="contentExplain">
            <div
              className="slds-scrollable--y"
              style={{ maxHeight: "200px", overflow: "initial" }}
            >
              <PsPatternExplain
                pattern={cmpState.processed}
                data={cmpState.plotData}
                handleEvent={handleEvent}
                parentCmp={parentCmp}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Description;
