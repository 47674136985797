import "@blueprintjs/table/lib/css/table.css";
// import "normalize.css"; // This causes three dots to appear under the star that indicate the required field for lightning design components.
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Table2, Column, Cell, ColumnHeaderCell } from "@blueprintjs/table";
import {
  HotkeysProvider,
  Menu,
  MenuItem,
  Classes,
  Icon,
} from "@blueprintjs/core";
import { useEffect, useState } from "react";

import CustomTooltip from "../../ui/CustomTooltip";
import CheckIcon from "../../ui/CheckIcon";
import {
  formattedDateTime,
  formattedNumber,
  formattedRelevance,
} from "../../../helpers";
import { bgColor, defaultColumnWidthCalc, sortadIcon } from "./Helper";

const RecordTable = ({ cmp, cmpState, PsRecordGrid }) => {
  const [sortedColumns, setSortedColumns] = useState([]);
  const [columns, setColumns] = useState(
    cmpState.columns || cmp.RECORD_COLUMNS || []
  );

  const psRecordGridWidth =
    cmpState.psRecordGridWidth || cmp.get("psRecordGridWidth");
  const defaultColumnWidth = defaultColumnWidthCalc(columns, psRecordGridWidth);
  const records = cmpState.recordList || [];

  const search = window?.location?.search;
  const params = new URLSearchParams(search);
  const selected = params.get("selected");
  const pathname = window?.location?.pathname;
  const selectedFilterFromUrl = selected && selected.split("_")[0];

  const cellRendererDependencies =
    pathname === "/StoreManagement" && selectedFilterFromUrl
      ? [defaultColumnWidth, selectedFilterFromUrl]
      : [defaultColumnWidth];

  useEffect(() => {
    const tableDivContainer = document.querySelector(
      ".bp5-table-quadrant-scroll-container"
    );
    const tableTop = document.querySelector(
      ".bp5-table-quadrant.bp5-table-quadrant-top"
    );
    const tableTopContainer = document.querySelector(
      ".bp5-table-column-header-tr"
    );

    if (tableTop) {
      tableTop.style.backgroundColor = bgColor;
    }
    if (tableDivContainer) {
      tableDivContainer.style.backgroundColor = bgColor;
    }
    if (tableTopContainer) {
      tableTopContainer.style.backgroundColor = bgColor;
    }
  }, []);

  const href = (record, column) => {
    try {
      const action = column.action;
      const pathname = window.location.pathname;
      const url = new URL(window.location.href);
      let names;

      // Explore
      if (pathname === "/Explore") {
        const recordId = record.id.replace(/-[^-]*$/, "");

        switch (action) {
          case "question":
            names =
              "types_dataRole_" + record.dataRole?.id + "~data_key_" + recordId;
            break;
          case "key":
            names = "data_key_" + recordId;
            break;
          case "dataRole":
            names = "types_dataRole_" + record.dataRole.id;
            break;
          case "details":
            names = selected + "&pattern=" + record.id;
            break;
          case "viewSource":
            names =
              selected.split("~")[0] +
              "~" +
              "data_source_" +
              record.key.container.source.id;
            break;
          case "viewContainer":
            names =
              selected.split("~")[0] +
              "~" +
              "data_container_" +
              record.key.container.id;
            break;
          case "viewKey":
            break;
          default:
        }

        return `/Explore?selected=${names}`;
      }

      // Search and Build
      if (
        (pathname === "/Search" || pathname === "/Build") &&
        action === "details"
      ) {
        const patternParam = url.searchParams.get("pattern");

        if (!patternParam) {
          url.searchParams.set("pattern", "result");
        } else if (record.id) {
          url.searchParams.set("pattern", record.id);
        }

        return url.toString();
      }

      // SavedInsights
      if (pathname === "/SavedInsights") {
        const url = new URL(window.location.href);
        let newUrl;

        switch (action) {
          case "details":
            url.searchParams.set("pattern", record.id);
            newUrl = url.toString();
            break;
          case "viewSource":
            newUrl = `/SavedInsights?selected=folders_folder_${record.key.container.source.id}`;
            break;
          case "viewContainer":
            newUrl = `/SavedInsights?selected=folders_folder_${record.key.container.id}`;
            break;
          default:
        }

        return newUrl;
      }

      // DataManagement
      if (pathname === "/DataManagement") {
        switch (action) {
          case "details":
            let obj =
              record.source && record.connectorType ? "connector" : "source";
            if (selected?.includes("pump_connector_")) {
              obj = "object";
            } else if (selected?.includes("pump_object_")) {
              obj = "field";
            }
            names = "pump_" + obj + "_" + record.id;
            break;
          case "viewSource":
            names = "pump_source_" + record.source.id;
            break;
          default:
        }

        return `/DataManagement?selected=${names}`;
      }

      // StoreManagement
      if (pathname === "/StoreManagement" && action === "details") {
        const selectedFilter = selected.split("_")[0];
        names = selectedFilter + "_source_" + record.id;

        const sourcePatterns = [
          "storeData_source_",
          "storeFilters_source_",
          "storeJoins_source_",
          "storeMaps_source_",
          "storeChains_source_",
        ];

        if (sourcePatterns.some((pattern) => selected?.includes(pattern))) {
          names = selectedFilter + "_container_" + record.id;
        }

        const containerPatterns = {
          storeData: "_key_",
          storeFilters: "_filter_",
          storeJoins: "_link_",
          storeMaps: "_map_",
          storeChains: "_chain_",
        };

        const selectedType = Object.keys(containerPatterns).find((type) =>
          selected?.includes(`${type}_container_`)
        );

        if (selectedType) {
          names = selectedFilter + containerPatterns[selectedType] + record.id;
        }

        return `/StoreManagement?selected=${names}`;
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const cellRenderer = (rowIndex, column) => {
    const record = records[rowIndex];
    const recordName = record[column.property];
    let cell = recordName;
    let title = recordName;
    if (column.type === "link") {
      cell = (
        <a
          href={href(record, column)}
          onClick={(event) => {
            event.preventDefault();
            PsRecordGrid.handleRecordRowAction(
              records[rowIndex],
              column.action,
              cmp
            );
          }}
          title={column.title || "View Details"}
        >
          {recordName}
        </a>
      );
    } else if (column.type === "formattedDate") {
      cell = formattedDateTime(recordName);
      title = cell;
    } else if (column.type === "boolean") {
      cell = recordName ? <CheckIcon /> : "";
    } else if (column.type === "percent") {
      cell = formattedRelevance(recordName);
      title = cell;
    } else if (column.type === "number") {
      cell = formattedNumber(recordName);
      title = cell;
    }

    return (
      <Cell style={{ display: "flex", alignItems: "center" }}>
        {column.type === "boolean" ? null : (
          <CustomTooltip
            content={
              <div
                className="slds-tree__item-label slds-truncate"
                style={{
                  fontFamily: "Arial, sans-serif",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {cell}
              </div>
            }
            title={title}
          />
        )}
      </Cell>
    );
  };

  const handleClickSort = (sortDirection, column) => {
    setSortedColumns((prev) => {
      const found = prev.find((item) => item.column === column.property);
      if (found && found.sortDirection === sortDirection) {
        return prev.filter((item) => item.column !== column.property);
      } else {
        return prev
          .filter((item) => item.column !== column.property)
          .concat([{ column: column.property, sortDirection }]);
      }
    });

    PsRecordGrid.handleOrderBy(cmp, {
      property: column.property,
      sortDirection: sortDirection,
    });
  };

  const renderMenu = (column) => {
    return (
      <Menu className={Classes.ELEVATION_1}>
        <MenuItem
          icon="sort-asc"
          text="Sort Asc"
          onClick={() => handleClickSort("asc", column)}
          active={
            sortedColumns.find((item) => item.column === column.property)
              ?.sortDirection === "asc"
          }
        />
        <MenuItem
          icon="sort-desc"
          text="Sort Desc"
          onClick={() => handleClickSort("desc", column)}
          active={
            sortedColumns.find((item) => item.column === column.property)
              ?.sortDirection === "desc"
          }
        />
      </Menu>
    );
  };

  const nameRenderer = (column) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        title={column.label}
      >
        <div
          className="slds-tree__item-label slds-truncate"
          style={{ display: "flex", alignItems: "center" }}
        >
          {sortadIcon(column, sortedColumns)}
          <span
            className="slds-tree__item-label slds-truncate"
            style={{
              fontWeight: "bold",
            }}
          >
            {column.label}
          </span>
        </div>

        {column.sortable ? (
          <Icon icon="chevron-down" text="chevron-down" />
        ) : null}
      </div>
    );
  };

  const renderColumnHeader = (column) => {
    const menuRenderer = column.sortable ? () => renderMenu(column) : undefined;

    return (
      <ColumnHeaderCell
        name={column.label}
        nameRenderer={() => nameRenderer(column)}
        {...(menuRenderer ? { menuRenderer } : {})}
        style={{
          backgroundColor: bgColor,
        }}
        menuIcon={
          column.sortable ? (
            <div
              style={{
                margin: "0 1px 15px -15px",
              }}
            >
              <Icon icon="chevron-down" text="chevron-down" />
            </div>
          ) : null
        }
      />
    );
  };

  const handleColumnWidthChanged = (index, width) => {
    const newColumns = columns.map((col, colIndex) => {
      if (colIndex === index) {
        return { ...col, width };
      } else {
        return col;
      }
    });
    setColumns(newColumns);
  };

  return (
    <HotkeysProvider>
      <Table2
        numRows={records.length}
        defaultRowHeight={25}
        enableRowHeader={false}
        cellRendererDependencies={cellRendererDependencies}
        columnWidths={columns.map((col) => col.width || defaultColumnWidth)}
        onColumnWidthChanged={(index, width) =>
          handleColumnWidthChanged(index, width)
        }
        selectionModes={[]}
      >
        {columns.map((column) => {
          return (
            <Column
              key={column.label}
              name={column.label}
              cellRenderer={(rowIndex) => cellRenderer(rowIndex, column)}
              columnHeaderCellRenderer={() => renderColumnHeader(column)}
            />
          );
        })}
      </Table2>
    </HotkeysProvider>
  );
};

export default RecordTable;
