// This is a 'helper' that replaces the old RecordGrid base component.

import Button from "@salesforce/design-system-react/components/button";
import Card from "@salesforce/design-system-react/components/card";
import Spinner from "@salesforce/design-system-react/components/spinner";

import "./PsRecordGrid.css";
import Record from "../../helpers/recordLayer.js";
import PsPatternChart from "../ps-pattern-chart/PsPatternChart.js";
import PsSuggestionTile from "../ps-suggestion-tile/PsSuggestionTile.js";
import PsConnectorTile from "../ps-connector-tile/PsConnectorTile.js";
import IllustrationOpenRoad from "../ui/IllustrationOpenRoad.js";
import IllustrationDesert from "../ui/IllustrationDesert.js";
import { toastErrorMessage } from "../../helpers";
import RecordTable from "./components/RecordTable";
import ViewModeRadioButtonGroup from "./components/ViewModeRadioButtonGroup";

const PsRecordGrid = {
  handleOrderBy: function (cmp, event) {
    cmp.set("orderBy", event.property);
    cmp.set("orderDirection", event.sortDirection);
    cmp.handleReload();
  },

  //Shouldn't be calling this
  handleReset: function (cmp, event) {
    let oldValue = JSON.stringify(event.getParam("oldValue"));
    let newValue = JSON.stringify(event.getParam("value"));
    if (newValue && oldValue === newValue) {
      return;
    }

    //cmp.handleReset();  this is not right.. that is where it came from
  },

  handleLoadMore: function (cmp) {
    // cmp.getConcreteComponent().handleLoadMore();
    cmp.handleLoadMore();
  },

  //called from datatable, so might have to put function in between so that I can pass cmpWorking.current?
  // const handleRecordRowAction = function (event){
  // const handleRecordRowAction = function (row, action) {
  //   if (parentFunctions.handleRecordRowAction) {
  //     parentFunctions.handleRecordRowAction(row, action);
  //   } else {
  //     // var args = event.arguments || {};
  //     // var wrappedEvent = (args.event) ? args.event : event;
  //     // var action = wrappedEvent.action;
  //     // var row = wrappedEvent.row;
  //     var parentId = cmpWorking.parentId;
  //     var recordModule = cmpWorking.current.recordModule; // cmp.get('recordModule');
  //     var recordObject = cmpWorking.current.recordObject; // cmp.get('recordObject');
  //     switch (action) {
  //       case "details":
  //         notifyNavigation(parentId, recordModule, recordObject, row.id);
  //         break;
  //       case "viewSource":
  //         //no in sourceList, but it is in connectorList? do we need to have additional check?
  //         notifyNavigation("sources", "core", "source", row.source.id);
  //         break;
  //       default:
  //     }
  //   }
  // };

  handleRecordRowAction: function (item, action, cmp) {
    // tempId is added to avoid id issue and infinite loops on the Search page table view
    cmp.handleRecordRowAction({
      ...item,
      id: item.id?.includes("tempId") ? null : item.id,
      action: { name: action },
    });
  },

  // handleRefreshEvent: function (cmp) {
  //     cmp.getConcreteComponent().handleReload();
  // },

  handleStartEdit: function (cmp) {
    this.setMode(cmp, "edit");
  },

  handleCancelEdit: function (cmp) {
    this.setMode(cmp, "view");
    this.resetGrid(cmp);
  },

  handleDataCompEvent: function (cmp, event) {
    var data = event.data;
    if (data.action === "gridItemDeleted") {
      var gridItems = cmp.get("gridItems") || [];
      var gridItem = gridItems[data.order];
      if (gridItem) {
        var gridDiv = gridItem.getElement();
        if (gridDiv) {
          gridDiv.style.display = "none";
        }
      }
    }
  },

  //RecordGridHelper
  RECORD_COLUMNS: [
    {
      label: "Name",
      type: "link",
      property: "name",
      key: "name",
      sortype: true,
      action: "details",
    },
  ],

  setRecordColumns: function (cmp) {
    cmp.set("recordColumns", cmp.recordColumns);
  },

  //Is this useful? can call this directly from the component?
  parseResponse: function (cmp, response) {
    return cmp.parseResponse(response);
  },

  setMode: function (cmp, mode) {
    cmp.set("loading", false);
    cmp.set("loadingMore", false);
    if (mode === "empty") {
      cmp.set("mode", mode);
      //TODO Record.setElementAttribute(cmp, 'ViewModeButton', 'disabled', false);
      this.setDragMode(cmp, false);
    } else if (mode === "view") {
      // var recordList = cmp.get("recordList") || [];
      // if (recordList.length) {
      //   cmp.set("mode", "view");
      // } else {
      //   cmp.set("mode", "empty");
      // }

      var recordList = cmp.get("recordList") || [];
      const initialRecordList = cmp.get("initialRecordList");
      let newMode;

      if (initialRecordList) {
        newMode =
          recordList.length === 0 && initialRecordList.length === 0
            ? "empty"
            : "view";
      } else {
        newMode = recordList.length === 0 ? "empty" : "view";
      }
      cmp.set("mode", newMode);

      //TODO Record.setElementAttribute(cmp, 'ViewModeButton', 'disabled', false);
      this.setDragMode(cmp, false);
    } else if (mode === "error") {
      cmp.set("mode", mode);
      //TODO Record.setElementAttribute(cmp, 'ViewModeButton', 'disabled', false);
      this.setDragMode(cmp, false);
    } else if (mode === "edit") {
      cmp.set("mode", mode);
      this.setDragMode(cmp, true);
    }
  },

  setDragMode: function (cmp, enable) {
    if (enable) {
      cmp.set("itemView", "drag");
      cmp.set("isDragMode", true);
    } else {
      cmp.set("itemView", "grid");
      cmp.set("isDragMode", false);
    }

    // To be known in PsPatternChart when itemView is drap
    const records = cmp.get("initialRecordList");
    if (records) {
      const start = 0;
      if (cmp.get("gridComponent")) {
        this.addGridItems(cmp, records, start);
      }
    }
  },

  setLoading: function (cmp) {
    const recordList = cmp.get("recordList");

    if (recordList.length > 0) {
      cmp.set("loadingMore", true);
    } else {
      cmp.set("loading", true);
    }
  },

  reset: function (cmp) {
    cmp.set("recordList", []);
    cmp.set("gridItems", []); // IMPROVEMENT: check if existing dynamically created items need to be explicitly destroyed?
    cmp.set("gridComponents", []);
    cmp.set("hasMore", true);
    cmp.set("lastValue", null);
    cmp.set("lastId", null);

    cmp.set("itemView", "grid");
  },

  getRecords: function (cmp, numRecords) {
    if (!cmp.get("queryFilter")) {
      this.setMode(cmp, "error");
      return;
    }

    this.setLoading(cmp);

    var onSuccess = (response) => {
      var records = cmp.parseResponse(response);

      var recordList = cmp.get("recordList");

      // if there are no further records available, show toast and disable the 'Load More' button
      if (!records.length || records.length < numRecords) {
        if (recordList.length) {
          cmp.setToastState(
            "info",
            "No Further Records Available",
            "You have reached the end."
          );
        }
        cmp.set("hasMore", false);
      }

      // set last row for pagination from raw API response (before it is processed by 'parseResponse')
      if (response.length) {
        // set last value and id
        var lastRow = response[response.length - 1];
        var orderBy = cmp.get("orderBy") || "name";
        cmp.set("lastValue", lastRow[orderBy]);
        cmp.set("lastId", lastRow.id);
      }

      // add items
      if (records.length) {
        // append items from records to recordList
        var start = recordList.length;
        recordList.push.apply(recordList, records);

        // sort recordList
        if (recordList.length > 0) {
          try {
            const orderBy = cmp.get("orderBy") || "name";
            const orderDirection = cmp.get("orderDirection") || "asc";

            recordList.sort((a, b) => {
              const valueA =
                typeof a[orderBy] === "string"
                  ? a[orderBy].toUpperCase()
                  : a[orderBy];
              const valueB =
                typeof b[orderBy] === "string"
                  ? b[orderBy].toUpperCase()
                  : b[orderBy];

              let comparison = 0;
              if (valueA > valueB) {
                comparison = 1;
              } else if (valueA < valueB) {
                comparison = -1;
              }
              return orderDirection === "desc" ? comparison * -1 : comparison;
            });
          } catch (err) {
            console.error(err.stack);
          }
        }

        cmp.set("recordList", recordList);

        // create grid items (NB: these are created now, but initialized on first render)
        if (cmp.get("gridComponent")) {
          this.addGridItems(cmp, records, start);

          // const reload = cmp.get("reload");
          // this.addGridItems(cmp, records, reload ? 0 : start);
          // if (reload) {
          //   cmp.set("reload", "");
          // }
        }
      }

      // set record mode depending on whether any records are loaded
      this.setMode(cmp, "view");
    };

    var onError = function (response) {
      cmp.checkUser(response);
      cmp.setToastState("error", "Error", toastErrorMessage(response));
      PsRecordGrid.setMode(cmp, "error");
    };

    var recordModule = cmp.get("recordModule");
    var recordObject = cmp.get("recordObject");
    var queryFilter = Object.assign({}, cmp.get("queryFilter")); // copy queryFilter from component to prevent changes

    // add pagination filters
    if (numRecords > 0) {
      queryFilter.orderBy =
        cmp.get("orderBy") + " " + cmp.get("orderDirection").toUpperCase();

      queryFilter.lastValue = cmp.get("lastValue");
      queryFilter.lastId = cmp.get("lastId");

      queryFilter.maxRecords = numRecords;
    }

    Record.getRecords(
      recordModule,
      recordObject,
      queryFilter,
      onSuccess,
      onError
    );
  },

  handleDragStart: function (cmp, id) {
    cmp.set("draggedRecordId", id);
  },

  handleDragOver: function (e) {
    e.preventDefault();
  },

  handleDrop: function (cmp, records, targetId) {
    const draggedId = cmp.get("draggedRecordId");

    const draggedRecordIndex = records.findIndex(
      (record) => record.id === draggedId
    );

    const targetRecordIndex = records.findIndex(
      (record) => record.id === targetId
    );

    const newRecords = [...records];
    [newRecords[draggedRecordIndex], newRecords[targetRecordIndex]] = [
      newRecords[targetRecordIndex],
      newRecords[draggedRecordIndex],
    ];

    cmp.set("recordList", newRecords);
    PsRecordGrid.addGridItems(cmp, newRecords, 0);
  },

  addGridItems: function (cmp, records, start) {
    var gridComponents = !start || start === 0 ? [] : cmp.get("gridComponents");

    var componentName = cmp.get("gridComponent");

    function addConnectorTile(record) {
      gridComponents.push(
        <PsConnectorTile
          record={record}
          key={record.id}
          childToParent={cmp.childToParent}
        />
      );
    }

    function addPatternChart(record) {
      // When itemView is drap, you cannot go to chart detail
      const view = cmp.get("view");
      const itemView = cmp.get("itemView");
      const mode = cmp.get("mode");

      gridComponents.push(
        <div
          className="grid-item slds-m-horizontal_xx-small"
          key={record.id || record.treeHash}
          draggable={mode === "edit"}
          onDragStart={() => PsRecordGrid.handleDragStart(cmp, record.id)}
          onDragOver={(e) => PsRecordGrid.handleDragOver(e)}
          onDrop={() => PsRecordGrid.handleDrop(cmp, records, record.id)}
        >
          {/* <p>{record.treeHash}</p> */}
          <PsPatternChart
            record={record}
            view={view}
            itemView={itemView}
            childToParent={cmp.childToParent}
          />
        </div>
      );
    }

    function addSuggestionTile(record, index) {
      gridComponents.push(
        <div key={index}>
          <PsSuggestionTile
            record={record}
            key={record.id}
            childToParent={cmp.childToParent}
          />
        </div>
      );
    }

    if (componentName === "ConnectorTile") {
      records.forEach(addConnectorTile);
    } else if (componentName === "PatternChart") {
      records.forEach(addPatternChart);
    } else if (componentName === "PsSuggestionTile") {
      records.forEach((record, index) => addSuggestionTile(record, index));
    }
    cmp.set("gridComponents", gridComponents);
  },

  resetGrid: function (cmp) {
    // reset item order
    const initialRecordList = cmp.get("initialRecordList");
    cmp.set("recordList", initialRecordList);
    this.addGridItems(cmp, initialRecordList, 0);

    // var gridDiv;
    // var gridItems = cmp.get("gridItems");
    // for (let i = 0; i < gridItems.length; i++) {
    //   gridDiv = gridItems[i].getElement();
    //   if (gridDiv) {
    //     this.setOrder(gridDiv, i);
    //     gridDiv.style.display = "block";
    //   }
    // }
  },

  moveGridItem: function (cmp, fromOrder, toOrder) {
    var gridDiv, currentOrder;
    var gridItems = cmp.get("gridItems");
    //var fromItem;
    for (let i = 0; i < gridItems.length; i++) {
      gridDiv = gridItems[i].getElement();
      if (gridDiv) {
        currentOrder = this.getOrder(gridDiv);
        if (currentOrder === fromOrder) {
          //fromItem = gridDiv;
          this.setOrder(gridDiv, toOrder);
        } else if (currentOrder >= toOrder && currentOrder < fromOrder) {
          // move the items between fromOrder and toOrder up by one
          this.setOrder(gridDiv, currentOrder + 1);
        } else if (currentOrder > fromOrder && currentOrder <= toOrder) {
          // move the items between toOrder and fromOrder down by one
          this.setOrder(gridDiv, currentOrder - 1);
        }
      }
    }
    //this.setOrder(fromItem, toOrder);
    // IMPROVEMENT: automated scrolling can cause items the flip back and forth rapidly, and even seem to prevent users to be able to drag. Some options are to prevent scrolling, scroll automatically a bit up or down.
    //fromItem.scrollIntoView(false); // helps a bit with scrolling
  },

  getOrder: function (item) {
    return parseInt(item.style.order);
  },

  setOrder: function (item, value) {
    item.style.order = String(value);
  },

  getOrders: function (cmp) {
    var results = [];
    var gridDiv;
    var gridItems = cmp.get("gridItems");
    for (let i = 0; i < gridItems.length; i++) {
      gridDiv = gridItems[i].getElement();
      if (gridDiv) {
        results.push({
          oldOrder: i,
          newOrder: this.getOrder(gridDiv),
          deleted: gridDiv.style.display === "none",
        });
      }
    }
    // sort by (deleted, newOrder)
    var resultsSort = [...results];
    resultsSort.sort(function (a, b) {
      if (a.deleted === b.deleted) {
        return a.newOrder - b.newOrder;
      }
      return a.deleted ? 1 : -1;
    });
    // renumber newOrder, so that deleted items are moved to the end, and there are not gaps
    for (let i = 0; i < resultsSort.length; i++) {
      resultsSort.newOrder = i;
    }

    return results;
  },

  // Is this an old SF function? doesn't seem to be used?
  // fire event for navigation to record
  notifyNavigation: function (cmp, parentId, module, object, id = null) {
    let event = new Event("navigation");
    event.parentId = parentId;
    event.module = module;
    event.obj = object;
    event.id = id;
    event.source = "grid";

    cmp.handleEvent(event);
  },

  // fire event for changed record(s)
  notifyChanged: function (cmp, action, parentId, module, object, id, record) {
    // var recordChangedEvent = $A.get('e.c:RecordChangedEvent');
    // recordChangedEvent.setParams({action, parentId, module, obj: object, id, record});
    // recordChangedEvent.fire();
  },

  render: (cmp, cmpState) => {
    const showTitle = cmp.get("showTitle");
    const maxRecords = cmpState.maxRecords || cmp.get("maxRecords");
    let gridComponents = cmpState.gridComponents
      ? [...cmpState.gridComponents]
      : [];

    // add a few ghost charts to ensure spacing is correct
    if (cmpState.view === "grid") {
      gridComponents = [...gridComponents, ...PsRecordGrid.ghostCharts()];
    }

    // to avoid id issue and infinite loops on the Search page table view
    const items =
      cmpState.recordList?.length > 0
        ? cmpState.recordList.map((record, index) =>
            record.id
              ? record
              : {
                  ...record,
                  id: "tempId_" + (index + 1),
                }
          )
        : [];

    return (
      <Card
        id="recordGrid"
        className="slds-scrollable"
        style={{
          height:
            cmpState.showEmptyCallToAction && cmpState.mode === "empty"
              ? "auto"
              : "100%",
        }}
        heading={
          <b className="card-main-title-lh32">
            {showTitle &&
              (cmpState.title ? cmpState.title : cmpState.recordLabelPlural)}
          </b>
        }
        headerActions={
          <div style={{ display: "flex", gap: "5px" }}>
            {cmpState.showEdit &&
              cmpState.mode !== "edit" &&
              cmpState.mode !== "error" &&
              cmpState.title !== "Liked" && (
                <Button
                  label="Edit"
                  onClick={() => PsRecordGrid.handleStartEdit(cmp)}
                />
              )}
            {/* v.cardActions > passed in through props? */}{" "}
            {cmpState.mode !== "edit" &&
              cmpState.title !== "Liked" &&
              cmpState.cardActions}{" "}
            {cmpState.showCardActions ? cmp.cardActions() : null}
            {cmpState.changeView &&
              (cmpState.mode === "init" || cmpState.mode === "view") && (
                <ViewModeRadioButtonGroup cmp={cmp} cmpState={cmpState} />
              )}
            {cmpState.mode === "edit" && (
              <div>
                <Button
                  label="Cancel"
                  onClick={() => PsRecordGrid.handleCancelEdit(cmp)}
                />
                <Button
                  label="Save"
                  variant="brand"
                  onClick={() => cmp.handleSaveEdit(cmp)}
                />
              </div>
            )}
          </div>
        }
        footer={
          <>
            {cmpState.showEmptyCallToAction && cmpState.mode === "empty"
              ? cmp.emptyCallToAction()
              : null}
          </>
        }
      >
        {showTitle && cmpState.tagLine && (
          <div className="slds-p-around_medium">{cmpState.tagLine}</div>
        )}

        {/* header */}
        {cmpState.showHeader && cmp.header()}

        {/* empty */}
        {cmpState.mode === "empty" && (
          <div className="slds-is-relative">
            <div
              className="slds-p-around_medium slds-illustration slds-illustration_large"
              aria-hidden="true"
            >
              {/* <img
                src="/img/chatter/OpenRoad.svg"
                className="slds-illustration__svg"
                alt=""
              /> */}
              <IllustrationOpenRoad />
              <div className="slds-text-color_weak">
                <h3 className="slds-text-heading_medium">
                  {cmpState.emptyLine}
                </h3>
              </div>
            </div>
            {cmpState.loading && (
              <Spinner
                // size="small"
                // variant="base"
                assistiveText={{ label: "Loading" }}
              />
            )}
          </div>
        )}

        {/* cannot load records */}
        {cmpState.mode === "error" && (
          <div className="slds-is-relative">
            <div
              className="slds-p-around_medium slds-illustration slds-illustration_large"
              aria-hidden="true"
            >
              {/* <img
                src="/img/chatter/Desert.svg"
                className="slds-illustration__svg"
                alt=""
              /> */}
              <IllustrationDesert />
              <div className="slds-text-color_weak">
                <h3 className="slds-text-heading_medium">
                  Error while loading {cmpState.recordLabelPlural}
                </h3>
              </div>
            </div>
            {cmpState.loading && (
              <Spinner
                // size="small"
                // variant="base"
                assistiveText={{ label: "Loading" }}
              />
            )}
          </div>
        )}

        {/* records table / grid  */}
        {(cmpState.mode === "init" ||
          cmpState.mode === "view" ||
          cmpState.mode === "edit") && (
          <div className="slds-p-around_medium slds-is-relative">
            {cmpState.view === "table" && cmpState.recordList && (
              <RecordTable
                cmp={cmp}
                cmpState={cmpState}
                PsRecordGrid={PsRecordGrid}
              />
            )}

            {(cmpState.view === "grid" ||
              (cmpState.view === "table" && cmpState.changeView)) && (
              <div className={cmpState.view === "table" ? "slds-hide" : "grid"}>
                {gridComponents}

                {/* add a few ghost card to ensure spacing is correct */}
                {(cmpState.recordLabel === "Suggestion" ||
                  cmpState.recordLabelPlural === "Suggestions") &&
                  PsRecordGrid.ghostCards()}
              </div>
            )}

            {cmpState.loading && (
              <Spinner
                // size="small"
                // variant="base"
                assistiveText={{ label: "Loading" }}
              />
            )}

            {cmpState.showLoadMore && maxRecords > 0 && (
              <div className="slds-align_absolute-center slds-m-top_small">
                <Button
                  label={
                    <>
                      Load More
                      {cmpState.loadingMore ? <Spinner size="small" /> : null}
                    </>
                  }
                  disabled={cmpState.loading || !cmpState.hasMore}
                  onClick={() => PsRecordGrid.handleLoadMore(cmp)}
                />
              </div>
            )}
          </div>
        )}

        {/* footer? */}
        {/* insert emptyCallToAction */}

        {cmpState.showFooter && cmp.footer(cmpState)}
      </Card>
    );
  },

  // new functions
  ghostCharts: function () {
    const charts = Array.from({ length: 6 }, (_, index) => (
      <div
        key={Math.floor(Math.random() * 1000000).toString()}
        className="grid-item grid-item-ghost slds-m-horizontal_xx-small"
      >
        empty
      </div>
    ));

    return charts;
  },

  ghostCards: function () {
    const cards = Array.from({ length: 3 }, (_, index) => (
      <div
        key={Math.floor(Math.random() * 1000000).toString()}
        className="empty-card"
      ></div>
    ));

    return cards;
  },

  handlePsRecordGridWidth: function (cmp, psRecordGridRef) {
    if (psRecordGridRef.current) {
      const bounding = psRecordGridRef.current.getBoundingClientRect();
      cmp.set("psRecordGridWidth", bounding.width);
    }
  },
};

export default PsRecordGrid;
