import { useState, useEffect, useRef } from "react";
import { Button } from "@salesforce/design-system-react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import Record from "../../helpers/recordLayer";
import useWindowSize from "../../hooks/useWindowSize";

const PsConnectorList = (props) => {
  const [cmpState, setCmpState] = useState({
    // <!-- interface -->
    recordLabel: "Connector",
    recordLabelPlural: "Connectors",
    showTitle: true,
    showFooter: false,
    tagLine: "Connectors load your data into the Point Sigma analytics engine.",
    emptyLine: "Connect this Source using a new Connector",
    recordModule: "pump",
    recordObject: "connector",
    gridComponent: "ConnectorTile",
    gridComponents: [],
    gridItems: [],
    viewOptions: [
      { label: "Table", value: "table" },
      { label: "Grid", value: "grid" },
    ],
    changeView: true,
    showEdit: false, // TODO > value="{! v.queryFilter.id != 'liked' }"/>
    showLoadMore: true, // <!-- whether to show the 'Load More' button -->
    view: "grid",
    isDragMode: true,
    // footer" type="Aura.Component[]"/> <!-- add modal dialogs and other extra content -->

    draggedStart: null,
    draggedIndex: null,

    // <!-- querying -->
    parentPrefix: "",
    orderBy: "name", //TODO this needs to use the pattern's order
    orderDirection: "asc",
    lastValue: null,
    lastId: null,
    hasMore: true,

    // <!-- records -->
    mode: "empty", //"init",  // <!-- init, empty, view, error -->
    loading: true,
    recordColumns: [],
    recordList: [],
    recordDefaultSortDirection: "asc",

    //PsSourceList specific
    showEmptyCallToAction: true,
    showCardActions: true,

    loadingMore: false,

    newButtonLabel: "Create a Connector",

    psRecordGridWidth: 1535,
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);
  const psRecordGridRef = useRef(null);

  const { width } = useWindowSize();

  const { recordList, mode, recordLabel, loading } = cmpState;
  const { sourceOptions, selectedObject, sourceOptionsLoaded } = props;
  const noConnectorsNoSources =
    (recordList?.length === 0 || mode === "empty") && !sourceOptions?.length;

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
  }, []);

  useEffect(() => {
    PsRecordGrid.handlePsRecordGridWidth(cmp, psRecordGridRef);
  }, [width]);

  useEffect(() => {
    if (!props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent.action]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    cmp.setEmptyLineAndNewButtonLabel();

    if (!sourceOptionsLoaded) {
      props.parentCmp.loadSources();
    }
  }, [selectedObject, recordList, sourceOptions]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }

    if (props.queryFilter && Object.keys(props.queryFilter).length > 0) {
      cmp.handleReset();
    }
  }, [props.queryFilter]);

  const cmp = {
    // --- Get/Set ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    // --- ConnectorListController.js ---

    init: function () {
      cmp.afterScriptsLoaded();
    },

    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      var maxRecords = cmp.get("maxRecords");
      var numRecords = !maxRecords
        ? 0
        : Math.max(cmp.get("recordList").length, maxRecords);
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleReset: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleLoadMore: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    afterScriptsLoaded: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleRecordRowAction: function (row) {
      var parentId = cmp.get("parentId");
      var recordModule = cmp.get("recordModule");
      var recordObject = cmp.get("recordObject");

      switch (row.action.name) {
        case "details":
          PsRecordGrid.notifyNavigation(
            cmp,
            parentId,
            recordModule,
            recordObject,
            row.id
          );
          break;
        case "viewSource":
          PsRecordGrid.notifyNavigation(
            cmp,
            "sources",
            "pump",
            "source",
            row.source.id
          );
          break;
        default:
      }
    },

    handleNew: function (noConnectorsNoSources) {
      var parentId = cmp.get("parentId");
      var recordModule = cmp.get("recordModule");
      var recordObject = noConnectorsNoSources
        ? "source"
        : cmp.get("recordObject");
      const type = "navigation";

      PsRecordGrid.notifyNavigation(
        cmp,
        parentId,
        recordModule,
        recordObject,
        null,
        type
      );
    },

    // --- ConnectorListHelper.js ---

    RECORD_COLUMNS: [
      {
        key: "name", //fieldName
        label: "Name", //label
        property: "name", //fieldName
        type: "link", //if type = button, this value is link
        action: "details", //name within typeAttributes
        sortable: true,
      },
      {
        key: "sourceName", //fieldName
        label: "Source", //label
        property: "sourceName", //fieldName
        type: "link", //if type = button, this value is link
        action: "viewSource", //name within typeAttributes
        sortable: true,
      },
      {
        key: "connectorTypeName", //fieldName
        label: "Type", //label
        property: "connectorTypeName", //fieldName
        type: "text", //if type = button, this value is link
        sortable: false,
      },
      {
        key: "status", //fieldName
        label: "Status", //label
        property: "status", //fieldName
        type: "text", //if type = button, this value is link
        sortable: true,
      },
      {
        key: "schedule", //fieldName
        label: "Schedule", //label
        property: "schedule", //fieldName
        type: "text", //if type = button, this value is link
      },

      {
        key: "runEnd", //fieldName
        label: "Last Run Completed", //label
        property: "runEnd", //fieldName
        type: "formattedDate", //if type = button, this value is link
        sortable: true,
        width: 200,
      },
      {
        key: "runStatus", //fieldName
        label: "Last Run Status", //label
        property: "runStatus", //fieldName
        type: "text", //if type = button, this value is link
        sortable: true,
      },
    ],

    parseResponse: function (response) {
      var results = response.map(
        ({
          id,
          name,
          status,
          schedule,
          source,
          nextScheduledRun,
          runEnd,
          runStatus,
          runMessage,
          connectorType,
        }) => ({
          id,
          name,
          status,
          schedule: Record.cronToHuman(schedule),
          source,
          sourceName: source.name,
          nextScheduledRun,
          runEnd,
          runStatus,
          runMessage,
          connectorType,
          connectorTypeName: connectorType.name,
        })
      );
      return results;
    },

    // --- Functions that used to be only on RecordGrid ---

    // footer
    emptyCallToAction: () => {
      const title = `Create a new ${
        noConnectorsNoSources ? "Source" : recordLabel
      }`;

      return (
        <Button
          label={cmpState.newButtonLabel}
          title={title}
          onClick={() => cmp.handleNew(noConnectorsNoSources)}
          disabled={loading}
          variant="brand"
        />
      );
    },

    cardActions: function () {
      const mode = cmp.get("mode");
      return (
        <>
          {mode === "init" || mode === "view" ? (
            <Button
              label="New"
              title={`Create a new ${recordLabel}`}
              onClick={() => cmp.handleNew()}
              disabled={loading}
            />
          ) : null}
        </>
      );
    },

    // --- New functions ---

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({
        variant,
        heading,
        details,
      });
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },

    childToParent: (event) => {
      cmp.handleEvent(event);
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    setEmptyLineAndNewButtonLabel: function () {
      let label = "Create a Source";
      let emptyLine = "Start by creating a new Source";

      if (noConnectorsNoSources) {
        emptyLine = "Start by creating a new Source";
      } else if (selectedObject === "source") {
        emptyLine = "Connect this Source using a new Connector";
        label = "Create a Connector";
      } else if (sourceOptions?.length > 0 && recordList?.length === 0) {
        emptyLine = "Start by creating a new Connector";
        label = "Create a Connector";
      }

      cmp.set("emptyLine", emptyLine);
      cmp.set("newButtonLabel", label);
    },
  };

  return (
    <div
      ref={psRecordGridRef}
      style={{
        height:
          cmpState.showEmptyCallToAction && cmpState.mode === "empty"
            ? "auto"
            : "100%",

        marginTop: selectedObject === "source" ? 15 : 0,
      }}
    >
      {PsRecordGrid.render(cmp, cmpState)}
    </div>
  );
};

export default PsConnectorList;
