import { createContext, useContext, useEffect, useState } from "react";
import Record from "../helpers/recordLayer";
import useAuthContext from "./useAuthContext";
import { ACCOUNT_SETUP } from "../constants";
import RecordConstants from "../constants/RecordConstants";
const { STATUS_LIST, STATUS_PROGRESS_LIST } = ACCOUNT_SETUP;
const { OFF, METADATA, DATA, FULL, DEBUG } =
  RecordConstants.ACCOUNT_SETTINGS_GENERATIVE_AI;

// Type AccountType = null|{
//   id: string,
//   name: string,
//   status: string,
//   setupStatus: string,
//   settings: {
//     generativeAI: string,
//     enableGenerativeAIExplain: boolean,
//     enableGenerativeAISearch: boolean,
//   },
//   limits: {
//     deferUpdatesSeconds: number,
//     maxNumTables: number,
//     maxStorageBytes: number,
//     maxNumPatterns: number,
//     generativeAI: string,
//   },
//   usage: {
//     computeCredit: number,
//     totalStorageBytes: number,
//   },
//   createdOn: string,
//   createdBy: {
//     id: string,
//     name: string,
//   },
//   lastModifiedOn: string,
//   lastModifiedBy: {
//     id: string,
//     name: string,
//   },
// };

const AccountSettingsContext = createContext(null);

const useAccountSettingsContextProvider = () => {
  const module = "core";
  const object = "account";
  const [account, setAccount] = useState(null);
  const [accountSetupProgress, setAccountSetupProgress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, handleLogout } = useAuthContext();

  async function fetchAccount() {
    setIsLoading(true);
    try {
      const accountRes = await getAccountSettingsFromBackend();
      setAccount(accountRes);
    } catch (error) {
      console.error("Failed to fetch user details:", error);
      setAccount(null);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateAccountSetupProgress(doneStatus) {
    if (account?.setupStatus === "Complete") {
      const updatedAccountSetupProgress = {
        progress: 100,
        statusMap: "Complete",
        setupStatus: account.setupStatus,
        done: true,
      };
      setAccountSetupProgress(updatedAccountSetupProgress);
      return;
    }
    const doneStatusLocal = doneStatus || "Complete";
    let setupStatus = "Not Authenticated";
    let statusMap = "Not Authenticated";
    let progress = 0;

    try {
      // fetch again account settings and check status progress
      const accountRes = await getAccountSettingsFromBackend();
      // update account
      setAccount(accountRes);
      const accountSetupStatus = accountRes.setupStatus;
      if (accountSetupStatus && accountSetupStatus.trim().length > 0) {
        const match = STATUS_PROGRESS_LIST.find(
          (item) => item.setupStatus === accountSetupStatus
        );
        setupStatus = match?.setupStatus || "Error";
        statusMap = match?.statusMap || "Error";
        progress = match?.progress || 0;
      } else {
        setupStatus = "Authenticated";
        statusMap = "Authenticated";
      }
    } catch (err) {
      setupStatus = "Unable to retrieve account settings";
      statusMap = "Unable to retrieve account settings";
    }

    const statusMapIndex = STATUS_LIST.indexOf(statusMap);
    const doneStatusIndex = STATUS_LIST.indexOf(doneStatusLocal);
    const done = doneStatusIndex >= 0 && statusMapIndex >= doneStatusIndex;
    const updatedAccountSetupProgress = {
      statusMap,
      progress,
      setupStatus,
      done,
    };
    setAccountSetupProgress(updatedAccountSetupProgress);
    return;
  }

  async function getAccountSettingsFromBackend() {
    return new Promise((resolve, reject) => {
      try {
        function onSuccess(response) {
          if (response?.length > 0) {
            const record = response[0];
            resolve(record);
          } else {
            reject(new Error("No account found"));
          }
        }
        function onError(response) {
          // log out user if the response is No current user
          if (response === "No current user") {
            handleLogout();
          }
          reject(new Error("Failed to retrieve account"));
        }
        Record.getRecords(module, object, {}, onSuccess, onError);
      } catch (err) {
        console.error(err.stack);
        reject(new Error("Unexpected error occurred while fetching account"));
      }
    });
  }

  // hasGenerativeAIAccess accept 2 parameters value and minValue of generativeAI and return (boolean) true if the value is equal to or higher than minValue in the hierarchy generativeAI levels
  function hasGenerativeAIAccess(value, minValue) {
    // Hierarchical order from most to least restricted, please do NOT change order
    const levels = [
      OFF.value,
      METADATA.value,
      DATA.value,
      FULL.value,
      DEBUG.value,
    ];
    const valueIndex = levels.indexOf(value);
    const minValueIndex = levels.indexOf(minValue);
    return valueIndex >= minValueIndex;
  }

  useEffect(() => {
    // update account and accountSetupProgress every time isAuthenticated true and has been changed
    if (isAuthenticated) updateAccountSetupProgress();
  }, [isAuthenticated]);

  // To prevent side effects, reFetchAccount is only used within the account page.
  return {
    account,
    isLoading,
    accountSetupProgress,
    refetchAccount: fetchAccount,
    updateAccountSetupProgress,
    hasGenerativeAIAccess,
  };
};

export const AccountSettingsContextProvider = (props) => {
  const value = useAccountSettingsContextProvider();
  return (
    <AccountSettingsContext.Provider value={value}>
      {props.children}
    </AccountSettingsContext.Provider>
  );
};

const useAccountSettingsContext = () => {
  const context = useContext(AccountSettingsContext);
  if (!context)
    throw new Error(
      "You can't use useAccountSettingsContext() outside of <AccountSettingsContextProvider />"
    );
  return context;
};

export default useAccountSettingsContext;
