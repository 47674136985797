import Combobox from "@salesforce/design-system-react/components/combobox";
import { IconSettings } from "@salesforce/design-system-react";

import { regions } from "../../helpers";
import useAuthContext from "../../context/useAuthContext";

const RegionSelection = () => {
  const { user, setUser } = useAuthContext();
  const region = user.region;

  return (
    <>
      {regions().length > 1 ? (
        <div className="user-inputs">
          <IconSettings iconPath="/assets/icons">
            <Combobox
              events={{
                onSelect: (event, data) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    region: data.selection[0].value,
                  }));
                },
              }}
              labels={{
                label: "Instance",
                placeholderReadOnly: "Select Instance",
              }}
              options={regions()}
              selection={[regions().find((option) => option.value === region)]}
              value={region}
              variant="readonly"
            />
          </IconSettings>
        </div>
      ) : null}
    </>
  );
};

export default RegionSelection;
