import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import { AuthContextProvider } from "./context/useAuthContext";
import { AccountSettingsContextProvider } from "./context/useAccountSettingsContext";
import PsErrorBoundary from "./components/ps-error-boundary/PsErrorBoundary";
import { Settings } from "@salesforce/design-system-react";

// Set the app element to ensure that when a modal is open,
// all other content in the '#root' element is hidden from screen readers for accessibility
Settings.setAppElement("#root");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PsErrorBoundary >
    <AuthContextProvider>
      <AccountSettingsContextProvider>
        <BrowserRouter>
          <IconSettings iconPath="/assets/icons">
            <App />
          </IconSettings>
        </BrowserRouter>
      </AccountSettingsContextProvider>
    </AuthContextProvider>
  </PsErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
