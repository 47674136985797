const IllustrationDesert = () => {
  return (
    <div className="slds-illustration slds-illustration_small">
      <svg
        className="slds-illustration__svg"
        viewBox="0 0 468 194"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-67.000000, -112.000000)">
            <g>
              <g
                transform="translate(245.000000, 200.000000)"
                className="slds-illustration__stroke-secondary"
                strokeLinecap="round"
                strokeWidth="3"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M18,27.5 L83.0004985,27.5"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M0,27.5 L8,27.5"
                ></path>
              </g>
              <g
                transform="translate(135.000000, 152.000000)"
                className="slds-illustration__stroke-secondary"
                strokeLinecap="round"
                strokeWidth="3"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M18,27.5 L83.0004985,27.5"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M0,27.5 L8,27.5"
                ></path>
              </g>
              <g
                transform="translate(69.000000, 256.000000)"
                className="slds-illustration__stroke-secondary"
                strokeLinecap="round"
                strokeWidth="3"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M14,36.5 L464,36.5"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M0,36.5 L6,36.5"
                ></path>
                <polyline
                  vectorEffect="non-scaling-stroke"
                  strokeLinejoin="round"
                  points="234.5 36 279.5 0 313.5 26"
                ></polyline>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M279.5,1 L279.5,35"
                  strokeLinejoin="round"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M313.5,11 C313.5,20.7437888 313.5,25.7437888 313.5,26 C313.5,25.7437888 313.5,20.7437888 313.5,11 Z"
                  strokeLinejoin="round"
                ></path>
                <polyline
                  vectorEffect="non-scaling-stroke"
                  strokeLinejoin="round"
                  points="303.5 17 313.5 9 347.5 36"
                ></polyline>
              </g>
              <g transform="translate(113.000000, 178.000000)">
                <g
                  transform="translate(30.000000, 8.000000)"
                  className="slds-illustration__fill-secondary"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M29.5,60.5 L29.5,12 C29.5,5.372583 34.872583,0 41.5,0 C48.127417,0 53.5,5.372583 53.5,12 L53.5,40.5 L70.5,40.5 L70.5,27 C70.5,23.1340068 73.6340068,20 77.5,20 C81.3659932,20 84.5,23.1340068 84.5,27 L84.5,48.5 C84.5,51.8137085 81.8137085,54.5 78.5,54.5 L53.5,54.5 L53.5,118.5 L29.5,118.5 L29.5,74.5 L6.5,74.5 C3.1862915,74.5 0.5,71.8137085 0.5,68.5 L0.5,39 C0.5,35.1340068 3.63400675,32 7.5,32 C11.3659932,32 14.5,35.1340068 14.5,39 L14.5,60.5 L29.5,60.5 Z"
                  ></path>
                </g>
                <g transform="translate(59.000000, 7.000000)" fill="#FFFFFF">
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M2,11 C3.65332031,8.49145508 5.65181478,6.77364095 7.9954834,5.84655762 C11.5109863,4.45593262 15.2684326,4.95605469 17.8156738,6.2824707 C20.362915,7.60888672 22.3626709,9.64978027 23.2602539,11.81604 C23.8586426,13.2602132 23.8586426,11.7547201 23.2602539,7.29956055 L19.612793,3.56494141 L13.7923584,0.564331055 L8.765625,1.42663574 L4.67321777,3.19787598 C2.69893392,5.13902708 1.69559733,6.16722532 1.66320801,6.2824707 C1.61462402,6.45533878 0.856079102,9.49145508 0.813964844,9.66003418 C0.785888672,9.77242025 1.18123372,10.2190755 2,11 Z"
                  ></path>
                </g>
                <g
                  className="slds-illustration__stroke-primary"
                  strokeWidth="3"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M0,126.5 L356,126.5"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M362.5,126.5 L372.013149,126.5"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M107.5,20 L107.5,28"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M113,30.6568542 L118.656854,25"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M37.5,32 L37.5,40"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M44,42.6568542 L49.6568542,37"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M25,42.6568542 L30.6568542,37"
                    strokeLinecap="round"
                    transform="translate(27.828427, 39.828427) scale(-1, 1) translate(-27.828427, -39.828427) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M71.5,0 L71.5,8"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M79,10.6568542 L84.6568542,5"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M58,10.6568542 L63.6568542,5"
                    strokeLinecap="round"
                    transform="translate(60.828427, 7.828427) scale(-1, 1) translate(-60.828427, -7.828427) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M59.5,68.5 L59.5,20 C59.5,13.372583 64.872583,8 71.5,8 C78.127417,8 83.5,13.372583 83.5,20 L83.5,48.5 L100.5,48.5 L100.5,35 C100.5,31.1340068 103.634007,28 107.5,28 C111.365993,28 114.5,31.1340068 114.5,35 L114.5,56.5 C114.5,59.8137085 111.813708,62.5 108.5,62.5 L83.5,62.5 L83.5,126.5 L59.5,126.5 L59.5,82.5 L36.5,82.5 C33.1862915,82.5 30.5,79.8137085 30.5,76.5 L30.5,47 C30.5,43.1340068 33.6340068,40 37.5,40 C41.3659932,40 44.5,43.1340068 44.5,47 L44.5,68.5 L59.5,68.5 Z"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M77,88.5 L92.5,88.5 L92.5,81 C92.5,77.1340068 95.6340068,74 99.5,74 L99.5,74 C103.365993,74 106.5,77.1340068 106.5,81 L106.5,96.5 C106.5,99.8137085 103.813708,102.5 100.5,102.5 L77,102.5"
                    className="slds-illustration__fill-secondary"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </g>
              </g>
              <g
                transform="translate(429.000000, 242.000000)"
                className="slds-illustration__stroke-secondary"
                strokeLinecap="round"
                strokeWidth="3"
              >
                <g>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M12.5,4 L12.5,50"
                  ></path>
                  <polyline
                    vectorEffect="non-scaling-stroke"
                    strokeLinejoin="round"
                    points="11 18.5 0.5 18.5 0.5 0"
                  ></polyline>
                  <polyline
                    vectorEffect="non-scaling-stroke"
                    strokeLinejoin="round"
                    points="14 25.5 24.5 25.5 24.5 8"
                  ></polyline>
                  <polyline
                    vectorEffect="non-scaling-stroke"
                    strokeLinejoin="round"
                    points="2.5 31 2.5 38.5 11 38.5"
                  ></polyline>
                </g>
              </g>
              <g transform="translate(327.000000, 95.000000)">
                <g>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-secondary"
                    cx="64"
                    cy="64"
                    r="23"
                  ></circle>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__stroke-secondary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="135,1,1,18"
                    transform="translate(64.000000, 64.000000) rotate(230.000000) translate(-64.000000, -64.000000) "
                    cx="64"
                    cy="64"
                    r="45"
                  ></circle>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__stroke-secondary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="107,10"
                    transform="translate(64.000000, 64.000000) rotate(150.000000) translate(-64.000000, -64.000000) "
                    cx="64"
                    cy="64"
                    r="33"
                  ></circle>
                </g>
                <g
                  transform="translate(41.000000, 41.000000)"
                  className="slds-illustration__stroke-primary"
                  strokeWidth="3"
                >
                  <circle
                    vectorEffect="non-scaling-stroke"
                    cx="23"
                    cy="23"
                    r="23"
                  ></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default IllustrationDesert;
