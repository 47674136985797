import Button from "@salesforce/design-system-react/components/button";

import "../PsPatternChart";

const HeaderActionsIcons = ({
  recordId,
  selectedView,
  handleLikeClick,
  handleDislikeClick,
  handleSaveClick,
  handleViewDetails,
}) => {
  return (
    <div className="header-actions-icons" style={{ margin: 0 }}>
      {!!recordId && (
        <>
          <Button
            assistiveText={{ icon: "Icon hint large" }}
            iconCategory="utility"
            iconName="like"
            iconSize="large"
            variant="icon"
            onClick={handleLikeClick}
            title="Like"
          />
          <Button
            assistiveText={{ icon: "Icon hint large" }}
            iconCategory="utility"
            iconName="dislike"
            iconSize="large"
            variant="icon"
            onClick={handleDislikeClick}
            title="Dislike"
          />
        </>
      )}
      <Button
        assistiveText={{ icon: "Icon hint large" }}
        iconCategory="utility"
        iconName="save"
        iconSize="large"
        variant="icon"
        onClick={handleSaveClick}
        title="Save"
      />
      {selectedView === "grid" && (
        <Button
          assistiveText={{ icon: "Icon hint large" }}
          iconCategory="utility"
          iconName="expand_alt"
          iconSize="large"
          variant="icon"
          onClick={handleViewDetails}
          title="Details"
        />
      )}
    </div>
  );
};

export default HeaderActionsIcons;
