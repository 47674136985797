import { useEffect, useRef, useState } from "react";
import { Button } from "@salesforce/design-system-react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import Record from "../../helpers/recordLayer";
import { toastErrorMessage } from "../../helpers";
import useWindowSize from "../../hooks/useWindowSize";

const PsContainerList = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Object",
    recordLabelPlural: "Objects",
    emptyLine: "No Objects found",
    recordModule: "store",
    recordObject: "container",
    changeView: false,

    //////
    orderBy: "name",
    orderDirection: "asc",
    recordList: [],
    recordColumns: [],
    view: "table",
    showTitle: true,
    hasMore: false,
    mode: "init",
    showLoadMore: true,
    showEmptyCallToAction: true,

    loadingMore: false,
    psRecordGridWidth: 1535,
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);
  const psRecordGridRef = useRef(null);

  const { width } = useWindowSize();

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded(cmp);
  }, []);

  useEffect(() => {
    PsRecordGrid.handlePsRecordGridWidth(cmp, psRecordGridRef);
  }, [width]);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.handleReset();
  }, [props.recordId, props.queryFilter]);

  const cmp = {
    // --- ContainerListController.js ---

    init: function () {
      cmp.setRecordColumns(cmp);
    },

    afterScriptsLoaded: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      var maxRecords = cmp.get("maxRecords");
      var numRecords = !maxRecords
        ? 0
        : Math.max(cmp.get("recordList").length, maxRecords);
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleReset: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleLoadMore: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleRunSupply: function () {
      cmp.actionRunSupply();
    },

    handleRecordRowAction: function (row) {
      const action = row.action.name;
      var parentId = cmp.get("parentId");
      var recordModule = cmp.get("recordModule");
      var recordObject = cmp.get("recordObject");
      switch (action) {
        case "details":
          cmp.notifyNavigation(parentId, recordModule, recordObject, row.id);
          break;
        default:
      }
    },

    // --- ContainerListHelper.js ---

    RECORD_COLUMNS: [
      {
        key: "name",
        label: "Name",
        property: "name",
        type: "link",
        action: "details",
        sortable: true,
      },
      {
        key: "numRows",
        label: "# Rows",
        property: "numRows",
        type: "number",
        width: 110,
      },
      //{ label: 'Relevance', fieldName: 'relevance', type: 'number', sortable: true},
      {
        key: "dataLastChangedOn",
        label: "Latest Data",
        property: "dataLastChangedOn",
        type: "formattedDate",
        width: 200,
      },
    ],

    parseResponse: function (response) {
      return response.map(
        ({ id, name, source, numRows, relevance, dataLastChangedOn }) => ({
          id,
          name,
          sourceName: source.name,
          numRows,
          relevance,
          dataLastChangedOn,
        })
      );
    },

    actionRunSupply: function () {
      var previousMode = cmp.get("mode");
      PsRecordGrid.setLoading(cmp);
      var onError = function (response) {
        cmp.checkUser(response);
        cmp.setToastState("error", "Error", toastErrorMessage(response));
        PsRecordGrid.setMode(cmp, previousMode);
      };
      var onSucces = function () {
        cmp.setToastState(
          "info",
          "Data Processing Started",
          "Started processing data, this may take a few moments"
        );
        PsRecordGrid.setMode(cmp, previousMode);
      };
      Record.doAction("store", "data", "supply", {}, onSucces, onError);
    },

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    setRecordColumns: function () {
      cmp.set("recordColumns", cmp.RECORD_COLUMNS);
    },

    notifyNavigation: function (parentId, module, object, id) {
      let event = new Event("navigation");
      event.parentId = parentId;
      event.module = module;
      event.obj = object;
      event.id = id;
      event.source = "grid";

      props.childToParent(event);
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    emptyCallToAction: function () {
      return (
        <Button
          // id="ViewModeButton"
          disabled={cmpState.loading}
          label="Process Data"
          title="Start processing data"
          onClick={cmp.handleRunSupply}
          variant="brand"
        />
      );
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },
  };

  return (
    <div
      ref={psRecordGridRef}
      style={{
        height:
          cmpState.showEmptyCallToAction && cmpState.mode === "empty"
            ? "auto"
            : "100%",

        marginTop: 15,
      }}
    >
      {PsRecordGrid.render(cmp, cmpState)}
    </div>
  );
};

export default PsContainerList;
