import {
  Button,
  Input,
  ProgressBar,
  Tooltip,
} from "@salesforce/design-system-react";

const BetweenDateTime = ({ filterState, handleChangeBetweenDateTime }) => {
  return (
    <>
      {/* Min DateTime */}
      <div className="slds-form__item" role="listitem">
        <div className="slds-form-element slds-form-element_stacked">
          {filterState.mode === "init" && (
            <>
              <span className="slds-form-element__label">&nbsp;</span>
              <div className="slds-form-element__static">
                <ProgressBar value="0" variant="circular" />
              </div>
            </>
          )}

          {filterState.mode === "view" && (
            <div className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
              <span className="slds-form-element__label">From</span>
              <div className="slds-form-element__control">
                <div className="slds-form-element__static">
                  <div className="slds-form-element__static">
                    {/* <LightningFormattedDateTime
                        value={v.minDate}
                        year="numeric"
                        month="long"
                        day="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                      /> */}
                  </div>
                </div>
                <div className="slds-button slds-button__icon slds-button__icon_hint">
                  <Button
                    auraId="EditRecord"
                    iconName="utility:edit"
                    variant="bare"
                    alternativeText="Edit"
                    // onClick={c.handleEdit}
                  />
                </div>
              </div>
            </div>
          )}
          {(filterState.mode === "new" || filterState.mode === "edit") && (
            <Input
              auraId="checkField"
              type="datetime-local"
              name="mindate"
              autoComplete="off"
              label="From"
              required={false}
              value={filterState.minDateLocal}
              onChange={(event, data) =>
                handleChangeBetweenDateTime(data.value, "minDate")
              }
              fieldLevelHelpTooltip={
                <Tooltip
                  id="field-level-help-tooltip"
                  align="top left"
                  content="Minimum date and time (included). Leave empty to ignore."
                />
              }
            />
          )}
        </div>
      </div>

      {/* Max DateTime */}
      <div className="slds-form__item" role="listitem">
        <div className="slds-form-element slds-form-element_stacked">
          {filterState.mode === "init" && (
            <>
              <span className="slds-form-element__label">&nbsp;</span>
              <div className="slds-form-element__static">
                <ProgressBar value="0" variant="circular" />
              </div>
            </>
          )}

          {filterState.mode === "view" && (
            <div className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
              <span className="slds-form-element__label">To</span>
              <div className="slds-form-element__control">
                <div className="slds-form-element__static">
                  <div className="slds-form-element__static">
                    {/* <LightningFormattedDateTime
                              value={v.maxDate}
                              year="numeric"
                              month="long"
                              day="numeric"
                              hour="2-digit"
                              minute="2-digit"
                              second="2-digit"
                        /> */}
                  </div>
                </div>
                <div className="slds-button slds-button__icon slds-button__icon_hint">
                  <Button
                    auraId="EditRecord"
                    iconName="utility:edit"
                    variant="bare"
                    alternativeText="Edit"
                    // onClick={c.handleEdit}
                  />
                </div>
              </div>
            </div>
          )}

          {(filterState.mode === "new" || filterState.mode === "edit") && (
            <Input
              auraId="checkField"
              type="datetime-local"
              name="maxdate"
              autoComplete="off"
              label="To"
              required={false}
              value={filterState.maxDateLocal}
              onChange={(event, data) =>
                handleChangeBetweenDateTime(data.value, "maxDate")
              }
              fieldLevelHelpTooltip={
                <Tooltip
                  id="field-level-help-tooltip"
                  align="top left"
                  content="Maximum date and time (excluded). Leave empty to ignore."
                />
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BetweenDateTime;
