import { useEffect, useRef, useState } from "react";
import { Button } from "@salesforce/design-system-react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import Record from "../../helpers/recordLayer";
import { toastErrorMessage } from "../../helpers";
import useWindowSize from "../../hooks/useWindowSize";

const PsChainList = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Path",
    recordLabelPlural: "Paths",
    emptyLine: "No Paths found",
    recordModule: "store",
    recordObject: "chain",
    changeView: false,

    //////
    orderBy: "name",
    orderDirection: "asc",
    recordList: [],
    recordColumns: [],
    view: "table",
    showTitle: true,
    hasMore: false,
    mode: "init",
    showLoadMore: true,
    showCardActions: true,
    showEmptyCallToAction: true,

    loadingMore: false,
    psRecordGridWidth: 1535,
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);
  const psRecordGridRef = useRef(null);

  const { width } = useWindowSize();

  useEffect(() => {
    cmpWorking.current = { ...cmpState };

    cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    PsRecordGrid.handlePsRecordGridWidth(cmp, psRecordGridRef);
  }, [width]);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.handleReset();
  }, [props.parentId, props.queryFilter]);

  const cmp = {
    // --- ChainListController.js ---

    init: function () {
      PsRecordGrid.setRecordColumns(cmp);
    },

    afterScriptsLoaded: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      var maxRecords = cmp.get("maxRecords");
      var numRecords = !maxRecords
        ? 0
        : Math.max(cmp.get("recordList").length, maxRecords);
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleReset: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleLoadMore: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleRunTack: function () {
      cmp.actionRunTack(cmp);
    },

    handleNew: function () {
      try {
        var parentNav =
          (cmp.get("parentPrefix") || "") + (cmp.get("parentId") || "");
        var recordModule = cmp.get("recordModule");
        var recordObject = cmp.get("recordObject");
        PsRecordGrid.notifyNavigation(
          cmp,
          parentNav,
          recordModule,
          recordObject,
          null
        );
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRecordRowAction: function (row) {
      const action = row.action.name;
      var parentId = cmp.get("parentId");
      var recordModule = cmp.get("recordModule");
      var recordObject = cmp.get("recordObject");
      switch (action) {
        case "details":
          PsRecordGrid.notifyNavigation(
            cmp,
            parentId,
            recordModule,
            recordObject,
            row.id
          );
          break;
        default:
      }
    },

    // --- ChainListHelper.js ---

    RECORD_COLUMNS: [
      {
        key: "name",
        label: "Name",
        type: "link",
        property: "name",
        action: "details",
      },
      {
        key: "custom",
        label: "Custom",
        width: 80,
        property: "custom",
        type: "boolean",
      },
      {
        key: "leftContainerName",
        label: "From",
        width: 200,
        property: "leftContainerName",
        type: "text",
        sortable: true,
      },
      {
        key: "rightContainerName",
        label: "To",
        width: 200,
        property: "rightContainerName",
        type: "text",
        sortable: true,
      },
      {
        key: "relevance",
        label: "Relevance",
        width: 110,
        property: "relevance",
        type: "number",
        sortable: true,
      },
    ],

    parseResponse: function (response) {
      return response.map(
        ({ id, name, custom, leftContainer, rightContainer, relevance }) => ({
          id,
          name,
          custom,
          leftContainerName: leftContainer.name,
          rightContainerName: rightContainer.name,
          relevance,
        })
      );
    },

    actionRunTack: function () {
      try {
        var previousMode = cmp.get("mode");
        PsRecordGrid.setLoading(cmp);
        var onError = function (response) {
          cmp.checkUser(response);
          cmp.setToastState("error", "Error", toastErrorMessage(response));
          PsRecordGrid.setMode(cmp, previousMode);
        };

        var onSuccess = function () {
          props.setToastState({
            variant: "info",
            heading: "Path finding started",
            details: "Started searching for Paths, this may take a few moments",
          });
          PsRecordGrid.setMode(cmp, previousMode);
        };
        Record.doAction("store", "data", "tack", {}, onSuccess, onError);
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    cardActions: function () {
      return (
        <>
          {cmpState.mode === "init" || cmpState.mode === "view" ? (
            <Button
              disabled={cmpState.loading}
              label="New"
              title={"Create a new " + cmpState.recordLabel}
              onClick={() => cmp.handleNew()}
            />
          ) : null}
        </>
      );
    },

    emptyCallToAction: function () {
      return (
        <>
          <Button
            // id="ViewModeButton"
            disabled={cmpState.loading}
            label="Find Paths"
            title="Start finding Paths"
            onClick={() => cmp.handleRunTack()}
            variant="brand"
          />
          <Button
            // id="ViewModeButton"
            disabled={cmpState.loading}
            label="Create Path"
            title={!"Create a new " + cmpState.recordLabel}
            onClick={() => cmp.handleNew()}
            variant="brand"
          />
        </>
      );
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },
  };

  return (
    <div
      ref={psRecordGridRef}
      style={{
        height:
          cmpState.showEmptyCallToAction && cmpState.mode === "empty"
            ? "auto"
            : "100%",

        marginTop: 15,
      }}
    >
      {PsRecordGrid.render(cmp, cmpState)}
    </div>
  );
};

export default PsChainList;
