import { useEffect, useState } from "react";
import Record from "../../../helpers/recordLayer";
import SetupPanel from "../setup-panel/SetupPanel";
import AccountForm from "./AccountForm";
import { PAGE_ROUTES } from "../../../constants";
import ToastComponent from "../../../components/toast-component";
import useAccountSettingsContext from "../../../context/useAccountSettingsContext";

const emptyToastState = {
  variant: "",
  heading: "",
  details: "",
};

const Account = () => {
  const [isEdit, setIsEdit] = useState(false);
  const { module, object } = PAGE_ROUTES.SETUP.ACCOUNT;
  const [accountValues, setAccountValues] = useState(null); // accountValues is same type with formValues and can be updated by the user
  const [readOnlyValues, setReadOnlyValues] = useState(null); // This values can NOT be updated by the user
  const [toastState, setToastState] = useState(emptyToastState);
  const [isLoading, setIsLoading] = useState(false);
  const {
    account,
    isLoading: loading,
    refetchAccount,
  } = useAccountSettingsContext();

  // values parameter is same type with accountValues
  async function onSaveAccountData(values) {
    setIsLoading(true);
    const onSuccess = (_res) => {
      setToastState({
        variant: "success",
        heading: "Account Information Saved",
        details: "Account information has been successfully saved.",
      });
      refetchAccount(); // refetch Account details
      setIsEdit(false);
      setIsLoading(false);
    };
    const onError = (err) => {
      const errMessage =
        err?.data?.message ||
        "User information could not be saved. Please try again.";
      setToastState({
        variant: "error",
        heading: "Save Failed",
        details: errMessage,
      });
      setIsLoading(false);
      return true;
    };
    try {
      Record.submitRecord(
        module,
        object,
        {
          id: values.id,
          settings: {
            enableGenerativeAIExplain: values.enableGenerativeAIExplain,
            enableGenerativeAISearch: values.enableGenerativeAISearch,
            generativeAI: values.generativeAI,
          },
        },
        onSuccess,
        onError
      );
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  }

  async function childToParent(event) {
    try {
      if (event.type === "navigation") {
        // Case that child action is navigation
      } else if (event.type === "dataCompEvent") {
        // Case that child action is dataCompEvent -> Do something
      } else if (event.type === "reload") {
        refetchAccount();
      }
    } catch (err) {
      console.error(err.stack);
    }
  }

  useEffect(() => {
    // update the
    if (!!account) {
      const resData = account;
      const readOnly = {
        name: resData?.name || "", // string
        setupStatus: resData?.setupStatus || "", // string
        generativeAI: resData.settings?.generativeAI || "", // string --->Not using in read only for now
        enableGenerativeAIExplain:
          resData?.settings?.enableGenerativeAIExplain || false, // boolean --->Not using in read only for now
        enableGenerativeAISearch:
          resData?.settings?.enableGenerativeAISearch || false, // boolean --->Not using in read only for now
        computeCredit: resData?.usage?.computeCredit || 0, //number
      };

      const editableValues = {
        id: resData?.id,
        generativeAI: resData.settings?.generativeAI || "", // string
        enableGenerativeAIExplain:
          resData?.settings?.enableGenerativeAIExplain || false, // boolean
        enableGenerativeAISearch:
          resData?.settings?.enableGenerativeAISearch || false, // boolean
      };
      setReadOnlyValues(readOnly);
      setAccountValues(editableValues);
    }
  }, [account]);

  return (
    <SetupPanel childToParent={childToParent}>
      <AccountForm
        loading={isLoading || loading}
        formValues={accountValues}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        onSave={onSaveAccountData}
        readOnlyValues={readOnlyValues}
      />
      {!!toastState.details ? (
        <ToastComponent
          close={() => setToastState(emptyToastState)}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </SetupPanel>
  );
};

export default Account;
