//This replaces the PatternFolderGrid
import { useState, useEffect, useRef } from "react";
import { Dropdown, Input } from "@salesforce/design-system-react";

import Record from "../../helpers/recordLayer";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import DownIcon from "../ui/DownIcon";
import Modal from "../ui/Modal";
import { toastErrorMessage } from "../../helpers";
import useWindowSize from "../../hooks/useWindowSize";

const PsDashboardGrid = (props) => {
  const [cmpState, setCmpState] = useState({
    // <!-- interface -->
    recordLabel: "Pattern",
    recordLabelPlural: "Patterns",
    title: props.selectedFolder.name, //use folderTitle
    // header" type="Aura.Component[]"/> <!-- extra content at top-->
    showTitle: true,
    showFooter: true,
    showHeader: true,
    tagLine: "",
    emptyLine: "This folder does not contain patterns yet",
    recordModule: "relate",
    recordObject: "pattern",
    gridComponent: "PatternChart",
    gridComponents: [],
    cardActions: null, //[],
    emptyCallToAction: [],
    gridItems: [],
    viewOptions: [
      { label: "Table", value: "table" },
      { label: "Grid", value: "grid" },
    ],
    changeView: true, // <!-- whether to render radiobuttons that change the default view -->
    showEdit: false, // TODO > value="{! v.queryFilter.id != 'liked' }"/>
    showLoadMore: true, // <!-- whether to show the 'Load More' button -->
    view: "grid", // props.view, // <!-- table vs grid --> // TODO: just use props. need to be able to change this from parent
    itemView: "grid", // <!-- send to each grid item -->
    isDragMode: true,
    // footer" type="Aura.Component[]"/> <!-- add modal dialogs and other extra content -->

    draggedStart: null,
    draggedIndex: null,

    // <!-- querying -->
    parentId: "",
    parentPrefix: "",
    // queryFilter:[], //use props
    orderBy: "relevance", //TODO this needs to use the pattern's order
    orderDirection: "asc",
    lastValue: "",
    lastId: "",
    maxRecords: 0, //TESTING ONLY, this should be 0     //<!-- number of records to load per API call, set to 0 to load all records -->
    hasMore: true,

    // <!-- records -->
    mode: "empty", //"init",  // <!-- init, empty, view, error -->
    loading: true,
    recordColumns: [],
    recordList: [],
    initialRecordList: [],
    recordDefaultSortDirection: "asc",

    //PatternGrid specific
    showDeleteConfirmDialog: false,
    folder: props.selectedFolder, // {}, //initialise with props.folder? we need to be able to update the folder and parent and this component need to be able to continue with updated values
    folderDescription: props.selectedFolder.description,

    // drag-drop
    draggedRecordId: "",

    loadingMore: false,
    psRecordGridWidth: 1535,
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);
  const psRecordGridRef = useRef(null);

  const { width } = useWindowSize();

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
  }, []);

  useEffect(() => {
    PsRecordGrid.handlePsRecordGridWidth(cmp, psRecordGridRef);
  }, [width]);

  useEffect(() => {
    cmp.set("title", props.selectedFolder.name);
    cmp.set("folderDescription", props.selectedFolder.description);
  }, [props.selectedFolder]);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }

    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }

    cmp.handleReset();
  }, [props.queryFilter?.folderId]);

  const cmp = {
    // --- DashboardGridController.js ---

    init: function () {
      PsRecordGrid.setRecordColumns(cmp);

      this.afterScriptsLoaded();
      cmp.set("cardActions", dropdownMenu);
    },

    afterScriptsLoaded: function () {
      var numRecords = cmp.get("maxRecords");
      if (props.queryFilter && Object.keys(props.queryFilter).length > 0) {
        this.getRecords(numRecords);
        return;
      }
      cmp.set("loading", false);
    },

    // called when refresh button is clicked
    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      var maxRecords = cmp.get("maxRecords");
      var numRecords = !maxRecords
        ? 0
        : Math.max(cmp.get("recordList").length, maxRecords);
      PsRecordGrid.reset(cmp);
      this.getRecords(numRecords);
    },

    //called when queryFilter changes
    handleReset: function () {
      try {
        var numRecords = cmp.get("maxRecords");
        PsRecordGrid.reset(cmp);
        this.getRecords(numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleLoadMore: function () {
      var numRecords = cmp.get("maxRecords");
      this.getRecords(numRecords);
    },

    handleMenuAction: function (event) {
      switch (event.value) {
        case "Delete":
          cmp.set("showDeleteConfirmDialog", true);
          break;
        default:
      }
    },

    handleSaveEdit: function () {
      PsRecordGrid.setLoading(cmp);
      this.saveFolder(cmp);
      this.deletePattern(cmp);
      this.saveItemsOrder(cmp);
    },

    handleRecordRowAction: function (row) {
      switch (row.action.name) {
        case "details":
          this.notifyDataComp(row);
          break;
        default:
          break;
      }
    },

    //   handleConfirmDialogDelete : function(cmp, event, helper) {
    //     helper.deleteFolder(cmp, event);
    //     cmp.set('v.showConfirmDialog', false);
    // },

    // handleConfirmDialogCancel : function(cmp, event, helper) {
    //     cmp.set('v.showConfirmDialog', false);
    // },

    // --- DashboardGridHelper.js ---

    RECORD_COLUMNS: [
      {
        key: "namePlain", //fieldName
        label: "Pattern", //label
        property: "namePlain", //fieldName
        type: "link", //if type = button, this value is link
        action: "details", //name within typeAttributes
      },
      {
        key: "relevance",
        label: "Relevance",
        property: "relevance",
        type: "percent",
        width: 120,
      },
      {
        key: "containerName",
        label: "Object",
        property: "containerName",
        type: "text",
        width: 200,
      },
      {
        key: "sourceName",
        label: "Source",
        property: "sourceName",
        type: "text",
        width: 200,
      },
    ],

    sortedRecords: function (records) {
      if (records.length === 0) {
        return records;
      }

      let sortedList = [];
      let recordsWithSortOrder = [];
      let recordsWithoutSortOrder = [];

      records.map((record) => {
        if (record.sortOrder) {
          recordsWithSortOrder.push(record);
        } else {
          recordsWithoutSortOrder.push(record);
        }
      });

      recordsWithSortOrder.sort((a, b) => a.sortOrder - b.sortOrder);

      recordsWithoutSortOrder.sort(
        (a, b) => new Date(a.createdOn) - new Date(b.createdOn)
      );

      sortedList = [...recordsWithSortOrder, ...recordsWithoutSortOrder];

      return sortedList;
    },

    //query filter should have the folder id to filter on
    getRecords: function (numRecords) {
      try {
        if (!cmp.get("queryFilter")) {
          PsRecordGrid.setMode(cmp, "error");
          return;
        }
        PsRecordGrid.setLoading(cmp);

        var onSuccess = function (response) {
          var records = cmp.sortedRecords(response);
          records = cmp.parseResponse(records);

          var recordList = cmp.get("recordList");
          cmp.set("showEdit", true);

          // set last row for pagination from raw API response (before it is processed by 'parseResponse')
          if (response.length) {
            // set last value and id
            var lastRow = response[response.length - 1];
            var orderBy = cmp.get("orderBy") || "name";
            cmp.set("lastValue", lastRow[orderBy]);
            cmp.set("lastId", lastRow.id);
          }

          cmp.set("initialRecordList", records);
          // add items
          if (records.length) {
            var start = 0;
            cmp.set("recordList", records);

            // create grid items (NB: these are created now, but initialized on first render)
            if (cmp.get("gridComponent")) {
              PsRecordGrid.addGridItems(cmp, records, start);
            }
          }

          // set record mode depending on whether any records are loaded
          PsRecordGrid.setMode(cmp, "view");
        };

        var onError = function (response) {
          cmp.checkUser(response);
          cmp.setToastState("error", "Error", toastErrorMessage(response));
          PsRecordGrid.setMode(cmp, "error");
        };

        var recordModule = cmp.get("recordModule");
        var recordObject = cmp.get("recordObject");
        var queryFilter = Object.assign({}, cmp.get("queryFilter")); // copy queryFilter from component to prevent changes

        Record.getRecords(
          recordModule,
          recordObject,
          queryFilter,
          onSuccess,
          onError
        );
      } catch (err) {
        console.error(err.stack);
      }
    },

    // fire event for selecting a pattern
    notifyDataComp: function (row) {
      var event = new Event("dataCompEvent");
      event.data = { action: "viewDetails", pattern: row };
      handleEvent(event);
    },

    showFolderSaveError: function () {
      cmp.set("loading", false);
      props.setToastState({
        heading: "Folder Save Error",
        details: "An error occurred while trying to update the folder",
        variant: "error",
      });
    },

    saveFolder: function () {
      //ability to update folder description
      try {
        const folderId = props.queryFilter.folderId;
        const description = cmp.get("folderDescription");
        const name = props.selectedFolder.name;

        // no change
        if (props.selectedFolder.description === description) {
          return;
        }

        var onSuccess = function (response) {
          const insertedFolder = response[0];
          if (insertedFolder.id) {
            cmp.set("itemView", "grid");
            // PsRecordGrid.setMode(cmp, "view");
          } else {
            cmp.showFolderSaveError();
          }
        };

        var onError = function (response) {
          cmp.checkUser(response);
          cmp.showFolderSaveError();
        };

        Record.updateRecord(
          "relate",
          "folder",
          JSON.stringify({
            name: name,
            description: description,
          }),
          folderId,
          onSuccess,
          onError
        );
      } catch (err) {
        console.error(err.stack);
        cmp.showFolderSaveError();
      }
    },

    saveItemsOrder: function () {
      try {
        const recordList = cmp.get("recordList");
        const initialRecordList = cmp.get("initialRecordList");
        const deletedRecordList = [];

        initialRecordList.forEach((initialItem) => {
          if (
            !recordList.find((recordItem) => recordItem.id === initialItem.id)
          ) {
            deletedRecordList.push(initialItem);
          }
        });

        if (recordList.length === 0 && deletedRecordList.length === 0) {
          PsRecordGrid.setMode(cmp, "view");
          return;
        }

        var onSuccess = function (response) {
          checkAndUpdateRecords();
        };

        var onError = function (response) {
          cmp.checkUser(response);
          cmp.showFolderSaveError();
        };

        let processedRecordsCount = 0;

        const checkAndUpdateRecords = function () {
          processedRecordsCount++;
          if (processedRecordsCount === recordList.length) {
            PsRecordGrid.setMode(
              cmp,
              recordList.length === 0 ? "empty" : "view"
            );
            cmp.set("initialRecordList", recordList);
            PsRecordGrid.addGridItems(cmp, recordList, 0);
          }
        };

        recordList.forEach((item, index) => {
          Record.updateRecord(
            "relate",
            "pattern",
            JSON.stringify({
              name: item.name,
              description: item.description || "",
              sortOrder: index + 1,
            }),
            item.id,
            onSuccess,
            onError
          );
        });
      } catch (err) {
        console.error(err.stack);
      }
    },

    deleteFolder: function () {
      try {
        const folderId = props.queryFilter.folderId;

        var onSuccess = function (response) {
          props.setToastState({
            variant: "success",
            heading: "Folder Deleted",
            details: "Folder was deleted successfully",
          });

          const event = new Event("deleteFolderEvent");
          cmp.childToParent(event);
        };

        var onError = function (response) {
          cmp.checkUser(response);
          cmp.folderDeleteError();
        };

        Record.deleteRecord("relate", "folder", folderId, onSuccess, onError);
      } catch (err) {
        console.error(err.stack);
        cmp.folderDeleteError();
      } finally {
        cmp.set("showDeleteConfirmDialog", false);
      }
    },

    deletePattern: function () {
      try {
        const recordList = cmp.get("recordList");
        const initialRecordList = cmp.get("initialRecordList");
        const deletedRecordList = [];

        initialRecordList.forEach((initialItem) => {
          if (
            !recordList.find((recordItem) => recordItem.id === initialItem.id)
          ) {
            deletedRecordList.push(initialItem);
          }
        });

        if (deletedRecordList.length === 0) {
          return;
        }

        var onSuccess = function (response) {
          checkAndUpdateRecords();
        };

        var onError = function (response) {
          cmp.checkUser(response);
          cmp.set("loading", false);
          cmp.setToastState("error", "Error", toastErrorMessage(response));
        };
        let processedRecordsCount = 0;

        const checkAndUpdateRecords = function () {
          processedRecordsCount++;
          if (processedRecordsCount === deletedRecordList.length) {
            PsRecordGrid.setMode(
              cmp,
              recordList.length === 0 ? "empty" : "view"
            );
            cmp.set("initialRecordList", recordList);
            PsRecordGrid.addGridItems(cmp, recordList, 0);
          }
        };

        deletedRecordList.forEach((item) => {
          Record.deleteRecord("relate", "pattern", item.id, onSuccess, onError);
        });
      } catch (err) {
        console.error(err.stack);
        cmp.folderDeleteError();
      } finally {
        cmp.set("showDeleteConfirmDialog", false);
      }
    },

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    childToParent: (event) => {
      handleEvent(event);
    },

    parseResponse: function (response) {
      return response.map(
        ({
          id,
          compositionId,
          name,
          description,
          treeHash,
          relevance,
          createdOn,
          lastRunOn,
          key,
          version,
        }) => ({
          id,
          compositionId,
          name,
          namePlain: Record.removeMarkup(name),
          nameMarkup: Record.markupToHtml(name),
          description,
          descriptionPlain: Record.removeMarkup(description),
          descriptionMarkup: Record.markupToHtml(description),
          treeHash,
          relevance,
          createdOn,
          lastRunOn,
          key,
          keyId: key.id,
          keyName: key.name,
          containerName: key.container.name,
          sourceName: key.container.source.name,
          version,
        })
      );
    },

    cancelDeleteFolder: function () {
      cmp.set("showDeleteConfirmDialog", false);
    },

    folderDeleteError: function () {
      cmp.set("loading", false);
      props.setToastState({
        variant: "error",
        heading: "Folder Delete Error",
        details: "An error occurred while trying to delete the folder",
      });
    },

    header: function () {
      return (
        <>
          {cmpState.mode === "edit" ? (
            <div className="slds-grid slds-m-around_medium slds-wrap">
              <div className="slds-col slds-size_1-of-1">
                <Input
                  type="text"
                  label="Folder Description"
                  autocomplete="off"
                  value={cmpState.folderDescription || "" || ""}
                  onChange={(e) => cmp.set("folderDescription", e.target.value)}
                />
              </div>
              {cmpState.recordList.length > 0 ? (
                <div className="slds-col slds-size_1-of-1">
                  <br />
                  Reorder tiles by dragging them to the desired position.
                </div>
              ) : null}
            </div>
          ) : (
            <div className="slds-m-around_medium">
              {cmpState.folderDescription}
            </div>
          )}
        </>
      );
    },

    footer: function () {
      return (
        <>
          {/* Confirmation Dialog */}
          {cmpState.showDeleteConfirmDialog ? (
            <Modal
              apply={cmp.deleteFolder}
              cancel={cmp.cancelDeleteFolder}
              header="Confirmation"
              modalContent="Are you sure you want to delete this folder?"
              applyButtonContent="Delete"
            />
          ) : null}
        </>
      );
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },
  };

  const dropdownMenu = (
    <Dropdown
      iconPosition="right"
      label={
        <div style={{ margin: "0 -7px 0 -7px" }}>
          <DownIcon />
        </div>
      }
      onSelect={(value) => cmp.handleMenuAction(value)}
      options={[{ label: "Delete", value: "Delete" }]}
      width="xx-small"
      align="right"
    />
  );

  const handleEvent = (event) => {
    let stopPropagation = false;

    if (event?.data?.action === "gridItemDeleted") {
      const deletedId = event.data.record.id;
      let records = cmp.get("recordList");
      records = records.filter((record) => record.id !== deletedId);

      cmp.set("recordList", records);

      PsRecordGrid.addGridItems(cmp, records, 0);
    } else if (!stopPropagation) {
      props.childToParent(event);
    }
  };

  return (
    <div
      ref={psRecordGridRef}
      style={{
        height:
          cmpState.showEmptyCallToAction && cmpState.mode === "empty"
            ? "auto"
            : "100%",
      }}
    >
      {PsRecordGrid.render(cmp, cmpState)}
    </div>
  );
};

export default PsDashboardGrid;
