export const COMPANY_NAME = "Point Sigma";

export const CARTESIAN_CHART_TYPES = [
  "bar",
  "horizontalBar",
  "line",
  "matrix",
  "scatter",
];
export const CIRCULAR_CHART_TYPES = ["doughnut", "pie", "polarArea", "radar"];
export const GEO_CHART_TYPES = ["choropleth"];

export const EMPTY_TOAST_STATE = {
  variant: "",
  heading: "",
  details: "",
};

// PAGE_SEO is using in the Ps-SEO component and title is required. Can add more information if is needed
export const PAGE_SEO = {
  default: {
    // default is the meta tags that has been used in index.html file
    title: COMPANY_NAME,
    description: "Point Sigma Instant Business Intelligence",
  },
  login: {
    title: `${COMPANY_NAME} - Login`,
  },
  signup: {
    title: `${COMPANY_NAME} - Signup`,
  },
  build: {
    title: `${COMPANY_NAME} - Build`,
  },
  dataManagement: {
    title: `${COMPANY_NAME} - Data Management`,
  },
  explore: {
    title: `${COMPANY_NAME} - Explore`,
  },
  forgotPassword: {
    title: `${COMPANY_NAME} - Forgot Password`,
  },
  home: {
    title: `${COMPANY_NAME} - Home`,
  },
  saveInsights: {
    title: `${COMPANY_NAME} - Save Insights`,
  },
  search: {
    title: `${COMPANY_NAME} - Search`,
  },
  store: {
    title: `${COMPANY_NAME} - Store`,
  },
  setup: {
    title: `${COMPANY_NAME} - Setup`,
  },
  profile: {
    title: `${COMPANY_NAME} - Profile`,
  },
};


export const PAGE_ROUTES = {
  SETUP: {
    DEFAULT: { module: "", object: "", path: "/setup" },
    USERS: { module: "core", object: "user", path: "/setup/core/user" }, // NOTE users section is default in setup page. (For now)
    ACCOUNT: { module: "core", object: "account", path: "/setup/core/account" },
    // Add other setup options
  },
  // Add other page routes
};

export const ACCOUNT_SETUP = {
  // ---> Please DO not change the order of the STATUS_LIST
  STATUS_LIST: [
    "Not Authenticated",
    "Authenticated",
    "Inventory Loaded",
    "Processing",
    "Onboarding",
    "Complete",
  ],
  // Map the account setupStatus (received from the server) to the corresponding statusMap and progress.
  STATUS_PROGRESS_LIST: [
    {
      statusMap: "Not Authenticated",
      setupStatus: "Not Authenticated",
      progress: 0,
    },
    { statusMap: "Authenticated", setupStatus: "Authenticated", progress: 0 },
    {
      statusMap: "Inventory Loaded",
      setupStatus: "Inventory Loaded",
      progress: 10,
    },
    { statusMap: "Processing", setupStatus: "Data Loaded", progress: 20 },
    {
      statusMap: "Processing",
      setupStatus: "Data Interpreted",
      progress: 30,
    },
    { statusMap: "Processing", setupStatus: "Data Connected", progress: 40 },
    {
      statusMap: "Processing",
      setupStatus: "Objects Connected",
      progress: 60,
    },
    {
      statusMap: "Onboarding",
      setupStatus: "Patterns Created",
      progress: 80,
    },
    { statusMap: "Onboarding", setupStatus: "No Results", progress: 100 },
    {
      statusMap: "Onboarding",
      setupStatus: "Relevance Model Trained",
      progress: 100,
    },
    { statusMap: "Complete", setupStatus: "Complete", progress: 100 },
  ],
};

