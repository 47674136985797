import { Input } from "@salesforce/design-system-react";
import { useEffect, useRef, useState } from "react";

import PsRecord from "../ps-record/PsRecord";
import Record from "../../helpers/recordLayer";
import EditButtonIcon from "../ui/EditButtonIcon";
import ProgressBarComponent from "../ui/ProgressBarComponent";
import DropDownMenu from "./components/DropDownMenu";
import UndoButtonIcon from "../ui/UndoButtonIcon";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import RecordConstants from "../../constants/RecordConstants";
import { formattedNumber, formattedDateTime } from "../../helpers";

const PsContainer = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Object",
    recordModule: "store",
    recordObject: "container",

    //
    loading: false,
    mode: "init",
    recordId: props.recordId,
    parentId: props.parentId,
    record: {
      name: "",
      status: "",
      restrict: "",
      scheduleReset: "",
      sourceName: "",
    },
    showEdit: true,
    showDelete: false,
    showCardActions: true,
    missingRequiredFields: [],
  });

  const cmpWorking = useRef({});

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.afterScriptsLoaded(cmp);
  }, [props.recordId]);

  useEffect(() => {
    if (!props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent.action]);

  const cmp = {
    // --- ContainerController.js ---
    init: function (cmp, event, helper) {},

    afterScriptsLoaded: function (cmp, event, helper) {
      cmp.set(
        "statusOptions",
        Object.values(RecordConstants.ENVIRONMENT_STATUS)
      );
      cmp.set(
        "restrictOptions",
        Object.values(RecordConstants.SOURCE_RESTRICT)
      );
      PsRecord.getRecord(cmp);
    },

    handleReload: function () {
      try {
        // helper.getRecord(cmp);
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleEdit: function (cmp, event, helper) {
      try {
        //  helper.setMode(cmp, 'edit');
        PsRecord.setMode(cmp, "edit");
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleCancel: function (cmp, event, helper) {
      try {
        // helper.cancelRecord(cmp);
        PsRecord.cancelRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleSubmit: function (cmp, event, helper) {
      try {
        // helper.submitRecord(cmp);
        PsRecord.submitRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleDelete: function (cmp, event, helper) {
      try {
        if (!cmp.get("v.scriptsLoaded")) {
          return;
        }
        Record.showToast(cmp, "Not Available", "", "info");
        // helper.deleteRecord(cmp); // NB: this navigates to parent record after successfull delete
      } catch (err) {
        console.error(err.stack);
      }
    },

    // Coming from PsContainerItem
    handleAction: function (action) {
      try {
        switch (action) {
          case "supply":
            cmp.actionSupply();
            break;
          case "conform":
            cmp.actionConform();
            break;
          case "tack":
            cmp.actionTack();
            break;
          default:
        }
      } catch (err) {
        console.error(err.stack);
      }
    },
    handleOverride: function (field) {
      try {
        PsRecord.setOverride(cmp, field);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRevert: function (field) {
      try {
        PsRecord.revertOverride(cmp, field);
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- ContainerHelper.js ---

    getDefaultRecord: function (cmp) {
      return {};
    },

    getOverrideFields: function () {
      return ["name"];
    },

    parseInputPlainText: function (record) {
      return (({ id, name, overridden, scheduleReset }) => ({
        id,
        name,
        overridden,
        scheduleReset,
      }))(record);
    },

    parseResponse: function (response) {
      return response.map(
        ({
          id,
          name,
          source,
          relevance,
          dataLastChangedOn,
          numRows,
          overridden,
          scheduleReset,
        }) => ({
          id,
          name,
          sourceId: source.id,
          sourceName: source.name,
          relevance,
          dataLastChangedOn,
          numRows,
          overridden,
          scheduleReset,
        })
      );
    },

    actionSupply: function () {
      var previousMode = cmp.get("mode");
      cmp.set("loading", true);
      var onError = function (response) {
        cmp.checkUser(response);
        const details = (
          <>
            {response?.data?.message && <>{response.data.message}</>}
            <br />
            {response?.data?.reference && <>{response.data.reference}</>}
            <br />
            {response?.data?.suggestion && <>{response.data.suggestion}</>}
          </>
        );

        props.setToastState({
          variant: "error",
          heading: "Error",
          details,
        });

        PsRecord.setMode(cmp, previousMode);
      };
      var onSucces = function () {
        props.setToastState({
          variant: "info",
          heading: "Data Processing Started",
          details: "Started processing data, this may take a few moments",
        });

        PsRecord.setMode(cmp, previousMode);
      };
      Record.doAction("store", "data", "supply", {}, onSucces, onError);
    },

    // Coming from PsContainerItem
    actionConform: function () {
      try {
        var previousMode = cmp.get("mode");
        PsRecord.setLoading(cmp);
        var onError = function () {
          PsRecord.setMode(cmp, previousMode);
        };
        var onSucces = function () {
          props.setToastState({
            variant: "info",
            heading: "Join finding started",
            details: "Started searching for Joins, this may take a few moments",
          });
          PsRecord.setMode(cmp, previousMode);
        };
        Record.doAction("store", "data", "conform", {}, onSucces, onError);
      } catch (error) {
        console.error(error.stack);
      }
    },

    // Coming from PsContainerItem
    actionTack: function () {
      var previousMode = cmp.get("mode");
      PsRecord.setLoading(cmp);
      var onError = function () {
        PsRecord.setMode(cmp, previousMode);
      };
      var onSucces = function () {
        props.setToastState({
          variant: "info",
          heading: "Path finding started",
          details: "Started searching for Paths, this may take a few moments",
        });
        PsRecord.setMode(cmp, previousMode);
      };

      Record.doAction("store", "data", "tack", {}, onSucces, onError);
    },

    setParent: function (cmp, record) {
      // var parentId = record.sourceId || (record.source || {}).id;
      // cmp.set("v.parentId", parentId);
      // return parentId;
    },

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    cardActions: function () {
      if (
        cmpState.mode !== "view" ||
        props.itemName === "filter" ||
        props.itemName === "map"
      ) {
        return;
      }

      return (
        <DropDownMenu
          handleAction={cmp.handleAction}
          itemName={props.itemName}
        />
      );
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    handleReprocessToggleChange: function () {
      let record = cmp.get("record");
      let scheduleReset = record.scheduleReset;
      scheduleReset =
        scheduleReset === undefined || !scheduleReset ? true : false;

      record = { ...record, scheduleReset };
      cmp.set("record", record);
    },

    checkForm: function () {
      try {
        const record = cmp.get("record");
        if (!record.name) {
          cmp.set("missingRequiredFields", ["record.name"]);
        }
        return !record.name;
      } catch (error) {
        console.error(error.stack);
      }
    },
    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },

    body: function () {
      return (
        <div className="slds-form slds-var-m-around_medium" role="list">
          <h3 className="slds-section-title--divider slds-var-m-top_medium">
            Object Details
          </h3>
          <div className="slds-form__row">
            {/* <!-- Name --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Name</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.name}
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon
                          handleEdit={() => cmp.handleEdit(cmp)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <div verticalalign="start" style={{ display: "flex" }}>
                    <div
                      flexibility="auto"
                      style={{ flexibility: "auto", width: "100%" }}
                    >
                      <Input
                        id="checkField"
                        name="name"
                        autoComplete="off"
                        label="Name"
                        required={true}
                        value={cmpState.record.name}
                        onChange={(e) =>
                          PsRecord.handleNameChange(cmp, e.target.value)
                        }
                        errorText={
                          cmp
                            .get("missingRequiredFields")
                            .includes("record.name")
                            ? "Complete this field."
                            : ""
                        }
                      />
                    </div>

                    {cmpState.record.hasOverride?.name && (
                      <UndoButtonIcon
                        handleRevert={cmp.handleRevert}
                        field="name"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="slds-form__row">
            {/* <!-- Source --> */}
            <div
              className="slds-form__item slds-grid_vertical-align-center"
              role="listitem"
            >
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {(cmpState.mode === "new" ||
                  cmpState.mode === "edit" ||
                  cmpState.mode === "view") && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Source</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.sourceName}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <!-- ScheduleReset --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Reprocess</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        <CheckAndCloseIcons
                          selectedItem={cmpState.record.scheduleReset}
                        />
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon
                          handleEdit={() => cmp.handleEdit(cmp)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <>
                    {/* Toggle */}
                    {/* <lightning:input
                    type="toggle"
                    variant="label-hidden"
                    aura:id="checkField"
                    name="scheduleReset"
                    checked="{!v.record.scheduleReset}"
                    value="{!v.record.scheduleReset}"
                    messageToggleActive="Yes"
                    messageToggleInactive="No"
                  /> */}
                    <div>
                      <span className="slds-form-element__label slds-m-bottom_none">
                        Reprocess
                      </span>
                      <label className="slds-checkbox_toggle slds-grid">
                        <input
                          type="checkbox"
                          checked={cmpState.record.scheduleReset || false} // scheduleReset comes as null from the backend. This was added to avoid the error in the console.
                          onChange={() => cmp.handleReprocessToggleChange()}
                        />
                        <span
                          id="checkbox-toggle-16"
                          className="slds-checkbox_faux_container"
                        >
                          <span className="slds-checkbox_faux"></span>
                          <span className="slds-checkbox_on">Yes</span>
                          <span className="slds-checkbox_off">No</span>
                        </span>
                      </label>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {(cmpState.mode === "init" || cmpState.mode === "view") && (
            <>
              <div className="slds-form__row">
                {/* <!-- DataLastChangedOn --> */}
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {cmpState.mode === "view" && (
                      <div
                        id="FormDiv"
                        className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                      >
                        <span className="slds-form-element__label">
                          Latest Data
                        </span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {/* <lightning:formattedDateTime value="{!v.record.dataLastChangedOn}" year="numeric" month="long" day="numeric" hour="2-digit" minute="2-digit" second="2-digit" /> */}
                            {formattedDateTime(
                              cmpState.record.dataLastChangedOn
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* <!-- Relevance --> */}
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {cmpState.mode === "view" && (
                      <div
                        id="FormDiv"
                        className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                      >
                        <span className="slds-form-element__label">
                          Relevance
                        </span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {formattedNumber(cmpState.record.relevance)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <h3 className="slds-section-title--divider slds-var-m-top_medium">
                Statistics
              </h3>
              <div className="slds-form__row">
                {/* <!-- NumRows --> */}
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {cmpState.mode === "view" && (
                      <div
                        id="FormDiv"
                        className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                      >
                        <span className="slds-form-element__label"># Rows</span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {formattedNumber(cmpState.record.numRows)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },
  };

  return PsRecord.render(cmp, cmpState);
};

export default PsContainer;
