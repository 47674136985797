import { useState, useEffect, useRef } from "react";

import Record from "../../helpers/recordLayer";
import { allSettings } from "./components/AllSettings";
import ChevronIcon from "../ui/ChevronIcon";
import "./PsNavigationTree.css";
import { ariaSelected } from "./components/Helper";
import ItemLabel from "./components/ItemLabel";
import { toastErrorMessage } from "../../helpers";
import { EMPTY_TOAST_STATE } from "../../constants";
import ToastComponent from "../toast-component";

function PsNavigationTree(props) {
  const [cmpState, setCmpState] = useState({
    selected: null,
    searchMode: false,
    filters: {},
    multiSelect: true,

    timer: 0,
    treeSettings: {},
    navTree: [],
    navMap: {},
    searchTree: [],
    searchMap: {},
    selectedNames: null,
    notifyNames: {},
    objects: [],
    loading: {},

    // added to avoid redundant api calls
    sources: [],
    sourcesLoaded: false,
  });
  const [toastState, setToastState] = useState(EMPTY_TOAST_STATE);

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);

  const init = () => {
    try {
      var navTree = [];
      var treeSettings = {};
      var objects = [];
      props.sections.forEach((section) => {
        var settings = allSettings[section];
        if (settings) {
          Object.keys(settings).forEach((config) => {
            var setting = settings[config];
            Object.assign(setting, { section, config });
            var childSetting = settings[setting.childConfig] || {};
            Object.assign(childSetting, { parentConfig: config });
            Object.keys(setting.records || {}).forEach((childConfig) => {
              var record = setting.records[childConfig];
              Object.assign(record, { childConfig });
              var childSetting = settings[record.childConfig] || {};
              Object.assign(childSetting, { parentConfig: config });
            });
          });
          var rootSetting = settings["root"];
          var root = createRootItem(rootSetting);

          var sectionExpanded =
            section === props.selectedSection || !props.selectedSection;
          navTree.push({
            name: section,
            items: [root],
            expanded: sectionExpanded,
            setting: {},
          });
          Object.values(settings).forEach((setting) => {
            if (setting.object) {
              objects.push(setting.object);
            }
          });
          treeSettings[section] = settings;
        }
      });

      // put items in navMap
      var navMap = navTree.reduce((obj, item) => {
        obj[item.items[0].name] = item.items[0];
        return obj;
      }, {});

      // cmp.set("sections", cmpWorking.current.sections);
      cmp.set("objects", Array.from(new Set(objects)));
      cmp.set("treeSettings", treeSettings);
      cmp.set("navTree", navTree);
      cmp.set("navMap", navMap);

      // afterScriptsLoaded
      parseSelected();
    } catch (err) {
      console.error(err.stack);
    }
  };

  useEffect(() => {
    cmpWorking.current = { ...cmpState };

    // We don't need to load sources on the Saved Insights page
    if (props.sections[0] === "folders") {
      init();
    } else {
      loadSources();
    }
  }, []);

  useEffect(() => {
    if (!cmpState.sourcesLoaded) {
      return;
    }

    init();
  }, [cmpState.sourcesLoaded]);

  useEffect(() => {
    if (isFirstRender.current || !cmpState.sourcesLoaded) {
      return;
    }
    onSelectedChange();
  }, [props.selected]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    cmp.set("filters", props.filters);
    onFiltersChange();
  }, [props.filters]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    if (!props.recordChangedEvent || !props.recordChangedEvent.action) {
      return;
    }
    handleRecordChangedEvent(props.recordChangedEvent);
  }, [props.recordChangedEvent]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    //---REVIEW---

    var treeName = cmpWorking.current.searchMode ? "searchTree" : "navTree";
    var itemTree = cmpWorking.current.searchMode
      ? cmpWorking.current.searchTree
      : cmpWorking.current.navTree;

    // var navTree = cmpWorking.current.navTree;
    // var navMap = cmpWorking.current.navMap;

    itemTree.forEach((rootItem) => {
      if (rootItem.name === props.selectedSection || !props.selectedSection) {
        rootItem.expanded = true;
      } else {
        rootItem.expanded = false;
      }

      // var root = rootItem.items[0];
      // if(rootItem.name === props.selectedSection){
      //   rootItem.expanded = true;
      //   loadChildren(navTree, navMap, root, true);
      // } else{
      //   //do something to hide existing items
      //   // loadChildren(navTree, navMap, root, false);
      //   rootItem.expanded = false;
      //   root.expanded = false;  //doesn't close it
      // }
    }); // reload all children for a root, and recursively refresh any previously loaded children

    cmp.set(treeName, itemTree);
  }, [props.selectedSection]);

  useEffect(() => {
    if (!props.parentToChildEvent?.action) {
      return;
    }
    handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent?.action]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }

    // cmp.set("searchText", props.searchText);
    cmp.set("isSearchSubmitted", props.isSearchSubmitted);
    onSearchTextChange();
  }, [props.searchText, props.isSearchSubmitted]);

  // ---------- cmp get & set ----------
  const cmp = {
    get: (key) => {
      if (props.hasOwnProperty(key)) return props[key];

      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },

    setToastState: function (variant, heading, details) {
      setToastState({ variant, heading, details });
    },
  };

  const onSelectedChange = () => {
    try {
      parseSelected();
    } catch (err) {
      console.error(err.stack);
    }
  };

  // TODO - review how event is to be picked up
  const onSearchTextChange = (event) => {
    try {
      if (checkSearch()) {
        if (
          cmpWorking.current.isSearchSubmitted ||
          props.searchText === null ||
          !props.searchText.length
        ) {
          doSearch();
        } else {
          delayedSearch();
        }
      } else {
        closeSearch();
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const onFiltersChange = () => {
    try {
      if (checkSearch()) {
        doSearch();
      } else {
        closeSearch();
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  // navigate to the selected item in the navigation tree
  const handleSelect = (selectedRoot) => {
    try {
      setSelected(selectedRoot.name);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleClickChevronIcon = (
    selectedRoot,
    parentRootName,
    treeItemLevel
  ) => {
    try {
      const selectedTreeItem = cmpWorking.current.searchMode
        ? "searchTree"
        : "navTree";
      if (treeItemLevel === "firstTreeItem") {
        //Replace with the following
        // cmp.set([selectedTreeItem], cmp[selectedTreeItem].map((prevNavTreeItem) => {
        //   if (selectedRoot.parentName.includes(prevNavTreeItem.name)) {
        //     prevNavTreeItem.items[0].items =
        //       prevNavTreeItem.items[0].items.map((item) => {
        //         if (item.id === selectedRoot.id) {
        //           return {
        //             ...item,
        //             expanded:
        //               item.expanded === undefined ? true : !item.expanded,
        //           };
        //         }
        //         return item;
        //       });
        //     return prevNavTreeItem;
        //   }
        //   return prevNavTreeItem;
        // }));

        setCmpState((prev) => ({
          ...prev,
          [selectedTreeItem]: prev[selectedTreeItem].map((prevNavTreeItem) => {
            if (selectedRoot.parentName.includes(prevNavTreeItem.name)) {
              prevNavTreeItem.items[0].items =
                prevNavTreeItem.items[0].items.map((item) => {
                  if (item.id === selectedRoot.id) {
                    return {
                      ...item,
                      expanded:
                        item.expanded === undefined ? true : !item.expanded,
                    };
                  }
                  return item;
                });
              return prevNavTreeItem;
            }
            return prevNavTreeItem;
          }),
        }));
      }
      if (treeItemLevel === "secondTreeItem") {
        setCmpState((prev) => ({
          ...prev,
          [selectedTreeItem]: prev[selectedTreeItem].map((prevNavTreeItem) => {
            if (selectedRoot.parentName.includes(prevNavTreeItem.name)) {
              prevNavTreeItem.items[0].items =
                prevNavTreeItem.items[0].items.map((item) => {
                  if (item.id === selectedRoot.parentId) {
                    item.items = item.items.map((nestedItem) => {
                      if (nestedItem.id === selectedRoot.id) {
                        return {
                          ...nestedItem,
                          expanded: !nestedItem.expanded,
                        };
                      }
                      return nestedItem;
                    });
                  }
                  return item;
                });
            }
            return prevNavTreeItem;
          }),
        }));
      }
      if (treeItemLevel === "thirdTreeItem") {
        setCmpState((prev) => ({
          ...prev,
          [selectedTreeItem]: prev[selectedTreeItem].map((prevNavTreeItem) => {
            if (selectedRoot.parentName.includes(prevNavTreeItem.name)) {
              prevNavTreeItem.items[0].items =
                prevNavTreeItem.items[0].items.map((item) => {
                  if (item.id === parentRootName[1].id) {
                    item.items = item.items.map((nestedItem) => {
                      if (nestedItem.id === selectedRoot.parentId) {
                        nestedItem.items = nestedItem.items.map(
                          (secondNestedItem) => {
                            if (secondNestedItem.id === selectedRoot.id) {
                              return {
                                ...secondNestedItem,
                                expanded: !secondNestedItem.expanded,
                              };
                            }
                            return secondNestedItem;
                          }
                        );
                      }
                      return nestedItem;
                    });
                  }
                  return item;
                });
            }
            return prevNavTreeItem;
          }),
        }));
      }
      if (treeItemLevel === "fourthTreeItem") {
        const navTree = cmp.get("navTree");

        const updatedNavigationTree = navTree.map((firstItem) => {
          if (selectedRoot.rootName.includes(firstItem.name)) {
            return {
              ...firstItem,
              items: [
                {
                  ...firstItem.items[0],
                  items: [
                    {
                      ...firstItem.items[0].items[0],
                      items: firstItem.items[0].items[0].items.map(
                        (secondItem) => {
                          if (secondItem.id === parentRootName[0].id) {
                            return {
                              ...secondItem,
                              items: secondItem.items.map((thirdItem) => {
                                if (thirdItem.id === parentRootName[1].id) {
                                  return {
                                    ...thirdItem,
                                    items: thirdItem.items.map((fifthItem) => {
                                      if (selectedRoot.id === fifthItem.id) {
                                        return {
                                          ...fifthItem,
                                          expanded: !fifthItem.expanded,
                                        };
                                      } else {
                                        return fifthItem;
                                      }
                                    }),
                                  };
                                }
                                return thirdItem;
                              }),
                            };
                          }
                          return secondItem;
                        }
                      ),
                    },
                  ],
                },
              ],
            };
          }
          return firstItem;
        });

        cmp.set("navTree", updatedNavigationTree);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  // update the navigation tree if a record changed (e.g., rename, delete)
  const handleRecordChangedEvent = (event) => {
    try {
      const action = event.action;
      const parentId = event.parentId;
      const object = event.object || "";
      const id = event.id || null;
      const record = event.obj || {};
      const sourceId = event.sourceId;
      const module = event.module;

      if (cmpWorking.current.objects.includes(object)) {
        if (action === "create") {
          upsertRecord(
            cmpWorking.current.navTree,
            cmpWorking.current.navMap,
            parentId,
            record
          );
          updateTree(
            "nav",
            cmpWorking.current.navTree,
            cmpWorking.current.navMap
          );
        } else if (action === "update") {
          updateRecord(
            cmpWorking.current.navTree,
            cmpWorking.current.navMap,
            record
          );
          updateTree(
            "nav",
            cmpWorking.current.navTree,
            cmpWorking.current.navMap
          );
          updateRecord(
            cmpWorking.current.searchTree,
            cmpWorking.current.searchMap,
            record
          );
          updateTree(
            "search",
            cmpWorking.current.searchTree,
            cmpWorking.current.searchMap
          );
        } else if (action === "delete") {
          deleteRecord(
            cmpWorking.current.navTree,
            cmpWorking.current.navMap,
            id,
            sourceId,
            module
          );
          updateTree(
            "nav",
            cmpWorking.current.navTree,
            cmpWorking.current.navMap
          );
          deleteRecord(
            cmpWorking.current.searchTree,
            cmpWorking.current.searchMap,
            id,
            sourceId,
            module
          );
          updateTree(
            "search",
            cmpWorking.current.searchTree,
            cmpWorking.current.searchMap
          );
        }
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  // on refresh, resubmit search (if searchMode=true) and reload children for the selected item(s)
  const handleRefreshEvent = () => {
    try {
      var navTree = cmpWorking.current.navTree;
      var navMap = cmpWorking.current.navMap;
      navTree.forEach((rootItems) => {
        var root = rootItems.items[0];
        loadChildren(navTree, navMap, root, true);
      }); // reload all children for a root, and recursively refresh any previously loaded children

      if (cmpWorking.current.searchMode) {
        doSearch();
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleParentToChildEvent = (event) => {
    if (event.action === "reload") {
      handleRefreshEvent();
      props.parentCmp.set("parentToChildEvent", {});
    }
  };

  // TODO In Salesforce, the parent component can call this function through an aura:method. We need to review how to do this in react.
  // return existing item in navigation or search map, e.g., when navigating to parent item after delete
  const getNameFromId = () => {
    try {
      // var params = event.getParam("arguments");
      // if (params && params.id) {
      //   var item = Object.values(cmp.get("v.navMap")).find(
      //     (obj) => obj.id === params.id
      //   );
      //   if (item) {
      //     return item.name;
      //   }
      //   var item = Object.values(cmp.get("v.searchMap")).find(
      //     (obj) => obj.id === params.id
      //   );
      //   if (item) {
      //     return item.name;
      //   }
      // }
    } catch (err) {
      console.error(err.stack);
    }
  };

  // parse the selected variable, update the tree, and navigate to the selected item(s)
  const parseSelected = () => {
    var selected = cmp.get("selected");

    var currentNames = cmpWorking.current.selectedNames;
    var isInit = currentNames === null;
    var selectedList =
      selected === null ? [] : Array.isArray(selected) ? selected : [selected];
    var selectedParsed = selectedList.map((name) => Record.parseName(name));
    var selectedNames = selectedParsed.reduce((obj, parsed) => {
      obj[parsed.section] = parsed.name;
      return obj;
    }, {});

    // no change
    if (
      !isInit &&
      JSON.stringify(currentNames, Object.keys(currentNames).sort()) ===
        JSON.stringify(selectedNames, Object.keys(selectedNames).sort())
    ) {
      return;
    }

    // set selectedNames and notifyNames
    var notifyNames = selectedParsed.reduce((obj, parsed) => {
      obj[parsed.name] = "change";
      return obj;
    }, {});

    // If you don't set this, you can't use it further down in the code
    cmp.set("selectedNames", selectedNames);
    cmp.set("notifyNames", notifyNames);

    // search on init
    if (isInit && checkSearch()) {
      delayedSearch();
      return;
    }
    // set values
    loadSelection(isInit, selectedParsed);
  };

  // get child records for navigation tree
  const setLoading = (name, value) => {
    var loading = cmpWorking.current.loading;
    loading[name] = value;
    for (let v in loading) {
      if (loading[v]) {
        props.parentCmp.set("navigationLoading", true);
        return true;
      }
    }
    cmp.set("loading", {});
    props.parentCmp.set("navigationLoading", false);

    //TODO - SF doesn't have this part, but because of how react deals with props, we have to deal with different names
    if (!value) {
      //TODO refactor > use the same state variable names in the other components and then just use parentCmp.set("isLoading",false)
      if (props.parentCmp.name === "PatternSave") {
        props.parentCmp.set("loading", "");
      }
    }

    return false;
  };

  // load roots and select items in navigation tree programatically
  const loadSelection = (isInit, selectedParsed) => {
    // update search tree first before loading to make sure the relevant selections update
    if (cmpWorking.current.searchMode) {
      updateTree(
        "search",
        cmpWorking.current.searchTree,
        cmpWorking.current.searchMap
      );
    }

    // always update navTree, regardless of searchMode, so that
    // (1) the correct state is returned when searchMode is closed, and
    // (2) navigation events are fired when an item is selected externally that is not in the search results
    setLoading("selected", true);
    var navTree = cmpWorking.current.navTree;
    var navMap = cmpWorking.current.navMap;

    var parsedMap = selectedParsed.reduce((obj, parsed) => {
      obj[parsed.section] = parsed;
      return obj;
    }, {});
    navTree.forEach((root) => {
      root = getRoot(navMap, root);
      var parsed = parsedMap[root.setting.section];

      //---REVIEW---
      // if(!root.expanded){
      //   //don't load children if the root is not expanded
      //   return;
      // }

      /// THIS IS WHERE LOADING FROM URL FAILS FOR DASHBOARD TAB
      if (parsed) {
        var existing = navMap[parsed.name];
        if (existing) {
          // IMPROVEMENT: expand the full path to the selected item, so that the scroll position can be correctly calculated
          loadChildren(navTree, navMap, existing);
        } else if (parsed.section && parsed.config && parsed.id) {
          // We don't need to call browseToRecord for folders on the dashboard tab
          if (parsed.config !== "folder" && parsed.section !== "folders") {
            browseToRecord(navTree, navMap, root, parsed);
          }
        } else if (isInit) {
          loadChildren(navTree, navMap, root);
        }
      } else {
        if (isInit) {
          loadChildren(navTree, navMap, root);
        }
      }
    });

    // update tree
    setLoading("selected", false);
    updateTree("nav", navTree, navMap);
  };

  // load the navigation item's children if not yet loaded
  const loadChildren = (navTree, navMap, item, reload = false) => {
    if (!item.loaded || reload) {
      var setting = item.setting || {};
      // var treeSettings = cmp.get("v.treeSettings");
      var treeSettings = cmpWorking.current.treeSettings;
      var settings = treeSettings[setting.section] || {};
      var childSetting = settings[setting.childConfig];
      if (childSetting) {
        if (childSetting.records) {
          addRecords(navTree, navMap, childSetting, item, reload);
        } else if (childSetting.module && childSetting.object) {
          loadRecords(navTree, navMap, childSetting, item, reload);
        }
      }
    }
  };

  const loadedRecordsProcess = (
    navTree,
    navMap,
    records,
    parent,
    setting,
    reload,
    loadName
  ) => {
    records.forEach((record) => {
      var item = itemFromRecord(navMap, parent, setting, record);
      // load next level if there is only 1 element, or if the item was loaded before and reload=true
      if (records.length === 1 || (reload && item.loaded)) {
        loadChildren(navTree, navMap, item, reload);
      }
    });

    postItemsChange(parent.items, false, false);
    setLoading(loadName, false);
    updateTree("nav", navTree, navMap);
  };

  // get child records for navigation tree item
  const loadRecords = (navTree, navMap, setting, parent, reload) => {
    try {
      if (cmp.name === "NavigationInput") {
        props.parentCmp.set("navigationLoading", false);
      }
      var loadName = [parent.name, "children"].join(".");

      // expand item and mark as loaded
      parent = syncItem(navTree, navMap, parent);
      parent.expanded = !parent.loaded ? true : parent.expanded;
      parent.loaded = true;

      // to avoid redundant api calls
      if (setting.module === "core" && setting.object === "source") {
        const records = cmp.get("sources");
        loadedRecordsProcess(
          navTree,
          navMap,
          records,
          parent,
          setting,
          reload,
          loadName
        );
      } else {
        var onSuccess = function (response) {
          const records = response;
          cmp.set("records", records);
          loadedRecordsProcess(
            navTree,
            navMap,
            records,
            parent,
            setting,
            reload,
            loadName
          );
        };

        var onError = function (response) {
          cmp.checkUser(response);
          cmp.setToastState("error", "Error", toastErrorMessage(response));
          postItemsChange(parent.items, true, false);
          setLoading(loadName, false);
          updateTree("nav", navTree, navMap);
        };

        // IMPROVEMENT: build paginator that loads more than 100 results
        setLoading(loadName, true);
        var parentId = parent.setting.object ? parent.id : parent.parentId;
        var filter =
          setting.parentField === null
            ? {}
            : { [setting.parentField]: parentId };

        // TODO - review how to get the filters - In case of object/container, the results should be filter to only show the container (when working with the filter component in the pattern chart)
        Record.getRecords(
          setting.module,
          setting.object,
          filter,
          onSuccess,
          onError
        );
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const loadSources = () => {
    try {
      var onSuccess = function (response) {
        const sources = response;
        cmp.set("sources", sources);
        cmp.set("sourcesLoaded", true);
      };

      var onError = function (response) {
        cmp.checkUser(response);
        cmp.setToastState("error", "Error", toastErrorMessage(response));
      };

      Record.getRecords("core", "source", {}, onSuccess, onError);
    } catch (err) {
      console.error(err.stack);
    }
  };

  // add child records that are specified in the settings
  const addRecords = (navTree, navMap, setting, parent, reload) => {
    // expand item and mark as loaded
    parent = syncItem(navTree, navMap, parent);
    parent.expanded = !parent.loaded ? true : parent.expanded;
    parent.loaded = true;

    // add records directly
    var records = Object.values(setting.records || {});
    records.forEach((record) => {
      var item = itemFromRecord(navMap, parent, setting, record);
      // load next level if there is only 1 element, or if the item was loaded before and reload=true
      if (records.length === 1 || (reload && item.loaded)) {
        loadChildren(navTree, navMap, item, reload);
      }
    });

    // this.postItemsChange(parent.items, false, false); no need to sort
    updateTree("nav", navTree, navMap);
  };

  // load records in the path from root to selected record
  const browseToRecord = (navTree, navMap, root, parsed, loadChild = null) => {
    // var self = this;
    var id = parsed.id;
    var section = parsed.section;
    var config = parsed.config;
    var loadName = [section, config, id, "record"].join(".");
    var treeSettings = cmpWorking.current.treeSettings;
    var settings = treeSettings[section] || {};
    var setting = settings[config];

    var onSuccess = function (response) {
      var record = response[0];
      var item = processRecord(
        // cmp,
        navTree,
        navMap,
        settings,
        root,
        config,
        record,
        false
      );
      loadChildren(navTree, navMap, item);
      if (loadChild) {
        item.items.forEach((item) => {
          if (item.id === loadChild) {
            loadChildren(navTree, navMap, item);
          }
        });
      }
      setLoading(loadName, false);
      updateTree("nav", navTree, navMap);
    };

    var onError = function (response) {
      if (response) {
        cmp.setToastState("error", "Error", toastErrorMessage(response));
        cmp.checkUser(response);
      }
      
      // load roots instead, so user can still navigate if there is an error
      setLoading(loadName, false);
      loadChildren(navTree, navMap, root);
      updateTree("nav", navTree, navMap);
    };

    if (setting.records && settings[setting.parentConfig]) {
      var parentId = id.slice(id.indexOf("-") + 1);
      var parent = { section, config: setting.parentConfig, id: parentId };
      browseToRecord(navTree, navMap, root, parent, id);
    } else if (setting.module && setting.object && id) {
      setLoading(loadName, true);

      Record.getRecord(
        setting.module,
        setting.object,
        id,
        {},
        "",
        "GET",
        onSuccess,
        onError
      );
    } else {
      onError(null);
    }
  };

  const processRecord = (
    itemTree,
    itemMap,
    settings,
    root,
    config,
    record,
    searchMode
  ) => {
    // build navigation tree backwards from selected record
    var setting = settings[config];
    var recordMap = {};

    while (setting && record) {
      if (setting.records) {
        recordMap[setting.config] = setting.records[config];
      } else {
        recordMap[setting.config] = record;
      }
      if (setting.parent) {
        record = record[setting.parent];
      }
      config = setting.config;
      setting = settings[setting.parentConfig];
    }

    // add items from root to selected record
    var parent = root;
    setting = settings[parent?.config] || {};

    while (true) {
      setting = settings[parent.setting.childConfig] || {};
      record = recordMap[setting.config];

      if (!parent || !record) {
        break;
      }

      if (searchMode) {
        parent.expanded = true;
        parent.loaded = true;
      } else {
        parent = syncItem(itemTree, itemMap, parent);
        parent.expanded = !parent.loaded ? true : parent.expanded;
        loadChildren(itemTree, itemMap, parent);
      }

      parent = itemFromRecord(itemMap, parent, setting, record);
    }
    return parent;
  };

  // issue: intermediate updates to the navigation tree leave old items selected
  // issue: when setting a lightning:tree:selectedItem and then loading additional items under the same parent, both the first and the selected item will show up as selected
  const updateTree = (which, itemTree, itemMap, initSearch = false) => {
    try {
      // Synchronosation issue:
      // Because react state/props updates are asynch, we can't read out the latest value of props.isLoading
      // props.isLoading is set by Explore tab's navigationLoading, but the last version is not yet available
      // postpone update until all loading is done
      if (props.parentCmp.get("navigationLoading")) {
        // if (props.isLoading) {
        return;
      }

      // definitive workaround for all kinds of issues with incorrect selections in lightning:tree
      // update the tree with a deep-copy (to prevent flickering), before updating it with the final values
      var f = window.console.warn;
      window.console.warn = function () {}; // suppress warnings while converting to JSON
      const item = `${which}Tree`;

      cmp.set(item, JSON.parse(JSON.stringify(itemTree)));
      window.console.warn = f;

      // mark items as selected in the specified tree, and create navigation events
      var name;
      //var selectedNames = cmp.get("v.selectedNames") || {};
      var selectedNames = cmpWorking.current.selectedNames || {};

      var events = [];
      var fireEvent = which === "nav" || initSearch;

      // var searchMode = cmp.get("v.searchMode") || initSearch;
      var searchMode = cmpWorking.current.searchMode || initSearch;
      var sendScroll =
        (searchMode && which === "search") || (!searchMode && which === "nav");
      itemTree.forEach((root) => {
        if (root.name === props.selectedSection || !props.selectedSection) {
          root.expanded = true;
        } else {
          root.expanded = false;
        }

        root = getRoot(itemMap, root);
        name = selectedNames[root.setting.section];

        if (fireEvent) {
          addEvent(events, itemTree, itemMap, root, name, sendScroll);
        }

        root.selected = name;
        root.expanded = root.expanded || name != null;
      });

      cmp.set(`${which}Tree`, itemTree);
      cmp.set(`${which}Map`, itemMap);

      // send navigation events after updating the tree
      if (fireEvent) {
        events.forEach((event) =>
          sendNavigationEvent(event.item, event.source, event.scroll)
        );
        cmp.set(`notifyNames`, null);
      }

      // set search mode
      if (initSearch) {
        cmp.set(`searchMode`, true);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  // add navigation event
  const addEvent = (events, itemTree, itemMap, root, name, sendScroll) => {
    var multiSelect = cmpWorking.current.multiSelect;
    var notifyNames = cmpWorking.current.notifyNames || {};
    var unselect = name === null && root.selected != null;

    if (name != null) {
      // navigation event(s) for notifyNames
      var source = notifyNames[name];
      var item = itemMap[name];
      if (source != null && item) {
        if (sendScroll) {
          var counts = getCounts(itemTree, name);
          var skip = 2 * itemTree.length;
          var scroll = (counts.selected - skip) / counts.total;
          events.push({ item, source, scroll });
        } else {
          events.push({ item, source, scroll: null });
        }
      }
    } else if (multiSelect && unselect) {
      // create navigation event for unselect in case of multiselect; for single select the newly selected item will already generate an event
      events.push({
        item: { setting: { section: root.setting.section } },
        source: "change",
        scroll: null,
      });
    }
  };

  // add empty placeholder indicating no records are available, and sort items
  const postItemsChange = (items, forceEmpty, sortRecursive) => {
    if (forceEmpty || !items.length) {
      //As we are no longer using the tree component, we had to add a label here. Once we move back to a tree component, we have to review this implementation
      items[0] = {
        name: "",
        label: "--None--",
        metatext: "--None--",
        disabled: true,
      };
    } else {
      var sortFunction = Record.sortByString;
      if (sortRecursive) {
        sortAll(items, "label");
      } else {
        items.sort(sortFunction("label", false));
      }
    }
  };

  // recursively goes through items tree to find the item with the specified field value
  const findItem = (itemTree, field, value) => {
    let selectedItem;
    itemTree.some((item) => {
      if (item[field] === value) {
        selectedItem = item;
        return true;
      }
      const items = item.items;
      if (items && items.length) {
        selectedItem = findItem(items, field, value);
        return selectedItem !== undefined;
      }
      return false;
    });
    return selectedItem;
  };

  // recursively goes through items tree to find the total number of elements (only including expanded), as well as the selected element
  const getCounts = (itemTree, name, counts) => {
    if (!counts) {
      counts = { total: 0, selected: 0 };
    }
    if (!itemTree) {
      return counts;
    }
    itemTree.forEach((item) => {
      counts.total = counts.total + 1;
      if (name === item.name) {
        counts.selected = counts.total;
      }
      if (item.expanded) {
        getCounts(item.items, name, counts);
      }
    });

    return counts;
  };

  // issue: lightning:tree deep-copies the content of the itemTree when updating 'expanded'
  // this function resyncs an item across itemTree and itemMap
  const syncItem = (itemTree, itemMap, item) => {
    var treeItem = findItem(itemTree, "name", item?.name) || {};
    var expanded = treeItem.expanded;
    var items = treeItem.items;
    Object.assign(treeItem, item, { expanded, items });
    itemMap[treeItem.name] = treeItem;

    return treeItem;
  };

  // issue: lightning:tree deep-copies the content of the itemTree when updating 'expanded'
  // this function resyncs a root across itemTree and itemMap
  const getRoot = (itemMap, root) => {
    var treeItem = root.items[0];
    var expanded = treeItem.expanded;
    var items = treeItem.items;
    var mapItem = itemMap[treeItem.name] || {};
    Object.assign(treeItem, mapItem, { expanded, items });
    itemMap[treeItem.name] = treeItem;
    return treeItem;
  };

  // recursively sort items
  const sortAll = (itemTree, field) => {
    if (!itemTree || !itemTree.length) {
      return;
    }
    var sortFunction = Record.sortByString;
    itemTree.sort(sortFunction(field, false));
    itemTree.forEach((item) => sortAll(item.items, field));
  };

  // update the item for the specified record
  const updateRecord = (itemTree, itemMap, record) => {
    // update from record id
    Object.values(itemMap).forEach((item) => {
      if (item.id === record.id) {
        item = syncItem(itemTree, itemMap, itemMap[item.name]);
        item.label = record.name;
        var parent = syncItem(itemTree, itemMap, itemMap[item.parentName]);
        postItemsChange(parent.items, false, false);
      }
    });
  };

  // create or update the item for the specified record under each parent item whose id matches the parentId
  const upsertRecord = (itemTree, itemMap, parentId, record) => {
    // var treeSettings = cmp.get("v.treeSettings");
    var treeSettings = cmpWorking.current.treeSettings;
    Object.values(itemMap).forEach((parent) => {
      if (parent.id === parentId) {
        parent = syncItem(itemTree, itemMap, parent);
        var settings = treeSettings[parent.setting.section];
        var setting = settings[parent.setting.childConfig];
        if (setting) {
          itemFromRecord(itemMap, parent, setting, record);
          postItemsChange(parent.items, false, false);
        }
      }
    });
  };

  // delete record from parent.items
  const deleteRecord = (itemTree, itemMap, id, sourceId, module) => {
    try {
      if (!itemTree || itemTree.length === 0) {
        return;
      }
      // TODO - we need generic solution for this
      // delete folder
      if (!sourceId && !module) {
        const newItemTree = [
          {
            ...itemTree[0],
            items: [
              {
                ...itemTree[0].items[0],
                items: itemTree[0].items[0].items.filter(
                  (item) => item.id !== id
                ),
              },
            ],
          },
        ];
        cmp.set(`navTree`, newItemTree);
        return;
      }

      Object.values(itemMap).forEach((item) => {
        if (item.id === id) {
          // delete item from itemMap
          delete itemMap[item.name];

          // delete item from parent.items
          var parent = syncItem(itemTree, itemMap, itemMap[item.parentName]);
          var index = parent.items?.findIndex((v) => v.name === item.name);
          if (index > -1) {
            parent.items.splice(index, 1);
            postItemsChange(parent.items, false, false);
          }

          // deselect and fire event if deleted item was selected
          var root = syncItem(itemTree, itemMap, itemMap[item.rootName]);
          if (root.selected === item.name) {
            root.selected = undefined;
            sendNavigationEvent(
              { setting: { section: root.setting.section } },
              "delete",
              null
            );
          }
        }
      });
    } catch (err) {
      console.error(err.stack);
    }
  };

  // create root item at initialization
  const createRootItem = (setting) => {
    var records = setting.records || { missing: { name: "Missing" } };
    var record = Object.values(records)[0];

    setting = (({ section, config }) => ({
      section,
      config,
      childConfig: record.childConfig,
    }))(setting);
    record = (({ name, childConfig }) => ({ id: childConfig, name }))(record);
    var name = [setting.section, setting.config, record.id].join("_");
    var breadcrumb = [{ name: record.name, id: record.id }];
    return {
      name,
      id: record.id,
      label: record.name,
      setting,
      rootName: name,
      parentName: null,
      parentId: null,
      breadcrumb,
      record,
      items: [],
    };
  };

  // create item from record, or update existing if present
  const itemFromRecord = (itemMap, parent, setting, record) => {
    if (setting.records) {
      var id = parent.setting.object
        ? record.childConfig + "-" + parent.id
        : record.childConfig;
      setting = (({ section, config }) => ({
        section,
        config,
        childConfig: record.childConfig,
      }))(setting);
      record = { name: record.name, id: id };
    }

    var breadcrumb = [...parent.breadcrumb] || [];
    breadcrumb.push({ name: record.name, id: record.id });
    var item = {
      id: record.id,
      label: record.name,
      setting,
      rootName: parent.rootName,
      parentId: parent.id,
      parentName: parent.name,
      breadcrumb,
      record,
    };

    item.name = [setting.section, setting.config, record.id].join("_");
    var existing = itemMap[item.name];

    if (existing) {
      Object.assign(existing, item);
      return existing;
    } else {
      var items = parent.items;
      if (items.length === 1 && items[0].name === "") {
        items.length = 0;
      } // remove --None-- item if present
      item.items = []; // default
      items.push(item);
      itemMap[item.name] = item;
      return item;
    }
  };

  // set the selected item in selectedNames and the tree root item
  const setSelected = (name) => {
    try {
      var searchMode = cmpWorking.current.searchMode;
      var selectedNames = cmpWorking.current.selectedNames || {};
      var multiSelect = cmpWorking.current.multiSelect;
      var itemTree = searchMode
        ? cmpWorking.current.searchTree
        : cmpWorking.current.navTree;
      var itemMap = searchMode
        ? cmpWorking.current.searchMap
        : cmpWorking.current.navMap;
      var item = itemMap[name] || {};

      // event is sent immediately, so no need to notify after loading the tree
      // NB; the event may trigger further changes, which may then set notifyNames again, e.g., closing search
      cmp.set("notifyNames", null);

      // no need to update the tree if the item is already selected
      if (selectedNames[item.setting?.section] === name) {
        sendNavigationEvent(item, "tree", null);
        return;
      }

      // set selected item name for each section, and unselect other items if necessary
      itemTree.forEach((root) => {
        root = getRoot(itemMap, root);
        var section = root.setting?.section;
        if (section === item.setting?.section) {
          selectedNames[section] = item.name;
        } else if (!multiSelect) {
          selectedNames[section] = undefined;
        } else if (section !== item.setting?.section) {
          // Double Container cannot be selected on the search page
          selectedNames = { [item.setting?.section]: item.name };
        }
      });

      // store selectedNames now, so the current selection is available to events triggered after this, but before the tree itself updates
      cmp.set("selectedNames", selectedNames);

      // update the searchTree first before loading any additional data in the navTree
      if (searchMode) {
        updateTree("search", itemTree, itemMap);
      }

      // update the navTree
      // NB: navTree is also updated in the background when in searchMode, so that it shows the right selection after closing search, and also fires the right navigation events
      var navTree = cmpWorking.current.navTree;
      var navMap = cmpWorking.current.navMap;
      var root = navMap[item.rootName];
      var existing = navMap[item.name];

      if (existing) {
        loadChildren(navTree, navMap, existing);
        updateTree("nav", navTree, navMap);
      } else {
        var parsed = Record.parseName(name);
        browseToRecord(navTree, navMap, root, parsed);
      }

      // send navigation event
      sendNavigationEvent(item, "tree", null);
    } catch (err) {
      console.error(err.stack);
    }
  };

  // delays search with 500 ms while typing to prevent unnecessary API calls
  const delayedSearch = () => {
    clearTimeout(cmpWorking.current.timer);
    var delayMs = 500;

    const timer = setTimeout(() => {
      doSearch();
    }, delayMs);
    cmp.set("timer", timer);
  };

  const escapeRegex = (string) => {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  };

  // submit search
  const doSearch = () => {
    // update timer
    clearTimeout(cmpWorking.current.timer);
    cmp.set("timer", null);

    // database query notation
    // var searchText = cmpWorking.current.searchText;

    var searchSql = props.searchText ? "%" + props.searchText + "%" : null;
    var searchRegex = props.searchText
      ? new RegExp(escapeRegex(props.searchText || ""), "gi")
      : null;

    // get and initalize variables
    var searchTree = [];
    var searchMap = {};

    var treeSettings = cmpWorking.current.treeSettings;
    var treeFilters = cmp.get("filters") || {};
    var navMap = cmpWorking.current.navMap;
    var navTree = cmpWorking.current.navTree;
    navTree.forEach((root) => {
      // copy root item
      root = getRoot(navMap, root);
      root = Object.assign({}, root, {
        items: [],
        expanded: true,
        selected: null,
        loaded: true,
      });
      var section = root.setting.section;
      searchTree.push({ name: section, items: [root] });
      searchMap[root.name] = root;

      // settings
      var settings = treeSettings[section];
      var filters = treeFilters[section] || {};
      Object.keys(settings).forEach((config) => {
        var setting = settings[config];
        var filter = filters[config] || {};
        if (setting.searchField) {
          var loadName = [section, config, "search"].join(".");
          var onSuccess = function (response) {
            response.forEach((record) => {
              processRecord(
                searchTree,
                searchMap,
                settings,
                root,
                config,
                record,
                true
              );
            });
            postItemsChange(root.items, false, true);
            setLoading(loadName, false);
            updateTree("search", searchTree, searchMap, true);
          };

          var onError = function (response) {
            cmp.checkUser(response);
            cmp.setToastState("error", "Error", toastErrorMessage(response));
            postItemsChange(root.items, true, true);
            setLoading(loadName, false);
            updateTree("search", searchTree, searchMap, true);
          };

          if (setting.records) {
            Object.values(setting.records).forEach((record) => {
              if (record.name.match(searchRegex)) {
                itemFromRecord(searchMap, root, setting, record);
              } // IMPROVEMENT: use actual parent instead of 'root'
            });
            postItemsChange(root.items, false, true);
          } else {
            setLoading(loadName, true);
            var queryFilter = Object.assign({}, filter);
            if (searchSql) {
              queryFilter[setting.searchField] =
                queryFilter[setting.searchField] || searchSql;
            }

            Record.getRecords(
              setting.module,
              setting.object,
              queryFilter,
              onSuccess,
              onError
            );
          }
        }
      });
    });

    updateTree("search", searchTree, searchMap, true);
  };

  // check if search should be on
  const checkSearch = () => {
    // checking searchText
    // var searchText = cmpWorking.current.searchText;
    var hasText = props.searchText != null && props.searchText.length;
    if (hasText) {
      return true;
    }

    // checking filters
    var filters = cmp.get("filters");
    var treeSettings = cmpWorking.current.treeSettings;
    var hasFilters =
      filters &&
      Object.keys(filters).some((section) => {
        var settings = treeSettings[section] || {};
        var filter = filters[section];
        return (
          filter &&
          Object.keys(filter).some((config) => {
            var setting = settings[config];
            var fields = filter[config];
            return (
              setting &&
              fields &&
              Object.values(fields).some((value) => value != null)
            );
          })
        );
      });

    if (hasFilters) {
      return true;
    }
  };

  // close search and stop any pending delayed searches
  const closeSearch = () => {
    clearTimeout(cmpWorking.current.timer);
    cmpWorking.current.searchMode = false;
    cmpWorking.current.timer = null;
    cmpWorking.current.searchTree = [];
    cmpWorking.current.searchMap = {};

    // set notifyNames, in case the navTree is still loading while search is being closed
    var selectedNames = cmpWorking.current.selectedNames || {};
    var notifyNames = Object.values(selectedNames).reduce((obj, item) => {
      obj[item] = "closeSearch";
      return obj;
    }, {});
    // cmp.set("v.notifyNames", notifyNames);

    cmp.set("searchMode", false);
    cmp.set("timer", null);
    cmp.set("searchTree", []);
    cmp.set("searchMap", {});
    cmp.set("notifyNames", notifyNames);

    updateTree("nav", cmpWorking.current.navTree, cmpWorking.current.navMap);
  };

  // send out a navigation event, so that containing components get access to the full record
  const sendNavigationEvent = (item, source, scroll) => {
    // var navigationEvent = cmp.getEvent("navigationEvent");

    var setting = item.setting || {};
    const event = {
      type: "navigation",
      id: item.id,
      label: item.label,
      section: setting.section,
      parentId: item.parentId,
      obj: setting.config,
      breadcrumb: item.breadcrumb,
      record: item.record,
      source,
      scroll,
    };

    props.childToParent(event);
  };

  const navigationTreeItems = cmpState.searchMode
    ? cmpState.searchTree
    : cmpState.navTree;

  return (
    <div>
      <div className="slds-tree_container">
        {navigationTreeItems.length > 0 &&
          navigationTreeItems.map((root) => (
            <div key={root.name}>
              {root.expanded && (
                <>
                  <h4 className="slds-text-title_caps slds-p-around_x-small">
                    {root.items[0].label}
                  </h4>
                  <ul
                    aria-labelledby="treeheading"
                    className="slds-tree"
                    role="tree"
                  >
                    <>
                      {root.items[0].items.map((firstLevelRootItem, index) => (
                        <div key={firstLevelRootItem.id || index}>
                          {firstLevelRootItem.id ? (
                            <li
                              aria-expanded={firstLevelRootItem.expanded}
                              aria-label="Tree Branch"
                              aria-level="1"
                              aria-disabled={firstLevelRootItem.disabled}
                              role="treeitem"
                              aria-selected={ariaSelected(
                                firstLevelRootItem,
                                props.sections,
                                cmpState.selectedNames
                              )}
                            >
                              {/* First Tree*/}
                              <div
                                className="slds-tree__item row1"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSelect(firstLevelRootItem);
                                }}
                              >
                                {root.name !== "folders" &&
                                //TODO -  Avoid the need for implementation specific to other components
                                //        Instead, we could pass down a prop to indicate the tree should be expanded
                                firstLevelRootItem.items.length > 0 ? (
                                  <button
                                    className="slds-button slds-button_icon slds-m-right_x-small"
                                    aria-hidden="true"
                                    tabIndex="-1"
                                    title={
                                      firstLevelRootItem.expanded
                                        ? "Collapse Tree Branch"
                                        : "Expand Tree Branch"
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickChevronIcon(
                                        firstLevelRootItem,
                                        root.name,
                                        "firstTreeItem"
                                      );
                                    }}
                                  >
                                    <ChevronIcon />
                                  </button>
                                ) : (
                                  <div className="emptyIcon"></div>
                                )}
                                <ItemLabel
                                  props={props}
                                  item={firstLevelRootItem}
                                  level={1}
                                />
                              </div>
                              {/* Second Tree */}
                              {root.name !== "folders" && //TODO - avoid the need for implementation specific to other components. We should have generic solution for folders
                                firstLevelRootItem.items?.length > 0 &&
                                firstLevelRootItem.expanded &&
                                firstLevelRootItem.items.map(
                                  (secondLevelRootItem) => (
                                    <div key={secondLevelRootItem.id || "none"}>
                                      <ul role="group">
                                        <li
                                          aria-expanded={
                                            secondLevelRootItem.expanded
                                          }
                                          aria-level="2"
                                          aria-disabled={
                                            secondLevelRootItem.disabled
                                          }
                                          aria-selected={ariaSelected(
                                            secondLevelRootItem,
                                            props.sections,
                                            cmpState.selectedNames
                                          )}
                                          role="treeitem"
                                          tabIndex="0"
                                        >
                                          <div
                                            className="slds-tree__item row2"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleSelect(secondLevelRootItem);
                                            }}
                                          >
                                            {secondLevelRootItem.items?.length >
                                            0 ? (
                                              <button
                                                className="slds-button slds-button_icon slds-m-right_x-small"
                                                aria-hidden="true"
                                                tabIndex="-1"
                                                title={
                                                  secondLevelRootItem.expanded
                                                    ? "Collapse Tree Branch"
                                                    : "Expand Tree Branch"
                                                }
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleClickChevronIcon(
                                                    secondLevelRootItem,
                                                    root.name,
                                                    "secondTreeItem"
                                                  );
                                                }}
                                              >
                                                <ChevronIcon />
                                              </button>
                                            ) : (
                                              <div className="emptyIcon"></div>
                                            )}
                                            <ItemLabel
                                              props={props}
                                              item={secondLevelRootItem}
                                              level={2}
                                            />
                                          </div>
                                          {/* Third Tree */}
                                          {secondLevelRootItem.items?.length >
                                            0 &&
                                            secondLevelRootItem.expanded &&
                                            secondLevelRootItem.items.map(
                                              (thirdLevelRootItem) => (
                                                <div
                                                  key={
                                                    thirdLevelRootItem.id ||
                                                    "none"
                                                  }
                                                >
                                                  <ul role="group">
                                                    <li
                                                      aria-expanded={
                                                        thirdLevelRootItem.expanded
                                                      }
                                                      aria-level="3"
                                                      aria-disabled={
                                                        thirdLevelRootItem.disabled
                                                      }
                                                      aria-selected={ariaSelected(
                                                        thirdLevelRootItem,
                                                        props.sections,
                                                        cmpState.selectedNames
                                                      )}
                                                      role="treeitem"
                                                      tabIndex="0"
                                                    >
                                                      <div
                                                        className="slds-tree__item row3"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleSelect(
                                                            thirdLevelRootItem
                                                          );
                                                        }}
                                                      >
                                                        {thirdLevelRootItem
                                                          .items?.length > 0 ? (
                                                          <button
                                                            className="slds-button slds-button_icon slds-m-right_x-small"
                                                            aria-hidden="true"
                                                            tabIndex="-1"
                                                            title={
                                                              thirdLevelRootItem.expanded
                                                                ? "Collapse Tree Branch"
                                                                : "Expand Tree Branch"
                                                            }
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              handleClickChevronIcon(
                                                                thirdLevelRootItem,
                                                                [
                                                                  root,
                                                                  firstLevelRootItem,
                                                                ],
                                                                "thirdTreeItem"
                                                              );
                                                            }}
                                                          >
                                                            <ChevronIcon />
                                                          </button>
                                                        ) : (
                                                          <div className="emptyIcon"></div>
                                                        )}
                                                        <ItemLabel
                                                          props={props}
                                                          item={
                                                            thirdLevelRootItem
                                                          }
                                                          level={3}
                                                        />
                                                      </div>
                                                      {/* Fourth Tree */}
                                                      {thirdLevelRootItem.items
                                                        ?.length > 0 &&
                                                        thirdLevelRootItem.expanded &&
                                                        thirdLevelRootItem.items.map(
                                                          (
                                                            fourthLevelRootItem
                                                          ) => {
                                                            return (
                                                              <div
                                                                key={
                                                                  fourthLevelRootItem.id ||
                                                                  "none"
                                                                }
                                                              >
                                                                <ul role="group">
                                                                  <li
                                                                    aria-expanded={
                                                                      fourthLevelRootItem.expanded
                                                                    }
                                                                    aria-level="4"
                                                                    aria-disabled={
                                                                      fourthLevelRootItem.disabled
                                                                    }
                                                                    aria-selected={ariaSelected(
                                                                      fourthLevelRootItem,
                                                                      props.sections,
                                                                      cmpState.selectedNames
                                                                    )}
                                                                    role="treeitem"
                                                                    tabIndex="0"
                                                                  >
                                                                    <div
                                                                      className="slds-tree__item"
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                        handleSelect(
                                                                          fourthLevelRootItem
                                                                        );
                                                                      }}
                                                                    >
                                                                      {fourthLevelRootItem
                                                                        .items
                                                                        ?.length >
                                                                      0 ? (
                                                                        <button
                                                                          className="slds-button slds-button_icon slds-m-right_x-small"
                                                                          aria-hidden="true"
                                                                          tabIndex="-1"
                                                                          title={
                                                                            fourthLevelRootItem.expanded
                                                                              ? "Collapse Tree Branch"
                                                                              : "Expand Tree Branch"
                                                                          }
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            e.stopPropagation();
                                                                            handleClickChevronIcon(
                                                                              fourthLevelRootItem,
                                                                              [
                                                                                secondLevelRootItem,
                                                                                thirdLevelRootItem,
                                                                              ],
                                                                              "fourthTreeItem"
                                                                            );
                                                                          }}
                                                                        >
                                                                          <ChevronIcon />
                                                                        </button>
                                                                      ) : (
                                                                        <div className="emptyIcon"></div>
                                                                      )}
                                                                      <span className="slds-has-flexi-truncate">
                                                                        <span
                                                                          className="slds-tree__item-label slds-truncate"
                                                                          title={
                                                                            fourthLevelRootItem.label
                                                                          }
                                                                        >
                                                                          {
                                                                            fourthLevelRootItem.label
                                                                          }
                                                                        </span>
                                                                      </span>
                                                                    </div>

                                                                    {/*Fifth Tree */}
                                                                    {fourthLevelRootItem
                                                                      .items
                                                                      ?.length >
                                                                      0 &&
                                                                      fourthLevelRootItem.expanded &&
                                                                      fourthLevelRootItem.items.map(
                                                                        (
                                                                          fifthLevelRootItem
                                                                        ) => {
                                                                          return (
                                                                            <div
                                                                              key={
                                                                                fifthLevelRootItem.id ||
                                                                                "none"
                                                                              }
                                                                            >
                                                                              <ul role="group">
                                                                                <li
                                                                                  aria-expanded={
                                                                                    fifthLevelRootItem.expanded
                                                                                  }
                                                                                  aria-level="5"
                                                                                  aria-disabled={
                                                                                    fifthLevelRootItem.disabled
                                                                                  }
                                                                                  aria-selected={ariaSelected(
                                                                                    fifthLevelRootItem,
                                                                                    props.sections,
                                                                                    cmpState.selectedNames
                                                                                  )}
                                                                                  role="treeitem"
                                                                                  tabIndex="0"
                                                                                >
                                                                                  <div
                                                                                    className="slds-tree__item"
                                                                                    onClick={(
                                                                                      e
                                                                                    ) => {
                                                                                      e.stopPropagation();
                                                                                      handleSelect(
                                                                                        fifthLevelRootItem
                                                                                      );
                                                                                    }}
                                                                                  >
                                                                                    {fifthLevelRootItem
                                                                                      .items
                                                                                      ?.length >
                                                                                    0 ? (
                                                                                      <button
                                                                                        className="slds-button slds-button_icon slds-m-right_x-small"
                                                                                        aria-hidden="true"
                                                                                        tabIndex="-1"
                                                                                        title={
                                                                                          fifthLevelRootItem.expanded
                                                                                            ? "Collapse Tree Branch"
                                                                                            : "Expand Tree Branch"
                                                                                        }
                                                                                        onClick={(
                                                                                          e
                                                                                        ) => {
                                                                                          e.stopPropagation();
                                                                                          handleClickChevronIcon(
                                                                                            fifthLevelRootItem,
                                                                                            [
                                                                                              root,
                                                                                              firstLevelRootItem,
                                                                                            ],
                                                                                            "fifthTreeItem"
                                                                                          );
                                                                                        }}
                                                                                      >
                                                                                        <ChevronIcon />
                                                                                      </button>
                                                                                    ) : (
                                                                                      <div className="emptyIcon"></div>
                                                                                    )}
                                                                                    <span className="slds-has-flexi-truncate">
                                                                                      <span
                                                                                        className="slds-tree__item-label slds-truncate"
                                                                                        title={
                                                                                          fifthLevelRootItem.label
                                                                                        }
                                                                                      >
                                                                                        {
                                                                                          fifthLevelRootItem.label
                                                                                        }
                                                                                      </span>
                                                                                    </span>
                                                                                  </div>
                                                                                </li>
                                                                              </ul>
                                                                            </div>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                    </li>
                                                  </ul>
                                                </div>
                                              )
                                            )}
                                        </li>
                                      </ul>
                                    </div>
                                  )
                                )}
                            </li>
                          ) : (
                            <div className="metaText">
                              {firstLevelRootItem.metatext}
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  </ul>
                </>
              )}
            </div>
          ))}
      </div>
      {toastState.details ? (
        <ToastComponent
          close={() => setToastState(EMPTY_TOAST_STATE)}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </div>
  );
}

export default PsNavigationTree;
