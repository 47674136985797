// WrapperField props type = { children: React.Node} prop children required
const FieldWrapper = (props) => {
  return (
    <div className="slds-form__item" role="listitem">
      <div className="slds-form-element slds-form-element_stacked">
        {props.children}
      </div>
    </div>
  );
};

export default FieldWrapper;
