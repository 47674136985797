// EditFieldWrapper all the props key are required
// EditFieldWrapper props type = { children: React.Node; name:string; value: string||jsx element } all props required
const EditFieldWrapper = ({ children, name, value }) => {
  return (
    <div className="slds-form__item" role="listitem">
      <div className="slds-form-element slds-form-element_stacked">
        <div
          id="FormDiv"
          className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
        >
          <span className="slds-form-element__label">{name}</span>
          <div className="slds-form-element__control">
            <div className="slds-form-element__static">{value}</div>
            <div className="slds-button slds-button__icon slds-button__icon_hint">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFieldWrapper;
