import { formattedNumber } from "../../../helpers";
import ProgressBarComponent from "../../ui/ProgressBarComponent";

const Statistics = ({ cmpState }) => {
  const { record, mode } = cmpState;

  return (
    <>
      <h3 className="slds-section-title--divider slds-var-m-top_medium">
        Statistics
      </h3>

      <div className="slds-form__row">
        {/* <!-- NumRows --> */}
        <div className="slds-form__item" role="listitem">
          <div className="slds-form-element slds-form-element_stacked">
            {mode === "init" && <ProgressBarComponent />}
            {mode === "view" && (
              <div
                id="FormDiv"
                className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
              >
                <span className="slds-form-element__label"># Rows</span>
                <div className="slds-form-element__control">
                  <div className="slds-form-element__static">
                    {formattedNumber(record.numRows)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!-- NumValues --> */}
        <div className="slds-form__item" role="listitem">
          <div className="slds-form-element slds-form-element_stacked">
            {mode === "init" && <ProgressBarComponent />}
            {mode === "view" && (
              <div
                id="FormDiv"
                className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
              >
                <span className="slds-form-element__label"># Values</span>
                <div className="slds-form-element__control">
                  <div className="slds-form-element__static">
                    {formattedNumber(record.numValues)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="slds-form__row">
        {/* <!-- RobustMin --> */}
        <div className="slds-form__item" role="listitem">
          <div className="slds-form-element slds-form-element_stacked">
            {mode === "init" && <ProgressBarComponent />}
            {mode === "view" && (
              <div
                id="FormDiv"
                className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
              >
                <span className="slds-form-element__label">Minimum</span>
                <div className="slds-form-element__control">
                  <div className="slds-form-element__static">
                    {formattedNumber(record.robustMin)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!-- RobustMax --> */}
        <div className="slds-form__item" role="listitem">
          <div className="slds-form-element slds-form-element_stacked">
            {mode === "init" && <ProgressBarComponent />}
            {mode === "view" && (
              <div
                id="FormDiv"
                className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
              >
                <span className="slds-form-element__label">Maximum</span>
                <div className="slds-form-element__control">
                  <div className="slds-form-element__static">
                    {formattedNumber(record.robustMax)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="slds-form__row">
        {/* <!-- Robust Distinct --> */}
        <div className="slds-form__item" role="listitem">
          <div className="slds-form-element slds-form-element_stacked">
            {mode === "init" && <ProgressBarComponent />}
            {mode === "view" && (
              <div
                id="FormDiv"
                className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
              >
                <span className="slds-form-element__label"># Distinct</span>
                <div className="slds-form-element__control">
                  <div className="slds-form-element__static">
                    {formattedNumber(record.robustDistinct)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!-- RobustRange --> */}
        <div className="slds-form__item" role="listitem">
          <div className="slds-form-element slds-form-element_stacked">
            {mode === "init" && <ProgressBarComponent />}
            {mode === "view" && (
              <div
                id="FormDiv"
                className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
              >
                <span className="slds-form-element__label">Range</span>
                <div className="slds-form-element__control">
                  <div className="slds-form-element__static">
                    {formattedNumber(record.robustRange)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
