import { Tooltip } from "antd";

const CustomTooltip = ({ content, title }) => {
  return (
    <>
      {title ? (
        <Tooltip
          title={
            <div style={{ fontSize: "12px", marginBottom: 0 }}>{title}</div>
          }
          placement="topLeft"
          color="#040042"
        >
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </>
  );
};

export default CustomTooltip;
