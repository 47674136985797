const Event = {
  // ENVIRONMENT_STATUS: {
  //     ACTIVE: {value: 'Active', label: 'Active', description: 'Connectors can load inventory and data'},
  //     INACTIVE: {value: 'Inactive', label: 'Inactive', description: 'Connectors won\'t contact the Source'},
  // },

  //not sure yet if this is useful. at least it documents what is contained in events
  createEvent: function(type){
    var event = {}

    switch(type) {
      case "navigation":
        event.type = "navigation"
        event.tab = "";  
        event.section = "";
        event.parentId = "";
        event.module = "";
        event.obj = "";
        event.id = "";
        event.record = {};
        event.label = "";
        event.breadcrumb = [];
        event.source = "";
        event.scroll = 0;
        break;
      case "recordChanged":
        event.type = "recordChanged"
        event.action = "";  //<!-- create, read, update, delete, list -->
        event.parentId = "";
        event.module = "";
        event.obj = "";
        event.id = "";
        event.record = {};
        break;
      case "dataCompEvent":
        event.type = "dataCompEvent"
        event.data = {}
        break;
      default:
    }
    return event;
  } 

}

export default Event;