import { useEffect, useRef, useState } from "react";

import PsRecord from "../ps-record/PsRecord";
import ProgressBarComponent from "../ui/ProgressBarComponent";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import EditButtonIcon from "../ui/EditButtonIcon";
import UndoButtonIcon from "../ui/UndoButtonIcon";
import Statistics from "./components/Statistics";
import Buttons from "./components/Buttons";
import NameInputWithUndoIcon from "../ui/NameInputWithUndoIcon";
import {
  RECORD_COLUMNS,
  parseInputPlainTextHelper,
  parseResponseHelper,
} from "./components/Helper";
import PsNavigationInput from "../ps-navigation-input/PsNavigationInput";
import Record from "../../helpers/recordLayer";
import PsTreeGrid from "../ps-tree-grid/PsTreeGrid";

const PsChain = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Path",
    recordModule: "store",
    recordObject: "chain",
    showDelete: false,

    dataTableColumns: [],
    dataTableKeyField: "id",
    dataTableRecords: [],

    leftKey: null,
    leftKeyId: "",
    leftKeySelected: null,
    leftKeyFilters: null,
    rightKey: null,
    rightKeyId: "",
    rightKeySelected: null,
    rightKeyFilters: null,

    //////
    loading: false,
    mode: "init",
    showCardActions: true,
    showEdit: true,

    record: {
      name: "",
      sourceName: "",
      rightContainerName: "",
      leftContainerName: "",
      custom: false,
      hasOverride: {
        name: false,
        accept: false,
      },
    },
    isExpanded: false,
    activeField: "",
    missingRequiredFields: [],
  });

  const cmpWorking = useRef({});
  const leftPsNavigationInputRef = useRef(null);
  const rightPsNavigationInputRef = useRef(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    document.addEventListener(
      "mousedown",
      cmp.handleClickOutsideOfPsNavigationInput
    );

    return () => {
      document.removeEventListener(
        "mousedown",
        cmp.handleClickOutsideOfPsNavigationInput
      );
    };
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.handleReset();
  }, [props.recordId, props.parentId]);

  const cmp = {
    // --- ChainController.js ---

    init: function () {
      try {
        cmp.initHelper();
      } catch (err) {
        console.error(err.stack);
      }
    },

    afterScriptsLoaded: function () {
      try {
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleReload: function () {
      try {
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleEdit: function () {
      try {
        PsRecord.setMode(cmp, "edit");
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleCancel: function () {
      try {
        PsRecord.cancelRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleSubmit: function () {
      try {
        var record = cmp.get("record") || {};

        if (
          cmp.get("mode") === "new" &&
          (!record.inputs || !record.inputs.length)
        ) {
          cmp.setToastState(
            "error",
            "Missing Joins",
            "Paths must have at least one Join"
          );

          return;
        }

        PsRecord.submitRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleDelete: function () {
      try {
        // if (!cmp.get('v.scriptsLoaded')) { return; }
        // Record.showToast(cmp, 'Not Available', '', 'info');
        // //helper.deleteRecord(cmp); // NB: this navigates to parent record after successfull delete
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleOverride: function (cmp, event, helper) {
      try {
        var field = event.getSource().get("v.name");
        helper.setOverride(cmp, field);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRevert: function (field) {
      try {
        PsRecord.revertOverride(cmp, field);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRecordRowAction: function (action, row) {
      try {
        var parentId = cmp.get("parentId");
        var recordModule = cmp.get("recordModule");
        var recordObject = cmp.get("recordObject");

        var parentNav =
          (cmp.get("parentPrefix") || "") + (cmp.get("parentId") || "");

        switch (action) {
          case "details":
            cmp.notifyNavigation(parentId, recordModule, recordObject, row.id);
            break;
          case "viewSource":
            cmp.notifyNavigation(
              "sources",
              "core",
              "source",
              row.key.container.source.id
            );
            break;
          case "viewContainer":
            cmp.notifyNavigation(
              row.key.container.source.id,
              "store",
              "container",
              row.key.container.id
            );
            break;
          case "viewKey":
            cmp.notifyNavigation(parentNav, "store", "key", row.key.id);
            break;
          default:
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleAddChainLink: function () {
      try {
        cmp.saveChainLink(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRemoveChainLink: function () {
      try {
        cmp.removeLastChainLink();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleLeftKeyChange: function (record) {
      try {
        cmp.setRightKeyFilter();
        cmp.set("leftKey", record);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRightKeyChange: function (record) {
      try {
        cmp.setRightKeyFilter();
        cmp.set("rightKey", record);
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- ChainHelper.js ---

    RECORD_COLUMNS: RECORD_COLUMNS,

    initHelper: function () {
      cmp.set("dataTableColumns", this.RECORD_COLUMNS);
      cmp.leftContainerFilterToParent();
    },

    clearKeyFields: function (cmp) {
      cmp.set("leftKeySelected", null);
      cmp.set("leftKeyId", null);
      cmp.set("leftKey", null);
      cmp.set("rightKeySelected", null);
      cmp.set("rightKeyId", null);
      cmp.set("rightKey", null);
    },

    getDefaultRecord: function () {
      return { custom: true, accept: true, name: "" };
    },

    getOverrideFields: function () {
      return ["name", "accept"];
    },

    parseInputPlainText: function (record) {
      return parseInputPlainTextHelper(record);
    },

    parseResponse: function (response) {
      return parseResponseHelper(response);
    },

    setParent: function (record) {
      var parentId = record.leftContainerId || (record.leftContainer || {}).id;
      cmp.set("parentId", parentId);
      return parentId;
    },

    leftContainerFilterToParent: function () {
      var parentId = cmp.get("parentId");
      if (parentId) {
        cmp.set("leftKeyFilters", {
          keys: { key: { containerId: parentId } },
        });
      } else {
        cmp.set("leftKeyFilters", null);
      }
    },

    setRightKeyFilter: function () {
      var leftKey = cmp.get("leftKey");
      if (leftKey && leftKey.dataType && leftKey.dataType.dataFormat) {
        cmp.set("rightKeyFilters", {
          keys: { key: { dataFormatId: leftKey.dataType.dataFormat.id } },
        });
      } else {
        cmp.set("rightKeyFilters", null);
      }
    },

    updateUI: function () {
      try {
        var chain = cmp.get("record") || {};
        var chainLinks = chain.inputs || [];
        // sort by argOrder
        var orderBy = "argOrder";
        var orderFunc = Record.sortFunction(chainLinks, orderBy);
        chainLinks.sort(orderFunc(orderBy));
        // set Key and Container details on records
        chainLinks.forEach((chainLink) => {
          var link = chainLink.link || {};
          var linkKeys = link.inputs || [];
          // sort by (isLeft, argOrder)
          var orderBy = "argOrder";
          var orderFunc = Record.sortFunction(linkKeys, orderBy);
          linkKeys.sort(orderFunc(orderBy));
          orderBy = "isLeft";
          orderFunc = Record.sortFunction(linkKeys, orderBy);
          linkKeys.sort(orderFunc(orderBy, true));
          // set Key and Container details on records
          linkKeys.forEach((linkKey) => {
            var key = linkKey.key || {};
            var container = key.container || {};
            var source = container.source || {};
            Object.assign(linkKey, {
              name: (linkKey.isLeft ? "From: " : "To: ") + key.name,
              keyName: key.name,
              robustDistinct: key.robustDistinct,
              containerName: container.name,
              sourceName: source.name,
            });
          });
          Object.assign(chainLink, {
            name: link.name,
            robustDistinct: link.robustDistinct,
            object: "link",
            _children: linkKeys,
          });
        });

        // split left and right
        cmp.set("dataTableRecords", chainLinks);
      } catch (err) {
        console.error(err.stack);
      }
    },

    saveChainLink: function () {
      try {
        var leftKey = cmp.get("leftKey");
        var rightKey = cmp.get("rightKey");
        if (!leftKey || !rightKey) {
          cmp.setToastState(
            "warning",
            "Missing Field",
            'Select both a "From" and a "To" Field'
          );
          return;
        }

        var leftType = leftKey.dataType || {};
        var rightType = rightKey.dataType || {};
        if (
          !leftType.dataFormat ||
          !rightType.dataFormat ||
          leftType.dataFormat.id !== rightType.dataFormat.id
        ) {
          cmp.setToastState(
            "warning",
            "Mismatching Formats",
            '"From" and "To" Fields must have the same data format'
          );
          return;
        }
        var leftContainer = leftKey.container || {};
        var rightContainer = rightKey.container || {};
        // construct LinkKeys, Link and ChainLink records
        var record = cmp.get("record") || {};
        var chainLinks = record.inputs || [];
        var numChainLinks = chainLinks.length;
        var name =
          (leftKey.container || {}).name +
          "." +
          leftKey.name +
          " + " +
          (rightKey.container || {}).name +
          "." +
          rightKey.name;
        var linkKeys = [
          {
            id: Record.uuidv4(),
            isLeft: true,
            argOrder: 0,
            keyId: leftKey.id,
            key: leftKey,
          },
          {
            id: Record.uuidv4(),
            isLeft: false,
            argOrder: 0,
            keyId: rightKey.id,
            key: rightKey,
          },
        ];
        var link = {
          name,
          type: "Custom Record",
          leftContainerId: leftContainer.id,
          rightContainerId: rightContainer.id,
          leftContainer,
          rightContainer,
          inputs: linkKeys,
        };
        var chainLink = { id: Record.uuidv4(), argOrder: numChainLinks, link };
        // update Chain record
        if (numChainLinks === 0) {
          Object.assign(record, {
            leftContainer,
            leftContainerId: leftContainer.id,
            leftContainerName: leftContainer.name,
          });
        }
        Object.assign(record, {
          rightContainer,
          rightContainerId: rightContainer.id,
          rightContainerName: rightContainer.name,
        });
        chainLinks.push(chainLink);
        record.inputs = chainLinks;
        // update filters
        cmp.set("leftKeyFilters", {
          keys: { key: { containerId: rightContainer.id } },
        });
        cmp.set("rightKeyFilters", null);
        // update UI
        this.clearKeyFields(cmp);
        cmp.set("record", record);
        // the next Link's leftContainer has to match the current Link's rightContainer
        this.updateUI();
      } catch (err) {
        console.error(err.stack);
      }
    },

    removeLastChainLink: function () {
      try {
        var record = cmp.get("record") || {};
        var chainLinks = record.inputs || [];
        var numChainLinks = chainLinks.length;
        if (numChainLinks) {
          chainLinks.pop();
          numChainLinks = chainLinks.length;
        }
        // update Chain.container fields
        if (numChainLinks) {
          var lastChainLink = chainLinks[numChainLinks - 1];
          var rightContainer = (lastChainLink.link || {}).rightContainer || {};
          cmp.set("leftKeyFilters", {
            keys: { key: { containerId: rightContainer.id } },
          });
          Object.assign(record, {
            rightContainer,
            rightContainerId: rightContainer.id,
            rightContainerName: rightContainer.name,
          });
        } else {
          this.leftContainerFilterToParent(cmp);
          Object.assign(record, {
            leftContainer: null,
            leftContainerId: null,
            leftContainerName: null,
            rightContainer: null,
            rightContainerId: null,
            rightContainerName: null,
          });
        }
        // update UI
        this.clearKeyFields(cmp);
        cmp.set("record", record);
        this.updateUI();
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- New functions ---

    name: "PsChain",

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    notifyNavigation: function (parentId, module, object, id) {
      let event = new Event("navigation");
      event.parentId = parentId;
      event.module = module;
      event.obj = object;
      event.id = id;
      event.source = "grid";

      cmp.handleEvent(event);
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    handleClickOutsideOfPsNavigationInput: function (event) {
      if (
        !leftPsNavigationInputRef.current &&
        !rightPsNavigationInputRef.current
      ) {
        return;
      }

      const isExpanded =
        leftPsNavigationInputRef.current.contains(event.target) ||
        rightPsNavigationInputRef.current.contains(event.target);

      cmp.set("isExpanded", isExpanded);
    },

    handleReset: function () {
      try {
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    checkForm: function () {
      try {
        const record = cmp.get("record");
        if (!record.name) {
          cmp.set("missingRequiredFields", ["record.name"]);
        }
        return !record.name;
      } catch (error) {
        console.error(error.stack);
      }
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },

    body: function () {
      return (
        <div className="slds-form slds-var-m-around_medium" role="list">
          <h3 className="slds-section-title--divider slds-var-m-top_medium">
            Path Details
          </h3>
          <div className="slds-form__row">
            {/* <!-- Name --> */}
            <NameInputWithUndoIcon cmpState={cmpState} cmp={cmp} />
          </div>

          <div className="slds-form__row">
            {/* <!-- Left Container --> */}
            <div
              className="slds-form__item slds-grid_vertical-align-center"
              role="listitem"
            >
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {(cmpState.mode === "new" ||
                  cmpState.mode === "edit" ||
                  cmpState.mode === "view") && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">From</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.leftContainerName}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <!-- Right Container --> */}
            <div
              className="slds-form__item slds-grid_vertical-align-center"
              role="listitem"
            >
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {(cmpState.mode === "new" ||
                  cmpState.mode === "edit" ||
                  cmpState.mode === "view") && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">To</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.rightContainerName}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="slds-form__row">
            {/* <!-- Custom --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {(cmpState.mode === "new" ||
                  cmpState.mode === "edit" ||
                  cmpState.mode === "view") && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Custom</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        <CheckAndCloseIcons
                          selectedItem={cmpState.record.custom}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <!-- Accept --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" ? (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">
                      Use in Pattern Discovery
                    </span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        <CheckAndCloseIcons
                          selectedItem={cmpState.record.accept}
                        />
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon handleEdit={cmp.handleEdit} />
                      </div>
                    </div>
                  </div>
                ) : null}
                {cmpState.mode === "new" || cmpState.mode === "edit" ? (
                  <div verticalalign="start">
                    <label
                      className="slds-form-element__label"
                      htmlFor="toggle"
                    >
                      Use in pattern discovery
                    </label>
                    <div style={{ display: "flex", width: "14em" }}>
                      <label className="slds-checkbox_toggle slds-grid">
                        <input
                          type="checkbox"
                          checked={cmpState.record.accept || false}
                          onChange={() =>
                            PsRecord.handleToggleChange(cmp, "accept")
                          }
                        />
                        <span
                          id="checkbox-toggle-16"
                          className="slds-checkbox_faux_container"
                        >
                          <span className="slds-checkbox_faux"></span>
                          <span className="slds-checkbox_on">Yes</span>
                          <span className="slds-checkbox_off">No</span>
                        </span>
                      </label>{" "}
                      {cmpState.record.hasOverride?.accept && (
                        <div
                          style={{
                            marginTop: "-25px",
                          }}
                        >
                          <UndoButtonIcon
                            handleRevert={cmp.handleRevert}
                            field="accept"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {(cmpState.mode === "init" || cmpState.mode === "view") && (
            <Statistics cmp={cmp} cmpState={cmpState} />
          )}
          {(cmpState.mode === "init" ||
            cmpState.mode === "new" ||
            cmpState.mode === "view") && (
            <>
              <h3 className="slds-section-title--divider slds-var-m-top_medium">
                Joins
              </h3>
              <p>&nbsp;</p>
              <div className="slds-form__row">
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {(cmpState.mode === "view" || cmpState.mode === "new") && (
                      <>
                        {/* <lightning:treeGrid aura:id="recordtree" keyField="{!v.dataTableKeyField}" columns="{!v.dataTableColumns}" data="{!v.dataTableRecords}" 
                        hideCheckboxColumn="true" minColumnWidth="100" columnWidthsMode="auto" isLoading="{!v.loading}" onrowaction="{!c.handleRecordRowAction}"/> */}
                        <PsTreeGrid
                          hideCheckboxColumn={true}
                          minColumnWidth="100"
                          columnWidthsMode="auto"
                          onrowaction={cmp.handleRecordRowAction}
                          cmpState={cmpState}
                          cmp={cmp}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {cmpState.mode === "new" && (
            <>
              {/* <!-- Left and Right Keys to add --> */}
              <div className="slds-form__row">
                <div
                  className="slds-form__item"
                  role="listitem"
                  ref={leftPsNavigationInputRef}
                >
                  {/* <c:navigationInput label="From Field" object="key" sections="['keys']" selected="{!v.leftKeySelected}" value="{!v.leftKeyId}" record="{!v.leftKey}" 
                  filters="{!v.leftKeyFilters}" onchange="{!c.handleLeftKeyChange}"/> */}

                  <PsNavigationInput
                    label="From Field"
                    object="key"
                    sections={["keys"]}
                    selected={cmpState.leftKeySelected}
                    value={cmpState.leftKeyId}
                    record={cmpState.leftKey}
                    filters={cmpState.leftKeyFilters}
                    onChange={cmp.handleLeftKeyChange}
                    ////
                    isExpanded={cmpState.isExpanded}
                    activeField={cmpState.activeField}
                    setParentCmpState={setCmpState}
                    showRequiredFieldError={false}
                    childToParent={props.childToParent}
                  />
                </div>
                <div
                  className="slds-form__item"
                  role="listitem"
                  ref={rightPsNavigationInputRef}
                >
                  {/* <c:navigationInput label="To Field" object="key" sections="['keys']" selected="{!v.rightKeySelected}" value="{!v.rightKeyId}" 
                  record="{!v.rightKey}" filters="{!v.rightKeyFilters}" onchange="{!c.handleRightKeyChange}" disabled="{! empty(v.leftKeyId) }"/> */}
                  <PsNavigationInput
                    label="To Field"
                    object="key"
                    sections={["keys"]}
                    selected={cmpState.rightKeySelected}
                    value={cmpState.rightKeyId}
                    record={cmpState.rightKey}
                    filters={cmpState.rightKeyFilters}
                    onChange={cmp.handleRightKeyChange}
                    disabled={!cmpState.leftKey}
                    ////
                    isExpanded={cmpState.isExpanded}
                    activeField={cmpState.activeField}
                    setParentCmpState={setCmpState}
                    showRequiredFieldError={false}
                    childToParent={props.childToParent}
                  />
                </div>
              </div>

              {/* <!-- Buttons for add / remove --> */}
              <Buttons cmpState={cmpState} cmp={cmp} />
            </>
          )}
        </div>
      );
    },
  };

  return PsRecord.render(cmp, cmpState);
};

export default PsChain;
