import { useEffect, useState, useRef } from "react";
import {
  Card,
  IconSettings,
  Input,
  Tooltip,
} from "@salesforce/design-system-react";
import Spinner from "@salesforce/design-system-react/components/spinner";

import Record from "../../../helpers/recordLayer";
import UndoButtonIcon from "../../ui/UndoButtonIcon";
import { toastErrorMessage } from "../../../helpers";
import RecordConstants from "../../../constants/RecordConstants";

const PatternEdit = (props) => {
  const selectedAxis = props.parentCmp.get("selectedAxis") || {};
  const inputId = selectedAxis.inputId;
  let pattern = props.parentCmp.get("processed");
  // let pattern = props.pattern;

  const [cmpState, setCmpState] = useState({
    loading: true,

    saveDisabledEditModal: true,
    editModalErrorMessage: "",

    selectedAxis: null,
    parsedAxis: null,

    chainLoading: true,
    chainOptionsLoaded: false,
    chainOptions: [],
    chainValue: "",
    chainFeedback: "",

    showKey: false,
    keyLoading: true,
    keyOptionsLoaded: false,
    keyOptions: [],
    keyValue: "",
    keyFeedback: "",

    intervalType: "",
    intervalOptions: [],
    intervalValue: "",

    fillMissingCheckbox: "",
    showFillMissing: false,
    significantDigitsValue: null,
    showSignificantDigits: false,
    showFormat: false,
    formatString: "",
    missingValueString: "",
  });
  const [tooltipOpen, setTooltipOpen] = useState([]);

  const cmpWorking = useRef({});

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    parseAxis();
  }, []);

  const cmp = {
    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },
  };

  const setLoading = () => {
    cmp.set("loading", true);
    cmp.set("chainLoading", true);
    cmp.set("keyLoading", true);
  };

  const unloadList = (item) => {
    cmp.set(
      [`${item}Options`],
      [{ id: "", name: "Not Available", selected: true }]
    );
    cmp.set([`${item}Value`], "");
    cmp.set([`${item}OptionsLoaded`], false);
    cmp.set([`${item}Loading`], false);
  };

  const getKeyOptions = (axis) => {
    try {
      var dataType = axis.key.dataType || {};
      var params = {
        containerId: axis.key.container?.id,
        orderBy: "name ASC",
      };
      if (dataType.format === "DateTime") {
        params.dataFormatId = axis.key.dataType.dataFormat.id;
      } else if (dataType.role === "Metric" && dataType.name !== "Count") {
        params.dataRoleId = axis.key.dataType.dataRole.id;
      } else {
        params.dataTypeId = axis.key.dataType?.id;
      }
      var suppressCount =
        dataType.role === "Metric" && dataType.name !== "Count";

      var onSuccess = function (response) {
        if (!response || !response.length) {
          unloadList("key");
        } else {
          var selected = response.find((v) => v.id === axis.key.id) || {};
          selected.selected = true;
          if (suppressCount) {
            // remove 'Count' field from result
            response = response.filter((v) => v.dataType.name !== "Count");
          }

          cmp.set("keyOptions", response);
          cmp.set("keyOptionsLoaded", true);
          cmp.set("keyLoading", false);
        }
      };

      var onError = function (response) {
        cmp.checkUser(response);
        props.parentCmp.setToastState(
          "error",
          "Error",
          toastErrorMessage(response)
        );
        unloadList("key");
        cmp.set("keyFeedback", "Alternative keys could not be loaded.");
      };
      Record.getRecords("store", "key", params, onSuccess, onError);
    } catch (err) {
      console.error("err-getKeyOptions:", err);
    }
  };

  const getChainOptions = (axis) => {
    var onSuccess = function (response) {
      if (!response || !response.length) {
        unloadList("chain");
        cmp.set("chainFeedback", "Alternative Chains could not be loaded.");
      } else {
        response.forEach((ch) => {
          if (ch.id === axis.chain.id) {
            ch.selected = true;
          }
        });
        cmp.set("chainOptions", response);
        cmp.set("chainOptionsLoaded", true);
        cmp.set("chainLoading", false);
      }
    };

    var onError = function (response) {
      cmp.checkUser(response);
      props.parentCmp.setToastState(
        "error",
        "Error",
        toastErrorMessage(response)
      );
      unloadList("chain");
      cmp.set("chainFeedback", "Alternative Chains could not be loaded.");
    };

    var leftContainerId = axis.chain.leftContainerId;
    var rightContainerId = axis.chain.rightContainerId;
    if (leftContainerId && rightContainerId) {
      Record.getRecords(
        "store",
        "chain",
        { leftContainerId, rightContainerId, orderBy: "name ASC" },
        onSuccess,
        onError
      );
    } else {
      unloadList("chain");
      cmp.set("chainFeedback", "Alternative Chains could not be loaded.");
    }
  };

  const getChainDetails = (axis) => {
    var onSuccess = function (response) {
      var record = response[0];

      axis.chain.leftContainerId = record.leftContainer.id;
      axis.chain.rightContainerId = record.rightContainer.id;
      getChainOptions(axis);
    };

    var onError = function (response) {
      cmp.checkUser(response);
      props.parentCmp.setToastState(
        "error",
        "Error",
        toastErrorMessage(response)
      );
      unloadList("chain");
      cmp.set("chainFeedback", "Chain could not be loaded.");
    };
    Record.getRecord(
      "store",
      "chain",
      axis.chain.id,
      {},
      "",
      "GET",
      onSuccess,
      onError
    );
  };

  const parseAxis = () => {
    try {
      setLoading();
      // pattern node
      var axis = {};
      var input = (pattern.inputs || []).find(
        (v) => (v.id || v.floatingId) === inputId
      );

      if (!input) {
        unloadList("key");
        unloadList("chain");
        cmp.set("loading", false);
        return;
      }

      // settings
      input.name = selectedAxis.labelString; // IMPROVEMENT: remove this line when input.name is set by DeepSigma in the future
      var isSql = input.type === "SQL";

      // overrides
      var fields = ["name"];
      var overridden = input.overridden || {};
      axis.name = input.name;
      axis.original = fields.reduce((result, item) => {
        result[item] = item in overridden ? overridden[item] : input[item];
        return result;
      }, {});
      axis.hasOverride = fields.reduce((result, item) => {
        result[item] = item in overridden;
        return result;
      }, {});

      // get Key and Chain
      var showKey = !isSql;
      if (showKey) {
        var flattened = Record.flatten(input, "inputs");
        flattened.forEach((node) => {
          if (node.type === "Load" && node.key) {
            axis.key = !axis.key ? node.key : null; // in case of multiple keys, disable the key again
            axis.keyNodeId = node.id || node.floatingId;
          }
          if (node.type === "Join" && node.chain) {
            axis.chain = node.chain;
            axis.chainNodeId = node.id || node.floatingId;
          }
          if ((node.type || "").startsWith("Transform")) {
            axis.transform = node;
          }
        });
      }

      if (axis.key) {
        cmp.set("keyValue", axis.key.id);
        getKeyOptions(axis);
      } else {
        unloadList("key");
        cmp.set(
          "keyFeedback",
          "Changing multiple Fields is not currently supported."
        );
      }

      if (axis.chain) {
        cmp.set("chainValue", axis.chain.id);
        getChainDetails(axis);
      } else {
        unloadList("chain");
        cmp.set(
          "chainFeedback",
          "Changing Path is not supported when source Field is on same Object."
        );
      }

      // set values from Refine Node
      var computed = input.computed || {};
      var parameters = input.parameters || {};

      // interval
      var dataType = computed.dataType || {};
      var interval = computed.interval;
      var intervalType = dataType.format;
      var minInterval = computed.minInterval;
      var showInterval = false;

      if (dataType.name === "Time Difference") {
        showInterval = true;
        intervalType = dataType.name;
        interval = computed.unit;
        minInterval = null;
      } else if (["DateTime", "Number"].includes(intervalType)) {
        var skipDecimate = Boolean(parameters.skipDecimate);
        var hasMaxNumberLike = parameters.maxNumberLike != null;
        var hasInterval = parameters.interval != null;
        showInterval = !skipDecimate && (hasMaxNumberLike || hasInterval);
      }

      // populate interval options
      var intervalOptions = null;
      if (["DateTime", "Time Difference"].includes(intervalType)) {
        interval = interval || "";
        intervalOptions = JSON.parse(
          JSON.stringify(RecordConstants.DATETIME_ROLLUP)
        );
        var disabled = Boolean(minInterval);
        intervalOptions.forEach((v) => {
          if (v.value === minInterval) {
            disabled = false;
          }
          v.selected = v.value === interval;
          v.disabled = disabled;
        });
      }

      // missing values
      var missingValue = parameters.missingValue;

      // significant digits
      var significantDigits = computed.significantDigits;
      var showSignificantDigits = dataType.role === "Metric";

      // format
      var formatString = parameters.format;
      var showFormat = true; //['DateTime', 'Number'].includes(dataType.format) || formatString != null;

      cmp.set("showKey", showKey);
      cmp.set("parsedAxis", axis);
      cmp.set("showInterval", showInterval);
      cmp.set("intervalValue", interval);
      cmp.set("intervalOptions", intervalOptions);
      cmp.set("intervalType", intervalType);
      cmp.set("missingValueString", missingValue);
      cmp.set("showFormat", showFormat);
      cmp.set("formatString", formatString);
      cmp.set("significantDigitsValue", significantDigits);
      cmp.set("showSignificantDigits", showSignificantDigits);
    } catch (err) {
      console.error(err.stack);
    } finally {
      cmp.set("loading", false);
    }
  };

  const handleOptionChange = (e, item) => {
    cmp.set([item], e.target.value);
  };

  const saveEditModal = () => {
    try {
      applyChanges();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const applyChanges = () => {
    try {
      if (!pattern.inputs) {
        return;
      }
      const axis = cmp.get("parsedAxis") || {};

      var input = pattern.inputs.find(
        (v) => (v.id || v.floatingId) === inputId
      );
      if (!input) {
        return;
      }

      var computed = input.computed || {};
      var dataType = computed.dataType || {};
      var parameters = input.parameters || {};
      input.parameters = parameters;

      // key and chain
      var oldKeyId = null;
      var keyId = cmp.get("keyValue");
      var chainId = cmp.get("chainValue");

      var flattened = Record.flatten(input, "inputs");
      flattened.forEach((node) => {
        var id = node.id || node.floatingId;
        if (keyId && id === axis.keyNodeId) {
          oldKeyId = node.keyId || (node.key || {}).id;
          node.key = { id: keyId };
          node.keyId = keyId;
        }
        if (chainId && id === axis.chainNodeId) {
          node.chain = { id: chainId };
          node.chainId = chainId;
        }
      });

      // TODO: unit and interval to be configured separately; sometimes we can have both interval and unit, e.g., if summing by timedelta buckets
      // interval
      var interval = cmp.get("intervalValue");
      var intervalType = cmp.get("intervalType");

      if (!interval && interval !== 0) {
        delete parameters.interval;
        delete parameters.unit;
      } else if (intervalType === "Number") {
        parameters.interval = parseFloat(interval);
      } else if (intervalType === "Time Difference") {
        input.parameters.unit = interval;
        parameters.unit = interval;
      } else {
        input.parameters.interval = interval;
        parameters.interval = interval;
      }

      // missingValue
      var missingValue = (cmp.get("missingValueString") || "").trim();
      if (missingValue) {
        parameters.missingValue =
          dataType.format === "Number" ? parseInt(missingValue) : missingValue;
      } else {
        delete parameters.missingValue;
      }

      // formatString
      const formatString = (cmp.get("formatString") || "").trim();
      if (formatString) {
        parameters.format = formatString;
      } else {
        delete parameters.format;
      }

      // significiant digits
      var significantDigits = cmp.get("significantDigitsValue");
      if (significantDigits || significantDigits === 0) {
        parameters.significantDigits = parseInt(significantDigits);
      } else {
        delete parameters.significantDigits;
      }

      // IMPROVEMENT: Replace the main key? How do we know if that is required?
      // LP COMMENT: this should no longer be necessary as we now only use containerId of the pattern, which should not be possible to change

      if (pattern.key.id === oldKeyId) {
        pattern = { ...pattern, key: { id: keyId }, keyId: keyId };
      }
      pattern = { ...pattern, id: undefined, name: undefined };

      // update pattern inputs
      const inputIndex = (pattern.inputs || []).findIndex(
        (v) => (v.id || v.floatingId) === inputId
      );

      if (inputIndex !== -1) {
        const parsedAxis = cmp.get("parsedAxis") || {};
        let updatedInput = {
          ...pattern.inputs[inputIndex],
          name: parsedAxis.name,
        };

        const originalName = parsedAxis.original.name;
        if (originalName && originalName !== parsedAxis.name) {
          updatedInput.overridden = { name: originalName };
        }
        const updatedInputs = [...pattern.inputs];
        updatedInputs[inputIndex] = updatedInput;
        pattern = { ...pattern, inputs: updatedInputs };
      }

      props.parentCmp.set("processed", pattern);

      var event = {
        data: { action: "update", pattern: pattern },
        type: "dataCompEvent",
      };
      props.handleEvent(event);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const setOverride = (field) => {
    var axis = cmp.get("parsedAxis") || {};
    if (axis.hasOverride) {
      axis.hasOverride[field] = true;
    }
    cmp.set("parsedAxis", axis);
  };

  const handleOverride = (field, value) => {
    try {
      const parsedAxis = cmp.get("parsedAxis");
      cmp.set(
        "parsedAxis",
        parsedAxis ? { ...parsedAxis, name: value } : { name: value }
      );

      setOverride(field);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const revertOverride = (field) => {
    var axis = cmp.get("parsedAxis") || {};
    if (axis.original) {
      axis[field] = axis.original[field];
    }
    if (axis.hasOverride) {
      axis.hasOverride[field] = false;
    }
    cmp.set("parsedAxis", axis);

    // update pattern
    const parsedAxis = cmp.get("parsedAxis");
    const inputIndex = (pattern.inputs || []).findIndex(
      (v) => (v.id || v.floatingId) === inputId
    );

    if (inputIndex === -1) {
      return;
    }

    const updatedInput = {
      ...pattern.inputs[inputIndex],
      [field]: parsedAxis.original[field],
      overridden: null,
    };

    const updatedInputs = [...pattern.inputs];
    updatedInputs[inputIndex] = updatedInput;
    const updatedPattern = { ...pattern, inputs: updatedInputs };
    props.parentCmp.set("processed", updatedPattern);
  };

  const handleRevert = (field) => {
    try {
      revertOverride(field);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleMouseEnter = (label) => {
    setTooltipOpen((prev) => [...prev, label]);
  };
  const handleMouseLeave = (label) => {
    setTimeout(() => {
      setTooltipOpen((prev) =>
        prev.filter((tooltipItem) => tooltipItem !== label)
      );
    }, 100);
  };

  const clickableTooltip = (label) => {
    return (
      <div
        onMouseEnter={() => handleMouseEnter(label)}
        onMouseLeave={() => handleMouseLeave(label)}
      >
        <Tooltip
          align="top left"
          content={
            <>
              Formatting string, for example: "%d/%m/%y %H:%M:%S.%f" for date
              and time, or "£ {"{"}:.2f {"}"}" for numbers. See Python&apos;s
              string formatting mini-language for full details:{" "}
              <span>
                <a
                  href="https://docs.python.org/3/library/string.html#formatspec"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#fff", textDecoration: "underline" }}
                >
                  https://docs.python.org/3/library/string.html#formatspec
                </a>
              </span>
            </>
          }
          isOpen={tooltipOpen.includes(label)}
          variant="list-item"
        >
          <button
            className="slds-button slds-button_icon"
            aria-describedby="help"
            aria-disabled="true"
            title=""
            onMouseEnter={() => handleMouseEnter(label)}
            onMouseLeave={() => handleMouseLeave(label)}
          >
            <svg className="slds-button__icon" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#info"></use>
            </svg>
          </button>
        </Tooltip>
      </div>
    );
  };

  const labelAndFieldLevelHelpTooltip = (label) => (
    <div style={{ display: "flex" }}>
      <label className="slds-form-element__label" htmlFor="text-input-id-50">
        {label}
      </label>
      <div style={{ marginTop: "2px" }}>{clickableTooltip(label)}</div>
    </div>
  );

  return (
    <IconSettings iconPath="/assets/icons">
      <div>
        <section
          role="dialog"
          tabIndex="-1"
          aria-modal={props.editModalIsOpen}
          aria-labelledby="modal-heading-01"
          className="slds-modal slds-fade-in-open"
        >
          <div className="slds-modal__container">
            <button
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              onClick={() => props.toggleEditModal()}
            >
              <svg
                className="slds-button__icon slds-button__icon_small"
                aria-hidden="true"
              >
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
              </svg>
              <span className="slds-assistive-text">Cancel and close</span>
            </button>
            <div className="slds-modal__header">
              <h1
                id="modal-heading-01"
                className="slds-modal__title slds-hyphenate"
              >
                Edit Axis
              </h1>
            </div>
            <div
              className="slds-modal__content slds-p-around_medium"
              id="modal-content-id-1"
            >
              <Card heading="">
                {cmpState.loading && (
                  <Spinner assistiveText={{ label: "Loading" }} />
                )}

                <div verticalalign="start" style={{ display: "flex" }}>
                  <div
                    flexibility="auto"
                    style={{ flexibility: "auto", width: "100%" }}
                  >
                    <Input
                      id="checkField"
                      name="name"
                      autoComplete="off"
                      label="Name"
                      value={cmpState.parsedAxis?.name || ""}
                      onChange={(e) => handleOverride("name", e.target.value)}
                    />
                  </div>

                  {cmpState.parsedAxis?.hasOverride?.name && (
                    <UndoButtonIcon handleRevert={handleRevert} field="name" />
                  )}
                </div>

                {cmpState.showKey && (
                  <>
                    <br />
                    <div className="slds-form-element">
                      <label
                        className="slds-form-element__label"
                        htmlFor="select-id"
                      >
                        Select a Field of the same Data Type and from the same
                        Object:
                      </label>
                      <div className="slds-form-element__control">
                        <div className="slds-select_container">
                          <select
                            className="slds-select"
                            id="select-id"
                            value={cmpState.keyValue}
                            onChange={(e) => handleOptionChange(e, "keyValue")}
                            disabled={cmpState.keyFeedback}
                          >
                            {cmpState.keyOptions?.map((option) => (
                              <option value={option.id} key={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {cmpState.keyFeedback || null}
                      </div>
                    </div>
                    <br />
                    <div className="slds-form-element">
                      <label
                        className="slds-form-element__label"
                        htmlFor="select-id"
                      >
                        Select a Path to connect to the Pattern:
                      </label>
                      <div className="slds-form-element__control">
                        <div className="slds-select_container">
                          <select
                            className="slds-select"
                            id="select-id"
                            value={cmpState.chainValue}
                            onChange={(e) =>
                              handleOptionChange(e, "chainValue")
                            }
                            disabled={cmpState.chainFeedback}
                          >
                            {cmpState.chainOptions?.map((option) => (
                              <option value={option.id} key={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {cmpState.chainFeedback || null}
                      </div>
                    </div>
                  </>
                )}

                {cmpState.showInterval &&
                  (cmpState.intervalType === "DateTime" ||
                    cmpState.intervalType === "Time Difference") && (
                    <>
                      <br />
                      <div className="slds-form-element">
                        <label
                          className="slds-form-element__label"
                          htmlFor="select-id"
                        >
                          Grouping Interval
                        </label>
                        <div className="slds-form-element__control">
                          <div className="slds-select_container">
                            <select
                              className="slds-select"
                              id="select-id"
                              value={cmpState.intervalValue}
                              onChange={(e) =>
                                handleOptionChange(e, "intervalValue")
                              }
                            >
                              {cmpState.intervalOptions?.map((option) => (
                                <option
                                  value={option.value}
                                  key={option.value}
                                  disabled={option.disabled}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {cmpState.showInterval &&
                  cmpState.intervalType === "Number" && (
                    <>
                      <br />
                      <div className="slds-form-element__control">
                        <Input
                          type="text"
                          label="Grouping Interval"
                          value={cmpState.intervalValue || ""}
                          onChange={(e) =>
                            cmp.set("intervalValue", e.target.value)
                          }
                        />
                      </div>
                    </>
                  )}
                <br />
                <Input
                  name="missingValue"
                  autocomplete="off"
                  label="Replace missing with"
                  value={cmpState.missingValueString || ""}
                  onChange={(e) =>
                    cmp.set("missingValueString", e.target.value)
                  }
                />

                {cmpState.showFormat && (
                  <>
                    <br />
                    {labelAndFieldLevelHelpTooltip("Format string")}
                    <Input
                      name="formatString"
                      autocomplete="off"
                      label=""
                      value={cmpState.formatString || ""}
                      onChange={(e) => cmp.set("formatString", e.target.value)}
                    />
                  </>
                )}

                {cmpState.showSignificantDigits && (
                  <>
                    <br />
                    <Input
                      type="number"
                      min="0"
                      step={1}
                      name="SignificantDigits"
                      label="Significant digits"
                      value={cmpState.significantDigitsValue || ""}
                      fieldLevelHelpTooltip={
                        <Tooltip
                          id="field-level-help-tooltip"
                          align="top left"
                          content="Round values to this number of significant digits. Set to 0 to disable, or leave empty to reset to the default."
                        />
                      }
                      onChange={(e) =>
                        cmp.set("significantDigitsValue", e.target.value)
                      }
                    />
                  </>
                )}
              </Card>
            </div>
            <div className="slds-modal__footer">
              <button
                className="slds-button slds-button_neutral"
                aria-label="Cancel and close"
                onClick={() => props.toggleEditModal()}
              >
                Cancel
              </button>
              <button
                className="slds-button slds-button_brand"
                onClick={() => saveEditModal()}
              >
                Save
              </button>
            </div>
          </div>
        </section>
        <div
          className="slds-backdrop slds-backdrop_open"
          role="presentation"
        ></div>
      </div>
    </IconSettings>
  );
};

export default PatternEdit;
