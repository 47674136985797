import Modal from "../ui/Modal";
import { useEffect, useRef, useState } from "react";
import PsRecord from "../ps-record/PsRecord";
import { isValidEmail } from "../../helpers";
import PsUserFormBody from "./components/PsUserFormBody";
import RecordConstants from "../../constants/RecordConstants";
import useAuthContext from "../../context/useAuthContext";

const PsUser = (props) => {
  const [fieldsError, setFieldError] = useState({ email: "", name: "" });
  const [cmpState, setCmpState] = useState({
    recordLabel: "User",
    recordModule: "core",
    recordObject: "user",
    loading: false,
    mode: "init",
    recordId: props.recordId,
    record: {
      name: "",
      email: "",
      status: "",
      type: "",
    },
    showEdit: true,
    showDelete: true,
    parentPrefix: "",
    errorTextcheckFields: ["name"],
    missingRequiredFields: [],
    showDeleteConfirmDialog: false,
    deleteConfirmation: false, // if deleteConfirmation is true it does NOT show the confirmation popUp
  });
  const cmpWorking = useRef({});
  const { userDetails } = useAuthContext();

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.afterScriptsLoaded();
  }, [props.recordId]);

  useEffect(() => {
    if (!cmpState?.record?.id) return;
    // Hide delete button when the user is updating the own profile
    setCmpState((prev) => ({
      ...prev,
      showDelete: userDetails?.id !== cmpState.record.id,
    }));
  }, [userDetails, cmpState.record.id]);

  useEffect(() => {
    if (!props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent.action]);

  const cmp = {
    init: function (cmp, event, helper) {},

    afterScriptsLoaded: function () {
      cmp.set("statusOptions", Object.values(RecordConstants.GENERIC_STATUS));
      cmp.set("userTypesOptions", Object.values(RecordConstants.USER_TYPE));
      PsRecord.getRecord(cmp);
    },

    handleReload: function () {
      PsRecord.getRecord(cmp);
    },

    handleEdit: function () {
      PsRecord.setMode(cmp, "edit");
    },

    handleCancel: function () {
      PsRecord.cancelRecord(cmp);
    },

    // Handle a generic message  onCreate and onUpdate user Information
    submitCallback: function (_cmp, isSuccess) {
      if (isSuccess) {
        cmp.setToastState(
          "success",
          "User Information Saved",
          "User information has been successfully saved."
        );
        return;
      }
      cmp.setToastState(
        "error",
        "Save Failed",
        "User information could not be saved. Please try again."
      );
    },

    handleSubmit: function (cmp) {
      PsRecord.submitRecord(cmp, cmp.submitCallback);
    },

    handleDelete: function (cmp) {
      PsRecord.deleteRecord(cmp); // NB: this navigates to parent record after successfull delete
    },

    getDefaultRecord: function (_cmp) {
      return { name: "", email: "", status: "Active", type: "User" };
    },

    parseResponse: function (response) {
      return response.map(({ id, name, type, status, email }) => ({
        id,
        name,
        type,
        status,
        email,
      }));
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({
        variant,
        heading,
        details,
      });
    },

    handleFieldChange: function (e) {
      const { value, name } = e.target;
      let record = cmp.get("record");
      record = { ...record, [name]: value };
      cmp.set("record", record);
      if (value) {
        cmp.set("missingRequiredFields", []);
        setFieldError((prev) => ({ ...prev, [name]: "" }));
      }
    },

    handleEvent: function (event) {
      if (!!event.obj) props.childToParent(event);
      if (!event.obj) props.childToParent({ ...event, obj: "users" }); // redirect the user to main table (PsUserList)
    },

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },
    // checkForm return true if there is an error
    checkForm: function () {
      try {
        const { name, email } = cmp.get("record");
        const isValidUserName = !!name && !name?.includes(" "); // type boolean
        const isValidUserEmail = isValidEmail(email); // type boolean
        if (!isValidUserName) {
          const missingRequiredFields = cmp.get("missingRequiredFields");
          setFieldError((prev) => ({
            ...prev,
            name: !name
              ? "Complete this field."
              : "This field required no empty space.",
          }));
          cmp.set("missingRequiredFields", [
            ...missingRequiredFields,
            "record.name",
          ]);
        }
        if (!isValidUserEmail) {
          const missingRequiredFields = cmp.get("missingRequiredFields");
          setFieldError((prev) => ({
            ...prev,
            email: !email ? "Complete this field." : "Invalid email format.",
          }));
          cmp.set("missingRequiredFields", [
            ...missingRequiredFields,
            "record.email",
          ]);
        }
        return !isValidUserName || !isValidUserEmail;
      } catch (error) {
        console.error(error.stack);
      }
    },

    body: function () {
      return (
        <PsUserFormBody
          mode={cmpState.mode}
          record={cmpState.record}
          handleEdit={cmp.handleEdit}
          handleFieldChange={cmp.handleFieldChange}
          getParentStateByKey={cmp.get}
          fieldsError={fieldsError}
        />
      );
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },
  };

  return (
    <>
      {cmpState.showDeleteConfirmDialog ? (
        <Modal
          apply={() => PsRecord.confirmDeleteRecord(cmp)}
          cancel={() => PsRecord.cancelDeleteRecord(cmp)}
          header="Confirmation"
          modalContent="Are you sure you want to delete this user?"
          applyButtonContent="Delete"
        />
      ) : null}
      {PsRecord.render(cmp, cmpState)}
    </>
  );
};

export default PsUser;
