import { formattedNumber, formattedDateTime } from "../../../helpers";
import ProgressBarComponent from "../../ui/ProgressBarComponent";

const Statistics = ({ cmp, cmpState }) => {
  return (
    <>
      <h3 className="slds-section-title--divider slds-var-m-top_medium">
        Statistics
      </h3>

      <div className="slds-form__row">
        {/* <!-- Relationship --> */}
        <div
          className="slds-form__item slds-grid_vertical-align-center"
          role="listitem"
        >
          <div className="slds-form-element slds-form-element_stacked">
            {cmpState.mode === "init" && <ProgressBarComponent />}
            {cmpState.mode === "view" && (
              <div
                id="FormDiv"
                className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
              >
                <span className="slds-form-element__label">Relationship</span>
                <div className="slds-form-element__control">
                  <div className="slds-form-element__static">
                    {cmpState.record.relationship}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* 
      <!-- LastRunOn --> */}
        <div className="slds-form__item" role="listitem">
          <div className="slds-form-element slds-form-element_stacked">
            {cmpState.mode === "init" && <ProgressBarComponent />}
            {cmpState.mode === "view" && (
              <div
                id="FormDiv"
                className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
              >
                <span className="slds-form-element__label">Last Run</span>
                <div className="slds-form-element__control">
                  <div className="slds-form-element__static">
                    {/* <lightning:formattedDateTime value="{!v.record.lastRunOn}" year="numeric" month="long" day="numeric" hour="2-digit" minute="2-digit" second="2-digit" /> */}
                    {formattedDateTime(cmpState.record.lastRunOn)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="slds-form__row">
        {/* <!-- Coverage --> */}
        <div className="slds-form__item" role="listitem">
          <div className="slds-form-element slds-form-element_stacked">
            {cmpState.mode === "init" && <ProgressBarComponent />}
            {cmpState.mode === "view" && (
              <div
                id="FormDiv"
                className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
              >
                <span className="slds-form-element__label">Coverage</span>
                <div className="slds-form-element__control">
                  <div className="slds-form-element__static">
                    {formattedNumber(cmpState.record.coverage)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!-- RobustDistinct --> */}
        <div className="slds-form__item" role="listitem">
          <div className="slds-form-element slds-form-element_stacked">
            {cmpState.mode === "init" && <ProgressBarComponent />}
            {cmpState.mode === "view" && (
              <div
                id="FormDiv"
                className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
              >
                <span className="slds-form-element__label"># Distinct</span>
                <div className="slds-form-element__control">
                  <div className="slds-form-element__static">
                    {formattedNumber(cmpState.record.robustDistinct)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {cmp.name === "PsChain" && (
        <div className="slds-form__row">
          {/* <!-- Relevance --> */}
          <div className="slds-form__item" role="listitem">
            <div className="slds-form-element slds-form-element_stacked">
              {cmpState.mode === "init" && <ProgressBarComponent />}
              {cmpState.mode === "view" && (
                <div
                  id="FormDiv"
                  className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                >
                  <span className="slds-form-element__label">Relevance</span>
                  <div className="slds-form-element__control">
                    <div className="slds-form-element__static">
                      {formattedNumber(cmpState.record.relevance)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="slds-form__item" role="listitem"></div>
        </div>
      )}
    </>
  );
};

export default Statistics;
