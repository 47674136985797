import { Checkbox } from "@salesforce/design-system-react";
import Icon from "@salesforce/design-system-react/components/icon";

const PlotOptions = ({
  cmpState,
  toggleSection,
  plotTypeOnChange,
  sortCheckboxOnChange,
  stack100CheckboxOnChange,
  stackCheckboxOnChange,
}) => {
  const {
    showSectionPlotOptions,
    stackCheckbox,
    stack100Checkbox,
    sortCheckbox,
    plotTypeSelected,
    plotTypeOptions,
    showStackCheckbox,
    showStack100Checkbox,
    showSortCheckbox,
  } = cmpState;

  return (
    <div
      className="slds-col slds-m-horizontal_x-small"
      style={{ minWidth: "300px" }}
    >
      <div
        className={`slds-section ${
          showSectionPlotOptions ? "slds-is-open" : "slds-is-close"
        }`}
        id="sectionPlotOptions"
      >
        <h3>
          <button
            aria-controls="contentPlotOptions"
            className="slds-button slds-section__title-action"
            onClick={() => toggleSection("showSectionPlotOptions")}
          >
            <span id="sectionPlotOptions">
              <Icon
                assistiveText={{ label: "button icon" }}
                category="utility"
                name="switch"
                size="x-small"
                className="slds-section__title-action-icon slds-button__icon_left"
              />
            </span>
            <span className="slds-truncate" title="Plot Options">
              Plot Options
            </span>
          </button>
        </h3>
        {showSectionPlotOptions ? (
          <div>
            <div className="slds-form-element" style={{ marginTop: "10px" }}>
              <label className="slds-form-element__label" htmlFor="select-id">
                Select a plot type:
              </label>
              <div className="slds-form-element__control">
                <div className="slds-select_container">
                  <select
                    className="slds-select"
                    id="select-id"
                    value={plotTypeSelected}
                    onChange={plotTypeOnChange}
                  >
                    {plotTypeOptions.map((option) => (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div
              className="slds-form-element"
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {showStackCheckbox && (
                <Checkbox
                  labels={{
                    label: "Stack",
                  }}
                  checked={stackCheckbox}
                  onChange={stackCheckboxOnChange}
                />
              )}
              {showStack100Checkbox && (
                <Checkbox
                  labels={{
                    label: "Stack to 100%",
                  }}
                  checked={stack100Checkbox}
                  onChange={stack100CheckboxOnChange}
                />
              )}
              {showSortCheckbox && (
                <Checkbox
                  labels={{
                    label: "Sort",
                  }}
                  value={sortCheckbox}
                  onChange={sortCheckboxOnChange}
                />
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PlotOptions;
