const ViewModeRadioButtonGroup = ({ cmp, cmpState }) => {
  return (
    <fieldset className="slds-form-element">
      <div className="slds-form-element__control">
        <div className="slds-radio_button-group">
          {cmpState.viewOptions.map((option) => (
            <span
              key={option.label}
              className="slds-button slds-radio_button"
              onClick={() => cmp.set("view", option.value)}
            >
              <input
                type="radio"
                name={option.label}
                value={option.value}
                checked={cmpState.view === option.value}
                onChange={() => {}}
              />
              <label
                className="slds-radio_button__label"
                htmlFor={option.value}
              >
                <span className="slds-radio_faux">{option.label}</span>
              </label>
            </span>
          ))}
        </div>
      </div>
    </fieldset>
  );
};

export default ViewModeRadioButtonGroup;
