export const RECORD_COLUMNS = [
  {
    key: "question", //fieldName
    label: "Suggestion", //label
    property: "question", //fieldName
    type: "link", //if type = button, this value is link
    action: "question", //name within typeAttributes
    title: "Select",
  },
  {
    key: "name",
    label: "Field",
    property: "name",
    type: "link",
    action: "key",
    title: "Select",
  },
  {
    key: "dataRoleName",
    label: "Data Role",
    property: "dataRoleName",
    type: "link",
    action: "dataRole",
    title: "Select",
  },
];
