import Icon from "@salesforce/design-system-react/components/icon";

import FilterSet from "../../filter-set/FilterSet";

const Filters = ({
  cmpStateParent,
  toggleSection,
  handleEvent,
  childToParent,
}) => {
  return (
    <>
      <div
        className="slds-col slds-m-horizontal_x-small"
        style={{ minWidth: "300px" }}
      >
        <div
          className={`slds-section ${
            cmpStateParent.showSectionFilters ? "slds-is-open" : "slds-is-close"
          }`}
          id="sectionFilters"
        >
          <h3>
            <button
              aria-controls="contentFilters"
              className="slds-button slds-section__title-action"
              onClick={() => toggleSection("showSectionFilters")}
            >
              <span id="sectionFilters">
                <Icon
                  assistiveText={{ label: "button icon" }}
                  category="utility"
                  name="switch"
                  size="x-small"
                  className="slds-section__title-action-icon slds-button__icon_left"
                />
              </span>
              <span className="slds-truncate" title="Filters">
                Filters
              </span>
            </button>
          </h3>
          {cmpStateParent.showSectionFilters ? (
            <div className="slds-section__content" id="contentFilters">
              {/* <c:FilterSet pattern="{!v.processed}" /> */}
              <FilterSet
                pattern={cmpStateParent.processed}
                handleEvent={handleEvent}
                childToParent={childToParent}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Filters;
