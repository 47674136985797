import { useState } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";

import { GENERAL_ERROR, LOGIN } from "../../../constants/ToastMessages";
import "../../Pages.css";
import RegionSelection from "../../../components/region-selection";
import { EMPTY_TOAST_STATE } from "../../../constants";
import useAuthContext from "../../../context/useAuthContext";

const LoginForm = ({ setToastState, setMode, setNewUser }) => {
  const { userHasAuthenticated, user, setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const { username, password, region } = user;

  const navigate = useNavigate();

  const handleUserInputChange = (e, item) => {
    setUser((prevUser) => ({ ...prevUser, [item]: e.target.value }));
    setToastState(EMPTY_TOAST_STATE);
  };

  const handleSubmitLoginForm = async (event) => {
    try {
      event.preventDefault();
      setToastState(EMPTY_TOAST_STATE);
      setIsLoading(true);

      var user = await Auth.signIn(username, password);

      if (user) {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setNewUser(user);
          setMode("newpassword");
          return;
        }
        setUser((prev) => ({
          ...prev,
          ...user,
          username: user.username,
          email: user.attributes.email,
          id: user.attributes.sub,
        }));

        // set region in cookies
        Cookies.set("region", region);

        // CHECK: this is not done in ConfirmationForm? is that not setting email?
        userHasAuthenticated(true); //also used in ConfirmationForm
        setToastState({
          heading: "Success",
          variant: "success",
          details: LOGIN.SUCCESS,
        });
        navigate("/", { replace: true });
        return;
      }
      setToastState({
        heading: "Error",
        variant: "error",
        details: GENERAL_ERROR,
      });
    } catch (err) {
      if (err.code === "PasswordResetRequiredException") {
        setMode("resetpassword");
        return;
      }
      console.error(err.stack);
      setToastState({
        heading: "Error",
        variant: "error",
        details: err.message || GENERAL_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmitLoginForm}>
      <RegionSelection />
      <div className="user-inputs">
        <Input
          type="text"
          label="Username"
          value={username}
          onChange={(e) => handleUserInputChange(e, "username")}
          autoComplete="username"
        />
      </div>
      <div className="user-inputs">
        <Input
          type="password"
          label="Password"
          value={password}
          onChange={(e) => handleUserInputChange(e, "password")}
          autoComplete="current-password"
        />
      </div>
      <div className="login-signup-button">
        <Button
          type="submit"
          label={<>Log in {isLoading ? <Spinner size="small" /> : null}</>}
          variant="brand"
          disabled={isLoading || !username || !password || !region}
          style={{ width: "100%" }}
        />
      </div>
      <nav className="login-signup-link">
        {/*  <p>Don’t have an account? <Link to="/signup">Sign up</Link></p>         */}
        <p>
          Forgot your password? <Link to="/forgotPassword">Reset here</Link>
        </p>
      </nav>
    </form>
  );
};

export default LoginForm;
