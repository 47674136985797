import { useState } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";

import { GENERAL_ERROR } from "../../../constants/ToastMessages";
import "../../Pages.css";
import { EMPTY_TOAST_STATE } from "../../../constants";
import useAuthContext from "../../../context/useAuthContext";

const ConfirmationForm = ({ setToastState, toastState, userForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");

  const { userHasAuthenticated, user, setUser } = useAuthContext();
  const { region } = user;
  const { username, password, email } = userForm;

  const navigate = useNavigate();

  const handleConfirmationCodeChange = (e) => {
    setConfirmationCode(e.target.value);
    if (toastState.details) {
      setToastState(EMPTY_TOAST_STATE);
    }
  };

  const handleConfirmationSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      setToastState(EMPTY_TOAST_STATE);

      const confirmationResult = await Auth.confirmSignUp(
        username,
        confirmationCode
      );
      const user = await Auth.signIn(username, password);
      if (confirmationResult && user) {
        setUser((prev) => ({ ...prev, username, email, region }));
        userHasAuthenticated(true);
        navigate("/", { replace: true });

        // set region in cookies
        Cookies.set("region", region);
        return;
      }
    } catch (err) {
      console.error(err.stack);
      setToastState({
        heading: "Error",
        variant: "error",
        details: err.message || GENERAL_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleConfirmationSubmit}>
      <div className="user-inputs">
        <Input
          type="tel"
          label="Confirmation Code"
          value={confirmationCode}
          onChange={(e) => handleConfirmationCodeChange(e)}
        />
      </div>
      <p style={{ textAlign: "center" }}>
        Please check your email for the code.
      </p>
      <div className="login-signup-link">
        <Button
          type="submit"
          label={<>Verify {isLoading ? <Spinner size="small" /> : null}</>}
          variant="brand"
          disabled={isLoading || !confirmationCode}
          style={{ width: "100%" }}
        />
      </div>
    </form>
  );
};

export default ConfirmationForm;
