import { dealIcon, dragAndDropIcon } from "./Helper";
import "../PsNavigationTree.css";

const ItemLabel = ({ props, item, level }) => {
  const { draggable, parentCmp } = props;

  return (
    <span
      className="slds-has-flexi-truncate"
      draggable={draggable}
      onDragStart={(e) => {
        if (!draggable) {
          return;
        }
        parentCmp.onDragStart(e, item);
      }}
    >
      <span className="slds-tree__item-label slds-truncate" title={item.label}>
        {!item.disabled &&
          item.label &&
          item.label !== "--None--" &&
          draggable && (
            <>
              <span className={`dragAndDropIcon${level}`}>
                {dragAndDropIcon}
              </span>
              {level === 3 && dealIcon(item)}
            </>
          )}
        {item.label}
      </span>
    </span>
  );
};

export default ItemLabel;
