import { useState, useEffect, useRef } from "react";
import { Button, Card, Input } from "@salesforce/design-system-react";
import Spinner from "@salesforce/design-system-react/components/spinner";
import { Link } from "react-router-dom";

import Record from "../../../helpers/recordLayer";
import PsNavigationTree from "../../ps-navigation-tree/PsNavigationTree";
import { EMPTY_TOAST_STATE } from "../../../constants";
import { cleanInput } from "./Helper";

const PatternSave = (props) => {
  const [cmpState, setCmpState] = useState({
    loading: "navTree", // general, createFolder, saveFolder, navTree

    //  <!-- saveModal -->
    pattern: { name: "", description: "", folderId: "" },

    newFolder: {},
    selectedInsightFolderName: "",

    showCreateFolderButton: false,
    createFolderDisabledSaveModal: true,
    saveDisabledSaveModal: true,

    saveModalErrorMessage: "",

    // <!-- saveModalTree -->
    searchText: "",
    itemMap: {},
    itemTree: {},
    selectedItem: "folders", //<!-- used to set the selected item in the tree; can't actually read this variable, only set it-->
    selectedObject: "rootObject",
    parentId: "",

    rootObjectId: "",

    itemFolderList: {},

    itemFolderId: "",

    //////
    newFolderName: "",
    selectedFolder: null,
    folderDescription: "",
  });

  const cmpWorking = useRef({});
  const folderListBox = useRef(null);
  const folderListTitle = useRef(null);
  // const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
  }, []);

  //this will run on the first page load, because the queryFilter is intially empty and then Search.js processes the searchParams and updates the queryFilter
  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.handleReset();
  }, [props.pattern]);

  const cmp = {
    name: "PatternSave", //Trying to undo parent specific code in PsNavigationTree

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    init: function () {
      cmp.set("itemTree", [
        {
          name: "folder1",
          label: "Folder 1",
          expanded: false,
          items: [],
        },
        {
          name: "folder2",
          label: "Folder 2",
          expanded: false,
          items: [],
        },
      ]);
      cmp.set("itemMap", {
        folder1: { object: "rootObject", parentId: null },
        folder2: { object: "rootObject", parentId: null },
      });

      var newFolder = {
        Label__c: "",
        Description__c: "",
        PS_Parent_Folder__c: "",
        Public__c: false,
      };
      cmp.set("newFolder", newFolder);

      cmp.set("createFolderDisabledSaveModal", false); //not required if we hide the whole thing
      cmp.set("showCreateFolderButton", true);
    },

    handleReset: function () {
      var parentPattern = props.parentCmp.get("processed");
      var {
        id,
        floatingId,
        computed,
        relevance,
        relevanceIntrinsic,
        custom,
        ...pattern
      } = parentPattern;
      this.cleanInput(pattern);
      pattern.description = "";
      pattern.folderId = "";

      cmp.set("pattern", pattern);
    },

    //Remove null properties and remove unwanted properties
    cleanInput: cleanInput,

    cancelSaveModal: function () {
      cmp.set("saveModalIsOpen", false);
      //this.resetSaveModal(cmp, event);
    },

    createFolderSaveModal: function () {
      cmp.set("saveModalErrorMessage", "");
      props.setToastState(EMPTY_TOAST_STATE);
      this.createSaveFolder();
      //make sure new folder is now selected folder in menu and as selected attributes
    },

    //saveSaveModal
    handleSave: function () {
      props.setToastState(EMPTY_TOAST_STATE);
      this.savePattern();
    },

    onItemFolderListChange: function () {
      var itemFolderList = cmp.get("itemFolderList") || [];
      var groupFolderId = cmp.get("groupFolderId");
      this.updateItems(
        "list",
        groupFolderId,
        "itemFolder",
        null,
        itemFolderList
      );
      //Check if we need to change selectedItem
      this.changeSelectedItem();
    },

    //process event from lightning:tree
    handleSelect: function (event) {
      var id = event.getParam("name");
      if (id) {
        var itemMap = cmp.get("itemMap") || {};
        var item = itemMap[id] || {};

        var itemTree = cmp.get("itemTree");
        var treeItem = this.findItem(itemTree, id);

        this.setSelectedId(item.parentId, item.object, id, treeItem.label);

        cmp.set("searchText", "");
      }
    },

    createSaveFolder: function () {
      try {
        // We need to differentiate between group and (item)folder
        // var folderNameInput = cmp.find("newFolderName");
        // var folderNameInputValue = folderNameInput.get("value");
        var folderNameInputValue = cmpState.newFolderName;
        const folderDescription = cmp.get("folderDescription");

        if (folderNameInputValue.toLowerCase() === "liked") {
          props.setToastState({
            heading: "Name cannot be 'Liked'",
            details:
              "You cannot create a folder named 'Liked'. Please choose a different name for the folder.",
            variant: "warning",
          });
          return;
        }

        if (!folderNameInputValue) {
          //IMPROVEMENT: create error against field instead and make sure to clear that error when you navigate in the menu
          props.setToastState({
            heading: "Name cannot be empty",
            details: "Name is created when creating a new folder.",
            variant: "error",
          });
          return;
        }
        cmp.set("loading", "createFolder");

        var newFolder = {
          Label__c: "",
          Description__c: "",
          PS_Parent_Folder__c: "",
          Public__c: false,
        };

        newFolder.Label__c = folderNameInputValue;

        var groupFolderSharing = cmp.get("groupFolderSharing"); //not yet defined everywhere, set it on open (private selected by default) and set it when selecting a group
        newFolder.Public__c = groupFolderSharing === "public";

        var onSuccess = function (response) {
          const insertedFolder = response[0];
          if (insertedFolder.id) {
            props.setToastState({
              heading: "Folder Inserted",
              details: "Folder successfully inserted",
              variant: "success",
            });

            cmp.set("selectedItem", [`folders_folder_${insertedFolder.id}`]);
            cmp.set("selectedFolder", {
              id: insertedFolder.id,
              description: cmpWorking.current.pattern.description,
              name: cmpWorking.current.newFolderName,
            });
            cmp.set("newFolderName", "");
            cmp.set("folderDescription", "");
          } else {
            cmp.showFolderInsertError();
          }
        };

        var onError = function (response) {
          cmp.checkUser(response);
          cmp.showFolderInsertError();
        };

        Record.createRecord(
          "relate",
          "folder",
          JSON.stringify([
            {
              name: folderNameInputValue,
              description: folderDescription,
            },
          ]),
          onSuccess,
          onError
        );
      } catch (err) {
        cmp.showFolderInsertError();
        console.error(err.stack);
      } finally {
        cmp.set("loading", "");
      }
    },

    showFolderInsertError: function () {
      props.setToastState({
        heading: "Folder Insert Error",
        details: "An error occurred while trying to insert the folder",
        variant: "error",
      });
    },

    changeSelectedItem: function (event) {
      //Check if we need to change the selectedItem
      var selectedItem = cmp.get("selectedItem");
      var newFolder = cmp.get("newFolder");
      if (
        newFolder !== undefined &&
        newFolder.id !== undefined &&
        newFolder.id !== ""
      ) {
        var object =
          selectedItem === "mySavedInsights" ||
          selectedItem === "sharedInsights"
            ? "groupFolder"
            : "itemFolder";
        if (selectedItem !== newFolder.id) {
          this.setSelectedId(
            cmp,
            selectedItem,
            object,
            newFolder.id,
            newFolder.name
          );
        }
      }
      newFolder = {
        Label__c: "",
        Description__c: "",
        PS_Parent_Folder__c: "",
        Public__c: false,
      };

      cmp.set("newFolder", newFolder);
    },

    savePattern: function () {
      var pattern = cmp.get("pattern");
      var patternList = [pattern];

      var onSuccess = function (response) {
        const savedFolder = cmp.get("selectedFolder");
        const message = (
          <>
            Insight {cmpState.pattern.name}
            was saved successfully in folder
            <Link
              to={`/SavedInsights?selected=folders_folder_${savedFolder.id}&folder=${savedFolder.id}`}
            >
              [{cmpState.selectedFolder.name}]
            </Link>
          </>
        );

        //do something with id
        //show toast
        props.setToastState({
          heading: "Pattern Saved",
          details: message,
          variant: "success",
        });
      };
      var onError = function (response) {
        cmp.checkUser(response);
        cmp.set("move", "error");
        //show toast
        props.setToastState({
          heading: "Error",
          details: response.data.message,
          variant: "error",
        });
      };

      Record.createRecord(
        "relate",
        "pattern",
        JSON.stringify(patternList),
        onSuccess,
        onError
      );
    },

    //process event from lightning:tree or navigation event from child components (not currently relevant)
    setSelectedId: function (parentId, object, id, label) {
      cmp.set("selectedItem", null); //blank it out, otherwise selected twice
      //assigning variables, to keep code below the same

      cmp.set("parentId", parentId); //not used in ExploreTab
      cmp.set("" + object + "Id", id);
      cmp.set("selectedObject", object);

      if (id) {
        cmp.set("selectedItem", id); // set item in navigation tree
      }

      switch (object) {
        case "rootObject":
          cmp.set("itemFolderId", null);
          cmp.set("saveDisabledSaveModal", true);
          cmp.set("createFolderDisabledSaveModal", false); //not required if we hide the whole thing
          cmp.set("showCreateFolderButton", true);
          cmp.set("selectedInsightFolderName", "");
          break;
        case "itemFolder":
          var folderItem = cmp.get("folderItem");
          folderItem.PS_Folder__c = id;
          cmp.set("folderItem", folderItem);

          cmp.set("selectedInsightFolderName", label);

          cmp.set("itemFolderId", id);
          cmp.set("saveDisabledSaveModal", false);
          cmp.set("createFolderDisabledSaveModal", true);
          cmp.set("showCreateFolderButton", false);
          break;
        default:
      }
    },

    itemFromRecord: function (item, record) {
      // When the user selects, child items will be loaded and added
      if (item) {
        // update any changed values
        Object.assign(item, { label: record.name });
        return item;
      } else {
        // create new list item from record
        return (({ id, name }) => ({
          name: id,
          label: name,
          expanded: false,
          items: [],
        }))(record);
      }
    },

    // recursively goes through items tree to find the item with the specified name
    findItem: function (itemTree, name) {
      var selectedItem;
      itemTree.some(function (item) {
        if (item.name === name) {
          selectedItem = item;
          return true;
        }
        var items = item.items;
        if (items && items.length) {
          selectedItem = this.findItem(items, name);
          return selectedItem !== undefined;
        }
      });
      return selectedItem;
    },

    updateItems: function (action, parentId, object, id, data) {
      // action: 'list', 'create', 'read', 'update', 'delete'
      // data: record or list of records (in case action === 'list') having at least 'id' and 'name' fields
      // object: object of the children
      var itemTree = cmp.get("itemTree") || [];

      var parent = this.findItem(itemTree, parentId) || {};
      var items = parent.items || [];
      var itemLookup = items.reduce((obj, item) => {
        obj[item.name] = item;
        return obj;
      }, {});

      if (action === "list") {
        // update items with list of loaded records
        items = data.reduce((obj, record) => {
          obj.push(this.itemFromRecord(itemLookup[record.id], record));
          return obj;
        }, []);
        parent.expanded = true;
      } else {
        // remove placeholder item
        if (items.length === 1 && items[0].name === undefined) {
          items = [];
        }

        // delete, add or update single record
        if (action === "delete") {
          var index = items.findIndex((item) => item.name === id);
          if (index > -1) {
            items.splice(index, 1);
          }
        } else {
          var item = this.itemFromRecord(itemLookup[id], data); // this updates any existing item
          if (!itemLookup[id]) {
            items.push(item);
          }
        }
      }

      // If there are no items, add a non-selectable '--None--' child item instead, to indicate that the items were loaded
      // Setting 'disabled=true' to make the item becomes non-selectable; NB: some kind of Salesforce-related javascript error is generated.
      // Alternatively; "items = [ {} ];" to show the expanded arrow without any child items.
      if (!items.length) {
        items = [{ label: " ", metatext: "--None--", disabled: true }];
      } else {
        // sort
        var sortFunction = Record.sortFunction(items, "label");
        items.sort(sortFunction("label", false));
      }

      // store updated itemTree
      parent.items = items;
      cmp.set("itemTree", itemTree);

      // update parent and object in itemMap
      var itemMap = cmp.get("itemMap") || {};
      items.reduce((obj, item) => {
        obj[item.name] = (({}) => ({ object, parentId }))(item);
        return obj;
      }, itemMap);
      cmp.set("itemMap", itemMap);
    },

    // fire event for changed record(s)
    notifyChanged: function (action, parentId, module, object, id, record) {
      var recordChangedEvent = {}; // $A.get("e.c:RecordChangedEvent");
      recordChangedEvent.setParams({
        action,
        parentId,
        module,
        obj: object,
        id,
        record,
      });
      recordChangedEvent.fire();
    },

    handleNavigationEvent: function (event) {
      try {
        // scroll only
        var source = event.source;
        if (["change", "closeSearch"].includes(source)) {
          const scroll = event.scroll;
          const scroller = folderListBox.current;
          const folderListTitleDiv = folderListTitle.current; // document.getElementById("searchdiv");
          // update scroll position after rendering, so that rendered sizes are available
          if (folderListTitleDiv && scroller && scroll != null) {
            var timer = setTimeout(() => {
              var top =
                folderListTitleDiv.offsetTop + folderListTitleDiv.offsetHeight;
              scroller.scrollTop = scroll * (scroller.scrollHeight - top);
            }, 0);
          }
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    childToParent: function (event) {
      if (event.type === "logout") {
        props.childToParent({ type: "logout" });
        return;
      }

      const pattern = cmp.get("pattern");
      cmp.set("pattern", { ...pattern, folderId: event.id });
      cmp.set("selectedFolder", event.record);
      if (event.type === "navigation") {
        cmp.handleNavigationEvent(event);
      }
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },
  };

  //NON CMP FUNCTIONS

  const toggleSaveModal = () => {
    props.parentCmp.set(
      "saveModalIsOpen",
      !props.parentCmp.get("saveModalIsOpen")
    );
  };

  const handleSave = () => {
    cmp.savePattern();
    props.parentCmp.set("saveModalIsOpen", false);
  };

  const handlePatternNameChange = (value) => {
    let pattern = cmp.get("pattern");
    pattern = { ...pattern, name: value };
    cmp.set("pattern", pattern);
  };

  const handlePatternDescriptionChange = (value) => {
    let pattern = cmp.get("pattern");
    pattern = { ...pattern, description: value };
    cmp.set("pattern", pattern);
  };

  return (
    <div>
      <section
        role="dialog"
        tabIndex="-1"
        aria-modal={cmpState.editModalIsOpen}
        aria-labelledby="modal-heading-01"
        className="slds-modal slds-fade-in-open"
      >
        <div className="slds-modal__container">
          <button
            className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
            onClick={() => toggleSaveModal()}
          >
            <svg
              className="slds-button__icon slds-button__icon_large"
              aria-hidden="true"
            >
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span className="slds-assistive-text">Cancel and close</span>
          </button>
          <div className="slds-modal__header">
            <h1
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Save Insight
            </h1>
          </div>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <Card heading="">
              {cmpState.loading === "general" && (
                <Spinner assistiveText={{ label: "Loading" }} />
              )}
              <div
                style={{
                  maxHeight: "540px",
                  marginTop: "-30px",
                }}
              >
                <Input
                  type="text"
                  label="Pattern Name"
                  value={cmpState.pattern.name}
                  onChange={(e) => handlePatternNameChange(e.target.value)}
                />
                {/* <div style={{ paddingTop: "10px" }}>
                  <Input
                    type="text"
                    label="Pattern Description"
                    autocomplete="off"
                    value={cmpState.pattern.description}
                    onChange={(e) =>
                      handlePatternDescriptionChange(e.target.value)
                    }
                  />
                </div> */}

                {/* <!-- folder --> */}
                <div className="slds-box slds-box_x-small slds-m-top_small">
                  {/* FOLDER */}
                  <div style={{ padding: "6px" }} ref={folderListTitle}>
                    <div className="slds-text-title">
                      <b>Select an existing folder or create a new folder.</b>
                    </div>
                  </div>

                  <div className="slds-grid slds-wrap">
                    <div className="slds-col slds-size_1-of-3">
                      <div style={{ padding: "6px" }}>
                        <div className="slds-text-title">Folder Structure:</div>
                      </div>
                    </div>
                    <div className="slds-col slds-size_2-of-3">
                      <div style={{ padding: "6px" }}>
                        <div className="slds-box slds-box_x-small">
                          {/* <!-- Select Folder Menu add scroll bar --> */}
                          {/* <ui:scrollerWrapper class="saveModalFolderMenuScrollSize">
                                        <lightning:tree aura:id="treenav" items="{!v.itemTree}" onselect="{!c.handleSelect}" selectedItem="{!v.selectedItem}"/>
                           </ui:scrollerWrapper> */}
                          <div
                            ref={folderListBox}
                            className="slds-scrollable"
                            style={{
                              height: "250px",
                            }}
                          >
                            {cmpState.loading === "navTree" && (
                              <Spinner assistiveText={{ label: "Loading" }} />
                            )}

                            {cmpState.itemTree?.length > 0 && (
                              <PsNavigationTree
                                multiSelect={false}
                                sections={["folders"]}
                                selected={cmpState.selectedItem}
                                isLoading={cmpState.loading === "general"}
                                parentCmp={cmp}
                                childToParent={cmp.childToParent}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: "0 6px 6px 6px" }}>
                    <Input
                      type="text"
                      label="Folder Name"
                      autocomplete="off"
                      readonly={cmpState.createFolderDisabledSaveModal}
                      value={cmpState.newFolderName}
                      onChange={(e) => cmp.set("newFolderName", e.target.value)}
                    />

                    <div style={{ paddingTop: "6px" }}>
                      <Input
                        type="text"
                        label="Folder Description"
                        autocomplete="off"
                        value={cmpState.folderDescription}
                        onChange={(e) =>
                          cmp.set("folderDescription", e.target.value)
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingTop: "13px",
                      }}
                    >
                      <Button
                        label={
                          <div>
                            Create Folder
                            {cmpState.loading === "createFolder" && (
                              <Spinner size="x-small" variant="brand" />
                            )}
                          </div>
                        }
                        title="Create Folder"
                        onClick={() => cmp.createFolderSaveModal()}
                        variant="brand"
                      />
                    </div>
                  </div>
                </div>

                {cmpState.saveModalErrorMessage ? (
                  <div className="slds-p-around_small">
                    <div className="slds-text-color_error">
                      {cmpState.saveModalErrorMessage}
                    </div>
                  </div>
                ) : null}
              </div>
            </Card>
          </div>
          <div className="slds-modal__footer">
            <button
              className="slds-button slds-button_neutral"
              aria-label="Cancel and close"
              onClick={() => toggleSaveModal()}
            >
              Cancel
            </button>
            <button
              className="slds-button slds-button_brand"
              onClick={() => handleSave()}
              disabled={!cmpState.pattern.folderId}
            >
              Save
            </button>
          </div>
        </div>
      </section>
      <div
        className="slds-backdrop slds-backdrop_open"
        role="presentation"
      ></div>
    </div>
  );
};

export default PatternSave;
