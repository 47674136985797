//  Remove null properties and remove unwanted properties
export const cleanInput = (obj) => {
  if (obj === undefined || obj === null) {
    return;
  }

  cleanInput(obj.options);

  const unwantedProperties = [
    "floatingId",
    "relevance",
    "relevanceIntrinsic",
    "custom",
    "computed",
  ];
  const idProperties = ["key", "chain", "map", "source", "container"];

  for (const key in obj) {
    if (
      unwantedProperties.includes(key) ||
      obj[key] === null ||
      obj[key] === undefined
    ) {
      delete obj[key];
      continue;
    }
    if (idProperties.includes(key)) {
      if (
        obj[key].id !== undefined &&
        obj[key].id !== null &&
        obj[key].id !== ""
      ) {
        obj[key + "Id"] = obj[key].id;
        delete obj[key];
        continue;
      }
    }
  }

  if (obj.filter !== undefined && obj.filter !== null) {
    cleanInput(obj.filter);
  }

  //retursively call cleanInput for all inputs
  if (
    obj.inputs !== undefined &&
    obj.inputs !== null &&
    Array.isArray(obj.inputs)
  ) {
    if (obj.inputs.length === 0) {
      delete obj.inputs;
      return;
    }
    obj.inputs.forEach((item) => {
      cleanInput(item);
    });
  }
};
