import "./Profile.css";
import { useEffect, useRef, useState } from "react";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader.js";
import PsUser from "../../components/ps-user/PsUser.js";
import ToastComponent from "../../components/toast-component/index.js";
import Record from "../../helpers/recordLayer.js";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import SearchBox from "../../components/ps-search-box/SearchBox.js";
import useAuthContext from "../../context/useAuthContext.js";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree.js";

function Profile() {
  const emptyToastState = {
    variant: "",
    heading: "",
    details: "",
  };
  const [toastState, setToastState] = useState(emptyToastState);
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();
  const { userDetails } = useAuthContext();

  const [cmpState, setCmpState] = useState({
    selectedObject: "users", // selectedObject key handles what component to show in the main use container
    parentToChildEvent: { action: "" },
    userId: "",
    // TODO This is not correct, as we are dealing with a single user and not a list of users,
    // but this is not used as we area always showing the user details. We also don't have an approach yet to show a single record by default.
    // Once we start adding more menu items we can work this out further.
    selectedItem: "overview_users_userList", // hardcoded for now, since we only have one option
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!!userDetails?.id) {
      setCmpState((prev) => ({ ...prev, userId: userDetails.id }));
      return;
    }
  }, [`${userDetails}`]);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    cmp.onPageReferenceChange();
  }, [searchParams]);

  const cmp = {
    get: (key) => {
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    init: function () {},

    afterScriptsLoaded: function () {
      try {
        cmp.parsePageRef();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleNavigationEvent: function (event) {
      try {
        const object = event.obj;
        if (event.obj === "user") cmp.set("userId", event.id);
        cmp.set("selectedObject", object);

        if (cmp.get("selectedObject") !== "users" && event.id) {
          const name = "core_" + event.obj + "_" + event.id;
          if (name !== searchParams.get("selected")) {
            cmp.navigateFromName(name);
          }
          return;
        }
        // navigate to specified record
        if (["record", "grid", "tree"].includes(event.source)) {
          cmp.navigateFromEvent(event);
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    navigateFromEvent: function (event) {
      // navigate
      const defaultSection = {
        root: "core",
        user: "core",
        users: "core",
      };
      let item = Record.itemFromEvent(event, defaultSection);
      if (event.module && !item.name.includes(event.module)) {
        item.name = event.module + item.name;
      }

      // navigate
      cmp.navigateFromName(item.name);
    },

    navigateFromName: function (name) {
      const selectedObject = cmp.get("selectedObject");
      const selectedId = selectedObject ? cmp.get(selectedObject + "Id") : null;
      const parsed = Record.parseName(name);
      const selected = searchParams.get("selected");
      if (
        parsed.id !== selectedId ||
        parsed.config !== selectedObject ||
        selected !== name
      ) {
        let search = "?selected=" + name;
        navigate({
          pathname: "/Profile",
          search,
        });
      }
    },

    onPageReferenceChange: function () {
      try {
        cmp.parsePageRef();
      } catch (err) {
        console.error(err.stack);
      }
    },

    parsePageRef: function () {
      const selected =
        searchParams.get("selected") ||
        Record.nameFromDetails("profile", "users", "userList");
      const parsed = Record.parseName(selected);
      const config = parsed.config;
      const id = parsed.id;

      // each config has its own id and queryFilter variable, to prevent its component from reloading when changing the selection
      var selectedIdName = config + "Id";
      cmp.set(selectedIdName, id);

      // set the config to show _after_ all its settings have been set on the component, to prevent reloading in afterScript, as well as due to a change in filter
      cmp.set("selectedObject", config);

      // update the tree
      cmp.set("selectedItem", selected);
    },
  };

  const handleReload = () => {
    const parentToChildEvent = cmp.get("parentToChildEvent");
    cmp.set("parentToChildEvent", {
      ...parentToChildEvent,
      action: "reload",
    });
    cmp.set("navigationLoading", true);
  };

  const childToParent = (event) => {
    try {
      if (event.type === "navigation") {
        // Case that child action is navigation
        cmp.handleNavigationEvent(event);
      } else if (event.type === "dataCompEvent") {
        // Case that child action is dataCompEvent -> Do something
      } else if (event.type === "reload") {
        handleReload(event);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };
  
  return (
    <div className="profile-page-container">
      <PsNavigationHeader view="list" childToParent={childToParent} />
      <div className="tab-content slds-p-around_medium">
        {/* <!-- left panel view --> */}
        <div className="um-left slds-m-right_medium">
          <article id="left" className="slds-card">
            <div className="slds-card__header">
              <h2 className="card-main-title-lh32 slds-card__header-title slds-text-heading_small slds-truncate">
                Profile
              </h2>
            </div>
            <div className="slds-card__body">
              <div className="slds-p-horizontal_x-small">
                <PsNavigationTree
                  multiSelect={false}
                  selected={cmpState.selectedItem}
                  sections={["profile"]}
                  parentCmp={cmp}
                  childToParent={childToParent}
                  parentToChildEvent={cmpState.parentToChildEvent}
                />
              </div>
            </div>
          </article>
        </div>
        {/* <!-- right panel view --> */}
        <div className="um-right" style={{ overflow: "scroll" }}>
          <PsUser
            recordId={cmpState.userId}
            childToParent={childToParent}
            parentCmp={cmp}
            parentToChildEvent={cmpState.parentToChildEvent}
            setToastState={setToastState}
          />
        </div>
      </div>
      {toastState.details ? (
        <ToastComponent
          close={() => setToastState(emptyToastState)}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </div>
  );
}

export default Profile;
