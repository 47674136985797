import SearchLoading from "../ui/SearchLoading";

const SearchBox = ({
  searchText,
  loading,
  handleSearchTextChange,
  handleSearchSubmit,
}) => {
  return (
    <div className="slds-form-element">
      <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left-right slds-input-has-icon_group-right">
        <svg
          className="slds-icon slds-input__icon slds-input__icon_left slds-icon-text-default"
          aria-hidden="true"
        >
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
        </svg>
        <form onSubmit={(e) => handleSearchSubmit(e)}>
          <input
            type="text"
            id="text-input-id-46"
            placeholder="Search…"
            className="slds-input"
            value={searchText}
            onChange={(e) => handleSearchTextChange(e.target.value)}
            autoComplete="off"
          />
        </form>
        <div className="slds-input__icon-group slds-input__icon-group_right">
          {loading ? <SearchLoading /> : null}
          {searchText ? (
            <button
              className="slds-button slds-button_icon slds-input__icon slds-input__icon_right"
              title="Clear"
              onClick={() => handleSearchTextChange("")}
            >
              <svg
                className="slds-button__icon slds-icon-text-light"
                aria-hidden="true"
              >
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clear"></use>
              </svg>
              <span className="slds-assistive-text">Clear</span>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
