const WarningIcon = () => {
  return (
    <span
      className="slds-icon_container slds-icon-utility-warning"
      title="This description is generated by an AI with limited context and may not correctly reflect your data or apply to your siutation."
      style={{
        position: "relative",
        bottom: "0.25em",
        paddingLeft: "0.40em",
      }}
    >
      <svg
        className="slds-icon slds-icon-text-default slds-icon_x-small"
        aria-hidden="true"
      >
        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#warning"></use>
      </svg>
    </span>
  );
};

export default WarningIcon;
