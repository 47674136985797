import { useEffect, useRef, useState } from "react";

import PsRecord from "../ps-record/PsRecord";
import NameInputWithUndoIcon from "../ui/NameInputWithUndoIcon";
import ProgressBarComponent from "../ui/ProgressBarComponent";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import EditButtonIcon from "../ui/EditButtonIcon";
import UndoButtonIcon from "../ui/UndoButtonIcon";
import Statistics from "../ps-chain/components/Statistics";
import Record from "../../helpers/recordLayer";
import PsTreeGrid from "../ps-tree-grid/PsTreeGrid";

const PsLink = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Join",
    recordModule: "store",
    recordObject: "link",
    showDelete: false,

    dataTableColumns: [],
    dataTableKeyField: "id",
    dataTableRecords: [],

    ////
    loading: false,
    mode: "init",
    showEdit: true,

    record: {
      name: "",
      sourceName: "",
      rightContainerName: "",
      leftContainerName: "",
      custom: false,
      hasOverride: {
        name: false,
        accept: false,
      },
    },
    missingRequiredFields: [],
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded(cmp);
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.handleReset();
  }, [props.recordId, props.parentId]);

  const cmp = {
    // --- LinkController.js ---

    init: function () {
      try {
        cmp.initHelper();
      } catch (err) {
        console.error(err.stack);
      }
    },

    afterScriptsLoaded: function () {
      try {
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleReload: function () {
      try {
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleEdit: function () {
      try {
        PsRecord.setMode(cmp, "edit");
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleCancel: function () {
      try {
        PsRecord.cancelRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleSubmit: function () {
      try {
        PsRecord.submitRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleDelete: function (cmp, event, helper) {
      // try {
      //     if (!cmp.get('v.scriptsLoaded')) { return; }
      //     Record.showToast(cmp, 'Not Available', '', 'info');
      //     //helper.deleteRecord(cmp); // NB: this navigates to parent record after successfull delete
      // } catch (err) {
      //     console.error(err.stack);
      // }
    },

    handleOverride: function (cmp, event, helper) {
      try {
        var field = event.getSource().get("v.name");
        helper.setOverride(cmp, field);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRevert: function (field) {
      try {
        PsRecord.revertOverride(cmp, field);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRecordRowAction: function (action, row) {
      try {
        var recordModule = cmp.get("recordModule");
        var recordObject = cmp.get("recordObject");
        var parentNav =
          (cmp.get("parentPrefix") || "") + (cmp.get("parentId") || "");

        switch (action) {
          case "details":
            cmp.notifyNavigation(null, recordModule, recordObject, row.id);
            break;
          case "viewSource":
            cmp.notifyNavigation(
              "sources",
              "core",
              "source",
              row.key.container.source.id
            );
            break;
          case "viewContainer":
            cmp.notifyNavigation(
              row.key.container.source.id,
              "store",
              "container",
              row.key.container.id
            );
            break;
          case "viewKey":
            cmp.notifyNavigation(parentNav, "store", "key", row.key.id);
            break;
          default:
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- LinkHelper.js ---

    RECORD_COLUMNS: [
      {
        label: "Name",
        property: "name",
        key: "name",
        type: "text",
        wrapText: false,
      },
      {
        label: "Order",
        width: 80,
        property: "argOrder",
        key: "argOrder",
        type: "number",
      },
      {
        label: "Field",
        type: "link",
        width: 100,
        property: "keyName",
        key: "keyName",
        action: "viewKey",
      },
      {
        label: "Object",
        type: "link",
        width: 100,
        property: "containerName",
        key: "containerName",
        action: "viewContainer",
      },
      {
        label: "Source",
        type: "link",
        width: 100,
        property: "sourceName",
        key: "sourceName",
        action: "viewSource",
      },
      {
        label: "# Distinct",
        width: 110,
        property: "robustDistinct",
        key: "robustDistinct",
        type: "number",
      },
    ],

    initHelper: function () {
      cmp.set("dataTableColumns", cmp.RECORD_COLUMNS);
    },

    getDefaultRecord: function () {
      return {};
    },

    getOverrideFields: function () {
      return ["name", "accept"];
    },

    parseInputPlainText: function (record) {
      return (({ id, name, overridden, accept }) => ({
        id,
        name,
        overridden,
        accept,
      }))(record);
    },

    parseResponse: function (response) {
      return response.map(
        ({
          id,
          name,
          leftContainer,
          rightContainer,
          relationship,
          lastRunOn,
          coverage,
          robustDistinct,
          overridden,
          accept,
          custom,
          inputs,
        }) => ({
          id,
          name,
          leftContainerName: leftContainer.name,
          rightContainerName: rightContainer.name,
          relationship,
          lastRunOn,
          coverage,
          robustDistinct,
          overridden,
          accept,
          custom,
          inputs,
        })
      );
    },

    setParent: function (cmp, record) {
      var parentId = record.leftContainerId || (record.leftContainer || {}).id;
      cmp.set("parentId", parentId);
      return parentId;
    },

    updateUI: function () {
      try {
        var link = cmp.get("record") || {};
        var linkKeys = link.inputs || [];
        // sort by (isLeft, argOrder)
        var orderBy = "argOrder";
        var orderFunc = Record.sortFunction(linkKeys, orderBy);
        linkKeys.sort(orderFunc(orderBy));
        orderBy = "isLeft";
        orderFunc = Record.sortFunction(linkKeys, orderBy);
        linkKeys.sort(orderFunc(orderBy, true));
        // set Key and Container details on records
        linkKeys.forEach((linkKey) => {
          var key = linkKey.key || {};
          var container = key.container || {};
          var source = container.source || {};
          Object.assign(linkKey, {
            name: (linkKey.isLeft ? "From: " : "To: ") + key.name,
            keyName: key.name,
            robustDistinct: key.robustDistinct,
            containerName: container.name,
            sourceName: source.name,
          });
        });
        cmp.set("dataTableRecords", linkKeys);
      } catch (error) {
        console.error(error.stack);
      }
    },

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    notifyNavigation: function (parentId, module, object, id) {
      let event = new Event("navigation");
      event.parentId = parentId;
      event.module = module;
      event.obj = object;
      event.id = id;
      event.source = "grid";

      cmp.handleEvent(event);
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    handleReset: function () {
      try {
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    checkForm: function () {
      try {
        const record = cmp.get("record");
        if (!record.name) {
          cmp.set("missingRequiredFields", ["record.name"]);
        }
        return !record.name;
      } catch (error) {
        console.error(error.stack);
      }
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },

    body: function () {
      return (
        <div className="slds-form slds-var-m-around_medium" role="list">
          <h3 className="slds-section-title--divider slds-var-m-top_medium">
            Join Details
          </h3>
          <div className="slds-form__row">
            {/* <!-- Name --> */}
            <NameInputWithUndoIcon cmpState={cmpState} cmp={cmp} />
          </div>

          <div className="slds-form__row">
            {/* <!-- Left Container --> */}
            <div
              className="slds-form__item slds-grid_vertical-align-center"
              role="listitem"
            >
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {(cmpState.mode === "new" ||
                  cmpState.mode === "edit" ||
                  cmpState.mode === "view") && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">From</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.leftContainerName}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <!-- Right Container --> */}
            <div
              className="slds-form__item slds-grid_vertical-align-center"
              role="listitem"
            >
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {(cmpState.mode === "new" ||
                  cmpState.mode === "edit" ||
                  cmpState.mode === "view") && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">To</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.rightContainerName}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="slds-form__row">
            {/* <!-- Custom --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {(cmpState.mode === "new" ||
                  cmpState.mode === "edit" ||
                  cmpState.mode === "view") && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Custom</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        <CheckAndCloseIcons
                          selectedItem={cmpState.record.custom}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <!-- Accept --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" ? (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">
                      Use in Pattern Discovery
                    </span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        <CheckAndCloseIcons
                          selectedItem={cmpState.record.accept}
                        />
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon handleEdit={cmp.handleEdit} />
                      </div>
                    </div>
                  </div>
                ) : null}
                {cmpState.mode === "new" || cmpState.mode === "edit" ? (
                  <div verticalalign="start">
                    <label
                      className="slds-form-element__label"
                      htmlFor="toggle"
                    >
                      Use in pattern discovery
                    </label>
                    <div style={{ display: "flex", width: "14em" }}>
                      <label className="slds-checkbox_toggle slds-grid">
                        <input
                          type="checkbox"
                          checked={cmpState.record.accept || false}
                          onChange={() =>
                            PsRecord.handleToggleChange(cmp, "accept")
                          }
                        />
                        <span
                          id="checkbox-toggle-16"
                          className="slds-checkbox_faux_container"
                        >
                          <span className="slds-checkbox_faux"></span>
                          <span className="slds-checkbox_on">Yes</span>
                          <span className="slds-checkbox_off">No</span>
                        </span>
                      </label>{" "}
                      {cmpState.record.hasOverride?.accept && (
                        <div
                          style={{
                            marginTop: "-25px",
                          }}
                        >
                          <UndoButtonIcon
                            handleRevert={cmp.handleRevert}
                            field="accept"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {(cmpState.mode === "init" || cmpState.mode === "view") && (
            <>
              <Statistics cmp={cmp} cmpState={cmpState} />

              <h3 className="slds-section-title--divider slds-var-m-top_medium">
                Fields
              </h3>
              <p>&nbsp;</p>
              <div className="slds-form__row">
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {(cmpState.mode === "view" || cmpState.mode === "new") && (
                      <>
                        {/* <lightning:treeGrid aura:id="recordtree" keyField="{!v.dataTableKeyField}" columns="{!v.dataTableColumns}" data="{!v.dataTableRecords}"
      hideCheckboxColumn="true" minColumnWidth="100" columnWidthsMode="auto" isLoading="{!v.loading}" onrowaction="{!c.handleRecordRowAction}"/> */}
                        <PsTreeGrid
                          onrowaction={cmp.handleRecordRowAction}
                          cmpState={cmpState}
                          cmp={cmp}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    },
  };

  return PsRecord.render(cmp, cmpState);
};

export default PsLink;
