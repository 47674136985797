import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useAuthContext from "../../context/useAuthContext";

// ---> This component is for giving the user correct access and it can not be used outside of the account   <SettingContextProvider>

const PsPermissionWrapper = ({
  children, // ---> children is React.Node element
  permittedUserTypes, // ---> permittedUserTypes  is an array of user type for example "Administrator"
  redirectPath, // ---> redirectPath  can be undefined or valid path to redirect the user if has not permission to visit children components
}) => {
  const { userDetails } = useAuthContext();
  const [hasPermission, setHasPermission] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userDetails?.type) return;
    const userAllowed = permittedUserTypes?.includes(userDetails.type);
    if (!!redirectPath && !userAllowed) navigate(redirectPath);
    setHasPermission(userAllowed);
  }, [userDetails?.type]);
  return <>{hasPermission ? <>{children}</> : null}</>;
};

export default PsPermissionWrapper;
