import Icon from "@salesforce/design-system-react/components/icon";

import { formattedRelevance } from "../../../helpers";

const Details = ({ cmpState, toggleSection }) => {
  const { showSectionDetails, relevanceType, relevance } = cmpState;

  return (
    <div
      className="slds-col slds-m-horizontal_x-small"
      style={{ minWidth: "300px" }}
    >
      <div
        className={`slds-section ${
          showSectionDetails ? "slds-is-open" : "slds-is-close"
        }`}
        id="sectionDetails"
      >
        <h3>
          <button
            aria-controls="contentDetails"
            className="slds-button slds-section__title-action"
            onClick={() => toggleSection("showSectionDetails")}
          >
            <span id="sectionDetails">
              <Icon
                assistiveText={{ label: "button icon" }}
                category="utility"
                name="switch"
                size="x-small"
                className="slds-section__title-action-icon slds-button__icon_left"
              />
            </span>
            <span className="slds-truncate" title="Details">
              Details
            </span>
          </button>
        </h3>
        <div
          className="slds-section__content"
          id="contentDetails"
          style={{ display: "flex" }}
        >
          {/* <p>Relevance: <lightning:formattedNumber value="{!v.relevance}" style="percent" maximumFractionDigits="0"/> ({!v.relevanceType})</p> */}
          <p style={{ marginRight: "0.25em" }}>Relevance: </p>
          {formattedRelevance(relevance)} ({relevanceType})
        </div>
      </div>
    </div>
  );
};

export default Details;
