import Icon from "@salesforce/design-system-react/components/icon";


import "./HeaderProfileCustomContent.css";
import useAuthContext from "../../../context/useAuthContext";

const HeaderProfileCustomContent = ({ onEditProfile, avatar }) => {
  const { user, handleLogout } = useAuthContext();
  const region = process.env[`REACT_APP_COGNITO_REGION_LABEL_${user?.region}`];
  const regionNumberCount = parseInt(
    process.env.REACT_APP_COGNITO_REGION_NUMBER_COUNT,
    10
  );

  return (
    <div className="header-profile-popover-body-wrapper">
      <div className="header-profile-popover-body-avatar-user-info">
        <div>{avatar}</div>
        <div className="user-info">
          <p className="user-name">{user?.username}</p>
          <p className="user-email" title={user?.email}>
            {user?.email}
          </p>
          {regionNumberCount > 1 && (
            <div className="badge-text">
              <img
                className="img-region"
                src="/assets/images/global-header/cloud.svg"
                alt="location"
              />
              {`Instance: ${region}`}
            </div>
          )}
        </div>
      </div>
      <div className="border-element" />
      <div className="header-profile-custom-content">
        <div className="logout" onClick={() => onEditProfile()}>
          <div className="header-profile-custom-content-item-icon">
            <Icon
              assistiveText={{ label: "edit" }}
              category="utility"
              colorVariant="default"
              name="edit"
              size="x-small"
            />
          </div>
          Edit Profile
        </div>
        <div className="logout" onClick={handleLogout}>
          <div className="header-profile-custom-content-item-icon">
            <Icon
              assistiveText={{ label: "logout" }}
              category="utility"
              colorVariant="default"
              name="logout"
              size="x-small"
            />
          </div>
          Log Out
        </div>
      </div>
    </div>
  );
};
export default HeaderProfileCustomContent;
