import { useEffect, useRef, useState } from "react";
import { Card } from "@salesforce/design-system-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./SavedInsights.css";
import Record from "../../helpers/recordLayer";
import PsFolderList from "../../components/ps-folder-list/PsFolderList";
import PsDashboardGrid from "../../components/ps-dashboard-grid/PsDashboardGrid";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree";
import IllustrationOpenRoad from "../../components/ui/IllustrationOpenRoad";
import SearchLoading from "../../components/ui/SearchLoading";
import { EMPTY_TOAST_STATE } from "../../constants";
import ToastComponent from "../../components/toast-component";
import PsPatternDetailedView from "../../components/ps-pattern-detailed-view/PsPatternDetailedView";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext";

const SavedInsights = () => {
  const { handleLogout } = useAuthContext();
  const [cmpState, setCmpState] = useState({
    type: {},
    view: "list",

    // <!-- navigation tree -->
    folderMap: {},
    folderTree: {},
    selectedId: "",
    setSelectedItem: "", // <!-- used to set the selected item in the tree; can't actually read this variable, only set it-->

    selectedObject: "folder",
    folderFilter: {},

    pattern: {},

    // view: "search", // "list",
    queryFilter: {},

    // navigation tree
    navigationLoading: true,

    // new
    selectedNames: [],
    selectedRecords: [],
    listFilter: {},

    recordChangedEvent: {},
    selectedFolder: { name: "", description: "" },

    parentToChildEvent: {},
  });

  const [toastState, setToastState] = useState(EMPTY_TOAST_STATE);

  const [searchParams, setSearchParams] = useSearchParams();

  const cmpWorking = useRef({});
  const leftRef = useRef(null);
  const navigationRef = useRef(null);
  const navigationscroll = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    init();
  }, []);

  useEffect(() => {
    onPageReferenceChange();
  }, [searchParams]);

  const cmp = {
    get: (key) => {
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },
  };

  const onPageReferenceChange = () => {
    try {
      parsePageRef();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const init = () => {
    try {
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleDeleteFolderEvent = (event) => {
    cmp.set("navigationLoading", true);

    // update NavigatinTree:
    const recordChangedEvent = {
      action: "delete",
      parentId: cmp.get("selectedId"),
      object: cmp.get("selectedObject"),
      id: cmp.get("listFilter").folderId,
      record: { id: "folder", name: "Folders" },
    };
    cmp.set("recordChangedEvent", recordChangedEvent);

    cmp.set("listFilter", {});
    cmp.set("queryFilter", {});
    cmp.set("selectedNames", []);

    navigateSelected([]);
    cmp.set("navigationLoading", false);
  };

  const handleReload = () => {
    cmp.set("parentToChildEvent", {
      action: "reload",
    });
  };

  const childToParent = (event) => {
    try {
      if (event.type === "navigation") {
        handleNavigationEvent(event);
      } else if (event.type === "dataCompEvent") {
        handleDataCompEvent(event);
      } else if (event.type === "deleteFolderEvent") {
        handleDeleteFolderEvent(event);
      }
      if (event.record) {
        cmp.set("selectedFolder", event.record);
      } else if (event.type === "reload") {
        handleReload(event);
      } else if (event.type === "logout") {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDataCompEvent = (event) => {
    try {
      var data = event.data;
      if (data.action === "viewDetails") {
        var pattern = data.pattern;
        var patternId = (pattern || {}).id;
        var currentId = (cmpState.pattern || {}).id;
        var currentView = cmpState.view;
        cmp.set("pattern", pattern);

        if (patternId) {
          if (patternId !== currentId || currentView !== "detail") {
            navigatePattern(patternId);
          }
        } else {
          // don't navigate if no patternId available
          cmp.set("view", "details");
        }
      } else if (data.action === "close") {
        navigatePattern(undefined);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const syncItem = (selected, event) => {
    // // default sections used when navigating from an external component
    // var sections = ["types", "data"];
    // var defaultSections = {
    //   root: "types",
    //   dataRole: "types",
    //   dataType: "types",
    //   source: "data",
    //   container: "data",
    //   key: "data",
    // };

    // // create item
    // var item = Record.itemFromEvent(event, defaultSections);

    // // don't update if the item is already selected, and the new event has isLoading
    // // the item would be stuck in loading, because no navigation is done when selecting an already selected item
    // if (!item.hasDetails && selected.find((i) => i.name === item.name)) {
    //   return selected;
    // }

    // // update / insert / delete new item
    // selected = selected.filter((i) => i.section !== item.section);
    // if (item.id) {
    //   selected.push(item);
    // }

    // // sort in order of the sections
    // var sectionMap = selected.reduce((obj, i) => {
    //   obj[i.section] = i;
    //   return obj;
    // }, {});

    // return sections.map((i) => sectionMap[i]).filter((i) => i != null);

    return [
      {
        config: "folder",
        hasDetails: true,
        id: event.id,
        label: event.label,
        name: `folders_folder_${event.id}`,
        section: "folders",
        title: event.label,
      },
    ];
  };

  // navigate from event
  const handleNavigationEvent = (event) => {
    try {
      // navigate to different tab, e.g., when using the buttons in connector status section
      var tab = event.tab || "";
      if (["savedInsights"].includes(tab.toLowerCase())) {
        navigate("/SavedInsights");
        return;
      }

      // sync records
      var selectedRecords = cmpWorking.current.selectedRecords || [];
      selectedRecords = syncItem(selectedRecords, event);

      cmpWorking.current.selectedRecords = selectedRecords;

      // scroll only
      var source = event.source;
      // if (["change", "closeSearch"].includes(source)) {
      //   var scroll = event.scroll;
      //   // var scroller = document.getElementById("left");
      //   var scroller = leftRef.current;
      //   var searchInput = searchdivRef.current; // document.getElementById("searchdiv");
      //   // update scroll position after rendering, so that rendered sizes are available
      //   if (searchInput && scroller && scroll != null) {
      //     var timer = setTimeout(() => {
      //       var top = searchInput.offsetTop + searchInput.offsetHeight;
      //       scroller.scrollTop = scroll * (scroller.scrollHeight - top);
      //     }, 0);
      //   }
      // }

      // navigate
      if (["record", "grid", "tree"].includes(source)) {
        var selectedNames = cmpWorking.current.selectedNames || [];
        var oldNames = selectedNames.join("~");
        var newNames = selectedRecords.map((item) => item.name).join("~");

        var folderId = "";
        if (event.obj === "folder") {
          folderId = event.id;
          navigateSelected(newNames, folderId);
          return;
        }

        if (newNames !== oldNames) {
          navigateSelected(newNames);
        }
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const navigatePattern = (patternId) => {
    if (!patternId) {
      searchParams.delete("pattern");
      setSearchParams(searchParams);
    } else {
      searchParams.set("pattern", patternId);
      setSearchParams(searchParams);
    }
  };

  const navigateSelected = (names, folderId = null) => {
    // var pageref = cmp.get("v.pageReference");
    // var namespace = cmp.getType().split(":")[0];
    // var state = {
    //   [namespace + "__selected"]: names,
    //   [namespace + "__pattern"]: undefined,
    // };
    // cmp
    //   .find("navService")
    //   .navigate({ type: pageref.type, attributes: pageref.attributes, state });

    var search = "?selected=" + names;
    if (folderId) {
      search += "&folder=" + folderId;
    }

    //TODO navigate to pattern undefined? doesn't make sense?
    navigate({
      pathname: "/SavedInsights",
      search: search, //"?selected=" + names + "&folder="+ folderId, //+ "&pattern=" + undefined,
    });
  };

  // updates component navigation items from query parameters
  const parsePageRef = () => {
    try {
      var selected = (searchParams.get("selected") || "").split("~");

      var selectedNames = [];
      var listFilter = {};

      selected.forEach((name) => {
        if (name) {
          selectedNames.push(name);
          var parsed = Record.parseName(name);
          if (parsed.config !== "root") {
            // listFilter.push(parsed.config + "Id=" + parsed.id);
            listFilter[parsed.config + "Id"] = parsed.id;
          }
        }
      });

      var folderId = searchParams.get("folder");
      if (folderId) {
        listFilter.folderId = folderId;
      }

      // determine view, but first set the component's filters and selected IDs before changing the view, so that that components are rendered with the correct filters, and don't need to reload after init with the newly set filters
      var patternId = searchParams.get("pattern");
      var view = patternId ? "detail" : "list";

      // update filters depending on the view
      if (view === "list") {
        if (
          JSON.stringify(listFilter) !== JSON.stringify(cmp.get("listFiter"))
        ) {
          cmp.set("listFilter", listFilter);
        }
      } else if (view === "detail") {
        var currentId = (cmp.get("pattern") || {}).id;
        if (patternId !== currentId) {
          cmp.set("pattern", { id: patternId });
        }
      }

      cmp.set("listFilter", listFilter);

      // update tree with selection
      cmp.set("selectedNames", selectedNames);

      // set right-side panel view mode
      cmp.set("view", view);
    } catch (err) {
      console.error(err.stack);
    }
  };

  return (
    <div className="SavedInsights">
      <PsNavigationHeader view={cmpState.view} childToParent={childToParent} />

      <div className="tab-content slds-p-around_medium">
        {/* <!-- using slds-hide to prevent rebuilding views that need to keep their state --> */}
        <div
          className={
            cmpState.view === "list" ? "left slds-m-right_medium" : "slds-hide"
          }
        >
          {/* <!-- navigation tree --> */}
          <article
            ref={leftRef}
            className="slds-card left-card slds-card_boundary slds-scrollable"
          >
            <div className="slds-card__header">
              <h2 className="card-main-title-lh32 slds-card__header-title slds-text-heading_small slds-truncate">
                Browse
              </h2>
            </div>

            <div className="slds-p-horizontal_x-small">
              {cmpState.navigationLoading && <SearchLoading />}
              <PsNavigationTree
                multiSelect={false}
                sections={["folders"]}
                selected={cmpState.selectedNames}
                parentCmp={cmp}
                childToParent={childToParent}
                recordChangedEvent={cmpState.recordChangedEvent}
              />
            </div>

            {/* <lightning:tree aura:id="treenav" items="{!v.folderTree}" onselect="{!c.handleSelect}" selectedItem="{!v.setSelectedItem}"/> */}
          </article>
        </div>

        {/* <!-- right panel view --> */}
        <div className={cmpState.view === "list" ? "right " : "slds-hide"}>
          <PsSetupStatus
            id="setupStatus"
            title="Saved Insights"
            tagLine="View saved and liked insights, and organize insights as dashboards."
            parentToChildEvent={cmpState.parentToChildEvent}
            parentCmp={cmp}
          />

          {cmpState.selectedObject === "topFolder" && (
            <PsFolderList
              parentId={cmpState.selectedId}
              queryFilter={cmpState.folderFilter}
              childToParent={childToParent}
            />
          )}

          {cmpState.selectedNames?.length === 0 ? (
            <Card className="PsRecoredGrid slds-scrollable" heading="">
              <div className="slds-is-relative">
                <div
                  className="slds-p-around_medium slds-illustration slds-illustration_large"
                  aria-hidden="true"
                >
                  <IllustrationOpenRoad />
                  <div
                    className="slds-text-color_weak"
                    style={{
                      marginBottom: "150px",
                    }}
                  >
                    <h3 className="slds-text-heading_medium">
                      Select a folder
                    </h3>
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            cmpState.selectedObject === "folder" && (
              // pass in folder or folder id
              <PsDashboardGrid
                view="grid"
                queryFilter={cmpState.listFilter}
                childToParent={childToParent}
                setToastState={setToastState}
                selectedFolder={cmpState.selectedFolder}
                parentToChildEvent={cmpState.parentToChildEvent}
                parentCmp={cmp}
              />
            )
          )}
        </div>

        {/* <!-- pattern detail --> */}
        {cmpState.view === "detail" && (
          <PsPatternDetailedView
            pattern={cmpState.pattern}
            childToParent={childToParent}
            setToastState={setToastState}
            parentToChildEvent={cmpState.parentToChildEvent}
            parentCmp={cmp}
          />
        )}
      </div>
      {toastState.details ? (
        <ToastComponent
          close={() => setToastState(EMPTY_TOAST_STATE)}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </div>
  );
};

export default SavedInsights;
