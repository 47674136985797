import { useState } from "react";
import { Card } from "@salesforce/design-system-react";

import "./../Pages.css";
import ToastComponent from "../../components/toast-component";
import UserForm from "./components/UserForm";
import ConfirmationForm from "./components/ConfirmationForm";
import { EMPTY_TOAST_STATE } from "../../constants";

const Signup = () => {
  const [toastState, setToastState] = useState(EMPTY_TOAST_STATE);
  const [newUser, setNewUser] = useState(null);
  const [userForm, setUserForm] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  return (
    <div className="login-signup-container">
      <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
        {newUser ? (
          <ConfirmationForm
            setToastState={setToastState}
            toastState={toastState}
            userForm={userForm}
          />
        ) : (
          <UserForm
            setToastState={setToastState}
            setNewUser={setNewUser}
            userForm={userForm}
            setUserForm={setUserForm}
          />
        )}
      </Card>
      {toastState.details ? (
        <ToastComponent
          close={() => setToastState(EMPTY_TOAST_STATE)}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </div>
  );
};

export default Signup;
