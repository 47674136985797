import { useState, useEffect } from "react";
import { Card } from "@salesforce/design-system-react";
import { useSearchParams } from "react-router-dom";

import "../Pages.css";
import ToastComponent from "../../components/toast-component";
import LoginForm from "./components/LoginForm";
import ResetForm from "./components/ResetForm";
import NewPasswordForm from "./components/NewPasswordForm";
import { EMPTY_TOAST_STATE } from "../../constants";

const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [toastState, setToastState] = useState(EMPTY_TOAST_STATE);
  const [newUser, setNewUser] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [mode, setMode] = useState(""); //login, resetPassword, newPassword

  const closeToast = () => setToastState(EMPTY_TOAST_STATE);

  useEffect(() => {
    onPageReferenceChange();
  }, [searchParams]);

  const onPageReferenceChange = () => {
    try {
      var mode = searchParams.get("mode") || "login";
      setMode(mode.toLowerCase());
    } catch (err) {
      console.error(err.stack);
    }
  };

  return (
    <div className="login-signup-container">
      <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
        {mode === "login" && (
          <LoginForm
            setToastState={setToastState}
            setMode={setMode}
            setNewUser={setNewUser}
          />
        )}

        {mode === "resetpassword" && (
          <ResetForm
            confirmationCode={confirmationCode}
            setConfirmationCode={setConfirmationCode}
            setToastState={setToastState}
            setMode={setMode}
          />
        )}

        {mode === "newpassword" && (
          <NewPasswordForm setToastState={setToastState} newUser={newUser} />
        )}
      </Card>
      {toastState.details ? (
        <ToastComponent
          close={closeToast}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </div>
  );
};

export default Login;
