// these all moved from recordLayer.js

const RecordConstants = {
  //Generic Active/Inactive Status than can be reused
  GENERIC_STATUS: {
    ACTIVE: { value: "Active", label: "Active" },
    INACTIVE: { value: "Inactive", label: "Inactive" },
  },

  ENVIRONMENT_STATUS: {
    ACTIVE: {
      value: "Active",
      label: "Active",
      description: "Connectors can load inventory and data",
    },
    INACTIVE: {
      value: "Inactive",
      label: "Inactive",
      description: "Connectors won't contact the Source",
    },
  },

  SOURCE_RESTRICT: {
    NONE: { value: "--None--", label: "--None--" },
    SUPPLEMENTARY: {
      value: "Supplementary",
      label: "Supplementary",
      description:
        "Only combine with other Sources; don't find Patterns within this Source",
    },
    ISOLATED: {
      value: "Isolated",
      label: "Isolated",
      description: "Don't combine with other Sources",
    },
  },

  CONNECTOR_STATUS: {
    ACTIVE: {
      value: "Active",
      label: "Active",
      description: "Download new data from the Source",
    },
    INACTIVE: {
      value: "Inactive",
      label: "Inactive",
      description: "Don't contact the Source, but keep data downloaded so far",
    },
  },

  RESTRICTION_STATUS: {
    ACTIVE: { value: "Active", label: "Active" },
    INACTIVE: { value: "Inactive", label: "Inactive" },
  },

  OBJECT_STATUS: {
    INCLUDED: { value: "Included", label: "Included" },
    EXCLUDED: { value: "Excluded", label: "Excluded" },
  },

  FIELD_STATUS: {
    INCLUDED: { value: "Included", label: "Included" },
    EXCLUDED: { value: "Excluded", label: "Excluded" },
  },

  DEFAULT_OBJECT_STATUS: {
    INCLUDED: {
      value: "Included",
      label: "Included",
      description: "Automatically load new Objects",
    },
    EXCLUDED: {
      value: "Excluded",
      label: "Excluded",
      description: "Let me choose which Objects to load",
    },
  },

  DEFAULT_FIELD_STATUS: {
    INCLUDED: {
      value: "Included",
      label: "Included",
      description: "Automatically load new Fields",
    },
    EXCLUDED: {
      value: "Excluded",
      label: "Excluded",
      description: "Let me choose which Fields to load",
    },
  },

  CONNECTOR_SCHEDULE: {
    NONE: { value: "--None--", label: "--None--" },
    //MINUTE: {value: 'Every Minute', label: 'Every Minute'}, // only used for testing
    HOUR: { value: "Hourly", label: "Hourly" },
    DAY: { value: "Daily", label: "Daily" },
    WEEK: { value: "Weekly", label: "Weekly" },
    MONTH: { value: "Monthly", label: "Monthly" },
  },

  FILTER_TYPES: {
    Text: [
      {
        value: "Includes",
        label: "Is In",
        useInput: "ListValues",
        default: true,
      },
      { value: "Excludes", label: "Is Not In", useInput: "ListValues" },
      { value: "Contains", label: "Contains", useInput: "FreeText" },
      { value: "Lacks", label: "Lacks", useInput: "FreeText" },
      { value: "Missing", label: "Missing", useInput: "Missing" },
      { value: "NotMissing", label: "Not Missing", useInput: "Missing" },
      {
        value: "PresetText",
        label: "Preset",
        useInput: "Preset",
        default: true,
      },
    ],
    Number: [
      {
        value: "Between",
        label: "Between",
        useInput: "BetweenNumber",
        default: true,
      },
      { value: "Missing", label: "Missing", useInput: "Missing" },
      { value: "NotMissing", label: "Not Missing", useInput: "Missing" },
      {
        value: "PresetNumber",
        label: "Preset",
        useInput: "Preset",
        default: true,
      },
    ],
    DateTime: [
      {
        value: "PresetDateTime",
        label: "Preset",
        useInput: "Preset",
        default: true,
      },
      {
        value: "RelativeDateTime",
        label: "Relative",
        operator: "Between",
        useInput: "RelativeDateTime",
      },
      {
        value: "BetweenDateTime",
        label: "Between",
        operator: "Between",
        useInput: "BetweenDateTime",
      },
      { value: "Missing", label: "Missing", useInput: "Missing" },
      { value: "NotMissing", label: "Not Missing", useInput: "Missing" },
    ],
  },

  FILTER_PRESETS: {
    Text: [
      {
        value: "AllValues",
        label: "All",
        operator: "Includes",
        description: "All values are included",
        settings: { values: null },
      },
      {
        value: "LoggedInUserId",
        label: "Logged In User Id",
        description: "Filter by logged in user id",
        operator: "Includes",
        settings: { values: null },
      },
      {
        value: "LoggedInUserValue",
        label: "Logged In User Value",
        description: "Filter by user field (defined in App Builder)",
        operator: "Includes",
        settings: { values: null },
      },
      {
        value: "EmbeddedRecordValue",
        label: "Embedded Record Value",
        description: "Filter by embedded record field (defined in App Builder)",
        operator: "Includes",
        settings: { values: null },
      },
    ],
    Number: [
      {
        value: "AllValues",
        label: "All",
        operator: "Between",
        settings: { min: null, max: null },
      },
    ],
    DateTime: [
      {
        value: "AllTime",
        label: "All Time",
        operator: "Between",
        settings: { min: null, max: null },
      },
      {
        value: "Today",
        label: "Today",
        operator: "Between",
        settings: { min: "0 days", max: "1 days" },
      },
      {
        value: "Last7Days",
        label: "Last 7 Days",
        operator: "Between",
        settings: { min: "-7 days", max: "0 days" },
      },
      {
        value: "Next7Days",
        label: "Next 7 Days",
        operator: "Between",
        settings: { min: "1 days", max: "8 days" },
      },
      {
        value: "LastWeek",
        label: "Last Week",
        operator: "Between",
        settings: { min: "-1 weeks", max: "0 weeks" },
      },
      {
        value: "ThisWeek",
        label: "This Week",
        operator: "Between",
        settings: { min: "0 weeks", max: "1 weeks" },
      },
      {
        value: "NextWeek",
        label: "Next Week",
        operator: "Between",
        settings: { min: "1 weeks", max: "2 weeks" },
      },
      {
        value: "Last30Days",
        label: "Last 30 Days",
        operator: "Between",
        settings: { min: "-30 days", max: "0 days" },
      },
      {
        value: "Next30Days",
        label: "Next 30 Days",
        operator: "Between",
        settings: { min: "1 days", max: "31 days" },
      },
      {
        value: "LastMonth",
        label: "Last Month",
        operator: "Between",
        settings: { min: "-1 months", max: "0 months" },
      },
      {
        value: "ThisMonth",
        label: "This Month",
        operator: "Between",
        settings: { min: "0 months", max: "1 months" },
      },
      {
        value: "NextMonth",
        label: "Next Month",
        operator: "Between",
        settings: { min: "1 months", max: "2 months" },
      },
      {
        value: "Last90Days",
        label: "Last 90 Days",
        operator: "Between",
        settings: { min: "-90 days", max: "0 days" },
      },
      {
        value: "Next90Days",
        label: "Next 90 Days",
        operator: "Between",
        settings: { min: "1 days", max: "91 days" },
      },
      {
        value: "LastQuarter",
        label: "Last Quarter",
        operator: "Between",
        settings: { min: "-1 quarters", max: "0 quarters" },
      },
      {
        value: "ThisQuarter",
        label: "This Quarter",
        operator: "Between",
        settings: { min: "0 quarters", max: "1 quarters" },
      },
      {
        value: "NextQuarter",
        label: "Next Quarter",
        operator: "Between",
        settings: { min: "1 quarters", max: "2 quarters" },
      },
      {
        value: "Last Year",
        label: "Last Year",
        operator: "Between",
        settings: { min: "-1 years", max: "0 years" },
      },
      {
        value: "This Year",
        label: "This Year",
        operator: "Between",
        settings: { min: "0 years", max: "1 years" },
      },
      {
        value: "Next Year",
        label: "Next Year",
        operator: "Between",
        settings: { min: "1 years", max: "2 years" },
      },
    ],
  },

  USER_TYPE: {
    USER: {
      value: "User",
      label: "User",
      description: "Standard user",
    },
    ADMINISTRATOR: {
      value: "Administrator",
      label: "Administrator",
      description: "User with administrative privileges",
    },
  },

  // ---> Do not change order of this object keys and values
  ACCOUNT_SETTINGS_GENERATIVE_AI: {
    OFF: {
      value: "Off",
      label: "Off",
      description: "No access allowed", // ---> (Most restricted)
    },
    METADATA: {
      value: "Metadata",
      label: "Metadata",
      description: "Access to metadata only, no data access",
    },
    DATA: {
      value: "Data",
      label: "Data",
      description: "Access to data, but not full system control",
    },
    FULL: {
      value: "Full",
      label: "Full",
      description: "Full access, including data and system controls",
    },
    DEBUG: {
      value: "Debug",
      label: "Debug",
      description: "Full access with additional debugging capabilities", // ---> (Least restricted)
    },
  },

  DATETIME_UNITS: [
    { label: "--None--", value: "" },
    { label: "Seconds", value: "seconds" },
    { label: "Minutes", value: "minutes" },
    { label: "Hours", value: "hours" },
    { label: "Days", value: "days" },
    { label: "Weeks", value: "weeks" },
    { label: "Months", value: "months" },
    { label: "Quarters", value: "quarters" },
    { label: "Years", value: "years" },
  ],

  DATETIME_ROLLUP: [
    { value: "N", label: "Nanosecond" },
    { value: "U", label: "Microsecond" },
    { value: "L", label: "Millisecond" },
    { value: "S", label: "Second" },
    { value: "T", label: "Minute" },
    { value: "H", label: "Hour" },
    { value: "D", label: "Day" },
    { value: "W", label: "Week" },
    { value: "M", label: "Month" },
    { value: "Q", label: "Quarter" },
    { value: "Y", label: "Year" },
    { value: "10Y", label: "Decade" },
    { value: "100Y", label: "Century" },
    { value: "", label: "Automatic" },
  ],

  FILTER_SCOPES: [
    {
      label: "Pattern",
      value: "Pattern",
      description: "Apply to this pattern only",
    },
    {
      label: "Outliers",
      value: "Outliers",
      description: "Automatic outlier filter",
    },
    {
      label: "Session",
      value: "Session",
      description: "Apply within this session",
    },
    {
      label: "Dashboard",
      value: "Dashboard",
      description: "Apply within this dashboard",
    },
    {
      label: "Project",
      value: "Project",
      description: "Apply within this project",
    },
    { label: "Global", value: "Global", description: "Apply to all patterns" },
  ],

  MAP_SCOPES: [
    {
      label: "Pattern",
      value: "Pattern",
      description: "Apply to this pattern only",
    },
    {
      label: "Session",
      value: "Session",
      description: "Apply within this session",
    },
    {
      label: "Dashboard",
      value: "Dashboard",
      description: "Apply within this dashboard",
    },
    {
      label: "Project",
      value: "Project",
      description: "Apply within this project",
    },
    { label: "Global", value: "Global", description: "Apply to all patterns" },
  ],

  SILENT_API_ERRORS: [
    "NotConnected",
    "NoSearchMatch",
    "EmptyPlotError",
    "PlotError",
    "Timeout",
  ],

  // IMPROVMENT: instead of defining this here separately, use the 'name' attributes in NavigationTree specification
  TERMINOLOGY: {
    source: "Source",
    container: "Object",
    key: "Field",
    dataType: "Data Type",
    dataRole: "Data Role",
    filter: "Filter",
    link: "Join",
    chain: "Path",
    agg: "Aggregation",
    transform: "Transformation",
  },
};
export default RecordConstants;
