const SearchIcon = () => {
  return (
    <svg
      className="slds-icon slds-input__icon slds-input__icon_left slds-icon-text-default"
      aria-hidden="true"
    >
      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
    </svg>
  );
};

export default SearchIcon;
