import { Input, ProgressBar, Tooltip } from "@salesforce/design-system-react";

import EditButtonIcon from "./EditButtonIcon";

const BetweenNumber = ({ filterState, handleChangeMaxMinNumber, cmp }) => {
  return (
    <>
      {/* Min Number */}
      <div className="slds-form__item" role="listitem">
        <div className="slds-form-element slds-form-element_stacked">
          {filterState.mode === "init" && (
            <>
              <span className="slds-form-element__label">&nbsp;</span>
              <div className="slds-form-element__static">
                <ProgressBar value={0} variant="circular" />
              </div>
            </>
          )}

          {filterState.mode === "view" && (
            <div className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
              <span className="slds-form-element__label">From</span>
              <div className="slds-form-element__control">
                <div className="slds-form-element__static">
                  {filterState.minNumber}
                </div>
                <div className="slds-button slds-button__icon slds-button__icon_hint">
                  <EditButtonIcon handleEdit={cmp.handleEdit} />
                </div>
              </div>
            </div>
          )}

          {(filterState.mode === "new" || filterState.mode === "edit") && (
            <Input
              auraId="checkField"
              type="text"
              name="minnumber"
              autoComplete="off"
              label="From"
              required={false}
              value={filterState.minNumber}
              fieldLevelHelpTooltip={
                <Tooltip
                  id="field-level-help-tooltip"
                  align="top left"
                  content="Minimum value (included). Leave empty to ignore."
                />
              }
              onChange={(e) =>
                handleChangeMaxMinNumber(e.target.value, "minNumber")
              }
            />
          )}
        </div>
      </div>
      {/* Max Number */}
      <div className="slds-form__item" role="listitem">
        <div className="slds-form-element slds-form-element_stacked">
          {filterState.mode === "init" && (
            <>
              <span className="slds-form-element__label">&nbsp;</span>
              <div className="slds-form-element__static">
                <ProgressBar value={0} variant="circular" />
              </div>
            </>
          )}

          {filterState.mode === "view" && (
            <div className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
              <span className="slds-form-element__label">To</span>
              <div className="slds-form-element__control">
                <div className="slds-form-element__static">
                  {filterState.maxNumber}
                </div>
                <div className="slds-button slds-button__icon slds-button__icon_hint">
                  <EditButtonIcon handleEdit={cmp.handleEdit} />
                </div>
              </div>
            </div>
          )}

          {(filterState.mode === "new" || filterState.mode === "edit") && (
            <Input
              auraId="checkField"
              type="text"
              name="maxnumber"
              autoComplete="off"
              label="To"
              required={false}
              value={filterState.maxNumber}
              fieldLevelHelpTooltip={
                <Tooltip
                  id="field-level-help-tooltip"
                  align="top left"
                  content="Maximum value (included). Leave empty to ignore."
                />
              }
              onChange={(e) =>
                handleChangeMaxMinNumber(e.target.value, "maxNumber")
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BetweenNumber;
