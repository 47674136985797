import { IconSettings, Tooltip } from "@salesforce/design-system-react";

const DualListBox = ({
  handleSelectedValuesChange,
  handleChangeSelectedValue,
  handleSelectedValueUpOrDown,
  label,
  fieldLevelHelp,
  valuesOptions,
  selectedOptions,
  selectedValue,
  sourceLabel,
  selectedLabel,
  hasNoUpDownButton,
  selectedFirstBoxItems,
  selectedSecondBoxItems,
}) => {
  const isDisabledUpDownButton =
    selectedFirstBoxItems === undefined
      ? !selectedOptions ||
        selectedOptions?.length === 0 ||
        selectedOptions?.length === 1 ||
        !selectedValue ||
        (selectedValue &&
          selectedOptions &&
          !selectedOptions.find((item) => item.value === selectedValue))
      : !selectedOptions ||
        selectedOptions?.length === 0 ||
        selectedSecondBoxItems.length > 1 ||
        selectedSecondBoxItems.length === 0;

  return (
    <div
      className="slds-form-element"
      role="group"
      aria-labelledby="picklist-group-label"
    >
      <span
        id="picklist-group-label"
        className="slds-form-element__label slds-form-element__legend"
      >
        {label}
      </span>

      {fieldLevelHelp && (
        <div style={{ paddingTop: "3px" }}>
          <IconSettings iconPath="/assets/icons">
            <Tooltip
              id="base"
              align="top left"
              content={fieldLevelHelp}
              variant="learnMore"
              dialogClassName="dialog-classname"
            />
          </IconSettings>
        </div>
      )}

      <div className="slds-form-element__control">
        <div className="slds-dueling-list">
          <div
            className="slds-assistive-text"
            id="drag-live-region"
            aria-live="assertive"
          >
            Option 5: final position 1 of 2.
          </div>
          <div className="slds-assistive-text" id="option-drag-label">
            Press space bar when on an item, to move it within the list.
            Cmd/Ctrl plus left and right arrow keys, to move items between
            lists.
          </div>

          {/* FIRST FIELD*/}
          <div className="slds-dueling-list__column slds-dueling-list__column_responsive">
            {sourceLabel && (
              <span className="slds-form-element__label" id="label-215">
                {sourceLabel}
              </span>
            )}
            <div className="slds-dueling-list__options">
              <ul
                aria-describedby="option-drag-label"
                aria-labelledby="label-73"
                // aria-multiselectable="false"
                className="slds-listbox slds-listbox_vertical"
                role="listbox"
              >
                {valuesOptions?.length > 0 &&
                  valuesOptions.map((option) => (
                    <li
                      role="presentation"
                      className="slds-listbox__item"
                      key={option.label}
                    >
                      <div
                        className="slds-listbox__option slds-listbox__option_plain slds-media slds-media_small slds-media_inline"
                        aria-selected={
                          selectedFirstBoxItems !== undefined
                            ? selectedFirstBoxItems.includes(option.value)
                            : selectedValue === option.value
                        }
                        draggable="false"
                        role="option"
                        tabIndex="0"
                        onClick={() => handleChangeSelectedValue(option.value)}
                      >
                        <span className="slds-media__body">
                          <span className="slds-truncate" title={option.label}>
                            {option.label}
                          </span>
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {/* FIRST BUTTONS*/}
          <div className="slds-dueling-list__column">
            <button
              className="slds-button slds-button_icon slds-button_icon-container"
              title="Move Selection to"
              disabled={!selectedValue}
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleSelectedValuesChange(selectedValue, "optionsToSelected")
              }
            >
              <svg className="slds-button__icon" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#right"></use>
              </svg>
              <span className="slds-assistive-text">Move Selection to</span>
            </button>
            <button
              className="slds-button slds-button_icon slds-button_icon-container"
              title="Move Selection to"
              disabled={!selectedValue}
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleSelectedValuesChange(selectedValue, "selectedToOptions")
              }
            >
              <svg className="slds-button__icon" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#left"></use>
              </svg>
              <span className="slds-assistive-text">Move Selection to</span>
            </button>
          </div>

          {/* SECOND FIELD*/}
          <div className="slds-dueling-list__column slds-dueling-list__column_responsive">
            {selectedLabel && (
              <span className="slds-form-element__label" id="label-215">
                {selectedLabel}
              </span>
            )}
            <div className="slds-dueling-list__options">
              <ul
                aria-describedby="option-drag-label"
                aria-labelledby="label-74"
                // aria-multiselectable="false"
                className="slds-listbox slds-listbox_vertical"
                role="listbox"
              >
                {selectedOptions?.length > 0 &&
                  selectedOptions.map((item, index) => (
                    <li
                      role="presentation"
                      className="slds-listbox__item"
                      key={index}
                    >
                      <div
                        className="slds-listbox__option slds-listbox__option_plain slds-media slds-media_small slds-media_inline slds-is-selected"
                        aria-selected={
                          selectedSecondBoxItems !== undefined
                            ? selectedSecondBoxItems.includes(item.value)
                            : selectedValue === item.value
                        }
                        draggable="false"
                        role="option"
                        tabIndex="0"
                        onClick={() => handleChangeSelectedValue(item.value)}
                      >
                        <span className="slds-media__body">
                          <span className="slds-truncate" title={item.label}>
                            {item.label}
                          </span>
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {/* SECOND BUTTONS*/}
          {hasNoUpDownButton ? null : (
            <div className="slds-dueling-list__column">
              <button
                className="slds-button slds-button_icon slds-button_icon-container"
                title="Move Selection Up"
                onClick={() => handleSelectedValueUpOrDown("up")}
                disabled={isDisabledUpDownButton}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#up"></use>
                </svg>
                <span className="slds-assistive-text">Move Selection Up</span>
              </button>
              <button
                className="slds-button slds-button_icon slds-button_icon-container"
                title="Move Selection Down"
                onClick={() => handleSelectedValueUpOrDown("down")}
                disabled={isDisabledUpDownButton}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                </svg>
                <span className="slds-assistive-text">Move Selection Down</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DualListBox;
