import "./PsUserFormBody";
import { Input } from "@salesforce/design-system-react";
import EditButtonIcon from "../../ui/EditButtonIcon";
import ProgressBarComponent from "../../ui/ProgressBarComponent";
import Combobox from "@salesforce/design-system-react/components/combobox";
import useAuthContext from "../../../context/useAuthContext";

// PsUserBody props type = {mode: "init"|"view"|"new"|"edit"; record:RecordType; fieldsError:{name:string; email:string}; handleEdit?:()=>void, handleFieldChange?:(e: input_event)=>void; getParentStateByKey?:(key:string)=>any}
// type RecordType = {name:string; email:string; id?:string; type?:string; status?:string}
const PsUserFormBody = ({
  mode,
  record,
  fieldsError,
  handleEdit = () => {
    return;
  },
  handleFieldChange = () => {
    return;
  },
  getParentStateByKey = () => {
    return;
  },
}) => {
  const { userDetails } = useAuthContext();
  const statusOptions =
    getParentStateByKey("statusOptions")?.map((item) => ({
      ...item,
      id: item.label,
    })) || [];
  const userTypesOptions =
    getParentStateByKey("userTypesOptions")?.map((item) => ({
      ...item,
      id: item.label,
    })) || [];

  return (
    <div
      className="slds-form slds-m-around_medium ps-user-form-container"
      role="list"
    >
      <h3 className="slds-section-title--divider slds-m-top_medium">
        User Details
      </h3>

      <div className="ps-user-form">
        {mode === "init" && (
          <WrapperField>
            <ProgressBarComponent />
          </WrapperField>
        )}
        {mode === "view" && (
          <>
            <div className="slds-form__row">
              <EditFieldWrapper name="Name" value={record.name || ""}>
                <EditButtonIcon handleEdit={handleEdit} />
              </EditFieldWrapper>
              <EditFieldWrapper name="Email" value={record.email || ""}>
                <EditButtonIcon handleEdit={handleEdit} />
              </EditFieldWrapper>
            </div>
            {record?.id !== userDetails?.id && ( // ------>  User can not update their own type and status<div className="slds-form__row">
              <div className="slds-form__row">
                <EditFieldWrapper name="Type" value={record?.type || ""}>
                  <EditButtonIcon handleEdit={handleEdit} />
                </EditFieldWrapper>
                <EditFieldWrapper name="Status" value={record?.status || ""}>
                  <EditButtonIcon handleEdit={handleEdit} />
                </EditFieldWrapper>
              </div>
            )}
          </>
        )}

        {(mode === "new" || mode === "edit") && (
          <>
            <div className="slds-form__row">
              <WrapperField>
                <Input
                  id="name"
                  name="name"
                  autoComplete="off"
                  label="Name"
                  required={true}
                  value={record.name || ""}
                  onChange={handleFieldChange}
                  errorText={
                    !!getParentStateByKey("missingRequiredFields")?.includes(
                      "record.name"
                    )
                      ? fieldsError.name
                      : ""
                  }
                />
              </WrapperField>

              <WrapperField>
                <Input
                  id="email"
                  name="email"
                  autoComplete="off"
                  label="Email"
                  required={true}
                  value={record.email || ""}
                  onChange={handleFieldChange}
                  errorText={
                    !!getParentStateByKey("missingRequiredFields")?.includes(
                      "record.email"
                    )
                      ? fieldsError.email
                      : ""
                  }
                />
              </WrapperField>
            </div>
            {record?.id !== userDetails?.id && ( // ------>  User can not update their own type and status
              <div className="slds-form__row">
                <WrapperField>
                  <Combobox
                    id="type"
                    events={{
                      onSelect: (_event, data) => {
                        if (data.selection.length === 0) return;
                        handleFieldChange({
                          target: {
                            name: "type",
                            value: data.selection[0].value,
                          }, // representing of input event so we can use the handleFieldChange function
                        });
                      },
                    }}
                    labels={{
                      name: "type",
                      label: "Type",
                      placeholder: "--Please Select--",
                    }}
                    menuPosition="relative"
                    options={userTypesOptions}
                    selection={[
                      userTypesOptions.find(
                        (option) => option.value === record?.type
                      ),
                    ]}
                    value={record.status}
                    variant="readonly"
                  />
                </WrapperField>
                {mode !== "new" && ( // ------>  Hide user status field on create (Server Need to handle email notification when create a user Inactive status)
                  <WrapperField>
                    <Combobox
                      events={{
                        onSelect: (_event, data) => {
                          if (data.selection.length === 0) return;
                          handleFieldChange({
                            target: {
                              name: "status",
                              value: data.selection[0].value,
                            }, // representing of input event so we can use the handleFieldChange function
                          });
                        },
                      }}
                      labels={{
                        label: "Status",
                        placeholder: "--Please Select--",
                      }}
                      menuPosition="relative"
                      options={statusOptions}
                      selection={[
                        statusOptions.find(
                          (option) => option.value === record?.status
                        ),
                      ]}
                      value={record.status}
                      variant="readonly"
                    />
                  </WrapperField>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
// WrapperField props type = { children: React.Node}
const WrapperField = (props) => {
  return (
    <div className="slds-form__item" role="listitem">
      <div className="slds-form-element slds-form-element_stacked">
        {props.children}
      </div>
    </div>
  );
};

// EditFieldWrapper all the props key are required
// EditFieldWrapper props type = { children: React.Node; name:string; value: string }

const EditFieldWrapper = ({ children, name, value }) => {
  return (
    <div className="slds-form__item" role="listitem">
      <div className="slds-form-element slds-form-element_stacked">
        <div
          id="FormDiv"
          className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
        >
          <span className="slds-form-element__label">{name}</span>
          <div className="slds-form-element__control">
            <div className="slds-form-element__static">{value}</div>
            <div className="slds-button slds-button__icon slds-button__icon_hint">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PsUserFormBody;
