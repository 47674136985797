import { Auth } from "aws-amplify";
import RecordConstants from "../constants/RecordConstants";

export function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
}

export const checkCurrentSession = async () => {
  try {
    const issCurrentSession = await Auth.currentSession();
    return issCurrentSession;
  } catch (error) {
    console.error("Error checking current session:", error);
    return null;
  }
};

export const getLocalDateTime = function (utcDatetime) {
  try {
    // utcDatetime 2022-09-12T13:09:46.000Z
    const utcDate = new Date(utcDatetime);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    // Convert to local timezone, automatically takes the local timezone of the system
    const formattedLocalDatetime = localDate.toISOString().slice(0, 23); // Format to '2022-09-12T13:09:46.000'
    // formattedLocalDatetime 2022-09-12T14:09:46.000 //BST, so this did work

    return formattedLocalDatetime;
  } catch (err) {
    console.error(err.stack);
  }
  return null;
};

export const getUTCDateTime = function (localDatetime) {
  try {
    // localDatetime 2022-09-12T14:09:46.000 //BST, so this did work

    const localDate = new Date(localDatetime);
    const formattedUtcDatetime = localDate.toISOString();

    // const utcDate = new Date(localDate.getTime() + (localDate.getTimezoneOffset() * 60000)); // Convert to UTC, automatically takes the local timezone of the system
    // const formattedUtcDatetime = utcDate.toISOString().slice(0, 23); // Format to '2022-09-12T13:09:46.000Z'

    // formattedUtcDatetime 2022-09-12T13:09:46.000Z

    return formattedUtcDatetime;
  } catch (err) {
    console.error(err.stack);
  }
  return null;
};

export function arrayToQueryString(obj) {
  const queryString = Object.entries(obj)
    .map(([key, value]) =>
      Array.isArray(value)
        ? value.map((val) => `${key}=${val}`).join("&")
        : `${key}=${value}`
    )
    .join("&");
  return queryString;
}

export function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export function toastDetails(response) {
  const message = response?.data?.message || null;
  const reference = response?.data?.reference;
  const suggestion = response?.data?.suggestion;

  return (
    <>
      {message}
      {reference && (
        <>
          <br /> {reference}
        </>
      )}
      {suggestion && (
        <>
          <br />
          {suggestion}
        </>
      )}
      {!message && !reference && !suggestion && "No response from server"}
    </>
  );
}

export function truncateText(text, maxLength) {
  return text.length <= maxLength ? text : text.substring(0, maxLength) + "...";
}

export const auth = (regionNumber) => {
  return {
    region: process.env[`REACT_APP_COGNITO_REGION_${regionNumber}`],
    userPoolId:
      process.env[`REACT_APP_COGNITO_USER_POOL_ID_REGION_${regionNumber}`],
    userPoolWebClientId:
      process.env[`REACT_APP_COGNITO_APP_CLIENT_ID_REGION_${regionNumber}`],
    mandatorySignIn: true,
  };
};

export const endpoints = (regionNumber) => {
  return [
    {
      name: "Application",
      endpoint:
        "https://" +
        process.env[`REACT_APP_COGNITO_REGION_${regionNumber}`] +
        "." +
        process.env.REACT_APP_API_URL,
      region: process.env[`REACT_APP_COGNITO_REGION_${regionNumber}`],
    },
  ];
};

export const formattedBlueText = (content) => {
  const matches = content.split(/(<\/?b>)/);

  const parsedContentArray = [];
  let isBlue = false;
  matches.forEach((match) => {
    if (match === "<b>") {
      isBlue = true;
    } else if (match === "</b>") {
      isBlue = false;
    } else if (match.trim() !== "") {
      parsedContentArray.push({ text: match, isBlue });
    }
  });

  return (
    <>
      {parsedContentArray.map((part, index) => (
        <span key={index} style={{ color: part.isBlue ? "#009edb" : "black" }}>
          {part.text}
        </span>
      ))}
    </>
  );
};

export const toastErrorMessage = (response) => {
  if (response === "No current user") {
    //if Auth.currentSession() fails
    response = { data: { message: "No current user" } };
  }

  const returnValue = response?.data || {};
  if (RecordConstants.SILENT_API_ERRORS.includes(returnValue.type)) {
    return null;
  }

  // some cases message is coming as an JWT token
  if (response?.data?.message?.includes("Authorization header: 'Bearer")) {
    return "No response from server";
  }

  return toastDetails(response) || "No response from server";
};

export const formattedRelevance = (relevance) => {
  return relevance
    ? new Intl.NumberFormat("en-US", {
        style: "percent",
        maximumFractionDigits: 0,
      }).format(relevance)
    : "";
};

export const formattedNumber = (number) => {
  return <>{number ? <span>{number.toLocaleString()}</span> : number}</>;
};

export const formattedDateTime = (dateString) => {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const regions = () => {
  const regionNumberCount = parseInt(
    process.env.REACT_APP_COGNITO_REGION_NUMBER_COUNT,
    10
  );
  const regionOptions = Array.from({ length: regionNumberCount }, (v, i) => {
    const id = (i + 1).toString();
    const label = process.env[`REACT_APP_COGNITO_REGION_LABEL_${id}`];
    const value = id;
    return { id, label, value };
  });

  return regionOptions;
};
