import { Button, Input, ProgressBar } from "@salesforce/design-system-react";
import Combobox from "@salesforce/design-system-react/components/combobox";

const RelativeDateTime = ({
  filterState,
  handleMaxAndMinDateAmount,
  handleMinDateUnitChange,
  handleMaxDateUnitChange,
  showRequiredFieldError,
}) => {
  // Combobox gives warning without id
  const dateTimeUnitsOptionsWithId =
    filterState.dateTimeUnits && filterState.dateTimeUnits.length > 0
      ? filterState.dateTimeUnits.map((item) => ({
          ...item,
          id: item.label,
        }))
      : filterState.dateTimeUnits;

  return (
    <>
      <div className="slds-form__item" role="listitem">
        <div className="slds-form-element slds-form-element_stacked">
          <div className="slds-form-element__label">
            <b>From</b>
          </div>
          {/* <Helptext content="Calendar date or time unit; e.g.: '0 weeks': start of this week; '-1 months': start of last month, '1 years': start of next year. Leave empty to ignore." /> */}
          <lightning-layout>
            {/* Min Amount */}
            <lightning-layout-item size="6">
              {filterState.mode === "init" && (
                <>
                  <span className="slds-form-element__label">&nbsp;</span>
                  <div className="slds-form-element__static">
                    <ProgressBar value={0} variant="circular" />
                  </div>
                </>
              )}

              {filterState.mode === "view" && (
                <div
                  id="FormDiv"
                  className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                >
                  <span className="slds-form-element__label">Amount</span>
                  <div className="slds-form-element__control">
                    <div className="slds-form-element__static">
                      {filterState.minDateAmount}
                    </div>
                    <div className="slds-button slds-button__icon slds-button__icon_hint">
                      <Button
                        auraId="EditRecord"
                        iconName="utility:edit"
                        variant="bare"
                        alternativeText="Edit"
                        // onClick={c.handleEdit}
                      />
                    </div>
                  </div>
                </div>
              )}

              {(filterState.mode === "new" || filterState.mode === "edit") && (
                <Input
                  type="number"
                  step={1}
                  auraId="checkField"
                  name="mindateamount"
                  label="Amount"
                  required={filterState.minDateUnit ? true : false}
                  value={filterState.minDateAmount}
                  onChange={(e) =>
                    handleMaxAndMinDateAmount(e.target.value, "minDateAmount")
                  }
                  errorText={
                    filterState.minDateUnit &&
                    !filterState.minDateAmount &&
                    showRequiredFieldError
                      ? "Complete this field."
                      : null
                  }
                />
              )}
            </lightning-layout-item>

            {/* Min Unit */}
            <lightning-layout-item size="6" className="slds-p-left_x-small">
              {filterState.mode === "init" && (
                <>
                  <span className="slds-form-element__label">&nbsp;</span>
                  <div className="slds-form-element__static">
                    <ProgressBar value={0} variant="circular" />
                  </div>
                </>
              )}

              {filterState.mode === "view" && (
                <div
                  auraId="FormDiv"
                  className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                >
                  <span className="slds-form-element__label">Unit</span>
                  <div className="slds-form-element__control">
                    <div className="slds-form-element__static">
                      {filterState.minDateUnitLabel}
                    </div>
                    <div className="slds-button slds-button__icon slds-button__icon_hint">
                      <Button
                        auraId="EditRecord"
                        iconName="utility:edit"
                        variant="bare"
                        alternativeText="Edit"
                        // onClick={c.handleEdit}
                      />
                    </div>
                  </div>
                </div>
              )}

              {(filterState.mode === "new" || filterState.mode === "edit") && (
                <Combobox
                  menuItemVisibleLength={10}
                  events={{
                    onSelect: (event, data) => {
                      handleMinDateUnitChange(event, data);
                    },
                  }}
                  labels={{
                    label: "Unit",
                    placeholder: "Select an option",
                  }}
                  menuPosition="relative"
                  options={dateTimeUnitsOptionsWithId}
                  selection={
                    [
                      dateTimeUnitsOptionsWithId.find(
                        (option) => option.value === filterState.minDateUnit
                      ),
                    ] || []
                  }
                  value={filterState.minDateUnit}
                  variant="readonly"
                  required={filterState.minDateAmount ? true : false}
                  errorText={
                    filterState.minDateAmount && !filterState.minDateUnit
                      ? "Complete this field."
                      : null
                  }
                />
              )}
            </lightning-layout-item>
          </lightning-layout>
        </div>
      </div>

      <div className="slds-form__item" role="listitem">
        <div className="slds-form-element slds-form-element_stacked">
          <div className="slds-form-element__label">
            <b>To</b>
          </div>
          {/* <Helptext content="Calendar date or time unit; e.g.: '0 weeks': end of last week; '-1 months': end of the month before last, '1 years': end of this year. Leave empty to ignore." /> */}

          <lightning-layout>
            {/* Max Amount */}
            <lightning-layout-item size="6">
              {filterState.mode === "init" && (
                <>
                  <span className="slds-form-element__label">&nbsp;</span>
                  <div className="slds-form-element__static">
                    <ProgressBar value={0} variant="circular" />
                  </div>
                </>
              )}

              {filterState.mode === "view" && (
                <div
                  auraId="FormDiv"
                  className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                >
                  <span className="slds-form-element__label">Amount</span>
                  <div className="slds-form-element__control">
                    <div className="slds-form-element__static">
                      {filterState.maxDateAmount}
                    </div>
                    <div className="slds-button slds-button__icon slds-button__icon_hint">
                      <Button
                        auraId="EditRecord"
                        iconName="utility:edit"
                        variant="bare"
                        alternativeText="Edit"
                        // onClick={handleEdit}
                      />
                    </div>
                  </div>
                </div>
              )}

              {(filterState.mode === "new" || filterState.mode === "edit") && (
                <Input
                  type="number"
                  step={1}
                  auraId="checkField"
                  name="maxdateamount"
                  label="Amount"
                  required={filterState.maxDateUnit ? true : false}
                  value={filterState.maxDateAmount}
                  onChange={(e) =>
                    handleMaxAndMinDateAmount(e.target.value, "maxDateAmount")
                  }
                  errorText={
                    filterState.minDateUnit &&
                    !filterState.minDateAmount &&
                    showRequiredFieldError
                      ? "Complete this field."
                      : null
                  }
                />
              )}
            </lightning-layout-item>

            {/* Max Unit */}
            <lightning-layout-item size="6" className="slds-p-left_x-small">
              {filterState.mode === "init" && (
                <>
                  <span className="slds-form-element__label">&nbsp;</span>
                  <div className="slds-form-element__static">
                    <ProgressBar value={0} variant="circular" />
                  </div>
                </>
              )}

              {filterState.mode === "view" && (
                <div
                  auraId="FormDiv"
                  className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                >
                  <span className="slds-form-element__label">Unit</span>
                  <div className="slds-form-element__control">
                    <div className="slds-form-element__static">
                      {filterState.maxDateUnitLabel}
                    </div>
                    <div className="slds-button slds-button__icon slds-button__icon_hint">
                      <Button
                        auraId="EditRecord"
                        iconName="utility:edit"
                        variant="bare"
                        alternativeText="Edit"
                        // onClick={c.handleEdit}
                      />
                    </div>
                  </div>
                </div>
              )}
              {(filterState.mode === "new" || filterState.mode === "edit") && (
                <>
                  <Combobox
                    menuItemVisibleLength={10}
                    events={{
                      onSelect: (event, data) => {
                        handleMaxDateUnitChange(event, data);
                      },
                    }}
                    labels={{
                      label: "Unit",
                      placeholder: "Select an option",
                    }}
                    menuPosition="relative"
                    options={dateTimeUnitsOptionsWithId}
                    selection={
                      [
                        dateTimeUnitsOptionsWithId.find(
                          (option) => option.value === filterState.maxDateUnit
                        ),
                      ] || []
                    }
                    value={filterState.maxDateUnit}
                    variant="readonly"
                    required={filterState.maxDateAmount ? true : false}
                    errorText={
                      filterState.maxDateAmount && !filterState.maxDateUnit
                        ? "Complete this field."
                        : null
                    }
                  />
                </>
              )}
            </lightning-layout-item>
          </lightning-layout>
        </div>
      </div>
    </>
  );
};

export default RelativeDateTime;
