const RecordItem = ({ record, index, handleRemoveItem, maxWidth }) => {
  return (
    <div
      key={record.id}
      style={{
        margin: "0.0625rem 0.3rem 0.0625rem 0",
        maxWidth: `${maxWidth}`,
      }}
    >
      <span id={index} className="slds-pill pill">
        <span
          id={index}
          title={record.title}
          className="slds-pill__label pill-label"
          style={{
            marginLeft: "0.2rem",
          }}
        >
          {record.label}
        </span>
        <button
          className="slds-button slds-button_icon"
          title="Remove"
          onClick={handleRemoveItem}
        >
          <svg
            className="slds-button__icon slds-button__icon_small"
            aria-hidden="true"
          >
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
          </svg>
          <span className="slds-assistive-text">Remove</span>
        </button>
      </span>
    </div>
  );
};

export default RecordItem;
