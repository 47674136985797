import { useState } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import {
  GENERAL_ERROR,
  INVALID_EMAIL,
  SIGNUP,
} from "../../../constants/ToastMessages";
import { isValidEmail } from "../../../helpers";
import "../../Pages.css";
import RegionSelection from "../../../components/region-selection";
import { EMPTY_TOAST_STATE } from "../../../constants";
import useAuthContext from "../../../context/useAuthContext";

const UserForm = ({ setToastState, setNewUser, userForm, setUserForm }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { username, email, password, confirmPassword } = userForm;
  const { user } = useAuthContext();

  const handleUserInputChange = (e, item) => {
    setUserForm((prevUser) => ({ ...prevUser, [item]: e.target.value }));
    setToastState(EMPTY_TOAST_STATE);
  };

  const handleSubmitUserForm = async (event) => {
    try {
      event.preventDefault();
      setToastState(EMPTY_TOAST_STATE);

      if (!isValidEmail(email)) {
        setToastState({
          heading: "Warning",
          variant: "warning",
          details: INVALID_EMAIL,
        });
        return;
      }
      if (password !== confirmPassword) {
        setToastState({
          heading: "Warning",
          variant: "warning",
          details: SIGNUP.PASSWORD_MISMATCH,
        });
        return;
      }

      setIsLoading(true);
      const newUser = await Auth.signUp({
        username,
        password,
        attributes: { email },
      });
      if (newUser) {
        setNewUser(newUser);
        setToastState({
          heading: "Success",
          variant: "success",
          details: SIGNUP.SUCCESS,
        });
      } else {
        setToastState({
          heading: "Error",
          variant: "error",
          details: GENERAL_ERROR,
        });
      }
    } catch (err) {
      console.error(err.stack);
      setToastState({
        heading: "Error",
        variant: "error",
        details: err.message || GENERAL_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmitUserForm}>
      <RegionSelection />
      <div className="user-inputs">
        <Input
          type="text"
          label="Username"
          value={username}
          onChange={(e) => handleUserInputChange(e, "username")}
          autoComplete="new-username"
        />
      </div>
      <div className="user-inputs">
        <Input
          type="text"
          label="Email"
          value={email}
          onChange={(e) => handleUserInputChange(e, "email")}
        />
      </div>
      <div className="user-inputs">
        <Input
          type="password"
          label="Password"
          value={password}
          onChange={(e) => handleUserInputChange(e, "password")}
          autoComplete="new-password"
        />
      </div>
      <div className="user-inputs">
        <Input
          type="password"
          label="Confirm password"
          value={confirmPassword}
          onChange={(e) => handleUserInputChange(e, "confirmPassword")}
          autoComplete="new-password"
        />
      </div>
      <div className="login-signup-button">
        <Button
          type="submit"
          label={<>Sign up {isLoading ? <Spinner size="small" /> : null}</>}
          variant="brand"
          disabled={
            isLoading ||
            !username ||
            !password ||
            !confirmPassword ||
            !email ||
            !user.region
          }
          style={{ width: "100%" }}
        />
      </div>
      <nav className="login-signup-link">
        Have an account? <Link to="/login">Log in</Link>
      </nav>
    </form>
  );
};

export default UserForm;
