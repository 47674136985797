import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./Store.css";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree";
import SearchBox from "../../components/ps-search-box/SearchBox";
import Record from "../../helpers/recordLayer";
import PsSourceList from "../../components/ps-source-list/PsSourceList";
// import PsContainerItemList from "../../components/ps-container-item-list/PsContainerItemList";
import ToastComponent from "../../components/toast-component";
import PsSource from "../../components/ps-source/PsSource";
import PsContainer from "../../components/ps-container/PsContainer";
import PsContainerList from "../../components/ps-container-list/PsContainerList";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsContainerItem from "../../components/ps-container-item/PsContainerItem";
import PsKeyList from "../../components/ps-key-list/PsKeyList";
import PsKey from "../../components/ps-key/PsKey";
import PsChainList from "../../components/ps-chain-list/PsChainList";
import PsChain from "../../components/ps-chain/PsChain";
import { PsLinkList } from "../../components/ps-link-list/PsLinkList";
import PsLink from "../../components/ps-link/PsLink";
import { PsMapList } from "../../components/ps-map-list/PsMapList";
import PsMap from "../../components/ps-map/PsMap";
import PsFilterList from "../../components/ps-filter-list/FilterList";
import { PsFilter } from "../../components/ps-filter/PsFilter";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext";
import useAccountSettingsContext from "../../context/useAccountSettingsContext";

const Store = () => {
  const { handleLogout } = useAuthContext();

  const emptyToastState = {
    variant: "",
    heading: "",
    details: "",
  };
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedSection = searchParams.get("selected")?.split("_")[0];

  const { accountSetupProgress } = useAccountSettingsContext();

  const [cmpState, setCmpState] = useState({
    selectedName: "",

    parentId: "source",
    selectedObject: "sources",
    searchText: "",
    searchLoading: false,
    rootId: "",
    rootFilter: {},
    sourcesId: "",
    sourcesFilter: {},
    sourceId: "",
    sourceFilter: {},
    containerId: "",
    containerFilter: {},
    containerItemId: "",
    itemName: "",
    keyId: "",
    keyFilter: {},
    filterId: "",
    filterFilter: {},
    mapId: "",
    mapFilter: {},
    chainId: "",
    chainFilter: {},
    linkId: "",
    linkFilter: {},

    filterOptions: [
      { label: "Data", value: "storeData" },
      { label: "Filters", value: "storeFilters" },
      { label: "Maps", value: "storeMaps" },
      { label: "Joins", value: "storeJoins" },
      { label: "Paths", value: "storeChains" },
    ],
    selectedFilter: selectedSection || "storeData",
    parentToChildEvent: { action: "" },
    recordChangedEvent: {},
  });
  const [toastState, setToastState] = useState(emptyToastState);

  const navigate = useNavigate();

  const cmpWorking = useRef({});
  const searchdivRef = useRef(null);
  const navigationTreeRef = useRef(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.onPageReferenceChange();
  }, [searchParams]);

  const cmp = {
    // --- StoreController.js ---

    init: function () {},

    afterScriptsLoaded: function () {
      try {
        cmp.parsePageRef();
      } catch (err) {
        console.error(err.stack);
      }
    },

    onPageReferenceChange: function () {
      try {
        cmp.parsePageRef();
      } catch (err) {
        console.error(err.stack);
      }
    },

    // handleNavigationEvent: function (cmp, event, helper) {
    //   try {
    //     if (!cmp.get("v.scriptsLoaded")) {
    //       return;
    //     }
    //     event.stopPropagation();

    //     // if no object is provided (e.g., when navigating to parentId), use the navigation tree to figure out where to go
    //     var id = event.getParam("id");
    //     var object = event.getParam("obj");

    //     if (id && !object) {
    //       var navigationTree = cmp.find("navigationTree");
    //       if (navigationTree) {
    //         var name =
    //           navigationTree.nameFromId(id) ||
    //           Record.nameFromDetails("store", "sources", "source");
    //         helper.navigateFromName(cmp, null, name);
    //       }
    //       return;
    //     }

    //     // scroll only
    //     var source = event.getParam("source");
    //     if (["change", "closeSearch"].includes(source)) {
    //       var scroll = event.getParam("scroll");
    //       var scroller = cmp.find("left");
    //       scroller = scroller ? scroller.getElement() : null;
    //       var searchInput = cmp.find("searchdiv");
    //       searchInput = searchInput ? searchInput.getElement() : null;
    //       // update scroll position after rendering, so that rendered sizes are available
    //       if (searchInput && scroller && scroll != null) {
    //         var timer = setTimeout(function () {
    //           $A.getCallback(function () {
    //             var top = searchInput.offsetTop + searchInput.offsetHeight;
    //             // calculation is not exactly correct, but close enough in practise
    //             scroller.scrollTop = scroll * (scroller.scrollHeight - top);
    //           })();
    //         }, 0);
    //       }
    //     }

    //     // navigate to specified record
    //     if (["record", "grid", "tree"].includes(source)) {
    //       helper.navigateFromEvent(cmp, event);
    //     }
    //   } catch (err) {
    //     console.error(err.stack);
    //   }
    // },

    // navigate from event
    handleNavigationEvent: function (event) {
      try {
        // if no object is provided (e.g., when navigating to parentId), use the navigation tree to figure out where to go
        var id = event.id;
        var object = event.obj;

        if (!id && !object) {
          const name = cmp.get("selectedFilter") + "_sources_source";
          cmp.navigateFromName(null, name);
          return;
        }

        // cancel create or after delete
        if (id && !object && navigationTreeRef) {
          const hyphenSeparatedParts = id.split("-");
          const firstPartOfParameters = hyphenSeparatedParts.shift();
          const restOfTheIdParameters = hyphenSeparatedParts.join("-");
          const underscoreSeparatedParts = id.split("_");

          // After delete a source
          if (underscoreSeparatedParts[1] === "source") {
            const name = underscoreSeparatedParts[0] + "_sources_source";
            cmp.navigateFromName(null, name);
            return;
          }

          if (firstPartOfParameters === "filter") {
            const name = "storeFilters_container_" + cmpState.containerId;
            cmp.navigateFromName(null, name);
            return;
          }

          if (firstPartOfParameters === "map") {
            const containerId = cmp.get("containerId");
            const name = containerId
              ? "storeMaps_container_" + restOfTheIdParameters
              : cmp.get("selectedFilter") + "_sources_source";
            cmp.navigateFromName(null, name);
            return;
          }

          if (firstPartOfParameters === "chain") {
            const name = "storeChains_container_" + restOfTheIdParameters;
            cmp.navigateFromName(null, name);
            return;
          }

          const parent = id.split("parent=")[1]?.split("&")[0] || null;
          if (parent === "source") {
            const name = cmp.get("selectedFilter") + "_sources_source";
            cmp.navigateFromName(null, name);
            return;
          }

          var name = Record.nameFromDetails("store", "sources", "source");
          name = id === "storeData_source" ? null : id;
          cmp.navigateFromName(null, name);
          return;
        }

        // scroll only
        var source = event.source;
        if (["change", "closeSearch"].includes(source)) {
          var scroll = event.scroll;
          var scroller = navigationTreeRef.current;
          var searchInput = searchdivRef.current;
          // update scroll position after rendering, so that rendered sizes are available
          if (searchInput && scroller && scroll != null) {
            var timer = setTimeout(() => {
              var top = searchInput.offsetTop + searchInput.offsetHeight;
              scroller.scrollTop = scroll * (scroller.scrollHeight - top);
            }, 0);
          }
        }

        // navigate to specified record
        if (["record", "grid", "tree"].includes(source)) {
          cmp.navigateFromEvent(event);
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRefreshEvent: function (cmp, event, helper) {
      try {
        event.stopPropagation();
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- StoreHelper.js ---

    // parsePageRef: function (cmp) {
    //   var namespace = cmp.getType().split(":")[0];

    //   var state = cmp.get("v.pageReference").state;

    //   var name =
    //     state[namespace + "__selected"] ||
    //     Record.nameFromDetails("store", "sources", "source");

    //   var parsed = Record.parseName(name);
    //   var config = parsed.config || "";
    //   var object = config;
    //   var id = parsed.id;
    //   var itemName = null;

    //   // left / right
    //   if (object.startsWith("right")) {
    //     object = object.replace(/(^right)/g, "");
    //   }

    //   // each object has its own id and queryFilter variable, to prevent its component from reloading when changing the selection
    //   var selectedIdName = "v." + object + "Id";
    //   if (id !== cmp.get(selectedIdName)) {
    //     cmp.set(selectedIdName, id);
    //   }

    //   // query filter
    //   var queryFilter = {};
    //   if (
    //     ["source", "container", "key", "link", "chain", "filter", "map"].includes(
    //       config
    //     )
    //   ) {
    //     var queryFilterName = "v." + config + "Filter";
    //     queryFilter[config + "Id"] = id;
    //     if (
    //       JSON.stringify(queryFilter) !== JSON.stringify(cmp.get(queryFilterName))
    //     ) {
    //       cmp.set(queryFilterName, queryFilter);
    //     }
    //   } else if (config === "containerItem") {
    //     var i = id.indexOf("-");
    //     itemName = id.slice(0, i);
    //     id = id.slice(i + 1);
    //     var queryFilterName = "v.containerFilter";
    //     var queryFilterObject = ["key", "filter", "map"].includes(itemName)
    //       ? "containerId"
    //       : itemName.startsWith("right")
    //       ? "rightContainerId"
    //       : "leftContainerId";
    //     queryFilter[queryFilterObject] = id;
    //     if (
    //       JSON.stringify(queryFilter) !== JSON.stringify(cmp.get(queryFilterName))
    //     ) {
    //       cmp.set(queryFilterName, queryFilter);
    //     }
    //     if (id !== cmp.get("v.containerId")) {
    //       cmp.set("v.containerId", id);
    //     }
    //   }

    //   // set parentId (only used when creating a new record)
    //   var parentId = state[namespace + "__parent"];
    //   if (parentId !== cmp.get("v.parentId")) {
    //     cmp.set("v.parentId", parentId);
    //   }

    //   // set containerItemId
    //   var containerItemId;
    //   if (
    //     parentId &&
    //     ["key", "link", "chain", "filter", "map"].includes(object)
    //   ) {
    //     var i = parentId.indexOf("-");
    //     containerItemId = parentId.slice(i + 1);
    //   }
    //   if (containerItemId !== cmp.get("v.containerItemId")) {
    //     cmp.set("v.containerItemId", containerItemId);
    //   }

    //   // set the object to show _after_ all its settings have been set on the component, to prevent reloading in aftersript, as well as due to a change in filter
    //   cmp.set("v.selectedObject", object);
    //   cmp.set("v.itemName", itemName);

    //   // update the tree
    //   cmp.set("v.selectedName", name);
    // },

    parsePageRef: function () {
      var selected = (searchParams.get("selected") || "").split("~");
      const name =
        selected[0] || Record.nameFromDetails("store", "sources", "source");

      const selectedFilter = cmp.get("selectedFilter");

      var parsed = Record.parseName(name);
      var config = parsed.config || "";
      var object = config;
      var id = parsed.id;
      var itemName = null;

      if (selected[0]) {
        cmp.set("selectedFilter", selected[0].split("_")[0]);
      } else {
        // SelectedFilter is added to the URL when the page first loads
        const name = selectedFilter + "_sources_source";
        cmp.navigateFromName(null, name);
      }

      // left / right
      if (object.startsWith("right")) {
        object = object.replace(/(^right)/g, "");
      }

      // each object has its own id and queryFilter variable, to prevent its component from reloading when changing the selection
      var selectedIdName = object + "Id";
      if (id !== cmp.get(selectedIdName)) {
        cmp.set(selectedIdName, id);
      }

      // query filter
      var queryFilter = {};
      if (
        [
          "source",
          "container",
          "key",
          "link",
          "chain",
          "filter",
          "map",
        ].includes(config)
      ) {
        var queryFilterName = config + "Filter";
        queryFilter[config + "Id"] = id;
        if (
          JSON.stringify(queryFilter) !==
          JSON.stringify(cmp.get(queryFilterName))
        ) {
          cmp.set(queryFilterName, queryFilter);
        }

        // set itemName
        const selectedFilter = cmp.get("selectedFilter");
        if (selectedFilter === "storeData") {
          itemName = "key";
        }

        if (selectedFilter === "storeFilters") {
          itemName = "filter";
        }

        if (selectedFilter === "storeMaps") {
          itemName = "map";
        }

        if (selectedFilter === "storeJoins") {
          itemName = "link";
        }

        if (selectedFilter === "storeChains") {
          itemName = parsed.section;
        }
      } else if (config === "containerItem") {
        var i = id.indexOf("-");
        itemName = id.slice(0, i);
        id = id.slice(i + 1);
        var queryFilterName = "containerFilter";
        var queryFilterObject = ["key", "filter", "map"].includes(itemName)
          ? "containerId"
          : itemName.startsWith("right")
          ? "rightContainerId"
          : "leftContainerId";
        queryFilter[queryFilterObject] = id;
        if (
          JSON.stringify(queryFilter) !==
          JSON.stringify(cmp.get(queryFilterName))
        ) {
          cmp.set(queryFilterName, queryFilter);
        }
        if (id !== cmp.get("containerId")) {
          cmp.set("containerId", id);
        }
      }

      // set parentId (only used when creating a new record)
      var parentId = searchParams.get("selected");

      if (parentId !== cmp.get("parentId")) {
        cmp.set("parentId", parentId);
      }

      // set containerItemId
      var containerItemId;
      if (
        parentId &&
        ["key", "link", "chain", "filter", "map"].includes(object)
      ) {
        const i = parentId.indexOf("-");
        containerItemId = parentId.slice(i + 1);
      }
      if (containerItemId !== cmp.get("containerItemId")) {
        cmp.set("containerItemId", containerItemId);
      }

      // set the object to show _after_ all its settings have been set on the component, to prevent reloading in aftersript, as well as due to a change in filter

      cmp.set("selectedObject", object || "sources");
      cmp.set("itemName", itemName);

      // update the tree
      cmp.set("selectedName", name);
    },

    // navigateFromEvent: function (cmp, event) {
    //   // if id is empty (i.e., when creating a new record), set the parent
    //   var id = event.getParam("id");
    //   var parentId = id ? undefined : event.getParam("parentId");

    //   // navigate
    //   var defaultSection = {
    //     root: "store",
    //     sources: "store",
    //     source: "store",
    //     container: "store",
    //     containerItem: "store",
    //     link: "store",
    //     rightlink: "store",
    //     key: "store",
    //     filter: "store",
    //     chain: "store",
    //     rightChain: "store",
    //   };
    //   var item = Record.itemFromEvent(event, defaultSection);

    //   // add in 'right' for links and chains where needed
    //   if (
    //     ["link", "chain"].includes(item.config) &&
    //     (cmp.get("v.itemName") || "").startsWith("right")
    //   ) {
    //     item.config = "right" + item.config;
    //     item.name = Record.nameFromDetails(item.section, item.config, item.id);
    //   }

    //   // navigate
    //   this.navigateFromName(cmp, parentId, item.name);
    // },

    navigateFromEvent: function (event) {
      // if id is empty (i.e., when creating a new record), set the parent
      var id = event.id;
      var parentId = id ? undefined : event.parentId;

      // navigate
      var defaultSection = {
        root: "store",
        sources: "store",
        source: "store",
        container: "store",
        containerItem: "store",
        link: "store",
        rightlink: "store",
        key: "store",
        filter: "store",
        chain: "store",
        rightChain: "store",
      };

      const selectedFilter = cmp.get("selectedFilter");
      const selectedName = cmp.get("selectedName");

      let selectedFilterFromId;
      if (id) {
        selectedFilterFromId = id.split("-")[0];
      }

      if (selectedFilterFromId === "link" || event.id?.includes("rightlink")) {
        if (selectedFilter === "link") {
          event.section = "data";
        } else {
          event.section = "joins";
          const newSelectedName = selectedName.replace("data", "joins");
          cmp.set("selectedName", newSelectedName);
          cmp.navigateFromName(parentId, newSelectedName);
        }

        return;
      } else if (selectedFilterFromId === "map") {
        event.section = "maps";

        const newSelectedName = selectedName.replace("data", "maps");
        cmp.set("selectedName", newSelectedName);
        cmp.navigateFromName(parentId, newSelectedName);

        return;
      } else if (selectedFilterFromId === "filter") {
        event.section = "filters";
        cmp.set("selectedFilter", "filters");
        event.section = "filters";

        const newSelectedName = selectedName.replace("data", "filters");
        cmp.set("selectedName", newSelectedName);
        cmp.navigateFromName(parentId, newSelectedName);

        return;
      } else if (selectedFilterFromId === "chain") {
        event.section = "joins";
      } else {
        event.section = selectedFilter;
      }

      // To go from the field in Joins or Paths to Data
      if (
        event.id &&
        event.source === "grid" &&
        ((event.section === "joins" && event.parentId.includes("link")) ||
          (event.section === "chains" && event.parentId.includes("chain")))
      ) {
        event.section = "data";
        cmp.navigateFromName(parentId, cmp.generateNameFromEvent(event));
        return;
      }

      var item = Record.itemFromEvent(event, defaultSection);

      // add in 'right' for links and chains where needed
      if (
        ["link", "chain"].includes(item.config) &&
        (cmp.get("itemName") || "").startsWith("right")
      ) {
        item.config = "right" + item.config;
        item.name = Record.nameFromDetails(item.section, item.config, item.id);
      }

      if (
        event.section === "data" &&
        event.source === "record" &&
        event.parentId === "source"
      ) {
        item.name = "data_source";
        parentId = null;
      }

      if (!item.name.includes(selectedFilter)) {
        const firstItemOfName = item.name.split("_")[0];
        if (firstItemOfName !== selectedFilter) {
          item.name =
            selectedFilter + item.name.substring(item.name.indexOf("_"));
        }
      }

      if (
        event.section === "storeJoins" &&
        event.module === "store" &&
        event.obj === "key" &&
        event.parentId.includes("link")
      ) {
        const name = "storeData_key_" + event.id;
        cmp.navigateFromName(null, name);
        return;
      }

      if (
        event.section === "storeChains" &&
        event.module === "store" &&
        event.obj === "key" &&
        event.parentId.includes("chain")
      ) {
        const name = "storeData_key_" + event.id;
        cmp.navigateFromName(null, name);
        return;
      }

      // navigate
      cmp.navigateFromName(parentId, item.name);
    },

    // navigateFromName: function (cmp, parentId, name) {
    //   var selectedObject = cmp.get("v.selectedObject");
    //   var selectedId = selectedObject
    //     ? cmp.get("v." + selectedObject + "Id")
    //     : null;
    //   var selectedParentId = cmp.get("v.parentId");
    //   var parsed = Record.parseName(name);

    //   if (
    //     parsed.id !== selectedId ||
    //     parsed.config !== selectedObject ||
    //     parentId !== selectedParentId
    //   ) {
    //     var namespace = cmp.getType().split(":")[0];
    //     var pageref = cmp.get("v.pageReference");
    //     var state = {
    //       [namespace + "__selected"]: name,
    //       [namespace + "__parent"]: parentId,
    //     };
    //     cmp.find("navService").navigate({
    //       type: pageref.type,
    //       attributes: pageref.attributes,
    //       state,
    //     });
    //   }
    // },

    navigateFromName: function (parentId, name) {
      if (!parentId && !name) {
        navigate({
          pathname: "/StoreManagement",
        });
        return;
      }

      var selectedObject = cmp.get("selectedObject");
      var selectedId = selectedObject ? cmp.get(selectedObject + "Id") : null;
      var selectedParentId = cmp.get("parentId");
      var parsed = Record.parseName(name);

      if (
        parsed.id !== selectedId ||
        parsed.config !== selectedObject ||
        parentId !== selectedParentId
      ) {
        let search = "?selected=" + name;
        if (parentId) {
          search = search + "__parent=" + parentId;
        }

        navigate({
          pathname: "/StoreManagement",
          search,
        });
      }
    },

    // --- New functions ---
    get: (key) => {
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    generateNameFromEvent: function (event) {
      const { obj, section, id } = event;
      const name = `${section}_${obj}_${id}`;
      return name;
    },

    processSelectedFilterChange: function (filterValue) {
      var selected = (searchParams.get("selected") || "").split("~");
      const selectedFilter = cmp.get("selectedFilter");
      let name = selectedFilter + "_sources_source";

      if (selected[0]) {
        let selectedItems = selected[0].split("_");

        if (selectedItems[1] === "source" || selectedItems[1] === "container") {
          name =
            selectedFilter + selected[0].substring(selected[0].indexOf("_"));
        } else if (selected[0].includes("_sources_source")) {
          name = selectedFilter + "_sources_source";
        } else {
          const containerId = cmp.get("containerId");

          name = containerId
            ? selectedFilter + "_container_" + containerId
            : selectedFilter;

          cmp.navigateFromName("", name);
          return;
        }

        if (
          selectedItems.length === 1 &&
          [
            "storeData",
            "storeFilters",
            "storeMaps",
            "storeJoins",
            "storeChains",
          ].includes(selectedItems[0])
        ) {
          name = filterValue;
        }
      }

      cmp.navigateFromName("", name);
    },
  };

  const handleDataCompEvent = (event) => {
    try {
      cmp.set("navigationLoading", true);

      // update NavigatinTree:
      let recordChangedEvent = {
        ...event,
        parentId: cmp.get("containerId"),
        object: cmp.get("selectedObject"),

        sourceId: cmp.get("sourceId"),
        obj: event.record,
      };

      // When Source is deleted, Source List will appear
      if (event.action === "delete" && event.obj === "source") {
        cmp.set("sourceId", "");
      }

      // When Map is deleted
      if (
        event.action === "delete" &&
        event.obj === "map" &&
        event.module === "store"
      ) {
        cmp.set("containerId", "");
      }

      cmp.set("recordChangedEvent", recordChangedEvent);
    } catch (err) {
      console.error(err.stack);
    } finally {
      cmp.set("navigationLoading", false);
    }
  };

  const handleReload = () => {
    const parentToChildEvent = cmp.get("parentToChildEvent");
    cmp.set("parentToChildEvent", {
      ...parentToChildEvent,
      action: "reload",
    });
    cmp.set("navigationLoading", true);
  };

  const childToParent = (event) => {
    try {
      if (event.type === "navigation") {
        cmp.handleNavigationEvent(event);
      } else if (event.type === "dataCompEvent") {
        handleDataCompEvent(event);
      } else if (event.type === "reload") {
        handleReload(event);
      } else if (event.type === "logout") {
        handleLogout();
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleSelectFilter = (filterValue) => {
    cmp.set("selectedFilter", filterValue);
    cmp.set("itemName", filterValue);

    cmp.processSelectedFilterChange(filterValue);
  };

  const handleSearchTextChange = (value) => {
    cmp.set("searchText", value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    cmp.set("isSearchSubmitted", true);
  };

  const PsChainListComponent = (
    <PsChainList
      queryFilter={cmpState.containerFilter}
      parentPrefix="chain-"
      parentId={cmpState.containerId}
      maxRecords="100"
      setToastState={setToastState}
      childToParent={childToParent}
      parentCmp={cmp}
      parentToChildEvent={cmpState.parentToChildEvent}
    />
  );

  const PsLinkListComponent = (
    <PsLinkList
      queryFilter={cmpState.containerFilter}
      parentPrefix="link-"
      parentId={cmpState.containerId}
      maxRecords="100"
      setToastState={setToastState}
      childToParent={childToParent}
      parentCmp={cmp}
      parentToChildEvent={cmpState.parentToChildEvent}
    />
  );

  const PsContainerItemComponent = (
    <PsContainerItem
      recordId={cmpState.containerId}
      parentId={cmpState.parentId}
      itemName={cmpState.itemName}
      childToParent={childToParent}
      setToastState={setToastState}
      parentCmp={cmp}
      parentToChildEvent={cmpState.parentToChildEvent}
    />
  );

  const PsMapListComponent = (
    <PsMapList
      queryFilter={cmpState.containerFilter}
      parentPrefix="map-"
      parentId={cmpState.containerId}
      maxRecords="100"
      childToParent={childToParent}
      setToastState={setToastState}
      parentCmp={cmp}
      parentToChildEvent={cmpState.parentToChildEvent}
    />
  );

  const PsFilterListComponent = (
    <PsFilterList
      queryFilter={cmpState.containerFilter}
      parentPrefix="filter-"
      parentId={cmpState.containerId}
      maxRecords="100"
      childToParent={childToParent}
      setToastState={setToastState}
      parentCmp={cmp}
      parentToChildEvent={cmpState.parentToChildEvent}
    />
  );

  return (
    <div className="Store">
      <PsNavigationHeader view="list" childToParent={childToParent} />

      <div className="tab-content slds-p-around_medium">
        <div className="left slds-m-right_medium">
          {/* <!--NB: only using right-margin, because flex has no collapsing margins--> */}
          {/* <!-- navigation tree --> */}
          <article className="slds-card">
            <div
              className="slds-card__header"
              style={{
                margin: "0px 0 -2px -5px",
              }}
            >
              <h2 className="card-main-title-lh32 slds-card__header-title slds-text-heading_small slds-truncate">
                Browse
              </h2>
            </div>

            <div ref={searchdivRef} className="slds-p-around_x-small">
              <SearchBox
                searchText={cmpState.searchText}
                loading={cmpState.navigationLoading}
                handleSearchTextChange={handleSearchTextChange}
                handleSearchSubmit={handleSearchSubmit}
              />
            </div>

            <div className="slds-p-around_x-small">
              <div className="slds-form-element">
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      name="Filter"
                      value={cmpState.selectedFilter}
                      id="select-01"
                      onChange={(e) => handleSelectFilter(e.target.value)}
                    >
                      {cmpState.filterOptions.map((item, idx) => (
                        <option key={idx} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="slds-p-horizontal_x-small"
              ref={navigationTreeRef}
              style={{
                overflowY: "auto",
                height: "calc(100vh - 15.65rem)",
              }}
            >
              {/* <c:navigationTree aura:id="navigationTree" multiSelect="false" sections="['store']" selected="{!v.selectedName}" searchText="{!v.searchText}" 
isLoading="{!v.searchLoading}"/> */}

              <PsNavigationTree
                multiSelect={false}
                selected={cmpState.selectedName}
                searchText={cmpState.searchText}
                isLoading={cmpState.navigationLoading}
                sections={[
                  "storeData",
                  "storeFilters",
                  "storeMaps",
                  "storeJoins",
                  "storeChains",
                ]}
                selectedSection={cmpState.selectedFilter}
                parentCmp={cmp}
                childToParent={childToParent}
                parentToChildEvent={cmpState.parentToChildEvent}
                recordChangedEvent={cmpState.recordChangedEvent}
              />
            </div>
          </article>
        </div>
        {/* <!-- right panel view --> */}
        <div
          className={
            accountSetupProgress?.done
              ? "slds-scrollable right"
              : "right right-noscroll"
          }
        >
          {/* Right   */}
          <PsSetupStatus
            id="setupStatus"
            showSpinner={false}
            title="Store Management"
            tagLine="Managed processed data."
            parentToChildEvent={cmpState.parentToChildEvent}
            parentCmp={cmp}
          />

          {cmpState.selectedObject === "sources" ? (
            <>
              {/* <c:SourceList parentId="{!v.sourcesId}" maxRecords="100"/> */}
              <PsSourceList
                parentId={cmpState.sourcesId}
                maxRecords="100"
                queryFilter={cmpState.sourcesFilter}
                childToParent={childToParent}
                setToastState={setToastState}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
              />
            </>
          ) : null}
          {cmpState.selectedObject === "source" ? (
            <div>
              {/* <!-- break height:100% chain --> */}
              {/* <c:Source recordId="{!v.sourceId}" parentId="{!v.parentId}" /> */}
              <PsSource
                recordId={cmpState.sourceId}
                parentId={cmpState.parentId}
                childToParent={childToParent}
                setToastState={setToastState}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
              />
              {cmpState.sourceId ? (
                <>
                  {/* <c:ContainerList queryFilter="{!v.sourceFilter}" parentId="{!v.sourceId}" maxRecords="100"/> */}
                  <PsContainerList
                    queryFilter={cmpState.sourceFilter}
                    parentId={cmpState.sourceId}
                    maxRecords="100"
                    setToastState={setToastState}
                    childToParent={childToParent}
                    parentCmp={cmp}
                    parentToChildEvent={cmpState.parentToChildEvent}
                  />
                </>
              ) : null}
            </div>
          ) : null}
          {cmpState.selectedObject === "container" ? (
            <div>
              <PsContainer
                recordId={cmpState.containerId}
                parentId={cmpState.parentId}
                childToParent={childToParent}
                setToastState={setToastState}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
                itemName={cmpState.itemName}
              />

              {(cmpState.selectedFilter === "storeData" ||
                cmpState.itemName === "key") && (
                <>
                  <PsKeyList
                    queryFilter={cmpState.containerFilter}
                    parentPrefix="key-"
                    parentId={cmpState.containerId}
                    maxRecords="100"
                    childToParent={childToParent}
                    setToastState={setToastState}
                    parentCmp={cmp}
                    parentToChildEvent={cmpState.parentToChildEvent}
                  />
                </>
              )}
              {(cmpState.selectedFilter === "storeFilters" ||
                cmpState.itemName === "filter" ||
                cmpState.itemName === "filters") &&
                PsFilterListComponent}

              {(cmpState.selectedFilter === "storeMaps" ||
                cmpState.itemName === "map") &&
                PsMapListComponent}

              {cmpState.selectedFilter === "storeChains" && (
                <>
                  {(cmpState.itemName === "chains" ||
                    cmpState.itemName === "chain" ||
                    cmpState.itemName === "storeChains" ||
                    cmpState.itemName === "rightchain") &&
                    PsChainListComponent}
                </>
              )}
              {(cmpState.selectedFilter === "storeJoins" ||
                cmpState.itemName === "link") &&
                PsLinkListComponent}
            </div>
          ) : null}

          {cmpState.selectedObject === "key" ? (
            <div>
              <PsKey
                recordId={cmpState.keyId}
                parentPrefix="key-"
                parentId={cmpState.containerItemId}
                childToParent={childToParent}
                setToastState={setToastState}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
              />
            </div>
          ) : null}
          {cmpState.selectedObject === "filter" ? (
            <div>
              <PsFilter
                recordId={cmpState.filterId}
                parentPrefix="filter-"
                parentId={cmpState.containerItemId}
                newScopes={["Global", "SubGroup"]}
                showRequiredFieldError={false}
                childToParent={childToParent}
                setToastState={setToastState}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
              />
            </div>
          ) : null}
          {cmpState.selectedObject === "map" ? (
            <div>
              <PsMap
                recordId={cmpState.mapId}
                parentPrefix="map-"
                parentId={cmpState.containerItemId}
                newScopes={["Global"]}
                childToParent={childToParent}
                setToastState={setToastState}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
              />
            </div>
          ) : null}
          {cmpState.selectedObject === "link" ? (
            <div>
              <PsLink
                recordId={cmpState.linkId}
                parentPrefix="link-"
                parentId={cmpState.containerItemId}
                childToParent={childToParent}
                setToastState={setToastState}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
              />
            </div>
          ) : null}
          {cmpState.selectedObject === "chain" ? (
            <div>
              <PsChain
                recordId={cmpState.chainId}
                parentPrefix="chain-"
                parentId={cmpState.containerItemId}
                childToParent={childToParent}
                setToastState={setToastState}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
              />
            </div>
          ) : null}
        </div>
      </div>
      {toastState.details ? (
        <ToastComponent
          close={() => setToastState(emptyToastState)}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </div>
  );
};

export default Store;
