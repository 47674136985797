import { useState } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import { GENERAL_ERROR, SIGNUP } from "../../../constants/ToastMessages";
import "../../Pages.css";
import { EMPTY_TOAST_STATE } from "../../../constants";
import useAuthContext from "../../../context/useAuthContext";

const ResetForm = ({
  confirmationCode,
  setConfirmationCode,
  setToastState,
  setMode,
}) => {
  const { user, setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const { username, password, confirmPassword } = user;

  // const navigate = useNavigate();

  const handleUserInputChange = (e, item) => {
    setUser((prevUser) => ({ ...prevUser, [item]: e.target.value })); //why do we have user here and above???
    setToastState(EMPTY_TOAST_STATE);
  };

  //needs to be separate because this is not on the user object
  const handleConfirmationCodeChange = (e) => {
    setConfirmationCode(e.target.value);
    setToastState(EMPTY_TOAST_STATE);
  };

  const handleSubmitResetForm = async (event) => {
    try {
      event.preventDefault();
      setToastState(EMPTY_TOAST_STATE);
      setIsLoading(true);

      if (password !== confirmPassword) {
        setToastState({
          heading: "Warning",
          variant: "warning",
          details: SIGNUP.PASSWORD_MISMATCH,
        });
        return;
      }

      var result = await Auth.forgotPasswordSubmit(
        username,
        confirmationCode,
        password
      ); //this just returns success
      if (result === "SUCCESS") {
        setToastState({
          heading: "Success",
          variant: "success",
          details:
            "Succesfully reset password. Please log in with your new password.",
        });
        setMode("login");
      } else {
        setToastState({
          heading: "Error",
          variant: "error",
          details: "Failed to change password.",
        });
      }
    } catch (err) {
      console.error(err.stack);
      setToastState({
        heading: "Error",
        variant: "error",
        details: err.message || GENERAL_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmitResetForm}>
      <div className="user-inputs">
        <Input
          type="text"
          label="Username"
          value={username}
          onChange={(e) => handleUserInputChange(e, "username")}
        />
      </div>
      <div className="user-inputs">
        <Input
          type="tel"
          label="Confirmation Code"
          value={confirmationCode}
          onChange={(e) => handleConfirmationCodeChange(e)}
        />
      </div>
      <div className="user-inputs">
        <Input
          type="password"
          label="Password"
          value={password}
          onChange={(e) => handleUserInputChange(e, "password")}
        />
      </div>
      <div className="user-inputs">
        <Input
          type="password"
          label="Confirm password"
          value={confirmPassword}
          onChange={(e) => handleUserInputChange(e, "confirmPassword")}
        />
      </div>
      <div className="login-signup-button">
        <Button
          type="submit"
          label={
            <>Change Password {isLoading ? <Spinner size="small" /> : null}</>
          }
          variant="brand"
          disabled={
            isLoading ||
            !username ||
            !password ||
            !confirmPassword ||
            !confirmationCode
          }
          style={{ width: "100%" }}
        />
      </div>

      {/* <nav className="login-signup-link">
        Don’t have an account? <Link to="/signup">Sign up</Link>
      </nav> */}
    </form>
  );
};

export default ResetForm;
