import { useState, useEffect, useRef } from "react";
import { Button } from "@salesforce/design-system-react";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import useWindowSize from "../../hooks/useWindowSize";

const PsUserList = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "User",
    recordLabelPlural: "Users",
    showTitle: true,
    tagLine: "Manage the users that can access Point Sigma.",
    emptyLine: "Create new user using a New button",
    recordModule: "core",
    recordObject: "user",
    changeView: false,
    showEdit: false,
    showLoadMore: true,
    view: "table",
    isDragMode: false,
    draggedStart: null,
    draggedIndex: null,
    parentPrefix: "",
    orderBy: "name",
    orderDirection: "asc",
    lastValue: null,
    lastId: null,
    mode: "empty",
    loading: true,
    recordList: [],
    recordDefaultSortDirection: "asc",
    showEmptyCallToAction: true,
    showCardActions: true,
    hasMore: true,
    loadingMore: false,

    psRecordGridWidth: 1535,
  });
  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);
  const psRecordGridRef = useRef(null);

  const { width } = useWindowSize();

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
  }, []);

  useEffect(() => {
    PsRecordGrid.handlePsRecordGridWidth(cmp, psRecordGridRef);
  }, [width]);

  useEffect(() => {
    if (!props.parentToChildEvent.action) return;
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent.action]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    if (props.queryFilter && Object.keys(props.queryFilter).length > 0) {
      cmp.handleReset();
    }
  }, [`${props?.queryFilter}`]); // make  the object string so does not call with the same values many type

  const cmp = {
    // --- Get/Set ---
    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    init: function () {
      cmp.afterScriptsLoaded();
    },

    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      const maxRecords = cmp.get("maxRecords");
      const numRecords = !maxRecords
        ? 0
        : Math.max(cmp.get("recordList").length, maxRecords);
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    afterScriptsLoaded: function () {
      const numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleReset: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleNew: function () {
      const recordModule = cmp.get("recordModule");
      const recordObject = cmp.get("recordObject");
      const type = "navigation";

      PsRecordGrid.notifyNavigation(
        cmp,
        "",
        recordModule,
        recordObject,
        null,
        type
      );
    },

    RECORD_COLUMNS: [
      {
        key: "name", //fieldName
        label: "Name", //label
        property: "name", //fieldName
        type: "link", //if type = button, this value is link
        action: "details", //name within typeAttributes
        sortable: true,
      },
      {
        key: "type", //fieldName
        label: "Type", //label
        property: "type", //fieldName
        type: "text", //if type = button, this value is link
        sortable: true,
      },
      {
        key: "status", //fieldName
        label: "Status", //label
        property: "status", //fieldName
        type: "text", //if type = button, this value is link
        sortable: true,
      },
    ],

    parseResponse: function (response) {
      const results = response.filter((item) => item.type !== "System"); // ----> Note: Filter System type should be in the server
      return results;
    },

    // --- Functions that used to be only on RecordGrid ---
    notifyDataComp: function (row) {
      var setting = row.setting || {};
      const event = {
        type: "navigation",
        id: row.id,
        label: row.name,
        section: setting.section || "core",
        obj: setting.config || "user",
        breadcrumb: row.breadcrumb || [],
        record: row,
        source: row.source || "tree",
        scroll: row || null,
      };

      cmp.handleEvent(event);
    },

    emptyCallToAction: function () {
      const content = `Create a new ${cmpState.recordLabel}`;
      return (
        <Button
          label={content}
          title={content}
          onClick={() => cmp.handleNew()}
          disabled={cmpState.loading}
          variant="brand"
        />
      );
    },

    cardActions: function () {
      const mode = cmp.get("mode");
      return (
        <>
          {mode === "init" || mode === "view" ? (
            <Button
              label="New"
              title={`Create a new ${cmpState.recordLabel}`}
              onClick={() => cmp.handleNew()}
              disabled={cmpState.loading}
            />
          ) : null}
        </>
      );
    },

    handleEvent: function (event) {
      let stopPropagation = false;
      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    handleRecordRowAction: function (itemRow) {
      switch (itemRow.action.name) {
        case "details":
          cmp.notifyDataComp(itemRow);
          break;
        default:
          break;
      }
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({
        variant,
        heading,
        details,
      });
    },
  };

  return (
    <div
      ref={psRecordGridRef}
      style={{
        height:
          cmpState.showEmptyCallToAction && cmpState.mode === "empty"
            ? "auto"
            : "100%",
      }}
    >
      {PsRecordGrid.render(cmp, cmpState)}
    </div>
  );
};

export default PsUserList;
