import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Combobox from "@salesforce/design-system-react/components/combobox";
import Input from "@salesforce/design-system-react/components/input";
import ProgressBar from "@salesforce/design-system-react/components/progress-bar";
import Tooltip from "@salesforce/design-system-react/components/tooltip";

import Record from "../../helpers/recordLayer";
import { getUseInput, getBreadCrumb, getTitle } from "./components/Helper";
import DualListBox from "../ui/DualListBox";
import RelativeDateTime from "../ui/RelativeDateTime";
import BetweenDateTime from "../ui/BetweenDateTime";
import { getLocalDateTime, toastErrorMessage } from "../../helpers";
import BetweenNumber from "../ui/BetweenNumber";
import PsNavigationInput from "../ps-navigation-input/PsNavigationInput";
import PsRecord from "../ps-record/PsRecord";
import ProgressBarComponent from "../ui/ProgressBarComponent";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import EditButtonIcon from "../ui/EditButtonIcon";
import Modal from "../ui/Modal";
import RecordConstants from "../../constants/RecordConstants";
import Toggle from "../ui/Toggle";

export const PsFilter = forwardRef((props, ref) => {
  const [cmpState, setCmpState] = useState({
    // Record.cmp attributes
    recordLabel: "Filter",
    recordModule: "store",
    recordObject: "filter",

    showEdit: true,
    showDelete: true,

    parentPrefix: "", //prefix to use when navigating using parentId

    loading: true,
    mode: "init", // init, new, view, edit, error
    recordId: "", //controlled by parent, parent is responsible for updating, DO NOT UPDATE from this componentd
    record: {
      active: true,
      name: "",
      acceptMissing: false,
    },

    //Filter.cmp attriutes

    // Parameters
    recordValue: null,
    newScopes: [],

    // Helper and formatting fields
    isInit: false,
    isSaved: false,

    keyItem: null,
    selectedKey: null, // {},

    containerItem: null,
    selectedContainer: {},
    containerFilter: null,

    needsChain: false,
    chainItem: null,
    selectedChain: null,
    chainFilter: null,

    operator: "",
    operatorLabel: "",
    scope: props.newScopes[0], // "",

    useInput: "",
    selectedPreset: "",
    selectedPresetLabel: "",
    freeTextValues: "",
    selectedValues: [], // ["Credit Card","Debit Card","Voucher"]
    selectedOptions: [], // [{label: 'Credit Card', value: 'Credit Card'}, {label: 'Debit Card', value: 'Debit Card'}, {label: 'Voucher', value: 'Voucher'}]
    minDate: null,
    maxDate: null,
    minDateLocal: null,
    maxDateLocal: null,
    minDateAmount: "",
    minDateUnit: "",
    minDateUnitLabel: "",
    maxDateAmount: "",
    maxDateUnit: "",
    maxDateUnitLabel: "",
    minNumber: "",
    maxNumber: "",
    minDateDefault: null,
    maxDateDefault: null,
    minDateDefaultLocal: null,
    maxDateDefaultLocal: null,
    minNumberDefault: null,
    maxNumberDefault: null,

    // options
    operatorOptionsLoaded: false,
    operatorOptions: [],
    scopeOptions: [
      {
        value: props.newScopes[0],
        label: props.newScopes[0],
        selected: true,
      },
    ],
    dateTimeUnits: RecordConstants.DATETIME_UNITS || [],
    presetOptions: [],

    valuesOptionsLoaded: false,
    valuesOptions: [],

    //new
    isExpanded: false, //TODO This is an attribute of NavigationInput. It shouldn't exist on Filter
    activeField: "", //TODO Review why we need this. Can't be shared by several NavigationInput components

    selectedValue: null,
    errorTexts: [],
    showDeleteConfirmDialog: false,
    deleteConfirmation: false,
  });

  const cmpWorking = useRef({});
  const searchFieldRef = useRef(null);
  const keyFieldRef = useRef(null);
  const pathFieldRef = useRef(null);
  const isFirstRender = useRef(true);

  // Use useCallback to memoize onRenderMenuItem function, ensuring a stable function reference across re-renders and preventing loss of event listener functionality.
  const onRenderMenuItem = useCallback(
    ({ option }) => (
      <>
        <span style={{ display: "block" }}>{option.label}</span>
        <span style={{ display: "block" }}>{option.description}</span>
      </>
    ),
    []
  );
  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    const listDivElement = document.getElementById("listDiv");
    if (listDivElement) {
      const handleClick = (e) => {
        if (
          (searchFieldRef.current &&
            searchFieldRef.current.contains(e.target)) ||
          (keyFieldRef.current && keyFieldRef.current.contains(e.target)) ||
          (pathFieldRef.current && pathFieldRef.current.contains(e.target))
        ) {
          return;
        }

        cmp.set("isExpanded", false);
        cmp.set("activeField", "");
      };

      listDivElement.addEventListener("click", handleClick);
      return () => {
        listDivElement.removeEventListener("click", handleClick);
      };
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      return;
    }
    cmp.handleReload();
  }, [props.recordId]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    //cmp.handleParentIdChange();
  }, [props.parentId]);

  const cmp = {
    // --- FilterController.js ---

    init: function () {},

    afterScriptsLoaded: function () {
      try {
        cmp.initHelper();
        cmp.setLoad();
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleFilterChange: function () {
      try {
        cmp.setLoad(cmp);
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleParentIdChange: function () {
      try {
        cmp.loadContainer();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleReload: function () {
      try {
        cmp.setLoad();
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleEdit: function () {
      try {
        PsRecord.setMode(cmp, "edit");
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleCancel: function () {
      try {
        cmp.setLoad();
        PsRecord.cancelRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleSubmit: function () {
      try {
        cmp.setInit();
        PsRecord.submitRecord(cmp);
        cmp.set("selectedValue", null);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleDelete: function () {
      try {
        //Record.showToast(cmp, 'Not Available', 'Cannot delete this Filter.\nInstead, toggle "Active" off to stop the Filter from being used.', 'warning', true);
        PsRecord.deleteRecord(cmp); // NB: this navigates to parent record after successfull delete
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleOperatorChange: function (event, data) {
      try {
        if (data.selection.length === 0) {
          return;
        }

        cmp.set("operator", data.selection[0].value);
        cmp.set("operatorLabel", data.selection[0].label);

        cmp.unsetInit();
        cmp.setOperator();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handlePresetChange: function () {
      try {
        Record.selectLoadedOption(
          cmp,
          "operatorOptions",
          "operator",
          "operatorLabel",
          false
        );
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleMinDateUnitChange: function (cmp, event, helper) {
      try {
        Record.selectLoadedOption(
          cmp,
          "v.dateTimeUnits",
          "v.minDateUnit",
          "v.minDateUnitLabel",
          false
        );
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleMaxDateUnitChange: function (cmp, event, helper) {
      try {
        Record.selectLoadedOption(
          cmp,
          "v.dateTimeUnits",
          "v.maxDateUnit",
          "v.maxDateUnitLabel",
          false
        );
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleKeySelect: function (record) {
      try {
        cmp.set("selectedKey", record);
        //
        cmp.unsetInit();
        cmp.loadKeyDetails();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleContainerSelect: function (record) {
      try {
        cmp.setContainer();
        cmp.loadChains();
        //
        cmp.set("selectedContainer", record);
      } catch (err) {
        console.error(err.stack);
      }
    },

    filterJson: function () {
      try {
        var filter = cmp.filterJsonHelper();
        return filter;
      } catch (err) {
        console.error(err.stack);
      }
    },

    //Review: This function is not used
    filterValid: function (cmp, event, helper) {
      try {
        return !PsRecord.checkForm(cmp);
        // return Record.checkForm(cmp, ["checkField"], true);
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- FilterHelper.js ---

    initHelper: function () {
      cmp.set("dateTimeUnits", RecordConstants.DATETIME_UNITS);
    },

    setInit: function () {
      cmp.set("isInit", true);
    },

    unsetInit: function () {
      cmp.set("isInit", false);
    },

    setLoad: function () {
      this.setInit();
      if (!cmp.get("recordValue") && !cmp.get("recordId")) {
        this.loadContainer();
      }
    },

    getDefaultRecord: function () {
      // return { active: true };
      var parentId = props.parentId;
      if (["connectors", "connectorList"].includes(parentId)) {
        parentId = null;
      }
      cmp.set("externalSource", parentId);
      return {
        sourceId: parentId,
        status: RecordConstants.CONNECTOR_STATUS.ACTIVE.value,
        defaultObjectStatus: RecordConstants.OBJECT_STATUS.INCLUDED.value,
        defaultFieldStatus: RecordConstants.FIELD_STATUS.INCLUDED.value,
        schedule: "",
        // new added
        name: "",
        scope: props.newScopes[0],
        active: true,
        acceptMissing: false,
      };
    },

    parseInputPlainText: function () {
      var filter = this.filterJson(cmp);
      filter = JSON.parse(JSON.stringify(filter)); // deepcopy to prevent changing original record
      // IMPROVEMENT: allow changing filter input Nodes structure; this is not currently possible because it may swtich from a join-with-load to a load-only, and the backend doesn't allow updating the actual Node structre or deleting input Nodes.
      filter = filter.id
        ? (({ id, active, name, acceptMissing, operator, settings }) => ({
            id,
            active,
            name,
            acceptMissing,
            operator,
            settings,
          }))(filter)
        : (({
            active,
            type,
            name,
            scope,
            acceptMissing,
            containerId,
            operator,
            settings,
            inputs,
          }) => ({
            active,
            type,
            name,
            scope,
            acceptMissing,
            containerId,
            operator,
            settings,
            inputs,
          }))(filter);

      return filter;
    },

    updateUI: function () {
      try {
        var filter = cmp.get("record");
        // get Key and Chain from nodes
        var key, chain, multiple;
        var container = filter.container;
        var nodes = Record.flatten(filter, "inputs");
        for (let node of nodes) {
          if (key && node.key) {
            multiple = true;
          }
          if (chain && node.chain) {
            multiple = true;
          }
          key = node.key;
          chain = node.chain;
        }
        // for now, unset key and chain if there are mulitple
        // IMPROVEMENT: edit tree structure in UI
        if (multiple) {
          key = null;
          chain = null;
        }
        // set selected key and chain
        if (container) {
          container.title = getTitle(getBreadCrumb("container", container));
          cmp.set("selectedContainer", container);
          cmp.set(
            "containerItem",
            Record.nameFromDetails("containers", "container", container.id)
          );
          cmp.set("containerFilter", {
            containers: {
              container: { sourceId: container.sourceId, name: container.name },
            },
          });
          this.setChainFilter("leftContainerId", container.id);
        }
        if (key) {
          key.title = getTitle(getBreadCrumb("key", key));
          cmp.set("selectedKey", key);
          cmp.set("keyItem", Record.nameFromDetails("keys", "key", key.id));
          this.setChainFilter(
            "rightContainerId",
            key.containerId || (key.container || {}).id
          );
        }
        if (chain) {
          chain.title = getTitle(getBreadCrumb("chain", chain));
          // the chain isn't always loaded with left and right container: IMPROVEMENT: udpate API to include left and right container
          chain.leftContainerId =
            chain.leftContainerId ||
            (chain.leftContainer || {}).id ||
            filter.containerId ||
            (container || {}).id;
          chain.rightContainerId =
            chain.rightContainerId ||
            (chain.rightContainer || {}).id ||
            key.containerId ||
            ((key || {}).container || {}).id;
          this.setChainFilter(
            "leftContainerId",
            chain.leftContainerId || (chain.leftContainer || {}).id
          );
          this.setChainFilter(
            "rightContainerId",
            chain.rightContainerId || (chain.rightContainer || {}).id
          );
          // make sure to set selectedChain as the last step, because setChainFilter may unset it
          cmp.set("selectedChain", chain);
          cmp.set(
            "chainItem",
            Record.nameFromDetails("chains", "chain", chain.id)
          );
        }
        // parse fields
        var type = filter.type;
        var settings = filter.settings || {};
        var alias = settings.alias;
        var operator = filter.operator;
        // settings.alias overrides the operator
        if (alias) {
          operator = alias;
        }
        try {
          cmp.set("operator", operator);
        } catch (err) {}
        // put settings into input fields
        var options = RecordConstants.FILTER_TYPES[type] || [];
        var option = options.find((i) => i.value === operator) || {};
        var useInput = option.useInput;
        if (useInput === "Preset") {
          var presets =
            filter.presets ||
            JSON.parse(JSON.stringify(RecordConstants.FILTER_PRESETS[type])); // deepclone to prevent changing original values
          try {
            cmp.set("selectedPreset", settings.preset);
          } catch (err) {}
          try {
            cmp.set("presetOptions", presets);
          } catch (err) {}
          Record.selectLoadedOption(
            cmp,
            "presetOptions",
            "selectedPreset",
            "selectedPresetLabel",
            false
          );
        } else if (
          ["FreeText", "MultiSelect", "ListValues"].includes(useInput)
        ) {
          // populate both freetext and multiselect fields, because (1) freeTextValues are used in 'view' mode, and (2) it is not clear yet which one will be used; select options are loaded later down the line
          try {
            cmp.set("selectedValues", settings.values);
          } catch (err) {}
          try {
            cmp.set("freeTextValues", Record.toCSV(settings.values));
          } catch (err) {}
        } else if (useInput === "BetweenDateTime") {
          try {
            cmp.set("minDate", settings.min);
          } catch (err) {}
          try {
            cmp.set("maxDate", settings.max);
          } catch (err) {}
        } else if (useInput === "RelativeDateTime") {
          var minParts = (settings.min || "").split(" ");
          var maxParts = (settings.max || "").split(" ");
          try {
            cmp.set("minDateAmount", minParts[0]);
            cmp.set("minDateUnit", minParts[1]);
          } catch (err) {}
          try {
            cmp.set("maxDateAmount", maxParts[0]);
            cmp.set("maxDateUnit", maxParts[1]);
          } catch (err) {}
          Record.selectLoadedOption(
            cmp,
            "dateTimeUnits",
            "minDateUnit",
            "minDateUnitLabel",
            false
          );
          Record.selectLoadedOption(
            cmp,
            "dateTimeUnits",
            "maxDateUnit",
            "maxDateUnitLabel",
            false
          );
        } else if (useInput === "BetweenNumber") {
          try {
            cmp.set("minNumber", settings.min);
          } catch (err) {}
          try {
            cmp.set("maxNumber", settings.max);
          } catch (err) {}
        }
        // new / existing record
        var isSaved = Boolean(filter.id || filter.floatingId);
        cmp.set("isSaved", isSaved);
        // scope
        var filterScopes = JSON.parse(
          JSON.stringify(RecordConstants.FILTER_SCOPES)
        ); // deepcopy to prevent changing underlying values
        if (!isSaved) {
          var newScopes = cmp.get("newScopes");
          filterScopes = filterScopes.filter(function (v) {
            return newScopes.includes(v.value);
          });
        }
        cmp.set("scopeOptions", filterScopes);
        cmp.set("scope", filter.scope);
        Record.selectLoadedOption(cmp, "scopeOptions", "scope");
        cmp.loadKeyDetails();
      } catch (error) {
        console.error(error);
      }
    },

    loadContainer: function () {
      var containerId = cmp.get("parentId");
      if (containerId) {
        var onSuccess = function (response) {
          var record = response[0];
          record.title = getTitle(getBreadCrumb("container", record));
          cmp.set("selectedContainer", record);
          cmp.set(
            "containerItem",
            Record.nameFromDetails("containers", "container", record.id)
          );
          cmp.set("containerFilter", {
            containers: {
              container: { sourceId: record.sourceId, name: record.name },
            },
          });
          cmp.setContainer();
        };
        var onError = function (response) {
          cmp.checkUser(response);
          cmp.setToastState("error", "Error", toastErrorMessage(response));
          cmp.unloadContainer();
        };
        Record.getRecord(
          "store",
          "container",
          containerId,
          {},
          "",
          "GET",
          onSuccess,
          onError
        );
      } else {
        cmp.unloadContainer();
      }
    },

    unloadContainer: function () {
      cmp.set("loading", false);
      this.setChainFilter("leftContainerId", null);
      cmp.set("selectedContainer", null);
      cmp.set("containerItem", null);
      cmp.set("containerFilter", null);
    },

    setChainFilter: function (name, containerId) {
      var chainFilter = cmp.get("chainFilter") || { chains: { chain: {} } };
      if (containerId) {
        chainFilter.chains.chain[name] = containerId;
      } else {
        delete chainFilter.chains.chain[name];
      }
      var leftContainerId = chainFilter.chains.chain.leftContainerId;
      var rightContainerId = chainFilter.chains.chain.rightContainerId;
      if (!Object.keys(chainFilter.chains.chain).length) {
        chainFilter = null;
      }
      cmp.set("chainFilter", chainFilter);
      // needsChain
      var needsChain =
        leftContainerId &&
        rightContainerId &&
        leftContainerId !== rightContainerId;
      cmp.set("needsChain", needsChain);
    },

    filterJsonHelper: function () {
      try {
        var filter = cmp.get("record");
        var operator = cmp.get("operator");
        var settings = {};
        var useInput = getUseInput(cmp, operator);
        // alias
        settings.alias = operator;
        operator = this.applyOperator("operatorOptions", operator, settings);
        // parse input fields to settings
        if (useInput === "Preset") {
          var preset = cmp.get("selectedPreset");
          settings.alias = operator;
          operator = this.applyOperator("presetOptions", preset, settings);
          settings.preset = preset;
        } else if (useInput === "MultiSelect") {
          var values = cmp.get("selectedValues");
          // 'settings.values = null' if no values selected (which is interpreted as 'all', and allows for creating template Global filters)
          settings.values = values && values.length ? values : null;
        } else if (useInput === "FreeText") {
          // NB: Record.parseCSV returns 'null' for empty string (which is interpreted as 'all', and allows for creating template Global filters)
          settings.values = Record.parseCSV(cmp.get("freeTextValues"));
        } else if (useInput === "BetweenDateTime") {
          settings.min = cmp.get("minDate");
          settings.max = cmp.get("maxDate");
        } else if (useInput === "RelativeDateTime") {
          var minAmount = cmp.get("minDateAmount");
          minAmount = minAmount || minAmount === 0 ? String(minAmount) : "";
          var maxAmount = cmp.get("maxDateAmount");
          maxAmount = maxAmount || maxAmount === 0 ? String(maxAmount) : "";
          var minUnit = cmp.get("minDateUnit");
          var maxUnit = cmp.get("maxDateUnit");
          settings.min =
            minAmount && minUnit ? minAmount + " " + minUnit : null;
          settings.max =
            maxAmount && maxUnit ? maxAmount + " " + maxUnit : null;
        } else if (useInput === "BetweenNumber") {
          var minNumber = cmp.get("minNumber");
          var maxNumber = cmp.get("maxNumber");
          // parse to float; NB: <lightning:input type="number"...> is not useful here, because it requires a 'step' setting, which we do not know
          settings.min =
            minNumber || minNumber === 0 ? parseFloat(minNumber) : null;
          settings.max =
            maxNumber || maxNumber === 0 ? parseFloat(maxNumber) : null;
        }
        // create / update filter settings
        var scope = cmp.get("scope");
        var key = cmp.get("selectedKey");
        var container = cmp.get("selectedContainer");
        var chain = cmp.get("selectedChain");
        var inputs;
        var node0 = (filter.inputs || [])[0] || {};
        var node1 = (node0.inputs || [])[0] || {};
        if (chain) {
          let load = {
            id: node1.id,
            type: "Load",
            argOrder: 0,
            rootOrder: 0,
            keyId: key.id,
            key,
            inputs: [],
          };
          var join = {
            id: node0.id,
            type: "Join",
            argOrder: 0,
            rootOrder: 0,
            chainId: chain.id,
            chain,
            inputs: [load],
          };
          inputs = [join];
        } else {
          let load = {
            id: node0.id,
            type: "Load",
            argOrder: 0,
            rootOrder: 0,
            keyId: key.id,
            key,
            inputs: [],
          };
          inputs = [load];
        }
        // include the selected Key with the Filter, so that other components (e.g., FilterSet) can read its values without having to query the API again
        var filterKey = cmp.get("selectedKey") || {};
        filter = (({ id, floatingId, name, type, active, acceptMissing }) => ({
          id,
          floatingId,
          name,
          type,
          active,
          scope,
          acceptMissing,
          containerId: container.id,
          container,
          operator,
          settings,
          inputs,
          filterKey,
        }))(filter);
        //Object.assign(filter, {containerId: container.id, container, operator, scope, settings, inputs, filterKey});
        return filter;
      } catch (error) {
        console.error(error);
      }
    },

    applyOperator: function (optionsAttribute, value, settings) {
      var operatorOptions = cmp.get(optionsAttribute) || [];
      var operatorOption = operatorOptions.find((i) => i.value === value) || {};
      // if the option has an operator, store it as the filter's operator, and store the option as alias
      var optionOperator = operatorOption.operator;
      if (optionOperator) {
        value = optionOperator;
      }
      // if the option has its own settings, apply these to the settings
      var optionSettings = operatorOption.settings;
      if (optionSettings) {
        Object.assign(settings, optionSettings);
      }
      return value;
    },

    loadKeyDetails: function () {
      // load existing record, or open new record
      var key = cmp.get("selectedKey");
      var self = this;
      if (key) {
        PsRecord.setLoading(cmp);
        var onSuccess = function (response) {
          var record = response[0];
          var type = (record.dataType || {}).format;
          record.title = getTitle(getBreadCrumb("key", record));

          cmp.set("record", { ...cmp.get("record"), type: type });
          cmp.set("selectedKey", record);
          var operatorOptions = RecordConstants.FILTER_TYPES[type];
          var isInit = cmp.get("isInit");
          if (!operatorOptions) {
            self.unloadKeyDetails();
          } else {
            // set values before selecting operator, so it can switch between MultiSelect and FreeText
            if (!isInit) {
              try {
                cmp.set("record", {
                  ...cmp.get("record"),
                  name: record.name,
                });
              } catch (err) {}
              try {
                cmp.set("selectedValues", null);
              } catch (err) {}
            }
            var values = record.values || [];
            values = values.map(({ label }) => ({ label, value: label })); // API expects labels, not values
            cmp.set("valuesOptions", values);
            cmp.set("valuesOptionsLoaded", true);
            Record.selectLoadedOptions(
              cmp.get("valuesOptions"),
              cmp.get("selectedValues")
            );
            // defaults
            if (type === "DateTime") {
              var minDate = record.robustMin
                ? new Date(record.robustMin / 1000000).toISOString()
                : null;
              var maxDate = record.robustMax
                ? new Date(record.robustMax / 1000000).toISOString()
                : null;
              try {
                cmp.set("minDateDefault", minDate);
              } catch (err) {}
              try {
                cmp.set("maxDateDefault", maxDate);
              } catch (err) {}
            } else if (type === "Number") {
              try {
                cmp.set("minNumberDefault", record.robustMin);
              } catch (err) {}
              try {
                cmp.set("maxNumberDefault", record.robustMax);
              } catch (err) {}
            }
            // operator
            cmp.set("operatorOptions", operatorOptions);
            // select first operator on init, or default operator otherwise
            cmp.setOperator();
            // chain
            cmp.setChainFilter(
              "rightContainerId",
              record.containerId || (record.container || {}).id
            );
            cmp.loadChains();
          }
        };
        var onError = function (response) {
          cmp.checkUser(response);
          cmp.setToastState("error", "Error", toastErrorMessage(response));
          cmp.unloadKeyDetails();
        };
        Record.getRecord(
          "store",
          "key",
          key.id,
          {},
          "",
          "GET",
          onSuccess,
          onError
        );
      } else {
        this.unloadKeyDetails(cmp);
      }
    },

    unloadKeyDetails: function () {
      cmp.set("record", { ...cmp.get("record"), type: null });
      cmp.set("operatorOptions", [
        { value: "Not Available", label: "Not Available", selected: true },
      ]);
      cmp.set("operator", "Not Available");
      cmp.set("operatorLabel", null);
      cmp.set("useInput", null);
      cmp.set("valuesOptions", [{ value: "", label: "Not Available" }]);
      cmp.set("selectedValues", null);
      cmp.set("valuesOptionsLoaded", false);
      cmp.set("freeTextValues", null);
      cmp.set("selectedPreset", null);
      cmp.set("selectedPresetLabel", null);
      cmp.set("presetOptions", null);
      cmp.set("minDateUnit", null);
      cmp.set("maxDateUnit", null);
      cmp.set("minDateUnitLabel", null);
      cmp.set("maxDateUnitLabel", null);
      this.setChainFilter("rightContainerId", null);
      this.loadChains();
    },

    // getUseInput: moved to Helper

    setOperator: function () {
      try {
        var isInit = cmp.get("isInit");
        var operator = Record.selectLoadedOption(
          cmp,
          "operatorOptions",
          "operator",
          "operatorLabel",
          !isInit
        );

        // the set of input fields to use
        cmp.set("useInput", getUseInput(cmp, operator));
        // set defaults (unless in isInit mode)
        // IMPROVEMENT: use operator instead of type
        var filter = cmp.get("record") || {};
        var settings = filter.settings || {};
        var type = filter.type;
        if (!cmp.get("isInit")) {
          var presets = filter.presets;
          var presetDefaults = JSON.parse(
            JSON.stringify(RecordConstants.FILTER_PRESETS[type])
          ); // deepclone to prevent changing original values
          cmp.set("presetOptions", presets || presetDefaults);
          if (type === "Text") {
            cmp.set("freeTextValues", null);
          } else if (type === "DateTime") {
            cmp.set("minDate", cmp.get("minDateDefault"));
            cmp.set("maxDate", cmp.get("maxDateDefault"));
          } else if (type === "Number") {
            cmp.set("minNumber", cmp.get("minNumberDefault"));
            cmp.set("maxNumber", cmp.get("maxNumberDefault"));
          }
        }
      } catch (error) {
        console.error(error.stack);
      }
    },

    setContainer: function () {
      var container = cmp.get("selectedContainer");
      this.setChainFilter("leftContainerId", container.id);
      // set selected key to the pattern.key's container, for ease of use
      var selectedKey = cmp.get("selectedKey") || {};
      var selectedContainerId =
        selectedKey.containerId || (selectedKey.container || {}).id;
      if (!selectedContainerId) {
        cmp.set(
          "keyItem",
          Record.nameFromDetails("keys", "container", container.id)
        );
      }
    },

    // set default / preferred chain when necessary
    loadChains: function () {
      var self = this;
      PsRecord.setLoading(cmp);
      var onSuccess = function (response) {
        if (!response || !response.length) {
          cmp.unloadChains();
        } else {
          var chain = response[0];
          chain.title = getTitle(getBreadCrumb("chain", chain));
          cmp.set("selectedChain", chain);
          cmp.set(
            "chainItem",
            Record.nameFromDetails("chains", "chain", chain.id)
          );
          cmp.set("loading", false);
        }
      };
      var onError = function (response) {
        cmp.checkUser(response);
        cmp.setToastState("error", "Error", toastErrorMessage(response));
        cmp.unloadChains();
      };
      // unset selectedChain if it doesn't match the newly selected leftContainerId or rightContainerId
      var needsChain = cmp.get("needsChain");
      if (needsChain) {
        var filter = ((cmp.get("chainFilter") || {}).chains || {}).chain || {};
        var chain = cmp.get("selectedChain") || {};
        var leftContainerId =
          chain.leftContainerId || (chain.leftContainer || {}).id;
        var rightContainerId =
          chain.rightContainerId || (chain.rightContainer || {}).id;
        var changed =
          leftContainerId !== filter.leftContainerId ||
          rightContainerId !== filter.rightContainerId;
        if (changed) {
          var queryFilter = Object.assign({}, filter, {
            orderby: "relevance DESC",
          });
          Record.getRecords("store", "chain", queryFilter, onSuccess, onError);
        } else {
          cmp.set("loading", false);
        }
      } else {
        cmp.unloadChains();
      }
    },

    unloadChains: function () {
      cmp.set("selectedChain", null);
      cmp.set(
        "chainItem",
        Object.assign(Record.nameFromDetails("chains", "chain", null), {
          label: "Not Available",
        })
      );
      cmp.set("loading", false);
    },

    // getBreadCrumb: moved to Helper

    // getTitle: moved to Helper

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];

      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    handleToggleChange: function (item) {
      const record = cmp.get("record");
      cmp.set("record", { ...record, [item]: !record[item] });
    },

    handleNameChange: function (name) {
      const record = cmp.get("record");
      cmp.set("record", { ...record, name });
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      const newEvent =
        event.action === "delete" || event.action === "update"
          ? { ...event, type: "dataCompEvent", record: cmp.get("record") }
          : event;

      if (!stopPropagation) {
        props.childToParent(newEvent);
      }
    },

    handleSelectPreset: function (event, data) {
      cmp.set("selectedPreset", data.selection[0].value);
      cmp.set("selectedPresetLabel", data.selection[0].label);

      cmp.handlePresetChange();
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    checkForm: function () {
      const checkDivs = document.querySelectorAll("[id^='check']");
      let errorTexts = [];
      let invalid = false;

      checkDivs.forEach((div) => {
        const inputOrElement = div.querySelector(
          "input, textarea, select, .combobox"
        );

        if (inputOrElement && inputOrElement.value.trim() === "") {
          errorTexts.push(div.id);
          invalid = true;
        }
      });

      const comboboxElementOfOperator = document.getElementById(
        "checkComboboxOfOperator"
      );
      if (
        !comboboxElementOfOperator ||
        !comboboxElementOfOperator.value?.trim()
      ) {
        errorTexts.push("checkComboboxOfOperator");
        invalid = true;
      }

      if (cmpState.useInput === "Preset") {
        const comboboxElementOfPreset = document.getElementById(
          "checkComboboxOfPreset"
        );
        if (
          !comboboxElementOfPreset ||
          !comboboxElementOfPreset.value?.trim()
        ) {
          errorTexts.push("checkComboboxOfPreset");
          invalid = true;
        }
      }

      cmp.set("errorTexts", errorTexts);
      return invalid;
    },

    handleSelectedValuesChange: function (value, process) {
      try {
        const selectedValues = cmp.get("selectedValues");

        let updatedSelectedValues;
        if (process === "optionsToSelected") {
          updatedSelectedValues =
            selectedValues && selectedValues.includes(value)
              ? selectedValues
              : [...(selectedValues || []), value];
        } else {
          updatedSelectedValues =
            selectedValues && selectedValues.length > 0
              ? selectedValues.filter((item) => item !== value)
              : [];
        }
        cmp.set("selectedValues", updatedSelectedValues);
        cmp.set("selectedValue", null);
      } catch (error) {
        console.error(error.stack);
      }
    },

    handleSelectedValueUpOrDown: function (process) {
      try {
        const selectedValues = cmp.get("selectedValues");
        const selectedValue = cmp.get("selectedValue");
        const currentIndex = selectedValues.indexOf(selectedValue);

        if (currentIndex === -1) {
          return;
        }

        const updatedSelectedValues = [...selectedValues];
        if (process === "up" && currentIndex > 0) {
          const temp = updatedSelectedValues[currentIndex];
          updatedSelectedValues[currentIndex] =
            updatedSelectedValues[currentIndex - 1];
          updatedSelectedValues[currentIndex - 1] = temp;
        } else if (
          process === "down" &&
          currentIndex < updatedSelectedValues.length - 1
        ) {
          const temp = updatedSelectedValues[currentIndex];
          updatedSelectedValues[currentIndex] =
            updatedSelectedValues[currentIndex + 1];
          updatedSelectedValues[currentIndex + 1] = temp;
        }

        cmp.set("selectedValues", updatedSelectedValues);
      } catch (error) {
        console.error(error.stack);
      }
    },

    handleChangeSelectedValue: function (selected) {
      cmp.set("selectedValue", selected);
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },

    body: function () {
      return (
        <div
          className="slds-form slds-var-m-around_medium"
          role="list"
          id="listDiv"
          style={{ paddingBottom: "100px" }}
        >
          <h3 className="slds-section-title--divider slds-var-m-top_medium">
            Filter Details
          </h3>
          <div className="slds-form__row">
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                <lightning-layout>
                  <div className="slds-grid slds-grid_vertical slds-wrap">
                    <div className="slds-col slds-size_1-of-1">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        {/* Active */}
                        <div style={{ flex: "0 0 50px" }}>
                          {cmpState.mode === "init" && <ProgressBarComponent />}
                          {cmpState.mode === "view" && (
                            <div
                              id="FormDiv"
                              className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                            >
                              <span className="slds-form-element__label">
                                Active
                              </span>
                              <div className="slds-form-element__control">
                                <div className="slds-form-element__static">
                                  <CheckAndCloseIcons
                                    selectedItem={cmpState.record.active}
                                  />
                                </div>
                                <div
                                  className="slds-button slds-button__icon slds-button__icon_hint"
                                  style={{ padding: "8px" }}
                                >
                                  <EditButtonIcon handleEdit={cmp.handleEdit} />
                                </div>
                              </div>
                            </div>
                          )}

                          {(cmpState.mode === "new" ||
                            cmpState.mode === "edit") && (
                            <>
                              <span className="slds-form-element__label slds-m-bottom_none">
                                Active
                              </span>
                              <label className="slds-checkbox_toggle slds-grid">
                                <input
                                  type="checkbox"
                                  checked={
                                    cmpState.record.active === undefined ||
                                    cmpState.record.active === null
                                      ? true
                                      : cmpState.record.active
                                  }
                                  onClick={() =>
                                    cmp.handleToggleChange("active")
                                  }
                                  onChange={() =>
                                    cmp.handleToggleChange("active")
                                  }
                                />
                                <span
                                  id="checkbox-toggle-16"
                                  className="slds-checkbox_faux_container"
                                >
                                  <span className="slds-checkbox_faux"></span>
                                </span>
                              </label>
                            </>
                          )}
                        </div>
                        {/* Name */}
                        <div
                          style={{
                            flex: "1",
                            marginLeft: "10px",
                          }}
                          id="checkRecordName"
                        >
                          {cmpState.mode === "view" && (
                            <div
                              id="FormDiv"
                              className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                            >
                              <span className="slds-form-element__label">
                                Name
                              </span>
                              <div className="slds-form-element__control">
                                <div className="slds-form-element__static">
                                  {cmpState.record.name}
                                </div>
                                <div className="slds-button slds-button__icon slds-button__icon_hint">
                                  <EditButtonIcon handleEdit={cmp.handleEdit} />{" "}
                                </div>
                              </div>
                            </div>
                          )}

                          {(cmpState.mode === "new" ||
                            cmpState.mode === "edit") && (
                            <Input
                              label="Name"
                              value={cmpState.record.name}
                              onChange={(e) =>
                                cmp.handleNameChange(e.target.value)
                              }
                              required
                              errorText={
                                !cmpState.record.name &&
                                (props.showRequiredFieldError ||
                                  cmpState.errorTexts.includes(
                                    "checkRecordName"
                                  ))
                                  ? "Complete this field."
                                  : null
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </lightning-layout>
              </div>
            </div>
            {/* Scope */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && (
                  <div className="slds-form-element__static">
                    <ProgressBar value={0} variant="circular" />
                  </div>
                )}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Scope</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.scope}
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <Combobox
                    menuItemVisibleLength={10}
                    events={{
                      onSelect: (_e, data) => handleSelectScope(data),
                    }}
                    labels={{
                      label: "Scope",
                      placeholder: "",
                    }}
                    menuPosition="relative"
                    options={scopeOptionsWithId}
                    selection={[
                      scopeOptionsWithId.find(
                        (sc) => sc.value === cmpState.scope
                      ),
                    ]}
                    onRenderMenuItem={onRenderMenuItem}
                    value={cmpState.scope}
                    variant="readonly"
                    singleInputDisabled={cmpState.isSaved}
                    disabled={cmpState.isSaved}
                    name="scope"
                  />
                )}
              </div>
            </div>
          </div>
          <h3 className="slds-section-title--divider slds-var-m-top_medium">
            Applies To
          </h3>
          <div className="slds-form__row">
            {/* Container */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && (
                  <div className="slds-form-element__static">
                    <ProgressBar value={0} variant="circular" />
                  </div>
                )}
                {cmpState.mode === "view" && (
                  <div className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                    <span className="slds-form-element__label">Object</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.selectedContainer.name}
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <div ref={searchFieldRef} id="checkPsNavigationInputOfObject">
                    <PsNavigationInput
                      label="Object"
                      object="container"
                      sections={["containers"]}
                      selected={cmpState.containerItem}
                      record={cmpState.selectedContainer}
                      required={true}
                      disabled={cmpState.isSaved}
                      onChange={cmp.handleContainerSelect}
                      filters={cmpState.containerFilter}
                      isExpanded={cmpState.isExpanded}
                      activeField={cmpState.activeField}
                      setFilterState={setCmpState}
                      filterSetState={props.filterSetState}
                      showRequiredFieldError={
                        props.showRequiredFieldError ||
                        (!cmpState.selectedContainer?.name &&
                          cmpState.errorTexts.includes(
                            "checkPsNavigationInputOfObject"
                          ))
                      }
                      childToParent={props.childToParent}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Chain */}
            <div className="slds-form__item" role="listitem">
              {cmpState.needsChain && (
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && (
                    <div className="slds-form-element__static">
                      <ProgressBar value={0} variant="circular" />
                    </div>
                  )}
                  {cmpState.mode === "view" && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">Path</span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {cmpState.selectedChain?.name}
                        </div>
                      </div>
                    </div>
                  )}
                  {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                    <div ref={pathFieldRef} id="checkPsNavigationInputOfPath">
                      <PsNavigationInput
                        label="Path"
                        object="chain"
                        sections={["chains"]}
                        selected={cmpState.chainItem}
                        record={cmpState.selectedChain}
                        disabled={cmpState.isSaved}
                        required={true}
                        filters={cmpState.chainFilter}
                        isExpanded={cmpState.isExpanded}
                        activeField={cmpState.activeField}
                        setFilterState={setCmpState}
                        filterSetState={props.filterSetState}
                        showRequiredFieldError={
                          props.showRequiredFieldError ||
                          (!cmpState.selectedChain?.name &&
                            cmpState.errorTexts.includes(
                              "checkPsNavigationInputOfPath"
                            ))
                        }
                        childToParent={props.childToParent}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <h3 className="slds-section-title--divider slds-var-m-top_medium">
            Settings
          </h3>
          <div className="slds-form__row">
            {/* Key (Field) */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && (
                  <div className="slds-form-element__static">
                    <ProgressBar value={0} variant="circular" />
                  </div>
                )}
                {cmpState.mode === "view" && (
                  <div className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                    <span className="slds-form-element__label">Field</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.selectedKey?.name}
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <div ref={keyFieldRef} id="checkPsNavigationInputOfField">
                    <PsNavigationInput
                      label="Field"
                      object="key"
                      sections={["keys"]}
                      selected={cmpState.keyItem}
                      record={cmpState.selectedKey}
                      required={true}
                      // disabled={cmpState.isSaved}
                      disabled={
                        cmpState.isSaved &&
                        !(
                          props.showRequiredFieldError ||
                          (!cmpState.selectedKey?.name &&
                            cmpState.errorTexts.includes(
                              "checkPsNavigationInputOfField"
                            ))
                        )
                      }
                      onChange={cmp.handleKeySelect}
                      activeField={cmpState.activeField}
                      setFilterState={setCmpState}
                      filterSetState={props.filterSetState}
                      showRequiredFieldError={
                        props.showRequiredFieldError ||
                        (!cmpState.selectedKey?.name &&
                          cmpState.errorTexts.includes(
                            "checkPsNavigationInputOfField"
                          ))
                      }
                      childToParent={props.childToParent}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* Operator */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && (
                  <div className="slds-form-element__static">
                    <ProgressBar value={0} variant="circular" />
                  </div>
                )}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Operator</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.operatorLabel}
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon handleEdit={cmp.handleEdit} />
                      </div>
                    </div>
                  </div>
                )}

                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <Combobox
                    menuItemVisibleLength={10}
                    id="checkComboboxOfOperator"
                    events={{
                      onSelect: (event, data) => {
                        cmp.handleOperatorChange(event, data);
                      },
                    }}
                    labels={{
                      label: "Operator",
                      placeholder: "--Please Select--",
                    }}
                    menuPosition="relative"
                    options={operatorOptionsWithId}
                    selection={[
                      operatorOptionsWithId.find(
                        (option) => option.value === cmpState.operator
                      ),
                    ]}
                    value={cmpState.operator}
                    variant="readonly"
                    required
                    errorText={
                      ((!cmpState.operator ||
                        cmpState.operator === "Not Available") &&
                        props.showRequiredFieldError) ||
                      cmpState.errorTexts.includes("checkComboboxOfOperator")
                        ? "Complete this field."
                        : null
                    }
                    disabled={
                      cmpState.operatorOptions.length === 0 ? true : false
                    }
                  />
                )}
              </div>
            </div>
          </div>
          {cmpState.operator && (
            <div className="slds-form__row">
              {/* Preset */}
              {cmpState.useInput === "Preset" && (
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && (
                      <>
                        <span className="slds-form-element__label">&nbsp;</span>
                        <div className="slds-form-element__static">
                          <ProgressBar value={0} variant="circular" />
                        </div>
                      </>
                    )}
                    {cmpState.mode === "view" && (
                      <div className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                        <span className="slds-form-element__label">Preset</span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {cmpState.selectedPresetLabel}
                          </div>
                          <div className="slds-button slds-button__icon slds-button__icon_hint">
                            <EditButtonIcon handleEdit={cmp.handleEdit} />
                          </div>
                        </div>
                      </div>
                    )}

                    {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                      <Combobox
                        menuItemVisibleLength={10}
                        events={{
                          onSelect: (event, data) =>
                            cmp.handleSelectPreset(event, data),
                        }}
                        labels={{
                          label: "Preset",
                          placeholder: "Select Lead Source",
                        }}
                        menuPosition="relative"
                        options={presetOptionsWithId}
                        selection={
                          [
                            presetOptionsWithId.find(
                              (option) =>
                                option.value === cmpState.selectedPreset
                            ),
                          ] || []
                        }
                        value={cmpState.selectedPreset}
                        variant="readonly"
                        required={true}
                        errorText={
                          (!cmpState.selectedPreset &&
                            props.showRequiredFieldError) ||
                          cmpState.errorTexts.includes("checkComboboxOfPreset")
                            ? "Complete this field."
                            : null
                        }
                        id="checkComboboxOfPreset"
                      />
                    )}
                  </div>
                </div>
              )}
              {/* Freetext */}
              {cmpState.useInput === "FreeText" && (
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && (
                      <>
                        <span className="slds-form-element__label">&nbsp;</span>
                        <div className="slds-form-element__static">
                          <ProgressBar value={0} variant="circular" />
                        </div>
                      </>
                    )}

                    {cmpState.mode === "view" && (
                      <div className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                        <span className="slds-form-element__label">Values</span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {cmpState.freeTextValues}
                          </div>
                          <div className="slds-button slds-button__icon slds-button__icon_hint">
                            <EditButtonIcon handleEdit={cmp.handleEdit} />
                          </div>
                        </div>
                      </div>
                    )}

                    {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                      <Input
                        id="checkField"
                        name="freeTextValues"
                        label="Values"
                        autocomplete="off"
                        value={cmpState.freeTextValues || ""}
                        fieldLevelHelpTooltip={
                          <Tooltip
                            id="field-level-help-tooltip"
                            align="top left"
                            content='Enter one or more values separated by commas. Enclose values with commas in double quotes. 
                        For example: orange, "pear, apple", banana'
                          />
                        }
                        onChange={(e) => handleChangeFreeTextValues(e)}
                      />
                    )}
                  </div>
                </div>
              )}
              {/* MultiSelect */}
              {cmpState.useInput === "MultiSelect" && (
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && (
                    <>
                      <span className="slds-form-element__label">&nbsp;</span>
                      <div className="slds-form-element__static">
                        <ProgressBar value={0} variant="circular" />
                      </div>
                    </>
                  )}

                  {cmpState.mode === "view" && (
                    <div className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent">
                      <span className="slds-form-element__label">Values</span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {cmpState.freeTextValues}
                        </div>
                        <div className="slds-button slds-button__icon slds-button__icon_hint">
                          <EditButtonIcon handleEdit={cmp.handleEdit} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* Between Number  */}
              {cmpState.useInput === "BetweenNumber" && (
                <BetweenNumber
                  filterState={cmpState}
                  handleChangeMaxMinNumber={handleChangeMaxMinNumber}
                  cmp={cmp}
                />
              )}

              {/* Between DateTime */}
              {cmpState.useInput === "BetweenDateTime" && (
                <BetweenDateTime
                  filterState={cmpState}
                  handleChangeBetweenDateTime={handleChangeBetweenDateTime}
                />
              )}

              {/* Relative DateTime */}
              {cmpState.useInput === "RelativeDateTime" && (
                <RelativeDateTime
                  filterState={cmpState}
                  handleMaxAndMinDateAmount={handleMaxAndMinDateAmount}
                  handleMinDateUnitChange={handleMinDateUnitChange}
                  handleMaxDateUnitChange={handleMaxDateUnitChange}
                  showRequiredFieldError={props.showRequiredFieldError}
                />
              )}
            </div>
          )}
          {cmpState.useInput !== "Missing" && (
            <div className="slds-form__row">
              {/* Missing values */}
              <div className="slds-form__item" role="listitem">
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && (
                    <>
                      <span className="slds-form-element__label">&nbsp;</span>
                      <div className="slds-form-element__static">
                        <ProgressBar value={0} variant="circular" />
                      </div>
                    </>
                  )}

                  {cmpState.mode === "view" && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">
                        Accept missing values
                      </span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          <CheckAndCloseIcons
                            selectedItem={cmpState.record.acceptMissing}
                          />
                        </div>
                        <div className="slds-button slds-button__icon slds-button__icon_hint">
                          <EditButtonIcon handleEdit={cmp.handleEdit} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {cmpState.useInput === "MultiSelect" &&
            (cmpState.mode === "new" || cmpState.mode === "edit") && (
              <DualListBox
                filterState={cmpState}
                handleSelectedValuesChange={cmp.handleSelectedValuesChange}
                handleChangeSelectedValue={cmp.handleChangeSelectedValue}
                handleSelectedValueUpOrDown={cmp.handleSelectedValueUpOrDown}
                label="Values"
                fieldLevelHelp=""
                valuesOptions={
                  cmpState.selectedValues && cmpState.selectedValues.length > 0
                    ? cmpState.valuesOptions.filter(
                        (option) =>
                          !cmpState.selectedValues.includes(option.value)
                      )
                    : cmpState.valuesOptions
                }
                selectedOptions={
                  cmpState.selectedValues && cmpState.selectedValues.length > 0
                    ? cmpState.selectedValues.map((selectedValue) => ({
                        value: selectedValue,
                        label: selectedValue,
                      }))
                    : []
                }
                selectedValue={cmpState.selectedValue}
              />
            )}

          <div
            className="slds-form__row"
            style={{ justifyContent: "space-between", paddingInline: "12px" }}
          >
            <div>
              {/* AcceptMissingValuesToggle */}
              {cmpState.operator &&
                cmpState.useInput !== "Missing" &&
                (cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <div
                    style={{
                      flex: "0 0 50px",
                      marginTop: "5px",
                      width: "135px",
                    }}
                  >
                    <label
                      className="slds-form-element__label"
                      htmlFor="toggle"
                    >
                      Accept missing values
                    </label>
                    <label className="slds-checkbox_toggle slds-grid">
                      <input
                        type="checkbox"
                        checked={cmpState.record.acceptMissing || false}
                        onClick={() => cmp.handleToggleChange("acceptMissing")}
                        onChange={() => cmp.handleToggleChange("acceptMissing")}
                      />
                      <span
                        id="checkbox-toggle-16"
                        className="slds-checkbox_faux_container"
                      >
                        <span className="slds-checkbox_faux"></span>
                      </span>
                    </label>
                  </div>
                )}
            </div>
            {(cmpState.mode === "new" || cmpState.mode === "edit") &&
              !!props.setApplyFilterOnSave &&
              props.applyFilterOnSave !== undefined && (
                <Toggle
                  label="Apply filters on save"
                  active={props.applyFilterOnSave}
                  onClick={() => props.setApplyFilterOnSave((prev) => !prev)}
                />
              )}
          </div>
        </div>
      );
    },
  };

  const handleMinDateUnitChange = (event, data) => {
    try {
      cmpWorking.current = {
        ...cmpWorking.current,
        minDateUnit: data.selection[0].value,
        minDateUnitLabel: data.selection[0].label,
      };

      setCmpState((prev) => ({
        ...prev,
        minDateUnit: data.selection[0].value,
        minDateUnitLabel: data.selection[0].label,
      }));

      Record.selectLoadedOption(
        cmp,
        "dateTimeUnits",
        "minDateUnit",
        "minDateUnitLabel",
        false
      );
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleMaxDateUnitChange = (event, data) => {
    try {
      cmpWorking.current = {
        ...cmpWorking.current,
        maxDateUnit: data.selection[0].value,
        maxDateUnitLabel: data.selection[0].label,
      };

      setCmpState((prev) => ({
        ...prev,
        maxDateUnit: data.selection[0].value,
        maxDateUnitLabel: data.selection[0].label,
      }));

      Record.selectLoadedOption(
        cmp,
        "dateTimeUnits",
        "maxDateUnit",
        "maxDateUnitLabel",
        false
      );
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleSelectScope = (data) => {
    if (!!data?.selection?.length) cmp.set("scope", data.selection[0].value);
  };

  const handleChangeFreeTextValues = (e) => {
    cmpWorking.current.freeTextValues = e.target.value;
    setCmpState((prev) => ({
      ...prev,
      freeTextValues: e.target.value,
    }));
  };

  const handleChangeMaxMinNumber = (newValue, item) => {
    cmpWorking.current[item] = newValue;
    setCmpState((prev) => ({ ...prev, [item]: newValue }));
  };

  const handleMaxAndMinDateAmount = (newValue, item) => {
    cmpWorking.current[item] = newValue;
    setCmpState((prev) => ({
      ...prev,
      [item]: newValue,
    }));
  };

  // Combobox gives warning without id
  const operatorOptionsWithId =
    cmpState.operatorOptions && cmpState.operatorOptions.length > 0
      ? cmpState.operatorOptions.map((item) => ({
          ...item,
          id: item.label,
        }))
      : cmpState.operatorOptions;

  const scopeOptionsWithId =
    cmpState.scopeOptions && cmpState.scopeOptions.length > 0
      ? cmpState.scopeOptions.map((item) => ({
          ...item,
          id: item.label,
        }))
      : cmpState.scopeOptions;

  const presetOptionsWithId =
    cmpState.presetOptions && cmpState.presetOptions.length > 0
      ? cmpState.presetOptions.map((item) => ({
          ...item,
          id: item.label,
        }))
      : cmpState.presetOptions;

  const handleChangeBetweenDateTime = (newValue, item) => {
    cmpWorking.current[item] = newValue;
    cmpWorking.current[`${item}Local`] = getLocalDateTime(newValue);
    setCmpState((prev) => ({
      ...prev,
      [item]: newValue,
      [`${item}Local`]: getLocalDateTime(newValue),
    }));
  };

  // To access the parent component child state
  useImperativeHandle(ref, () => ({
    getFilterCmpState: () => cmpState,
    getCmp: () => cmp,
  }));

  return (
    <>
      {cmpState.showDeleteConfirmDialog ? (
        <Modal
          apply={() => PsRecord.confirmDeleteRecord(cmp)}
          cancel={() => PsRecord.cancelDeleteRecord(cmp)}
          header="Confirmation"
          modalContent="Deleting this Record will also delete all its associated loaded data. Are you sure?"
          applyButtonContent="Delete"
        />
      ) : null}
      {PsRecord.render(cmp, cmpState)}
    </>
  );
});
