import { Icon } from "@blueprintjs/core";

export const bgColor = "#f3f3f3";

export const defaultColumnWidthCalc = (columns, psRecordGridWidth) => {
  let totalColumnWidth = 0;
  columns.forEach((col) => {
    if (col.width) {
      totalColumnWidth += col.width;
    }
  });

  const diff = 34;

  const width = psRecordGridWidth - diff;
  const defaultColumnWidth =
    totalColumnWidth > 0
      ? (width - totalColumnWidth) /
        Math.max(1, columns.length - columns.filter((col) => col.width).length)
      : width / Math.max(1, columns.length);

  return Math.max(30, defaultColumnWidth);
};

export const sortadIcon = (column, sortedColumns) => {
  const sortadItem = sortedColumns.find(
    (item) => item.column === column.property
  );

  if (!sortadItem) {
    return;
  }

  return (
    <div style={{ marginRight: "5px" }}>
      {sortadItem.sortDirection === "asc" ? (
        <Icon icon="sort-asc" text="Sort Asc" color="#2d72d2" />
      ) : (
        <Icon icon="sort-desc" text="Sort Desc" color="#2d72d2" />
      )}
    </div>
  );
};
