import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Button from "@salesforce/design-system-react/components/button";
import { Spinner } from "@salesforce/design-system-react";

import "./Explore.css";
import Record from "../../helpers/recordLayer.js";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree";
import NoActiveFilters from "./components/NoActiveFilters";
import RecordItem from "./components/RecordItem";
import ToastComponent from "../../components/toast-component";
import SearchBox from "../../components/ps-search-box/SearchBox.js"; //This shouldn't be Explore sub component
import PsSuggestionList from "../../components/ps-suggestion-list/PsSuggestionList";
import PsCompositionGrid from "../../components/ps-composition-grid/PsCompositionGrid";
import PsPatternDetailedView from "../../components/ps-pattern-detailed-view/PsPatternDetailedView";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import { toastErrorMessage } from "../../helpers";
import { EMPTY_TOAST_STATE } from "../../constants";
import useAuthContext from "../../context/useAuthContext.js";

function Explore() {
  const { handleLogout } = useAuthContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [cmpState, setCmpState] = useState({
    view: "list", // list or detail
    selectedNames: [],
    selectedRecords: [],
    searchText: "",
    navigationLoading: true,
    listFilter: {},
    pattern: "",
    isSearchSubmitted: false,
    isFindPatternsLoading: false,
    parentToChildEvent: {},
  });
  const [toastState, setToastState] = useState(EMPTY_TOAST_STATE);

  const cmpWorking = useRef({});
  const leftRef = useRef(null);
  const searchdivRef = useRef(null);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    onPageReferenceChange();
    setCmpState({ ...cmpWorking.current });
  }, [searchParams]); //runs on init and when search params are updated

  const cmp = {
    name: "Explore", //Trying to undo parent specific code in PsNavigationTree

    get: (key) => {
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    checkUser: function (response) {
      if (response === "No current user") {
        childToParent({ type: "logout" });
      }
    },
  };

  const handleReload = () => {
    cmp.set("parentToChildEvent", {
      action: "reload",
    });
    cmp.set("navigationLoading", true);
  };

  const childToParent = (event) => {
    try {
      if (event.type === "navigation") {
        handleNavigationEvent(event);
      } else if (event.type === "dataCompEvent") {
        handleDataCompEvent(event);
      } else if (event.type === "reload") {
        handleReload(event);
      } else if (event.type === "logout") {
        handleLogout();
      }

      setCmpState({ ...cmpWorking.current });
    } catch (err) {
      console.error(err);
    }
  };

  const onPageReferenceChange = () => {
    try {
      parsePageRef();
    } catch (err) {
      console.error(err.stack);
    }
  };

  // IMPROVEMENT: on source/key/object name change (RecordChangedEvent), update pills name / title

  // navigate from event
  const handleNavigationEvent = (event) => {
    try {
      // navigate to different tab, e.g., when using the buttons in connector status section
      var tab = event.tab || "";
      if (["search"].includes(tab.toLowerCase())) {
        navigate("/Search");
        return;
      }

      // sync records
      var selectedRecords = cmpWorking.current.selectedRecords || [];
      selectedRecords = syncItem(selectedRecords, event);
      cmpWorking.current.selectedRecords = selectedRecords;

      // scroll only
      var source = event.source;
      if (["change", "closeSearch"].includes(source)) {
        var scroll = event.scroll;
        // var scroller = document.getElementById("left");
        var scroller = leftRef.current;
        var searchInput = searchdivRef.current; // document.getElementById("searchdiv");
        // update scroll position after rendering, so that rendered sizes are available
        if (searchInput && scroller && scroll != null) {
          var timer = setTimeout(() => {
            var top = searchInput.offsetTop + searchInput.offsetHeight;
            scroller.scrollTop = scroll * (scroller.scrollHeight - top);
          }, 0);
        }
      }

      // navigate
      if (["record", "grid", "tree"].includes(source)) {
        var selectedNames = cmpWorking.current.selectedNames || [];
        var oldNames = selectedNames.join("~");
        var newNames = selectedRecords.map((item) => item.name).join("~");
        if (newNames != oldNames) {
          navigateSelected(newNames);
        }
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleReset = () => {
    try {
      navigateSelected("");
      cmpWorking.current.selectedRecords = [];
      setCmpState((prev) => ({
        ...prev,
        selectedRecords: [],
      }));
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleRemoveItem = (event) => {
    try {
      var index = event.target.id;
      var selectedRecords = cmpWorking.current.selectedRecords || [];
      if (index < selectedRecords.length) {
        selectedRecords.splice(index, 1);
        var newNames = selectedRecords.map((item) => item.name).join("~");
        navigateSelected(newNames);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleDataCompEvent = (event) => {
    try {
      var data = event.data;
      if (data.action === "viewDetails") {
        var pattern = data.pattern;
        var patternId = (pattern || {}).id;
        var currentId = (cmpState.pattern || {}).id;
        var currentView = cmpState.view;
        cmpWorking.current.pattern = pattern;

        if (patternId) {
          if (patternId !== currentId || currentView !== "detail") {
            navigatePattern(patternId);
          }
        } else {
          // don't navigate if no patternId available
          cmpWorking.current.view = "detail";
        }
      } else if (data.action === "close") {
        navigatePattern(undefined);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleRefreshEvent = (event) => {
    event.stopPropagation(); // restrict refresh to this tab
  };

  const handleFind = () => {
    setCmpState((prev) => ({ ...prev, isFindPatternsLoading: true }));
    actionFind();
  };

  // updates component navigation items from query parameters
  const parsePageRef = () => {
    var selected = (searchParams.get("selected") || "").split("~");
    var selectedNames = [];
    var listFilter = {};
    selected.forEach((name) => {
      if (name) {
        selectedNames.push(name);
        var parsed = Record.parseName(name);
        if (parsed.config !== "root") {
          // listFilter.push(parsed.config + "Id=" + parsed.id);
          listFilter[parsed.config + "Id"] = parsed.id;
        }
      }
    });

    // determine view, but first set the component's filters and selected IDs before changing the view, so that that components are rendered with the correct filters, and don't need to reload after init with the newly set filters
    var patternId = searchParams.get("pattern");
    var view = patternId ? "detail" : "list";

    // update filters depending on the view
    if (view === "list") {
      if (
        JSON.stringify(listFilter) !==
        JSON.stringify(cmpWorking.current.listFilter)
      ) {
        cmpWorking.current.listFilter = listFilter;
      }
    } else if (view === "detail") {
      var currentId = (cmpWorking.current.pattern || {}).id;
      if (patternId !== currentId) {
        cmpWorking.current.pattern = { id: patternId };
      }
    }

    // update tree with selection
    cmpWorking.current.selectedNames = selectedNames;

    // set right-side panel view mode
    cmpWorking.current.view = view;
  };

  const navigateSelected = (names) => {
    // var pageref = cmp.get("v.pageReference");
    // var namespace = cmp.getType().split(":")[0];
    // var state = {
    //   [namespace + "__selected"]: names,
    //   [namespace + "__pattern"]: undefined,
    // };
    // cmp
    //   .find("navService")
    //   .navigate({ type: pageref.type, attributes: pageref.attributes, state });

    //TODO navigate to pattern undefined? doesn't make sense?
    navigate({
      pathname: "/Explore",
      search: "?selected=" + names, //+ "&pattern=" + undefined,
    });
  };

  const navigatePattern = (patternId) => {
    if (!patternId) {
      searchParams.delete("pattern");
      setSearchParams(searchParams);
    } else {
      searchParams.set("pattern", patternId);
      setSearchParams(searchParams);
    }
  };

  const syncItem = (selected, event) => {
    // default sections used when navigating from an external component
    var sections = ["types", "data"];
    var defaultSections = {
      root: "types",
      dataRole: "types",
      dataType: "types",
      source: "data",
      container: "data",
      key: "data",
    };

    // create item
    var item = Record.itemFromEvent(event, defaultSections);

    // don't update if the item is already selected, and the new event has isLoading
    // the item would be stuck in loading, because no navigation is done when selecting an already selected item
    if (!item.hasDetails && selected.find((i) => i.name === item.name)) {
      return selected;
    }

    // update / insert / delete new item
    selected = selected.filter((i) => i.section !== item.section);
    if (item.id) {
      selected.push(item);
    }

    // sort in order of the sections
    var sectionMap = selected.reduce((obj, i) => {
      obj[i.section] = i;
      return obj;
    }, {});
    return sections.map((i) => sectionMap[i]).filter((i) => i != null);
  };

  const actionFind = () => {
    try {
      var queryFilter = cmpWorking.current.listFilter;
      var onSuccess = function () {
        //To use ToastComponent
        setToastState({
          variant: "info",
          heading: "Searching more Patterns",
          details: "This may take a few mintues",
        });
        setCmpState((prev) => ({ ...prev, isFindPatternsLoading: false }));
      };

      var onError = function (response) {
        cmp.checkUser(response);
        setToastState({
          variant: "error",
          heading: "Error",
          details: toastErrorMessage(response),
        });
        setCmpState((prev) => ({ ...prev, isFindPatternsLoading: false }));
      };

      Record.doAction(
        "relate",
        "composition",
        "run.select",
        queryFilter,
        onSuccess,
        onError
      );
    } catch (err) {
      console.error(err.stack);
      setCmpState((prev) => ({ ...prev, isFindPatternsLoading: false }));
    }
  };

  const handleSearchTextChange = (value) => {
    cmp.set("searchText", value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    cmp.set("isSearchSubmitted", true);
  };

  return (
    <div className="Explore">
      <PsNavigationHeader view={cmpState.view} childToParent={childToParent} />
      <div className="tab-content slds-p-around_medium">
        {/* <!-- using slds-hide to prevent rebuilding views that need to keep their state --> */}
        <div
          className={
            cmpState.view === "list" ? "left slds-m-right_medium" : "slds-hide"
          }
        >
          {/* <!-- navigation tree --> */}
          <article
            className="slds-card" // style={{ maxWidth: "420px" }}
          >
            <div style={{ height: "11rem" }}>
              <div
                className="slds-card__header"
                style={{
                  marginLeft: "-5px",
                }}
              >
                <h2 className=" card-main-title-lh32 slds-card__header-title slds-text-heading_small slds-truncate">
                  Refine Results
                </h2>
              </div>
              {cmpState.selectedRecords.length > 0 ? (
                <>
                  <div
                    className="slds-box slds-box_xx-small slds-m-horizontal_x-small"
                    style={{
                      marginTop: "-5px",
                    }}
                  >
                    <div
                      className="slds-p-horizontal_x-small"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {cmpState.selectedRecords.map((record, index) => (
                        <RecordItem
                          key={record.id}
                          record={record}
                          index={index}
                          handleRemoveItem={handleRemoveItem}
                          maxWidth={
                            cmpState.selectedRecords.length === 1
                              ? "23rem"
                              : "15rem"
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <div
                    className="slds-grid slds-grid_align-end slds-m-around_x-small"
                    style={{
                      marginBottom: "-1px",
                    }}
                  >
                    <div className="slds-col slds-p-left_x-small">
                      <Button
                        label="Reset"
                        title="Reset"
                        onClick={handleReset}
                      />
                    </div>
                    <div className="slds-col slds-p-left_x-small">
                      <Button
                        label={
                          <div>
                            Find Patterns
                            {cmpState.isFindPatternsLoading && (
                              <Spinner size="x-small" variant="brand" />
                            )}
                          </div>
                        }
                        title="Find more Patterns"
                        onClick={handleFind}
                        fullWidth
                      />
                    </div>
                  </div>
                </>
              ) : (
                <NoActiveFilters />
              )}
              <div ref={searchdivRef} className="slds-p-around_x-small">
                <SearchBox
                  searchText={cmpState.searchText}
                  loading={cmpState.navigationLoading}
                  handleSearchTextChange={handleSearchTextChange}
                  handleSearchSubmit={handleSearchSubmit}
                />
              </div>
            </div>

            <div
              ref={leftRef}
              className="slds-p-horizontal_x-small"
              style={{
                overflowY: "auto",
                height: "calc(100vh - 18.25rem)",
              }}
            >
              <PsNavigationTree
                multiSelect={true}
                sections={["types", "data"]}
                selected={cmpState.selectedNames}
                searchText={cmpState.searchText}
                isLoading={cmpState.navigationLoading}
                parentCmp={cmp}
                childToParent={childToParent}
                isSearchSubmitted={cmpState.isSearchSubmitted}
                parentToChildEvent={cmpState.parentToChildEvent}
              />
            </div>
          </article>
        </div>
        {/* <!-- right -->
        <!-- uses slds-hide instead of aura:if to prevent reloading setupStatus and also to keep the scroll position when going back from details to list view --> */}
        <div
          className={
            cmpState.view === "list"
              ? cmpState.listFilter
                ? "right rightExplore"
                : "slds-scrollable right-scroll"
              : "slds-hide"
          }
        >
          <PsSetupStatus
            id="setupStatus"
            showSpinner={false}
            title="Explore"
            tagLine="Explore discovered patterns using simple navigation."
            parentToChildEvent={cmpState.parentToChildEvent}
            parentCmp={cmp}
          />

          {/* <c:SuggestionList view="grid" tagLine="Refine results by selecting one or more filters on the left. Some suggestions:"/> */}
          {(!cmpState.listFilter ||
            Object.keys(cmpState.listFilter).length === 0) &&
            cmpState.view !== "detail" && (
              <PsSuggestionList
                view="grid"
                tagLine="Refine results by selecting one or more filters on the left. Some suggestions:"
                childToParent={childToParent}
                queryFilter={cmpState.listFilter}
                setToastState={setToastState}
                parentToChildEvent={cmpState.parentToChildEvent}
                parentCmp={cmp}
              />
            )}

          {cmpState.listFilter &&
            Object.keys(cmpState.listFilter).length !== 0 && (
              <PsCompositionGrid
                view="grid"
                queryFilter={cmpState.listFilter}
                maxRecords="12"
                childToParent={childToParent}
                setToastState={setToastState}
                parentToChildEvent={cmpState.parentToChildEvent}
                parentCmp={cmp}
              />
            )}
        </div>

        {/* <!-- pattern detail --> */}
        {cmpState.view === "detail" && (
          <PsPatternDetailedView
            pattern={cmpState.pattern}
            childToParent={childToParent}
            setCmpState={setCmpState}
            setToastState={setToastState}
            parentToChildEvent={cmpState.parentToChildEvent}
            parentCmp={cmp}
          />
        )}
      </div>
      {toastState.details ? (
        <ToastComponent
          close={() => setToastState(EMPTY_TOAST_STATE)}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </div>
  );
}

export default Explore;
