// Helper for Filter.js
import { getUTCDateTime } from "../../../helpers";
import Record from "../../../helpers/recordLayer";

export const getTitle = (breadcrumb) => {
  return breadcrumb
    .map((item) => item.name)
    .filter((item) => item != null)
    .join("->");
};

export const getBreadCrumb = (object, record) => {
  let container = {};
  let source = {};
  if (object === "key") {
    container = record.container || {};
    source = container.source || {};
    return [
      { id: source.id, name: source.name },
      { id: container.id, name: container.name },
      { id: record.id, name: record.name },
    ];
  } else if (object === "chain") {
    container = record.leftContainer || {};
    source = container.source || {};
    return [
      { id: source.id, name: source.name },
      { id: container.id, name: container.name },
      { id: record.id, name: record.name },
    ];
  } else if (object === "container") {
    source = record.source || {};
    return [
      { id: source.id, name: source.name },
      { id: record.id, name: record.name },
    ];
  }
  return [];
};

export const applyOperator = (cmp, optionsAttribute, value, settings) => {
  var operatorOptions = cmp.get(optionsAttribute) || [];
  var operatorOption = operatorOptions.find((i) => i.value === value) || {};

  // if the option has an operator, store it as the filter's operator, and store the option as alias
  var optionOperator = operatorOption.operator;
  if (optionOperator) {
    value = optionOperator;
  }

  // if the option has its own settings, apply these to the settings
  var optionSettings = operatorOption.settings;
  if (optionSettings) {
    Object.assign(settings, optionSettings);
  }
  return value;
};

export const getUseInput = (cmp, operator) => {
  // use MultiSelect if values are predefined, otherwise use FreeText
  var options = cmp.get('operatorOptions') || [];
  var option = options.find(i => i.value === operator) || {};
  var useInput = option.useInput;
  if (useInput === 'ListValues') {
      var valuesOptions = cmp.get('valuesOptions');
      useInput = (valuesOptions && valuesOptions.length) ? 'MultiSelect' : 'FreeText';
  }
  return useInput;
};

export const filterJson = (cmp) => {
  var filter = cmp.get("record");
  var operator = cmp.get("operator");

  var settings = {};
  var useInput = getUseInput(cmp, operator);

  // alias
  settings.alias = operator;
  operator = applyOperator(cmp, "operatorOptions", operator, settings);

  // parse input fields to settings
  if (useInput === "Preset") {
    var preset = cmp.get("selectedPreset");
    settings.alias = operator;
    operator = applyOperator(cmp, "presetOptions", preset, settings);
    settings.preset = preset;
  } else if (useInput === "MultiSelect") {
    var values = cmp.get("selectedValues");
    // 'settings.values = null' if no values selected (which is interpreted as 'all', and allows for creating template Global filters)
    settings.values = values && values.length ? values : null;
  } else if (useInput === "FreeText") {
    // NB: Record.parseCSV returns 'null' for empty string (which is interpreted as 'all', and allows for creating template Global filters)
    settings.values = Record.parseCSV(cmp.get("freeTextValues"));
  } else if (useInput === "BetweenDateTime") {
    settings.min = getUTCDateTime(cmp.get("minDateLocal"));
    settings.max = getUTCDateTime(cmp.get("maxDateLocal"));
  } else if (useInput === "RelativeDateTime") {
    var minAmount = cmp.get("minDateAmount");
    minAmount = minAmount || minAmount === 0 ? String(minAmount) : "";
    var maxAmount = cmp.get("maxDateAmount");
    maxAmount = maxAmount || maxAmount === 0 ? String(maxAmount) : "";
    var minUnit = cmp.get("minDateUnit");
    var maxUnit = cmp.get("maxDateUnit");
    settings.min = minAmount && minUnit ? minAmount + " " + minUnit : null;
    settings.max = maxAmount && maxUnit ? maxAmount + " " + maxUnit : null;
  } else if (useInput === "BetweenNumber") {
    var minNumber = cmp.get("minNumber");
    var maxNumber = cmp.get("maxNumber");
    // parse to float; NB: <lightning:input type="number"...> is not useful here, because it requires a 'step' setting, which we do not know
    settings.min = minNumber || minNumber === 0 ? parseFloat(minNumber) : null;
    settings.max = maxNumber || maxNumber === 0 ? parseFloat(maxNumber) : null;
  }

  // create / update filter settings
  var scope = cmp.get("scope");
  var key = cmp.get("selectedKey");
  var container = cmp.get("selectedContainer");
  var chain = cmp.get("selectedChain");
  var inputs;
  var node0 = (filter.inputs || [])[0] || {};
  var node1 = (node0.inputs || [])[0] || {};
  if (chain) {
    var load = {
      id: node1.id,
      type: "Load",
      argOrder: 0,
      rootOrder: 0,
      keyId: key.id,
      key,
      inputs: [],
    };
    var join = {
      id: node0.id,
      type: "Join",
      argOrder: 0,
      rootOrder: 0,
      chainId: chain.id,
      chain,
      inputs: [load],
    };
    inputs = [join];
  } else {
    var load = {
      id: node0.id,
      type: "Load",
      argOrder: 0,
      rootOrder: 0,
      keyId: key.id,
      key,
      inputs: [],
    };
    inputs = [load];
  }

  // include the selected Key with the Filter, so that other components (e.g., FilterSet) can read its values without having to query the API again
  var filterKey = cmp.get("selectedKey") || {};

  filter = (({ id, floatingId, name, type, active, acceptMissing }) => ({
    id,
    floatingId,
    name,
    type,
    active,
    scope,
    acceptMissing,
    containerId: container.id,
    container,
    operator,
    settings,
    inputs,
    filterKey,
  }))(filter);
  //Object.assign(filter, {containerId: container.id, container, operator, scope, settings, inputs, filterKey});

  return filter;
};

export const parseInputPlainText = (cmp) => {
  var filter = filterJson(cmp);
  filter = JSON.parse(JSON.stringify(filter)); // deepcopy to prevent changing original record
  // IMPROVEMENT: allow changing filter input Nodes structure; this is not currently possible because it may swtich from a join-with-load to a load-only, and the backend doesn't allow updating the actual Node structre or deleting input Nodes.

  filter = filter.id
    ? (({ id, active, name, acceptMissing, operator, settings }) => ({
        id,
        active,
        name,
        acceptMissing,
        operator,
        settings,
      }))(filter)
    : (({
        active,
        type,
        name,
        scope,
        acceptMissing,
        containerId,
        operator,
        settings,
        inputs,
      }) => ({
        active,
        type,
        name,
        scope,
        acceptMissing,
        containerId,
        operator,
        settings,
        inputs,
      }))(filter);
  return filter;
};
