export const LOGIN = {
  SUCCESS: "Login success",
};

export const SIGNUP = {
  SUCCESS: "Registration successful. Please check your email for verification.",
  PASSWORD_MISMATCH: "The passwords do not match.",
};

export const GENERAL_ERROR = "Something went wrong.";
export const INVALID_EMAIL = "Please enter a valid email address.";
