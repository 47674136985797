import { useNavigate } from "react-router";
import { PAGE_ROUTES } from "../../../constants";
import { useEffect } from "react";

const Setup = () => {
  const navigate = useNavigate();

  // Immediately navigate to the user page when this component renders (For now)
  useEffect(() => {
    navigate(PAGE_ROUTES.SETUP.USERS.path);
  }, []);

  return null;
};

export default Setup;
