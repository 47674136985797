export const RECORD_COLUMNS = [
  {
    key: "name",
    label: "Pattern",
    property: "namePlain",
    type: "link",
    action: "details",
    sortable: true,
  },
  {
    key: "relevance",
    label: "Relevance",
    property: "relevance",
    type: "percent",
    width: 120,
    sortable: true,
  },

  {
    key: "containerName",
    label: "Object",
    property: "containerName",
    type: "link",
    action: "viewContainer",
  },
  {
    key: "sourceName",
    label: "Source",
    property: "sourceName",
    type: "link",
    action: "viewSource",
  },
  {
    key: "createdOn",
    label: "Created On",
    property: "createdOn",
    type: "formattedDate",
    width: 200,
    sortable: true,
  },
  {
    key: "lastRunOn",
    label: "Last Run On",
    property: "lastRunOn",
    type: "formattedDate",
    width: 200,
    sortable: true,
  },
];
