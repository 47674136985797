import { useState } from "react";
import { Button, Input, Spinner, Card } from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import "../Pages.css";
import ToastComponent from "../../components/toast-component";
import { GENERAL_ERROR } from "../../constants/ToastMessages";
import RegionSelection from "../../components/region-selection";
import { EMPTY_TOAST_STATE } from "../../constants";
import useAuthContext from "../../context/useAuthContext";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [toastState, setToastState] = useState(EMPTY_TOAST_STATE);
  // const [mode,setMode] = useState('login'); //login, resetpassword, newpassword - would we need confirm password mode too?

  const { user } = useAuthContext();
  const region = user.region;

  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setToastState(EMPTY_TOAST_STATE);
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setToastState(EMPTY_TOAST_STATE);
      setIsLoading(true);

      const data = await Auth.forgotPassword(username);
      setToastState({
        heading: "Success",
        variant: "success",
        details:
          "Reset requested. Please check your email for further instructions.",
      });

      navigate("/login?mode=resetPassword");
    } catch (err) {
      console.error(err.stack);
      setToastState({
        heading: "Error",
        variant: "error",
        details: err.message || GENERAL_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeToast = () => setToastState(EMPTY_TOAST_STATE);

  return (
    <div className="login-signup-container">
      <form onSubmit={handleSubmit}>
        <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
          <RegionSelection />
          <div className="user-inputs">
            <Input
              type="text"
              label="Username"
              value={username}
              onChange={handleUsernameChange}
              autoComplete="username"
            />
          </div>
          <div className="login-signup-button">
            <Button
              type="submit"
              label={
                <>
                  Reset my password{isLoading ? <Spinner size="small" /> : null}
                </>
              }
              variant="brand"
              disabled={isLoading || !username || !region}
              style={{ width: "100%" }}
            />
          </div>
        </Card>
      </form>
      {toastState.details ? (
        <ToastComponent
          close={closeToast}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </div>
  );
};

export default ForgotPassword;
