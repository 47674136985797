import {
  Button,
  Checkbox,
  IconSettings,
  Input,
  Textarea,
  Tooltip,
} from "@salesforce/design-system-react";
import Combobox from "@salesforce/design-system-react/components/combobox";
import { useState } from "react";

import "../PsConnector.css";

const AuthenticationDetailsModalWindow = ({ cmpState, cmp }) => {
  const [tooltipOpen, setTooltipOpen] = useState([]);

  // to avoid id issue on the console
  const connectOptions =
    cmpState.connectOptions.map((item) => ({
      ...item,
      id: item.value,
    })) || [];

  const errorText = (name, value) =>
    cmpState.missingRequiredFields.includes(name) && !value
      ? "Complete this field."
      : null;

  const renderTooltipContent = (content) => {
    // Check if content contains <a> tag
    const linkRegex = /<a href="([^"]*)">(.*?)<\/a>/g;
    let lastIndex = 0;
    const elements = [];
    let uniqueKey = 0;

    let match;
    while ((match = linkRegex.exec(content)) !== null) {
      // Add text before the <a> tag
      if (match.index > lastIndex) {
        elements.push(
          <span key={uniqueKey++}>
            {content.substring(lastIndex, match.index)}
          </span>
        );
      }

      // Add the <a> tag as a React element
      elements.push(
        <a
          key={uniqueKey++}
          href={match[1]}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fff", textDecoration: "underline" }}
        >
          {match[2]}
        </a>
      );

      lastIndex = linkRegex.lastIndex;
    }

    // Add any remaining text after the last <a> tag
    if (lastIndex < content.length) {
      elements.push(
        <span key={uniqueKey++}>{content.substring(lastIndex)}</span>
      );
    }

    return elements;
  };

  const handleMouseEnter = (item) => {
    setTooltipOpen((prev) => [...prev, item]);
  };
  const handleMouseLeave = (item) => {
    setTimeout(() => {
      setTooltipOpen((prev) =>
        prev.filter((tooltipItem) => tooltipItem !== item)
      );
    }, 100);
  };

  const clickableTooltip = (item) => {
    return (
      <div
        onMouseEnter={() => handleMouseEnter(item.label)}
        onMouseLeave={() => handleMouseLeave(item.label)}
      >
        <Tooltip
          align="top left"
          content={renderTooltipContent(item.help)}
          isOpen={tooltipOpen.includes(item.label)}
          variant="list-item"
        >
          <button
            className="slds-button slds-button_icon"
            aria-describedby="help"
            aria-disabled="true"
            title=""
            onMouseEnter={() => handleMouseEnter(item.label)}
            onMouseLeave={() => handleMouseLeave(item.label)}
          >
            <svg className="slds-button__icon" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#info"></use>
            </svg>
          </button>
        </Tooltip>
      </div>
    );
  };

  const labelAndFieldLevelHelpTooltip = (item) => (
    <div style={{ display: "flex" }}>
      <label className="slds-form-element__label" htmlFor="text-input-id-50">
        {item.required && (
          <abbr className="slds-required" title="required">
            *
          </abbr>
        )}
        {item.label}
      </label>
      <div style={{ marginTop: "2px" }}>{clickableTooltip(item)}</div>
    </div>
  );

  return (
    <div>
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className="slds-modal slds-fade-in-open"
      >
        <div className="slds-modal__container">
          <button
            className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
            onClick={cmp.handleCancelCredentials}
          >
            <svg
              className="slds-button__icon slds-button__icon_small"
              aria-hidden="true"
            >
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span className="slds-assistive-text">Cancel and close</span>
          </button>
          <header className="slds-modal__header">
            <h2
              id="modal-heading-01"
              className="slds-text-heading_medium slds-hyphenate"
            >
              Authentication Details
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <div
              className="slds-form slds-m-around_medium"
              role="list"
            >
              <div
                className="message"
                title=""
                severity="info"
                closable="false"
              >
                <p>
                  Point Sigma will connect to your source system to download
                  data. Your authentication details are securely encrypted using
                  AES-256 cryptography.{" "}
                </p>
                <p>
                  Point Sigma will only download data that is accessible to the
                  user whose authentication details you provide.
                </p>
              </div>

              {/* <!-- only show Authentiation method combobox if it has more than 1 option (selectedConnectOption is already set automatically in code if there is just one option anyway) --> */}
              {cmpState.connectOptions[1] && (
                <div className="slds-form__row">
                  <div
                    className="slds-form__item slds-grid_vertical-align-center"
                    role="listitem"
                  >
                    <div className="slds-form-element slds-form-element_stacked">
                      <Combobox
                        menuItemVisibleLength={10}
                        menuPosition="relative"
                        id="checkField-selectedConnectOption"
                        events={{
                          onSelect: (event, data) => {
                            cmp.selectConnect(data);
                          },
                        }}
                        labels={{
                          label: "Authentication Method",
                          placeholderReadOnly: "--Please Select --",
                        }}
                        options={connectOptions}
                        selection={[
                          connectOptions.find(
                            (option) =>
                              option.value === cmpState.selectedConnectOption
                          ),
                        ]}
                        value={cmpState.selectedConnectOption}
                        variant="readonly"
                        required={true}
                        errorText={errorText(
                          "selectedConnectOption",
                          !cmpState.selectedConnectOption
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}

              {cmpState.selectedConnectMethod &&
                cmpState.selectedConnectMethod.inputs &&
                cmpState.selectedConnectMethod.inputs.map((item1, index) => {
                  // to avoid id issue on the console
                  const itemOneOptions = item1.options
                    ? item1.options.map((option) => ({
                        ...option,
                        id: option.value,
                      }))
                    : [];
                  return (
                    // extra div might break the row part
                    <div key={"selectedConnectMethod_inputs_" + index}>
                      <IconSettings iconPath="/assets/icons">
                        {!item1.oauth &&
                          item1.type &&
                          item1.type !== "section" && (
                            <div className="slds-form__row">
                              <div
                                className="slds-form__item slds-grid_vertical-align-center"
                                role="listitem"
                              >
                                <div className="slds-form-element slds-form-element_stacked">
                                  {(item1.type === "text" ||
                                    item1.type === "password") && (
                                    <>
                                      {labelAndFieldLevelHelpTooltip(item1)}
                                      <Input
                                        id={
                                          item1.required
                                            ? `checkField-${item1.name}`
                                            : item1.name
                                        }
                                        type={item1.type}
                                        placeholder={item1.placeholder}
                                        label=""
                                        onChange={(e) =>
                                          cmp.handleChangeSelectedConnectMethod(
                                            e,
                                            [index],
                                            item1.name
                                          )
                                        }
                                        value={item1.value || ""}
                                        required={item1.required}
                                        pattern={item1.pattern}
                                        errorText={errorText(
                                          item1.name,
                                          item1.value
                                        )}
                                      />
                                    </>
                                  )}

                                  {item1.type === "checkbox" && (
                                    <div style={{ display: "flex" }}>
                                      <Checkbox
                                        id={
                                          item1.required
                                            ? `checkField-${item1.name}`
                                            : item1.name
                                        }
                                        assistiveText={{
                                          label: item1.label,
                                        }}
                                        labels={{
                                          label: item1.label,
                                        }}
                                        onChange={(e) =>
                                          cmp.handleChangeSelectedConnectMethod(
                                            e,
                                            [index],
                                            item1.name,
                                            item1.type
                                          )
                                        }
                                        checked={
                                          item1.value === undefined
                                            ? false
                                            : item1.value
                                        }
                                        required={item1.required}
                                        pattern={item1.pattern}
                                        errorText={errorText(
                                          item1.name,
                                          item1.value
                                        )}
                                      />
                                      {clickableTooltip(item1)}
                                    </div>
                                  )}
                                  {item1.type === "number" && (
                                    <>
                                      {labelAndFieldLevelHelpTooltip(item1)}
                                      <Input
                                        id={
                                          item1.required
                                            ? `checkField-${item1.name}`
                                            : item1.name
                                        }
                                        type="text"
                                        placeholder={item1.placeholder}
                                        label=""
                                        onChange={(e) =>
                                          cmp.handleChangeSelectedConnectMethod(
                                            e,
                                            [index],
                                            item1.name
                                          )
                                        }
                                        value={item1.value || ""}
                                        required={item1.required}
                                        pattern={
                                          item1.pattern
                                            ? item1.pattern
                                            : "^\\d*"
                                        }
                                        errorText={errorText(
                                          item1.name,
                                          item1.value
                                        )}
                                      />
                                    </>
                                  )}

                                  {item1.type === "textarea" && (
                                    <>
                                      {labelAndFieldLevelHelpTooltip(item1)}
                                      <Textarea
                                        id={
                                          item1.required
                                            ? `checkField-${item1.name}`
                                            : item1.name
                                        }
                                        label=""
                                        placeholder={item1.placeholder}
                                        value={item1.value || ""}
                                        required={item1.required}
                                        errorText={errorText(
                                          item1.name,
                                          item1.value
                                        )}
                                        onChange={(e) =>
                                          cmp.handleChangeSelectedConnectMethod(
                                            e,
                                            [index],
                                            item1.name
                                          )
                                        }
                                      />
                                    </>
                                  )}
                                  {item1.type === "list" && (
                                    <>
                                      {labelAndFieldLevelHelpTooltip(item1)}
                                      <Input
                                        id={
                                          item1.required
                                            ? `checkField-${item1.name}`
                                            : item1.name
                                        }
                                        type="text"
                                        placeholder={item1.placeholder}
                                        label=""
                                        onChange={(e) =>
                                          cmp.handleChangeSelectedConnectMethod(
                                            e,
                                            [index],
                                            item1.name
                                          )
                                        }
                                        value={item1.value || ""}
                                        required={item1.required}
                                        pattern={item1.pattern}
                                        errorText={errorText(
                                          item1.name,
                                          item1.value
                                        )}
                                      />
                                    </>
                                  )}
                                  {item1.type === "select" && (
                                    <>
                                      {labelAndFieldLevelHelpTooltip(item1)}
                                      <Combobox
                                        menuItemVisibleLength={10}
                                        menuPosition="relative"
                                        id={
                                          item1.required
                                            ? `checkField-${item1.name}`
                                            : item1.name
                                        }
                                        events={{
                                          onSelect: (event, data) => {
                                            cmp.selectConnectMethodInput(
                                              data,
                                              index
                                            );
                                          },
                                        }}
                                        labels={
                                          //workaround, as I can't just pass object that contains label: {item1.label}
                                          (item1) => {
                                            return {
                                              label: "",
                                              placeholderReadOnly:
                                                item1.placeholder,
                                            };
                                          }
                                        }
                                        options={itemOneOptions}
                                        selection={[
                                          itemOneOptions.find(
                                            (option) =>
                                              option.value === item1.value
                                          ),
                                        ]}
                                        value={item1.value}
                                        variant="readonly"
                                        required={item1.required}
                                        errorText={errorText(
                                          item1.name,
                                          item1.value
                                        )}
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}

                        {/* <!-- subsection, optionally with conditional input set selector dropdown --> */}
                        {!item1.oauth && item1.type === "section" && (
                          <div className="slds-box slds-m-bottom_x-small slds-m-horizontal_xx-small">
                            {!item1.options && (
                              <div className="slds-p-bottom_x-small">
                                <label className="slds-form-element__label">
                                  {item1.label}
                                </label>
                                {clickableTooltip(item1)}
                              </div>
                            )}
                            {item1.options && (
                              <>
                                {labelAndFieldLevelHelpTooltip(item1)}
                                <Combobox
                                  id={`checkField-${item1.name}`}
                                  events={{
                                    onSelect: (event, data) => {
                                      cmp.selectConnectMethodInput(data, index);
                                    },
                                  }}
                                  labels={{
                                    label: "",
                                    placeholder: item1.placeholder,
                                  }}
                                  options={itemOneOptions}
                                  selection={[
                                    itemOneOptions.find(
                                      (option) => option.value === item1.value
                                    ),
                                  ]}
                                  value={item1.value}
                                  variant="readonly"
                                  required={true}
                                  errorText={errorText(item1.name, item1.value)}
                                />
                              </>
                            )}

                            {item1.inputs &&
                              item1.inputs.map((item2, nestedIndex) => {
                                // to avoid id issue on the console
                                const itemTwoOptions = item2.options
                                  ? item2.options.map((option) => ({
                                      ...option,
                                      id: option.value,
                                    }))
                                  : [];
                                return (
                                  // TODO extra div breaks row structure

                                  <div key={"item1_input_" + nestedIndex}>
                                    {(!item1.options ||
                                      item1.value === item2.option) &&
                                      item2.type &&
                                      item2.type !== "section" && (
                                        <div className="slds-form__row">
                                          <div
                                            className="slds-form__item slds-grid_vertical-align-center"
                                            role="listitem"
                                          >
                                            <div className="slds-form-element slds-form-element_stacked">
                                              {(item2.type === "text" ||
                                                item2.type === "password") && (
                                                <>
                                                  {labelAndFieldLevelHelpTooltip(
                                                    item2
                                                  )}
                                                  <Input
                                                    id={
                                                      item2.required
                                                        ? `checkField-${item2.name}`
                                                        : item2.name
                                                    }
                                                    type={item2.type}
                                                    placeholder={
                                                      item2.placeholder
                                                    }
                                                    label=""
                                                    onChange={(e) =>
                                                      cmp.handleChangeSelectedConnectMethod(
                                                        e,
                                                        [index, nestedIndex],
                                                        item2.name
                                                      )
                                                    }
                                                    value={item2.value || ""}
                                                    required={item2.required}
                                                    pattern={item2.pattern}
                                                    errorText={errorText(
                                                      item2.name,
                                                      item2.value
                                                    )}
                                                  />
                                                </>
                                              )}

                                              {item2.type === "checkbox" && (
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <Checkbox
                                                    id={
                                                      item2.required
                                                        ? `checkField-${item2.name}`
                                                        : item2.name
                                                    }
                                                    assistiveText={{
                                                      label: item2.label,
                                                    }}
                                                    labels={{
                                                      label: item2.label,
                                                    }}
                                                    onChange={(e) =>
                                                      cmp.handleChangeSelectedConnectMethod(
                                                        e,
                                                        [index],
                                                        item2.name,
                                                        item2.type
                                                      )
                                                    }
                                                    checked={
                                                      item2.value === undefined
                                                        ? false
                                                        : item2.value
                                                    }
                                                    required={item2.required}
                                                    pattern={item2.pattern}
                                                    errorText={errorText(
                                                      item2.name,
                                                      item2.value
                                                    )}
                                                  />
                                                  {clickableTooltip(item2)}
                                                </div>
                                              )}

                                              {item2.type === "number" && (
                                                <>
                                                  {labelAndFieldLevelHelpTooltip(
                                                    item2
                                                  )}
                                                  <Input
                                                    id={
                                                      item2.required
                                                        ? `checkField-${item2.name}`
                                                        : item2.name
                                                    }
                                                    type="text"
                                                    placeholder={
                                                      item2.placeholder
                                                    }
                                                    label=""
                                                    onChange={(e) =>
                                                      cmp.handleChangeSelectedConnectMethod(
                                                        e,
                                                        [index, nestedIndex],
                                                        item2.name
                                                      )
                                                    }
                                                    value={item2.value || ""}
                                                    required={item2.required}
                                                    pattern={
                                                      item2.pattern
                                                        ? item2.pattern
                                                        : "^\\d*"
                                                    }
                                                    errorText={errorText(
                                                      item2.name,
                                                      item2.value
                                                    )}
                                                  />
                                                </>
                                              )}

                                              {item2.type === "textarea" && (
                                                <>
                                                  {labelAndFieldLevelHelpTooltip(
                                                    item2
                                                  )}
                                                  <Textarea
                                                    id={
                                                      item2.required
                                                        ? `checkField-${item2.name}`
                                                        : item2.name
                                                    }
                                                    label=""
                                                    placeholder={
                                                      item2.placeholder
                                                    }
                                                    value={item2.value || ""}
                                                    required={item2.required}
                                                    errorText={errorText(
                                                      item2.name,
                                                      item2.value
                                                    )}
                                                    onChange={(e) =>
                                                      cmp.handleChangeSelectedConnectMethod(
                                                        e,
                                                        [index, nestedIndex],
                                                        item2.name
                                                      )
                                                    }
                                                  />
                                                </>
                                              )}
                                              {item2.type === "list" && (
                                                <>
                                                  {labelAndFieldLevelHelpTooltip(
                                                    item2
                                                  )}
                                                  <Input
                                                    id={
                                                      item2.required
                                                        ? `checkField-${item2.name}`
                                                        : item2.name
                                                    }
                                                    type="text"
                                                    placeholder={
                                                      item2.placeholder
                                                    }
                                                    label=""
                                                    onChange={(e) =>
                                                      cmp.handleChangeSelectedConnectMethod(
                                                        e,
                                                        [index, nestedIndex],
                                                        item2.name
                                                      )
                                                    }
                                                    value={item2.value || ""}
                                                    required={item2.required}
                                                    pattern={item2.pattern}
                                                    errorText={errorText(
                                                      item2.name,
                                                      item2.value
                                                    )}
                                                  />
                                                </>
                                              )}
                                              {item2.type === "select" && (
                                                <>
                                                  {labelAndFieldLevelHelpTooltip(
                                                    item2
                                                  )}
                                                  <Combobox
                                                    menuItemVisibleLength={10}
                                                    menuPosition="relative"
                                                    id={
                                                      item2.required
                                                        ? `checkField-${item2.name}`
                                                        : item2.name
                                                    }
                                                    events={{
                                                      onSelect: (
                                                        event,
                                                        data
                                                      ) => {
                                                        cmp.selectConnectMethodInputInput(
                                                          data,
                                                          index,
                                                          nestedIndex
                                                        );
                                                      },
                                                    }}
                                                    labels={{
                                                      label: "",
                                                      placeholder:
                                                        item2.placeholder,
                                                    }}
                                                    options={itemTwoOptions}
                                                    selection={[
                                                      itemTwoOptions.find(
                                                        (option) =>
                                                          option.value ===
                                                          item2.value
                                                      ),
                                                    ]}
                                                    value={item2.value}
                                                    variant="readonly"
                                                    required={item2.required}
                                                    errorText={errorText(
                                                      item2.name,
                                                      item2.value
                                                    )}
                                                  />
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </IconSettings>
                    </div>
                  );
                })}
              <div
                style={{
                  height: "10px",
                  width: "100%",
                }}
              ></div>
            </div>
          </div>
          <footer className="slds-modal__footer">
            <Button
              label="Cancel"
              title="Cancel"
              onClick={cmp.handleCancelCredentials}
              disabled={cmpState.loading}
              variant="neutral"
            />
            {cmpState.selectedConnectMethod.button && (
              <Button
                label={cmpState.selectedConnectMethod.button}
                title={cmpState.selectedConnectMethod.button}
                onClick={cmp.handleSaveCredentials}
                disabled={
                  cmpState.loading ||
                  (cmpState.connectOptions[1] &&
                    !cmpState.selectedConnectOption)
                }
                variant="brand"
              />
            )}
          </footer>
        </div>
      </section>
      <div className="slds-backdrop slds-backdrop_open"></div>
    </div>
  );
};

export default AuthenticationDetailsModalWindow;
