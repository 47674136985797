import { Icon } from "@salesforce/design-system-react";

export const ariaSelected = (item, sections, selectedNames) => {
  const rootItemId = item.id;

  let selected = false;
  try {
    if (selectedNames) {
      sections.forEach((section) => {
        if (selectedNames[section]?.includes(rootItemId)) {
          const partsOfName = selectedNames[section].split("_");
          if (
            partsOfName[2] === rootItemId &&
            item.rootName.includes(partsOfName[0])
          ) {
            selected = true;
          }
        }
      });
    }
  } catch (e) {
    console.error("ariaSelected", e);
  }
  return selected;
};

export const dataTypeMapping = {
  Count: "Number",
  "Machine Identifier": "String",
  UUID: "String",
  "Ordered Category": "String",
  Money: "Currency",
  Text: "String",
  Place: "String",
  "Zip / Postal Code": "String",
  "Date and Time": "Date",
  Number: "Number",
  Latitude: "Number",
  Longitude: "Number",
  SIC: "String",
  Date: "Date",
  Boolean: "Boolean",
  List: "List",
  Multiple: "Multiple",
  "Machine Index": "String",
  Organization: "Hierarchy",
  Story: "Story",
  URL: "Link",
  Other: "Other",
  "Phone Number": "String",
  Email: "String",
  "Email Address": "String",
  Percentage: "Percentage",
  "Time Part": "Date Time",
};

export const dataTypeToNameMap = {
  Date: "event",
  String: "text",
  Number: "topic2",
  Currency: "currency",
  List: "groups",
  Boolean: "toggle",
  Multiple: "multi_picklist",
  Hierarchy: "hierarchy",
  Story: "news",
  Link: "link",
  Other: "standard_objects",
  Percentage: "percent",
  "Date Time": "date_time",
};

export const dragAndDropIcon = (
  <Icon
    assistiveText={{ label: "Drag and Drop" }}
    category="utility"
    name="drag_and_drop"
    size="x-small"
    title="Drag and Drop"
  />
);

export const dealIcon = (item) => {
  const dataType = item.record?.dataType?.name || "Other";
  const mappedType = dataTypeMapping[dataType];
  const name = dataTypeToNameMap[mappedType] || "standard_objects";

  return (
    <Icon
      assistiveText={{ label: dataType }}
      category="utility"
      name={name}
      size="x-small"
      title={dataType}
      style={{ margin: "0 5px 0 5px" }}
    />
  );
};
