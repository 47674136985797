import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuthContext from "../../context/useAuthContext";
import Cookies from "js-cookie";

const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  const { isAuthenticated, handleLogout } = useAuthContext();
  const { pathname } = useLocation();

  // Check if there is no region in cookies log out user.( This runs only if the path url changed)
  useEffect(() => {
    const regionFromCookies = Cookies.get("region");
    if (!regionFromCookies) handleLogout();
  }, [pathname]);

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;
