import { Icon, IconSettings } from "@salesforce/design-system-react";

const CheckIcon = () => {
  return (
    <IconSettings iconPath="/assets/icons">
      <Icon
        assistiveText={{ label: "Yes" }}
        category="utility"
        name="check"
        size="x-small"
        title="Yes"
      />
    </IconSettings>
  );
};

export default CheckIcon;
