import { useEffect, useRef, useState } from "react";
import { Button } from "@salesforce/design-system-react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import useWindowSize from "../../hooks/useWindowSize";

export const PsMapList = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Map",
    recordLabelPlural: "Maps",
    emptyLine: "No Maps found",
    recordModule: "store",
    recordObject: "map",
    changeView: false,

    //////
    orderBy: "name",
    orderDirection: "asc",
    recordList: [],
    recordColumns: [],
    view: "table",
    showTitle: true,
    hasMore: false,
    mode: "init",
    showLoadMore: true,
    showEmptyCallToAction: true,
    showCardActions: true,

    loadingMore: false,
    psRecordGridWidth: 1530,
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);
  const psRecordGridRef = useRef(null);

  const { width } = useWindowSize();

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    PsRecordGrid.handlePsRecordGridWidth(cmp, psRecordGridRef);
  }, [width]);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.handleReset();
  }, [props.parentId, props.queryFilter]);

  const cmp = {
    // --- MapListController.js ---

    init: function () {
      try {
        PsRecordGrid.setRecordColumns(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    afterScriptsLoaded: function () {
      try {
        var numRecords = cmp.get("maxRecords");
        PsRecordGrid.getRecords(cmp, numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      try {
        var maxRecords = cmp.get("maxRecords");
        var numRecords = !maxRecords
          ? 0
          : Math.max(cmp.get("recordList").length, maxRecords);
        PsRecordGrid.reset(cmp);
        PsRecordGrid.getRecords(cmp, numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleReset: function () {
      try {
        var numRecords = cmp.get("maxRecords");
        PsRecordGrid.reset(cmp);
        PsRecordGrid.getRecords(cmp, numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleLoadMore: function () {
      try {
        var numRecords = cmp.get("maxRecords");
        PsRecordGrid.getRecords(cmp, numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleNew: function () {
      try {
        var parentNav =
          (cmp.get("parentPrefix") || "") + (cmp.get("parentId") || "");
        var recordModule = cmp.get("recordModule");
        var recordObject = cmp.get("recordObject");
        cmp.notifyNavigation(parentNav, recordModule, recordObject, null);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRecordRowAction: function (row) {
      try {
        const action = row.action.name;
        var parentId = cmp.get("parentId");
        var recordModule = cmp.get("recordModule");
        var recordObject = cmp.get("recordObject");
        switch (action) {
          case "details":
            cmp.notifyNavigation(parentId, recordModule, recordObject, row.id);
            break;
          default:
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- MapListHelper.js ---

    RECORD_COLUMNS: [
      {
        label: "Name",
        type: "link",
        property: "name",
        key: "name",
        sortable: true,
        action: "details",
      },
      {
        label: "# Distinct",
        width: 110,
        property: "robustDistinct",
        key: "robustDistinct",
        type: "number",
      },
      {
        label: "Relevance",
        width: 110,
        property: "relevance",
        key: "relevance",
        type: "number",
        sortable: true,
      },
      {
        label: "Latest Data",
        width: 200,
        property: "dataLastChangedOn",
        key: "dataLastChangedOn",
        type: "formattedDate",
      },
    ],

    parseResponse: function (response) {
      return response.map(
        ({
          id,
          name,
          container,
          robustDistinct,
          relevance,
          dataLastChangedOn,
        }) => ({
          id,
          name,
          source: container.source.name,
          container: container.name,
          robustDistinct,
          relevance,
          dataLastChangedOn,
        })
      );
    },

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    notifyNavigation: function (parentId, module, object, id) {
      let event = new Event("navigation");
      event.parentId = parentId;
      event.module = module;
      event.obj = object;
      event.id = id;
      event.source = "grid";

      props.childToParent(event);
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    cardActions: function () {
      return cmpState.mode === "init" || cmpState.mode === "view" ? (
        <Button
          // id="ViewModeButton"
          disabled={cmpState.loading}
          label="New"
          title={"Create a new " + cmpState.recordLabel}
          onClick={() => cmp.handleNew()}
        />
      ) : null;
    },

    emptyCallToAction: function () {
      return (
        <Button
          // id="ViewModeButton"
          disabled={cmpState.loading}
          label="Create Map"
          title={"Create a new " + cmpState.recordLabel}
          onClick={cmp.handleNew}
          variant="brand"
        />
      );
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },
  };

  return (
    <div
      ref={psRecordGridRef}
      style={{
        height:
          cmpState.showEmptyCallToAction && cmpState.mode === "empty"
            ? "auto"
            : "100%",

        marginTop: 15,
      }}
    >
      {PsRecordGrid.render(cmp, cmpState)}
    </div>
  );
};
