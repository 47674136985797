import { Helmet } from "react-helmet";

// title required to use this component
const PsSEO = ({ title, description = undefined, favicon = undefined }) => {
  // Add more element if it is required
  return (
    <Helmet>
      {<title>{title}</title>}
      {!!description && <meta name="description" content={description} />}
      {!!favicon && <link rel="icon" href={favicon} />}
    </Helmet>
  );
};

export default PsSEO;
