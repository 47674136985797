import { useEffect, useState } from "react";
import "@blueprintjs/table/lib/css/table.css";
// import "normalize.css"; // This causes three dots to appear under the star that indicate the required field for lightning design components.
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Table2, Column, Cell } from "@blueprintjs/table";
import { HotkeysProvider } from "@blueprintjs/core";

import { formattedNumber } from "../../helpers";
import CustomTooltip from "../ui/CustomTooltip";

const PsTreeGrid = ({ cmpState, onrowaction, cmp }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [records, setRecords] = useState(cmpState.dataTableRecords || []);

  useEffect(() => {
    setRecords(cmpState.dataTableRecords);
  }, [cmpState.dataTableRecords]);

  const columns = cmpState.dataTableColumns;

  const handleExpandCollapseClick = (recordId, expanded) => {
    let updatedExpandedRowIds = [...expandedRowIds];
    updatedExpandedRowIds = updatedExpandedRowIds.includes(recordId)
      ? [...updatedExpandedRowIds.filter((id) => id !== recordId)]
      : [...updatedExpandedRowIds, recordId];

    setExpandedRowIds(updatedExpandedRowIds);

    if (updatedExpandedRowIds.length === 0) {
      setRecords(cmpState.dataTableRecords);
      return;
    }

    setRecords((prev) => {
      const selectedRecordIndex = prev.findIndex(
        (record) => record.id === recordId
      );
      if (selectedRecordIndex !== -1 && prev[selectedRecordIndex]._children) {
        if (expanded) {
          return [
            ...prev.filter(
              (child) => child.parentName !== prev[selectedRecordIndex].name
            ),
          ];
        } else {
          return [
            ...prev.slice(0, selectedRecordIndex + 1),
            ...prev[selectedRecordIndex]._children.map((child) => {
              return {
                ...child,
                parentName: prev[selectedRecordIndex].name,
              };
            }),

            ...prev.slice(selectedRecordIndex + 1),
          ];
        }
      }
      return prev;
    });
  };

  const expandCollapseButton = (record) => {
    const expanded = expandedRowIds.includes(record.id);

    return (
      <button
        className="slds-button slds-button_icon slds-button_icon-x-small slds-m-right_x-small"
        aria-hidden="true"
        tabIndex="-1"
        title={expanded ? "Collapse" : "Expand"}
        onClick={() => handleExpandCollapseClick(record.id, expanded)}
        style={{
          margin: 1,
        }}
      >
        <svg
          className="slds-button__icon slds-button__icon_small"
          aria-hidden="true"
        >
          <use
            xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#chevron${
              expanded ? "down" : "right"
            }`}
          />
        </svg>
        <span className="slds-assistive-text">Expand {record.name}</span>
      </button>
    );
  };

  const cellRenderer = (rowIndex, column) => {
    const record = records[rowIndex];
    const recordName = record[column.property];
    let cell = recordName;
    let title = recordName;

    if (column.type === "link") {
      cell = (
        <a
          href="/#"
          onClick={(event) => {
            event.preventDefault();
            onrowaction(column.action, record);
          }}
          title="View Details"
        >
          {recordName}
        </a>
      );
    } else if (column.type === "number") {
      cell = formattedNumber(recordName);
      title = cell;
    }

    if (cmp.name === "PsChain" && column.property === "name") {
      cell = (
        <div style={{ display: "flex", marginLeft: record._children ? 0 : 35 }}>
          {record._children && expandCollapseButton(record)}
          {record.name}
        </div>
      );
    }

    return (
      <Cell style={{ display: "flex", alignItems: "center" }}>
        <CustomTooltip content={cell} title={title} />
      </Cell>
    );
  };

  const rowHeaderCellRenderer = (index) => {
    return (
      <Cell
        style={{
          display: "flex",
          alignItems: "center",
          minWidth: 40,
          justifyContent: "end",
        }}
      >
        {index + 1}
      </Cell>
    );
  };

  return (
    <HotkeysProvider>
      <Table2
        numRows={records.length}
        columnWidths={columns.map((column) => column.width || 400)}
        defaultRowHeight={26}
        rowHeaderCellRenderer={(index) => rowHeaderCellRenderer(index)}
        cellRendererDependencies={records}
      >
        {columns.map((column) => {
          return (
            <Column
              key={column.label}
              name={column.label}
              cellRenderer={(rowIndex) => cellRenderer(rowIndex, column)}
            />
          );
        })}
      </Table2>
    </HotkeysProvider>
  );
};

export default PsTreeGrid;
