import { Card, Tooltip } from "@salesforce/design-system-react";
import { Tag } from "antd";

import {
  boxes,
  clearButton,
  dragContent,
  isDropzoneDisabled,
  sourceOptions,
} from "./Helper";
import { truncateText } from "../../../helpers";

const RightSideBar = ({ parentCmp, cmpState }) => {
  return (
    <div
      style={{
        width: "300px",
        marginRight: "10px",
        marginLeft: "-5px",
        overflow: "hidden",
      }}
    >
      <Card className="PsRecoredGrid slds-scrollable" heading="">
        <div className="right-sidebar">
          <div
            style={{
              marginBottom: "25px",
              marginTop: "-15px",
            }}
          >
            {boxes.map((box) => {
              const selectedBox = `box${box.order}`;
              const order = box.order;
              const selectedValues = cmpState.selectedValues;
              const emptyBox = selectedValues[selectedBox].length === 0;

              return (
                <div key={box.order}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <p style={{ marginBottom: 0, marginRight: 2 }}>
                      {box.name}
                    </p>
                    <div
                      style={{
                        position: "relative",
                        width: 170,
                      }}
                    >
                      <Tooltip
                        content={box.info}
                        id="tooltip"
                        align="top left"
                        variant="learnMore"
                      ></Tooltip>
                    </div>
                  </div>

                  <div
                    className={`drop-zone ${
                      isDropzoneDisabled(order, selectedValues)
                        ? "disabled"
                        : "enabled"
                    }`}
                    style={{
                      minHeight: "60px",
                      display: "flex",
                      justifyContent: emptyBox ? "center" : "",
                      alignItems: emptyBox ? "center" : "",
                      textAlign: emptyBox ? "center" : "",
                      position: "relative",
                      marginBottom: "15px",
                    }}
                    onDrop={(e) => {
                      if (isDropzoneDisabled(order, selectedValues)) {
                        return;
                      }
                      parentCmp.handleOnDrop(e, box);
                    }}
                    onDragOver={parentCmp.onDragOver}
                  >
                    <div>
                      {emptyBox
                        ? dragContent(cmpState.selectedValues, selectedBox)
                        : cmpState.selectedValues[selectedBox].map((item) => (
                            <Tag
                              key={item.id}
                              // color={
                              //   sourceOptions.find((s) => s.id === item.color)
                              //     ?.color || "green"
                              // }
                              color="green"
                              closable
                              onClose={() =>
                                parentCmp.onRemove(item.id, selectedBox)
                              }
                              title={item.title}
                              style={{ marginBottom: "3px" }}
                            >
                              {truncateText(item.label, 35)}
                            </Tag>
                          ))}
                    </div>
                    <div
                      style={{ position: "absolute", top: "3px", right: "3px" }}
                    >
                      {!emptyBox &&
                        clearButton(selectedBox, parentCmp.removeAllTags)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default RightSideBar;
