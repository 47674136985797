import { useEffect, useRef, useState } from "react";

import Event from "../../helpers/event";
import { formattedBlueText } from "../../helpers";

const PsSuggestionTile = (props) => {
  const [isExpand, setIsExpand] = useState(false);
  const [, setClamped] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    // Check if the content is clamped
    function handleResize() {
      if (contentRef && contentRef.current) {
        setClamped(
          contentRef.current.scrollHeight > contentRef.current.clientHeight
        );
      }
    }
    handleResize();
  }, [props.record.questionMarkup]);

  const notifyNavigation = (parentId, module, object, id, source) => {
    let navigationEvent = Event.createEvent("navigation");
    navigationEvent = {
      ...navigationEvent,
      parentId,
      module,
      obj: object,
      id,
      source,
    };
    props.childToParent(navigationEvent);
  };

  const handleSelect = () => {
    var parentId = null; //cmp.get('v.parentId');
    var record = props.record || {};

    // send two navigation events; one for KeyId and DataRoleId
    // the first one has source='change' to prevent changing the url already, which would break the 'back' button functionality
    notifyNavigation(parentId, "store", "key", record.id, "change");
    notifyNavigation(
      parentId,
      "core",
      "dataRole",
      (record.dataRole || {}).id,
      "grid"
    );
  };

  const handleToggleClick = (event) => {
    event.stopPropagation();
    setIsExpand(!isExpand);
  };

  const showIcon = () => {
    const contentElement = contentRef.current;
    if (!contentElement) return false;

    // Get the computed styles of the element
    const style = window.getComputedStyle(contentElement);
    const { overflow } = style;

    return overflow === "hidden";
  };

  const collapseIcon = (
    <button
      className="slds-button slds-button_icon"
      title="Collapse"
      onClick={handleToggleClick}
      style={{
        marginTop: "-5px",
        marginBottom: "20px",
      }}
    >
      <span className="slds-icon_container slds-icon-utility-chevronup">
        <svg
          className="slds-icon slds-icon-text-default slds-icon_x-small"
          aria-hidden="true"
        >
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevronup"></use>
        </svg>
      </span>
    </button>
  );

  const expandIcon = (
    <button
      className="slds-button slds-button_icon"
      title="Expand"
      onClick={handleToggleClick}
      style={{
        marginTop: "-5px",
        marginBottom: "20px",
      }}
    >
      <span className="slds-icon_container slds-icon-utility-chevrondown">
        <svg
          className="slds-icon slds-icon-text-default slds-icon_x-small"
          aria-hidden="true"
        >
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevrondown"></use>
        </svg>
      </span>
    </button>
  );

  return (
    <div
      className="slds-card_boundary"
      onClick={handleSelect}
      title={props.record.title}
      style={{
        width: "300px",
        cursor: "pointer",
        margin: "5px",
      }}
    >
      <div
        className="slds-p-around_medium question"
        style={{
          flex: "1",
          height: isExpand ? "auto" : "5.5em",
          marginRight: "-5px",
        }}
      >
        <div
          ref={contentRef}
          style={{
            maxWidth: "100%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: isExpand ? null : 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {formattedBlueText(props.record.questionMarkup)}
        </div>
        {showIcon() && (
          <div style={{ float: "right" }}>
            {isExpand ? collapseIcon : expandIcon}
          </div>
        )}
      </div>
    </div>
  );
};

export default PsSuggestionTile;
