import { Routes, Route } from "react-router-dom";

import "./App.css";
import Home from "./pages/home/Home";
import Explore from "./pages/explore/Explore";
import Search from "./pages/search/Search";
import SavedInsights from "./pages/saved-insights/SavedInsights";
import DataManagement from "./pages/data-management/DataManagement";
import NotFound from "./pages/not-found/NotFound";
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import Login from "./pages/login";
import Signup from "./pages/signup";
import ForgotPassword from "./pages/forgot-password";
import Store from "./pages/store/Store";
import PsTopMenuBar from "./components/ps-top-menu-bar/PsTopMenuBar";
import PsSEO from "./components/ps-SEO/PsSEO";
import { PAGE_SEO } from "./constants";
import Profile from "./pages/profile/Profile";
import { setupRoutes } from "./pages/setup/routes";
import Record from "./helpers/recordLayer";
import PsPermissionWrapper from "./components/ps-permission-wrapper/PsPermissionWrapper";
import useAuthContext from "./context/useAuthContext";
import Build from "./pages/builder/Build";
import RecordConstants from "./constants/RecordConstants";

const App = () => {
  const { isAuthenticating } = useAuthContext();

  return isAuthenticating ? null : (
    <div className="App">
      <PsTopMenuBar />
      <div style={{ height: "40px" }}></div>
      <Routes>
        <Route
          path=""
          element={
            <ProtectedRoute>
              <PsSEO {...PAGE_SEO.home} />
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="explore"
          element={
            <ProtectedRoute>
              <PsSEO {...PAGE_SEO.explore} />
              <Explore />
            </ProtectedRoute>
          }
        />
        <Route
          path="search"
          element={
            <ProtectedRoute>
              <PsSEO {...PAGE_SEO.search} />
              <Search />
            </ProtectedRoute>
          }
        />
        <Route
          path="build"
          element={
            <ProtectedRoute>
              <PsSEO {...PAGE_SEO.build} />
              <Build />
            </ProtectedRoute>
          }
        />
        <Route
          path="savedInsights"
          element={
            <ProtectedRoute>
              <PsSEO {...PAGE_SEO.saveInsights} />
              <SavedInsights />
            </ProtectedRoute>
          }
        />

        {setupRoutes.map((item) => (
          <Route
            key={"Setup"}
            path={item.path}
            element={
              <ProtectedRoute>
                <PsPermissionWrapper
                  permittedUserTypes={[
                    RecordConstants.USER_TYPE.ADMINISTRATOR.value,
                  ]}
                  redirectPath="/"
                >
                  <PsSEO {...PAGE_SEO.setup} />
                  {item.element}
                </PsPermissionWrapper>
              </ProtectedRoute>
            }
          />
        ))}
        <Route
          path="storeManagement"
          element={
            <ProtectedRoute>
              <PsSEO {...PAGE_SEO.store} />
              <Store />
            </ProtectedRoute>
          }
        />
        <Route
          path="dataManagement"
          element={
            <ProtectedRoute>
              <PsSEO {...PAGE_SEO.dataManagement} />
              <DataManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <PsSEO {...PAGE_SEO.profile} />
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="login"
          element={
            <>
              <PsSEO {...PAGE_SEO.login} />
              <Login />
            </>
          }
        />
        <Route
          path="signup"
          element={
            <>
              <PsSEO {...PAGE_SEO.signup} />
              <Signup />
            </>
          }
        />
        <Route
          path="forgotPassword"
          element={
            <>
              <PsSEO {...PAGE_SEO.forgotPassword} />
              <ForgotPassword />
            </>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
