const IllustrationOpenRoad = () => {
  return (
    <div className="slds-illustration slds-illustration_small">
      <svg
        className="slds-illustration__svg"
        viewBox="0 0 454 272"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd" transform="translate(-65 -74)">
          <g transform="translate(77 180)">
            <polyline
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__stroke-secondary"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="21 74 111.5 0 177.5 54"
            ></polyline>
            <polyline
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__stroke-secondary"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="160 37 181.5 19 249 74"
            ></polyline>
            <path
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__fill-secondary"
              d="M5.83623454,54.8238008 C6.47896445,52.7119739 7.52041887,52.7099477 8.16376546,54.8238008 L14,74 L0,74 L5.83623454,54.8238008 Z"
            ></path>
            <path
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__fill-secondary"
              d="M14.9699006 61.8628727C15.5388088 59.729467 16.4627585 59.7353443 17.0300994 61.8628727L20 73 12 73 14.9699006 61.8628727zM239.734375 56.5589921C240.43336 54.4620359 241.506942 54.4857215 242.128332 56.5984458L246 69.7621171 237 64.7621171 239.734375 56.5589921z"
            ></path>
            <path
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__fill-secondary"
              d="M247.921591,53.8514618 C248.51718,51.7243582 249.484912,51.7318303 250.077772,53.8491859 L254.357732,69.1347569 L242,75 L247.921591,53.8514618 Z"
              transform="matrix(-1 0 0 1 496.358 0)"
            ></path>
          </g>
          <g transform="matrix(-1 0 0 1 496 216)">
            <path
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__fill-secondary"
              d="M5.9215907,18.8514618 C6.51717971,16.7243582 7.48113835,16.7183512 8.0784093,18.8514618 L14,40 L0,40 L5.9215907,18.8514618 Z"
              transform="matrix(-1 0 0 1 14 0)"
            ></path>
            <path
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__fill-secondary"
              d="M123.136984,25.2363093 C123.613615,23.448945 124.38595,23.4473111 124.863437,25.237887 L126.693756,32.1015868 L120,37 L123.136984,25.2363093 Z"
              transform="matrix(-1 0 0 1 246.694 0)"
            ></path>
            <polyline
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__stroke-secondary"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="13 37 59.5 0 92.5 27"
            ></polyline>
            <polyline
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__stroke-secondary"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="83 17 92.5 9 127 38"
            ></polyline>
          </g>
          <g
            className="slds-illustration__stroke-primary"
            strokeLinecap="round"
            strokeWidth="3"
            transform="translate(67 253)"
          >
            <path
              vectorEffect="non-scaling-stroke"
              d="M0 1.5L450 1.5M284 2L419 61M282 2L65 91M443 1.5L449 1.5"
            ></path>
          </g>
          <g transform="translate(73 163)">
            <polygon
              vectorEffect="non-scaling-stroke"
              fill="#FFF"
              points="20.906 .73 24.63 2.34 27.093 9.155 11.046 19.877 .131 19.877"
              transform="translate(80 22)"
            ></polygon>
            <g
              className="slds-illustration__stroke-secondary"
              strokeLinecap="round"
              strokeWidth="3"
            >
              <path
                vectorEffect="non-scaling-stroke"
                d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13"
              ></path>
              <path
                vectorEffect="non-scaling-stroke"
                d="M83 20.5C84.0558268 16.8461914 86.2227376 14.4572754 89.5007324 13.333252 94.4177246 11.6472168 99.0800781 13.8925781 100.942383 16.1518555 102.804687 18.4111328 103.39502 20.2260742 103.746582 22.1201172 103.980957 23.3828125 104.06543 24.8427734 104 26.5 108.141764 26.3313802 110.918945 27.1647135 112.331543 29 114.040039 31.1936035 114.215332 33.817627 113.593018 35.75 112.970703 37.682373 110.894531 40.5 107 40.5L28 40.5M18 27.5L83.0004985 27.5M0 27.5L8 27.5"
              ></path>
            </g>
          </g>
          <g
            className="slds-illustration__stroke-secondary"
            strokeLinecap="round"
            strokeWidth="3"
            transform="translate(292 139)"
          >
            <path
              vectorEffect="non-scaling-stroke"
              d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13"
            ></path>
            <path
              vectorEffect="non-scaling-stroke"
              d="M83 20.5C84.0558268 16.8461914 86.2227376 14.4572754 89.5007324 13.333252 94.4177246 11.6472168 99.0800781 13.8925781 100.942383 16.1518555 102.804687 18.4111328 103.39502 20.2260742 103.746582 22.1201172 103.980957 23.3828125 104.06543 24.8427734 104 26.5 108.141764 26.3313802 110.918945 27.1647135 112.331543 29 114.040039 31.1936035 114.215332 33.817627 113.593018 35.75 112.970703 37.682373 110.894531 40.5 107 40.5L28 40.5M18 27.5L83.0004985 27.5M0 27.5L8 27.5"
            ></path>
          </g>
          <g transform="translate(204.5 228.5)">
            <path
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__fill-secondary"
              d="M18.9209988,1.95433401 L33.259296,51.443436 C33.5666778,52.5043744 32.9557995,53.613617 31.8948612,53.9209988 C31.7139843,53.9734036 31.5266126,54 31.3382972,54 L2.6617028,54 C1.5571333,54 0.661702805,53.1045695 0.661702805,52 C0.661702805,51.8116846 0.688299176,51.6243129 0.74070397,51.443436 L15.0790012,1.95433401 C15.386383,0.893395645 16.4956256,0.282517358 17.556564,0.589899164 C18.2152102,0.780726338 18.7301717,1.29568777 18.9209988,1.95433401 Z"
            ></path>
            <g
              className="slds-illustration__stroke-primary"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            >
              <polygon points="17 .324 34 54 0 54"></polygon>
              <path
                vectorEffect="non-scaling-stroke"
                d="M17,6.32394366 C17,42.1378022 17,60.4711356 17,61.3239437 C17,60.4711356 17,42.1378022 17,6.32394366 Z"
              ></path>
              <path
                vectorEffect="non-scaling-stroke"
                d="M17,29.3239437 C22.3333333,35.7851611 25,39.1184944 25,39.3239437 C25,39.1184944 22.3333333,35.7851611 17,29.3239437 Z"
                transform="matrix(-1 0 0 1 42 0)"
              ></path>
            </g>
          </g>
          <g transform="translate(439.5 227.5)">
            <path
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__fill-secondary"
              d="M18.9209988,1.95433401 L33.259296,51.443436 C33.5666778,52.5043744 32.9557995,53.613617 31.8948612,53.9209988 C31.7139843,53.9734036 31.5266126,54 31.3382972,54 L2.6617028,54 C1.5571333,54 0.661702805,53.1045695 0.661702805,52 C0.661702805,51.8116846 0.688299176,51.6243129 0.74070397,51.443436 L15.0790012,1.95433401 C15.386383,0.893395645 16.4956256,0.282517358 17.556564,0.589899164 C18.2152102,0.780726338 18.7301717,1.29568777 18.9209988,1.95433401 Z"
            ></path>
            <g
              className="slds-illustration__stroke-primary"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            >
              <polygon
                vectorEffect="non-scaling-stroke"
                points="17 -4.676 34 54 0 54"
              ></polygon>
              <path
                vectorEffect="non-scaling-stroke"
                d="M17,1.32394366 C17,40.3936076 17,60.3936076 17,61.3239437 C17,60.3936076 17,40.3936076 17,1.32394366 Z"
              ></path>
              <path
                vectorEffect="non-scaling-stroke"
                d="M17,29.3239437 C22.3333333,35.7851611 25,39.1184944 25,39.3239437 C25,39.1184944 22.3333333,35.7851611 17,29.3239437 Z"
                transform="matrix(-1 0 0 1 42 0)"
              ></path>
            </g>
          </g>
          <g transform="translate(141.852 213.5)">
            <path
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__fill-secondary"
              d="M25.6478873,0 L50.8997802,86.4391718 C51.2095171,87.4994249 50.6011034,88.6100213 49.5408502,88.9197582 C49.3586621,88.9729817 49.1698252,89 48.980022,89 L2.31575269,89 C1.21118319,89 0.315752686,88.1045695 0.315752686,87 C0.315752686,86.8101968 0.342770948,86.6213599 0.395994453,86.4391718 L25.6478873,0 Z"
              transform="translate(1)"
            ></path>
            <g
              className="slds-illustration__stroke-primary"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              transform="translate(0 2)"
            >
              <polygon
                vectorEffect="non-scaling-stroke"
                points="26.648 0 52.648 87 .648 87"
              ></polygon>
              <path
                vectorEffect="non-scaling-stroke"
                d="M26.6478873 6C26.6478873 64.4972948 26.6478873 94.4972948 26.6478873 96 26.6478873 94.4972948 26.6478873 64.4972948 26.6478873 6zM15.6478873 42C22.9812207 49.078692 26.6478873 52.7453587 26.6478873 53 26.6478873 52.7453587 22.9812207 49.078692 15.6478873 42zM27.6478873 68C36.9812207 57.078692 41.6478873 51.7453587 41.6478873 52 41.6478873 51.7453587 36.9812207 57.078692 27.6478873 68z"
              ></path>
            </g>
          </g>
          <g transform="translate(168 57)">
            <circle
              vectorEffect="non-scaling-stroke"
              cx="64"
              cy="64"
              r="23"
              className="slds-illustration__fill-secondary"
            ></circle>
            <path
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__stroke-secondary"
              strokeDasharray="135 1 1 18"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              d="M64,109 C88.8528137,109 109,88.8528137 109,64 C109,39.1471863 88.8528137,19 64,19 C39.1471863,19 19,39.1471863 19,64 C19,88.8528137 39.1471863,109 64,109 Z"
              transform="rotate(-130 64 64)"
            ></path>
            <path
              vectorEffect="non-scaling-stroke"
              className="slds-illustration__stroke-secondary"
              strokeDasharray="107 10"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              d="M64,97 C82.2253967,97 97,82.2253967 97,64 C97,45.7746033 82.2253967,31 64,31 C45.7746033,31 31,45.7746033 31,64 C31,82.2253967 45.7746033,97 64,97 Z"
              transform="rotate(150 64 64)"
            ></path>
            <g
              className="slds-illustration__stroke-primary"
              strokeWidth="3"
              transform="translate(41 41)"
            >
              <circle
                vectorEffect="non-scaling-stroke"
                cx="23"
                cy="23"
                r="23"
              ></circle>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default IllustrationOpenRoad;
