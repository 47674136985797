import { useEffect, useRef, useState } from "react";
import {
  Card,
  IconSettings,
  Button,
  Icon,
  Spinner,
} from "@salesforce/design-system-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./Build.css";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import SearchBox from "../../components/ps-search-box/SearchBox";
import Record from "../../helpers/recordLayer";
import PsSearchGrid from "../../components/ps-search-grid/PsSearchGrid";
import { EMPTY_TOAST_STATE } from "../../constants";
import ToastComponent from "../../components/toast-component";
import {
  boxes,
  initialSelectedValues,
  searchString,
  sourceOptions,
} from "./components/Helper";
import PsPatternDetailedView from "../../components/ps-pattern-detailed-view/PsPatternDetailedView";
import Divider from "./components/Divider";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import RightSideBar from "./components/RightSideBar";
import useAuthContext from "../../context/useAuthContext";

const Build = () => {
  const { handleLogout } = useAuthContext();
  const location = useLocation();
  const selectedSourceFromUrl = new URLSearchParams(location.search)
    .get("selected")
    ?.split("_")[0];

  const [cmpState, setCmpState] = useState({
    view: "list",
    selectedNames: [],
    selectedRecords: [],
    queryFilter: { query: "" },
    pattern: null,
    showBuildResult: false,
    selectedSource: selectedSourceFromUrl || "buildData",

    xAxis: null,
    yAxis: null,

    // search
    isSearchSubmitted: false,
    searchText: "",

    refreshOnChange: true,
    navigationTreeExpanded: true,

    selectedValues: initialSelectedValues(),
    loading: false,

    parentToChildEvent: {},
  });
  const [toastState, setToastState] = useState(EMPTY_TOAST_STATE);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const cmpWorking = useRef({});
  const navigationTreeRef = useRef(null);
  const searchdivRef = useRef(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmp.init();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    onPageReferenceChange();
  }, [searchParams]);

  const cmp = {
    get: (key) => {
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    onDragStart: function (e, item) {
      // create a drag view to avoid displaying multiple elements
      const dragImage = document.createElement("span");
      dragImage.textContent = item.label;
      dragImage.style.position = "absolute";
      dragImage.style.top = "-9999px";
      dragImage.style.padding = "2px 4px 2px 16px";
      dragImage.style.backgroundColor = "#f5f5f5";
      dragImage.style.border = "1px solid #ccc";
      dragImage.style.borderRadius = "3px";
      dragImage.style.minWidth = "200px";
      document.body.appendChild(dragImage);
      e.dataTransfer.setDragImage(dragImage, 0, 0);

      setTimeout(() => {
        document.body.removeChild(dragImage);
      }, 0);

      e.dataTransfer.setData("item", JSON.stringify(item));
    },

    onDragOver: function (e) {
      e.preventDefault();
    },

    init: function () {
      try {
        cmpWorking.current = { ...cmpState };
        parsePageRef();
      } catch (err) {
        console.error(err.stack);
      }
    },

    tagTitleFromItem: (item) => {
      let title = "";
      item.breadcrumb?.map((breadcrumbItem) => {
        if (breadcrumbItem.name) {
          title = title
            ? title + " -> " + breadcrumbItem.name
            : breadcrumbItem.name;
        }
        return title;
      });
      return title || item.label;
    },

    async tagTitle(recordId, object, sourceId, label, box, boxItemIndex) {
      try {
        if (recordId && !object && !sourceId && !label) {
          return this.tagTitleFromItem(recordId);
        }

        const source =
          sourceOptions.find((s) => s.id === sourceId)?.value ||
          cmp.get("selectedSource");

        let module = "store";
        if (source === "buildTypes") {
          module = "core";
        }

        // to avoid unnecessary api calls
        const selectedValues = cmp.get("selectedValues");
        const selectedBox = `box${box.order}`;
        if (selectedValues[selectedBox].length > 0) {
          const boxItem = selectedValues[selectedBox][boxItemIndex];
          if (boxItem && boxItem.title) {
            return boxItem.title;
          }
        }

        const record = await cmp.getRecord(recordId, object, module);

        let title = label;
        if (record && source === "buildData") {
          if (object === "key") {
            title =
              record.container.source.name +
              " -> " +
              record.container.name +
              " -> " +
              label;
          } else if (object === "container") {
            title = record.source.name + " -> " + label;
          }
        }

        if (record && source === "buildTypes") {
          title = (record.role || record.dataRole.name) + " -> " + record.name;
        }

        if (record && source === "buildChains") {
          if (object === "container") {
            title = record.source.name + " -> " + label;
          } else if (object === "chain") {
            title =
              record.leftContainer.source.name +
              " -> " +
              record.leftContainer.name +
              " - " +
              record.rightContainer.name +
              " -> " +
              label;
          }
        }

        return title;
      } catch (error) {
        console.error(error.stack);
      }
    },

    getRecord: function (recordId, object, module) {
      return new Promise((resolve, reject) => {
        try {
          var onSuccess = function (response) {
            if (response?.length > 0) {
              const record = response[0];
              resolve(record);
            } else {
              reject({});
            }
          };
          var onError = function (response) {
            cmp.checkUser(response);
            reject({});
          };

          Record.getRecord(
            module,
            object,
            recordId,
            {},
            "",
            "GET",
            onSuccess,
            onError
          );
        } catch (err) {
          console.error(err.stack);
          reject({});
        }
      });
    },

    refreshCheckbox: function () {
      return (
        <div style={{ margin: "10px" }}>
          <div className="slds-form-element">
            <div className="slds-form-element__control">
              <div className="slds-checkbox">
                <input
                  type="checkbox"
                  name="options"
                  id="checkbox-unique-id-159"
                  value="checkbox-unique-id-159"
                  onChange={() => cmp.handleRefreshCheckboxChange()}
                  checked={cmpState.refreshOnChange}
                />
                <label
                  className="slds-checkbox__label"
                  htmlFor="checkbox-unique-id-159"
                >
                  <span className="slds-checkbox_faux"></span>
                  <span
                    className="slds-form-element__label"
                    style={{ color: "white" }}
                  >
                    Rebuild as I make changes
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    },

    handleRefreshCheckboxChange: function () {
      const refreshOnChange = cmp.get("refreshOnChange");
      cmp.set("refreshOnChange", !refreshOnChange);

      if (cmp.get("refreshOnChange")) {
        cmp.handleBuild();
      }
    },

    undoRedoRadioButtonGroup: function () {
      return (
        <>
          <div
            className="navigationHeaderButton slds-p-around_medium borderRight"
            onClick={() => cmp.handleUndoRedoChange("back")}
          >
            <Icon
              assistiveText={{ label: "Undo" }}
              category="utility"
              name="undo"
              size="x-small"
              inverse
              title="Undo"
            />
          </div>
          <div
            className="navigationHeaderButton slds-p-around_medium borderRight"
            onClick={() => cmp.handleUndoRedoChange("forward")}
          >
            <Icon
              assistiveText={{ label: "Redo" }}
              category="utility"
              name="redo"
              size="x-small"
              inverse
              title="Redo"
            />
          </div>
        </>
      );
    },

    handleUndoRedoChange: function (item) {
      window.history[item]();
    },

    buildButton: function () {
      return (
        <Button
          label={
            <>Build {cmpState.loading ? <Spinner size="small" /> : null}</>
          }
          disabled={cmpState.refreshOnChange}
          onClick={cmp.handleBuild}
          title="Start building"
        />
      );
    },

    handleBuild: function () {
      updateQueryFilter();
    },

    handleOnDrop: function (e, box) {
      e.preventDefault();
      const colorId = sourceOptions.find(
        (source) => source.value === cmp.get("selectedSource")
      ).id;
      const item = {
        ...JSON.parse(e.dataTransfer.getData("item")),
        color: colorId,
        title: cmp.tagTitleFromItem(JSON.parse(e.dataTransfer.getData("item"))),
      };

      const selectedValues = cmp.get("selectedValues");
      const selectedBox = `box${box.order}`;

      const updatedSelectedValues = {
        ...selectedValues,
        [selectedBox]: selectedValues[selectedBox].some((i) => i.id === item.id)
          ? selectedValues[selectedBox]
          : [...selectedValues[selectedBox], item],
      };
      cmp.set("selectedValues", updatedSelectedValues);

      if (cmp.get("refreshOnChange")) {
        updateQueryFilter();
      }
      navigateSelectedValues();
    },

    onRemove: function (id, selectedBox) {
      const selectedValues = cmp.get("selectedValues");
      const updatedSelectedValues = {
        ...selectedValues,
        [selectedBox]: selectedValues[selectedBox].filter(
          (item) => item.id !== id
        ),
      };
      cmp.set("selectedValues", updatedSelectedValues);

      if (cmp.get("refreshOnChange")) {
        updateQueryFilter();
      }
      navigateSelectedValues();
    },

    removeAllTags: function (selectedBox) {
      const selectedValues = cmp.get("selectedValues");
      const updatedSelectedValues = {
        ...selectedValues,
        [selectedBox]: [],
      };
      cmp.set("selectedValues", updatedSelectedValues);
      if (cmp.get("refreshOnChange")) {
        updateQueryFilter();
      }
      navigateSelectedValues();
    },

    handleDragEnd: function (result, selectedBox) {
      try {
        if (!result.destination) {
          return;
        }

        const items = cmp.get("selectedValues")[selectedBox];
        const newItems = Array.from(items);
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);

        let selectedValues = cmp.get("selectedValues");
        selectedValues[selectedBox] = newItems;
        cmp.set("selectedValues", selectedValues);

        navigateSelectedValues();
        if (cmp.get("refreshOnChange")) {
          updateQueryFilter();
        }
      } catch (error) {
        console.error(error.stack);
      }
    },

    checkUser: function (response) {
      if (response === "No current user") {
        childToParent({ type: "logout" });
      }
    },
  };

  const parsePageRef = async () => {
    try {
      const patternId = new URLSearchParams(location.search).get("pattern");
      // determine view
      var pattern = cmp.get("pattern");
      var showBuildResult = patternId === "result";
      var hasBuildPattern = pattern && !pattern.id;
      var view =
        patternId && (!showBuildResult || hasBuildPattern) ? "detail" : "list";

      if (view === "detail") {
        if (patternId !== "result") {
          var currentId = (cmp.get("pattern") || {}).id;
          if (patternId !== currentId) {
            cmp.set("pattern", { id: patternId });
          }
        }
      }

      // set panel view mode
      // IMPROVEMENT: remove pattern=result from the url when reading it direclty from the URL (on browser refresh), to prevent subsequent navigation issues
      cmp.set("showBuildResult", showBuildResult && hasBuildPattern);
      cmp.set("view", view);

      let selectedValues = cmp.get("selectedValues");
      for (const box of boxes) {
        let boxValues = new URLSearchParams(location.search).get(
          `${box.urlParameter}`
        );
        if (boxValues) {
          boxValues = await Promise.all(
            boxValues.split(",").map(async (value, boxItemIndex) => {
              const [objectAndkey, name] = value.split(":");
              const key = objectAndkey.split("_")[1];
              const config = objectAndkey.split("_")[0]; //value in url is config, not object
              const object = config.toLowerCase();
              const color = name.split("_")[1];
              const label = name.split("_")[0];
              const sourceId = parseInt(color);
              const title =
                object === "source" ||
                object === "agg" ||
                object === "transform" ||
                object === "datarole"
                  ? label
                  : await cmp.tagTitle(
                      key,
                      object,
                      sourceId,
                      label,
                      box,
                      boxItemIndex
                    );

              return {
                id: key,
                label,
                config,
                object,
                color: sourceId,
                title,
              };
            })
          );

          selectedValues[`box${box.order}`] = boxValues;
        } else {
          selectedValues[`box${box.order}`] = [];
        }
        cmp.set("selectedValues", selectedValues);
      }

      if (cmp.get("refreshOnChange")) {
        updateQueryFilter();
      }

      // TODO: scroll position after refresh and removing pattern in the URL after come back from pattern detail page
      // const pattern = new URLSearchParams(location.search).get("pattern");
      // if (pattern && cmp.get("view") === "list") {
      //   searchParams.delete("pattern");
      //   setSearchParams(searchParams);
      // }

      // update selectedNames and NavigationTree
      const selected = (searchParams.get("selected") || "").split("~");
      let selectedNames = [];
      let listFilter = {};
      selected.forEach((name) => {
        if (name) {
          selectedNames.push(name);
          let parsed = Record.parseName(name);
          if (parsed.config !== "root") {
            // listFilter.push(parsed.config + "Id=" + parsed.id);
            listFilter[parsed.config + "Id"] = parsed.id;
          }
        }
      });

      cmp.set("selectedNames", selected);

      // update selectedSource
      const selectedSourceFromUrl = selected[0] && selected[0].split("_")[0];
      if (
        selectedSourceFromUrl &&
        selectedSourceFromUrl !== cmp.get("selectedSource")
      ) {
        cmp.set("selectedSource", selectedSourceFromUrl);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const onPageReferenceChange = function () {
    try {
      parsePageRef();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const navigateSelectedValues = (name) => {
    try {
      const selectedSource = cmp.get("selectedSource");
      const selectedValues = cmp.get("selectedValues");
      const allValuesEmpty = Object.values(selectedValues).every(
        (value) => !value || value.length === 0
      );

      if (allValuesEmpty) {
        navigate(`/Build?selected=${selectedSource}`, { replace: true });
      } else {
        const selectedSourceFromUrl = new URLSearchParams(location.search)
          .get("selected")
          ?.split("_")[0];
        if (cmp.get("selectedSource") !== selectedSourceFromUrl) {
          searchParams.set("selected", selectedSource || selectedSourceFromUrl);
        }

        Object.entries(selectedValues).forEach(([key, value]) => {
          const boxIndex = key.split("box")[1];
          const urlParameter = boxes[boxIndex - 1].urlParameter;
          if (value && value.length > 0) {
            const formattedValues = value.map((item) => {
              const itemId = item.id;
              const itemName = item.label;
              const configName = item.config || item.setting?.config;
              const color = item.color;

              return `${configName}_${itemId}:${itemName}_${color}`;
            });

            if (formattedValues.join(",")) {
              searchParams.set(urlParameter, formattedValues.join(","));
            }
          } else {
            // If value is empty, remove the key from URL
            searchParams.delete(urlParameter);
          }
        });

        let replace = false;

        // when the left selection was changed it wont save the browser stack
        if (name || name === "") {
          replace = true;
        }

        setSearchParams(searchParams, { replace });
      }

      // from childToParent event
      if (name) {
        searchParams.set("selected", name);

        // when the left selection was changed it wont save the browser stack
        setSearchParams(searchParams, { replace: true });
      }
    } catch (error) {
      console.error(error.stack);
    }
  };

  const handleSelectSource = (value) => {
    cmp.set("selectedSource", value);
    navigateSelectedValues();
  };

  const syncItem = (selected, event) => {
    // default sections used when navigating from an external component
    var sections = ["types", "data"];
    var defaultSections = {
      root: "types",
      dataRole: "types",
      dataType: "types",
      source: "data",
      container: "data",
      key: "data",
    };

    // create item
    var item = Record.itemFromEvent(event, defaultSections);

    // don't update if the item is already selected, and the new event has isLoading
    // the item would be stuck in loading, because no navigation is done when selecting an already selected item
    if (!item.hasDetails && selected.find((i) => i.name === item.name)) {
      return selected;
    }

    // update / insert / delete new item
    selected = selected.filter((i) => i.section !== item.section);
    if (item.id) {
      selected.push(item);
    }

    // sort in order of the sections
    var sectionMap = selected.reduce((obj, i) => {
      obj[i.section] = i;
      return obj;
    }, {});
    return sections.map((i) => sectionMap[i]).filter((i) => i != null);
  };

  // navigate from event
  const handleNavigationEvent = (event) => {
    try {
      // navigate to different tab, e.g., when using the buttons in connector status section
      var tab = event.tab || "";
      if (["build"].includes(tab.toLowerCase())) {
        navigate("/Build");
        return;
      }

      // sync records
      var selectedRecords = cmp.get("selectedRecords") || [];
      selectedRecords = syncItem(selectedRecords, event);
      cmp.set("selectedRecords", selectedRecords);

      // scroll only
      var source = event.source;
      if (["change", "closeSearch"].includes(source)) {
        var scroll = event.scroll;
        // var scroller = document.getElementById("left");
        var scroller = navigationTreeRef.current;
        var searchInput = searchdivRef.current; // document.getElementById("searchdiv");
        // update scroll position after rendering, so that rendered sizes are available
        if (searchInput && scroller && scroll != null) {
          var timer = setTimeout(() => {
            var top = searchInput.offsetTop + searchInput.offsetHeight;
            scroller.scrollTop = scroll * (scroller.scrollHeight - top);
          }, 0);
        }
      }

      // navigate
      if (["record", "grid", "tree"].includes(source)) {
        var selectedNames = cmp.get("selectedNames") || [];
        var oldNames = selectedNames.join("~");
        var newNames = selectedRecords.map((item) => item.name).join("~");
        if (newNames !== oldNames) {
          navigateSelectedValues(newNames);
        }

        // navigate selected values
        const name = event.section + "_" + event.obj + "_" + event.id;
        navigateSelectedValues(name);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleReload = () => {
    cmp.set("parentToChildEvent", {
      action: "reload",
    });
  };

  const navigatePattern = (patternId) => {
    const currentPattern = searchParams.get("pattern");
    if (patternId === undefined) {
      if (currentPattern !== null) {
        searchParams.delete("pattern");
        setSearchParams(searchParams);
      } else {
        //Force reload
        onPageReferenceChange();
      }
    } else {
      if (
        currentPattern !== patternId ||
        (patternId === "result" && currentPattern === "result")
      ) {
        searchParams.set("pattern", patternId);
        setSearchParams(searchParams);

        // After refershing the page on the detail view it will go back to search view, when you click the chart again you should go back to detail view
        if (patternId === "result" && currentPattern === "result") {
          cmp.set("view", "detail");
        }
      } else {
        //Force reload
        onPageReferenceChange();
      }
    }
  };

  const handleDataCompEvent = (event) => {
    try {
      var data = event.data;
      if (data.action === "viewDetails") {
        var pattern = data.pattern;
        var patternId = (pattern || {}).id;
        var currentId = (cmp.get("pattern") || {}).id;
        cmp.set("pattern", pattern);
        if (patternId) {
          if (patternId !== currentId || cmp.get("view") !== "detail") {
            navigatePattern(patternId);
          }
        } else if (!cmp.get("showBuildResult")) {
          navigatePattern("result");
        }
      } else if (data.action === "close") {
        navigatePattern(undefined);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const childToParent = (event) => {
    try {
      if (event.type === "navigation") {
        handleNavigationEvent(event);
      } else if (event.type === "dataCompEvent") {
        handleDataCompEvent(event);
      } else if (event.type === "reload") {
        handleReload();
      } else if (event.type === "logout") {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateQueryFilter = () => {
    try {
      let selectedValues = cmp.get("selectedValues");
      let query = "";

      const selectedValuesArray = Object.entries(selectedValues).map(
        ([key, value]) => ({ [key]: value })
      );

      selectedValuesArray.map((selectedValue, index) => {
        const value = Object.values(selectedValue)[0];

        value.length > 0 &&
          value.forEach((item, boxIndex) => {
            query = query
              ? query +
                " " +
                searchString(index, boxIndex) +
                "${" +
                // (item.object || item.setting?.object) +
                (item.config || item.setting?.config) +
                ":" +
                item.id +
                "}"
              : "${" +
                // (item.object || item.setting?.object) +
                (item.config || item.setting?.config) +
                ":" +
                item.id +
                "}";
          });
      });

      cmp.set("queryFilter", { query: query });
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleSearchTextChange = (value) => {
    cmp.set("searchText", value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    cmp.set("isSearchSubmitted", true);
  };

  const handleExpandCollapse = () => {
    const navigationTreeExpanded = cmp.get("navigationTreeExpanded");
    cmp.set("navigationTreeExpanded", !navigationTreeExpanded);
  };

  return (
    <IconSettings iconPath="/assets/icons">
      <div className="build">
        <PsNavigationHeader
          view={cmpState.view}
          childToParent={childToParent}
          parentCmp={cmp}
        />
        <div className="tab-content slds-p-around_medium">
          {/* <!-- using slds-hide to prevent rebuilding views that need to keep their state --> */}
          <div
            className={
              cmpState.view === "list"
                ? "left slds-m-right_medium"
                : "slds-hide"
            }
            style={{
              display: cmpState.navigationTreeExpanded ? "block" : "none",
            }}
          >
            {/* <!-- navigation tree --> */}
            <article className="slds-card">
              <div
                className="slds-card__header slds-grid"
                style={{
                  margin: "-5px 0 -2px -5px",
                }}
              >
                <header className="slds-media slds-media_center slds-has-flexi-truncate">
                  <div className="slds-media__body">
                    <h2 className="slds-card__header-title">Browse</h2>
                  </div>

                  {cmpState.navigationTreeExpanded && (
                    <div
                      className="slds-no-flex"
                      style={{ marginRight: "-8px" }}
                    >
                      <button
                        className="slds-button slds-button_icon slds-button_icon-brand slds-button_icon-x-small"
                        title="Collapse"
                        style={{ borderRadius: "50%" }}
                        onClick={handleExpandCollapse}
                      >
                        <svg className="slds-button__icon" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#jump_to_left"></use>
                        </svg>
                      </button>
                    </div>
                  )}
                </header>
              </div>

              <div ref={searchdivRef} className="slds-p-around_x-small">
                <SearchBox
                  searchText={cmpState.searchText}
                  loading={cmpState.navigationLoading}
                  handleSearchTextChange={handleSearchTextChange}
                  handleSearchSubmit={handleSearchSubmit}
                />
              </div>

              <div className="slds-p-around_x-small">
                <div className="slds-form-element">
                  <div className="slds-form-element__control">
                    <div className="slds-select_container">
                      <select
                        label="Source"
                        className="slds-select"
                        name="Source"
                        value={cmpState.selectedSource}
                        id="select-01"
                        onChange={(e) => handleSelectSource(e.target.value)}
                      >
                        {sourceOptions.map((item, idx) => (
                          <option key={idx} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="slds-p-horizontal_x-small"
                ref={navigationTreeRef}
                style={{
                  overflowY: "auto",
                  height: "calc(100vh - 15.5rem)",
                }}
              >
                <PsNavigationTree
                  multiSelect={false}
                  sections={[
                    "buildTransforms",
                    "buildAggs",
                    "buildTypes",
                    "buildData",
                    "buildChains",
                  ]}
                  selected={cmpState.selectedNames}
                  searchText={cmpState.searchText}
                  isLoading={cmpState.navigationLoading}
                  parentCmp={cmp}
                  childToParent={childToParent}
                  isSearchSubmitted={cmpState.isSearchSubmitted}
                  parentToChildEvent={cmpState.parentToChildEvent}
                  selectedSection={cmpState.selectedSource}
                  pageName="build"
                  draggable={true}
                />
              </div>
            </article>
          </div>

          {/* <!-- right panel view --> */}

          {cmpState.view === "list" && (
            <>
              {/* divider */}
              {!cmpState.navigationTreeExpanded && (
                <Divider handleExpandCollapse={handleExpandCollapse} />
              )}
              <RightSideBar parentCmp={cmp} cmpState={cmpState} />
            </>
          )}
          {/* content */}
          <div className={cmpState.view === "list" ? "right " : "slds-hide"}>
            <PsSetupStatus
              id="setupStatus"
              title="Build"
              tagLine="Drag items to create the graphs you want to see."
              parentToChildEvent={cmpState.parentToChildEvent}
              parentCmp={cmp}
            />

            <Card className="PsRecoredGrid slds-scrollable" heading="">
              <div className="slds-is-relative" style={{ marginTop: "-40px" }}>
                <div
                  className="slds-p-around_medium slds-illustration slds-illustration_large"
                  aria-hidden="true"
                >
                  <div
                    className={`${
                      cmpState.queryFilter ? "results" : "slds-hide"
                    }`}
                  >
                    <PsSearchGrid
                      view="grid"
                      queryFilter={cmpState.queryFilter}
                      maxRecords="12"
                      childToParent={childToParent}
                      setToastState={setToastState}
                      parentToChildEvent={cmpState.parentToChildEvent}
                      parentCmp={cmp}
                      emptyLine="Drag items to create the graphs you want to see"
                      navigationTreeExpanded={cmpState.navigationTreeExpanded}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>

          {/* Detail View */}
          {cmpState.view === "detail" && (
            <>
              <PsPatternDetailedView
                pattern={cmpState.pattern}
                childToParent={childToParent}
                setToastState={setToastState}
                parentToChildEvent={cmpState.parentToChildEvent}
                parentCmp={cmp}
              />
            </>
          )}
        </div>

        {toastState.details ? (
          <ToastComponent
            close={() => setToastState(EMPTY_TOAST_STATE)}
            details={toastState.details}
            variant={toastState.variant}
            heading={toastState.heading}
          />
        ) : null}
      </div>
    </IconSettings>
  );
};

export default Build;
