import { StopOutlined } from "@ant-design/icons";

export const sourceOptions = [
  { id: 1, label: "Data", value: "buildData", color: "green" },
  { id: 2, label: "Types", value: "buildTypes", color: "blue" },
  { id: 5, label: "Paths", value: "buildChains", color: "yellow" },
  { id: 3, label: "Transformations", value: "buildTransforms", color: "red" },
  { id: 4, label: "Aggregations", value: "buildAggs", color: "purple" },
];

export const boxes = [
  {
    order: 1,
    name: "Value",
    // Prefix in search string:
    searchString: "",
    urlParameter: "val",
    info: "The value or values to be shown",
  },

  {
    order: 2,
    name: "Breakdown",
    searchString: "by",
    urlParameter: "xst",
    info: "Break down the values",
    // Prefix in search string: by
    // Condition: enable only when box 1 is filled out
  },

  {
    order: 3,
    name: "Detail",
    searchString: "and",
    urlParameter: "xnd",
    info: "Break down the values further on a separate axis",
    // Prefix in search string: and
    // Condition: enable only when box 1 and 2 are filled out
  },

  // {
  //   order: 4,
  //   name: "Filters",
  //   searchString: "where",
  //   urlParameter: "flt",
  //   info: 'Filter the data'
  //   // Prefix in search string: where
  //   // Condition: enable only when box 1 is filled out
  // },

  {
    order: 4,
    name: "Value Grouping",
    searchString: "per",
    urlParameter: "qsh",
    info: "Group and aggregate values before breaking down",
    // Prefix in search string: per
    // Condition: enable only when box 1 is filled out
  },

  {
    order: 5,
    name: "Value Select",
    searchString: "at",
    urlParameter: "acr",
    info: "Specify how to select a single row from each group in the Value Grouping",
    // Prefix in search string: at
    // Condition: enable only when box 1 is filled out
  },

  // We will make Order available once it is fully supported for search
  // {
  //   order: 6,
  //   name: "Order",
  //   searchString: "sort",
  //   urlParameter: "srt",
  //   info: "Sort the results",
  //   // Prefix in search string: sort
  //   // Condition: enable only when box 1 is filled out
  // },
];

export const initialSelectedValues = () => {
  const selectedValues = {};
  boxes.forEach((box) => {
    selectedValues[`box${box.order}`] = [];
  });
  return selectedValues;
};

export const searchString = (boxesIndex, boxIndex) => {
  return boxes[boxesIndex].searchString && boxIndex === 0
    ? boxes[boxesIndex].searchString + " "
    : "";
};

export const isDropzoneDisabled = (order, selectedValues) => {
  if (
    ((order === 2 ||
      order === 4 ||
      order === 5 ||
      order === 6 ||
      order === 7) &&
      selectedValues["box1"].length < 1) ||
    (order === 3 &&
      (selectedValues["box2"].length < 1 || selectedValues["box1"].length < 1))
  ) {
    return true;
  } else {
    return false;
  }
};

export const dragContent = (items, box) => {
  let content = "Drag from selection menu on the left";
  const order = parseInt(box.replace("box", ""));

  if (
    (order === 2 || order === 4 || order === 5 || order === 6 || order === 7) &&
    items["box1"].length < 1
  ) {
    content = "Populate the Value box to enable this box";
  } else if (
    order === 3 &&
    (items["box2"].length < 1 || items["box1"].length < 1)
  ) {
    content = "Populate the Value and Breakdown boxes to enable this box";
  }

  return (
    <div style={{ color: "#aaa", marginTop: "7px" }}>
      {isDropzoneDisabled(order, items) && (
        <StopOutlined style={{ fontSize: "18px" }} />
      )}

      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: "12px",
        }}
      >
        {items[box].length === 0 && content}
      </p>
    </div>
  );
};

export const clearButton = (selectedBox, removeAllTags) => (
  <button
    className="slds-button slds-button_icon slds-input__icon slds-input__icon_right"
    title="Clear"
    onClick={() => removeAllTags(selectedBox)}
  >
    <svg className="slds-button__icon slds-icon-text-light" aria-hidden="true">
      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clear"></use>
    </svg>
    <span className="slds-assistive-text">Clear</span>
  </button>
);
