import { Icon, IconSettings } from "@salesforce/design-system-react";
import CheckIcon from "../../ui/CheckIcon";

const CheckAndCloseIcons = ({ selectedItem }) => {
  return (
    <IconSettings iconPath="/assets/icons">
      {selectedItem ? (
        <CheckIcon />
      ) : (
        <Icon
          assistiveText={{ label: "No" }}
          category="utility"
          name="close"
          size="x-small"
          title="No"
        />
      )}
    </IconSettings>
  );
};

export default CheckAndCloseIcons;
