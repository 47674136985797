import { Spinner } from "@salesforce/design-system-react";
import { useEffect, useMemo, useRef, useState } from "react";

import WarningIcon from "./components/WarningIcon";
import Record from "../../helpers/recordLayer";
import { toastErrorMessage } from "../../helpers";
import useAccountSettingsContext from "../../context/useAccountSettingsContext";
import RecordConstants from "../../constants/RecordConstants";

const PsPatternExplain = (props) => {
  const { DEBUG } = RecordConstants.ACCOUNT_SETTINGS_GENERATIVE_AI;
  const [cmpState, setCmpState] = useState({
    // <!-- Parameters -->
    // pattern: "",
    // data: "",

    // <!-- Debug -->
    debugPrompt: "",

    // <!-- User Interface -->
    loading: false,
    // explainContent: "",
  });

  const { account } = useAccountSettingsContext();

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
  }, []);

  // we don't need this we are updating pattern in the patternChart
  //   useEffect(() => {
  //     cmp.handlePatternChange();
  //   }, [props.pattern]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
  }, []);

  const debug = useMemo(() => {
    if (!account) return false;
    if (
      account?.settings?.generativeAI === DEBUG.value &&
      account?.limits?.generativeAI === DEBUG.value
    )
      return true;
    return false;
  }, [account]);

  const explainContent = props.pattern?.explainContent || "";

  const cmp = {
    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    handleExplain: function () {
      try {
        cmp.getExplain();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleClear: function () {
      try {
        cmp.clearExplain();
      } catch (err) {
        console.error(err.stack);
      }
    },

    // we don't need this we are updating pattern in the patternChart
    // handlePatternChange: function () {
    //   try {
    //     cmp.parsePattern();
    //   } catch (err) {
    //     console.error(err.stack);
    //   }
    // },

    // Helper
    getExplain: function () {
      try {
        const data = props.data;
        var pattern = props.pattern; // cmp.get("pattern") || {};
        if (!data) {
          return;
        }

        var onSuccess = function (response) {
          const explainContent = response[0];
          try {
            pattern = Object.assign({}, pattern); // copy to prevent changing input value directly
            pattern.explainContent = explainContent;
            cmp.notifyPatternUpdate(pattern);
            cmp.unsetLoading();
          } catch (err) {
            console.error(err.stack);
            cmp.setError();
            cmp.unsetLoading();
          }
        };

        var onError = function (response) {
          if (response === "No current user") {
            cmp.childToParent({ type: "logout" });
          }
          props.parentCmp.setToastState(
            "error",
            "Error",
            toastErrorMessage(response)
          );
          cmp.set("explainContent", "Error while processing.");
          // leave the underlying pattern.explainContent in place
          cmp.unsetLoading();
        };
        cmp.setLoading(cmp);
        var body = { data: data, plotOptions: pattern.options };
        if (debug) {
          body.debug = true;
          body.prompt = cmp.prepJson(cmp.get("debugPrompt"));
        }
        var bodyString = JSON.stringify(body);
        Record.getRecord(
          "present",
          "explain",
          "",
          {},
          bodyString,
          "PUT",
          onSuccess,
          onError
        );
      } catch (err) {
        console.error(err.stack);
        cmp.unsetLoading();
      }
    },

    setLoading: function () {
      cmp.set("loading", true);
    },

    unsetLoading: function () {
      cmp.set("loading", false);
    },
    setError: function (type) {
      // cmp.set("v.loading", true);
      // cmp.set("v.errorType", type);
      // // reset plot data
      // cmp.set("v.plotJSON", null);
      // this.setMode(cmp, "error");
    },

    notifyPatternUpdate: function (pattern) {
      let event = new Event("dataCompEvent");
      event.data = { action: "update", pattern: pattern, inplace: true };
      cmp.childToParent(event);
    },

    clearExplain: function () {
      var pattern = props.pattern;
      pattern = Object.assign({}, pattern); // copy to prevent changing input value directly
      pattern.explainContent = null;
      cmp.notifyPatternUpdate(pattern);
    },

    prepJson: function (text) {
      // escape undesired JSON control characters before sending to the API
      text = JSON.stringify(text || "");
      text = text.substring(1, text.length - 1);
      return text;
    },

    // we don't need this we are updating pattern in the patternChart
    // parsePattern: function () {
    //   var pattern = props.pattern || {};
    //   var explainContent = pattern.explainContent;
    //   cmp.set("explainContent", explainContent);
    // },

    //Functions
    childToParent: (event) => {
      // handleEvent(event);
      props.handleEvent(event);
    },
  };

  return (
    <div className="slds-is-relative">
      {cmpState.loading && <Spinner assistiveText={{ label: "Loading" }} />}

      <div className="slds-m-bottom_x-small">
        {/* <lightning:formattedRichText value="{!v.explainContent}"/> */}
        <p>
          {explainContent} {explainContent ? <WarningIcon /> : null}
        </p>
        {debug && (
          <>
            {/* <lightning:textarea aura:id="prompt" label="GPT Prompt" value="{!v.debugPrompt}"/> */}
            {/* <Textarea
              id="unique-id-1"
              label="Textarea Label"
              value="This description is generated by an AI with limited context and may not correctly reflect your data or apply to your siutation."
              // disabled
            /> */}
            <div className="slds-form-element">
              <label
                className="slds-form-element__label"
                htmlFor="textarea-id-01"
              >
                GPT Prompt
              </label>
              <div className="slds-form-element__control slds-border_bottom">
                <div className="slds-form-element__static">
                  <p>
                    {cmpState.debugPrompt}
                    <WarningIcon />
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="slds-grid slds-gutters_xx-small">
        <div className="slds-col slds-size_1-of-2">
          <button
            type="submit"
            className={
              explainContent
                ? "slds-button slds-button_neutral slds-button_stretch"
                : "slds-button slds-button_brand slds-button_stretch"
            }
            onClick={cmp.handleExplain}
            label="Explain"
            title="Retrieve explanation"
          >
            Explain
          </button>
        </div>
        {explainContent && (
          <div className="slds-col slds-size_1-of-2">
            <button
              type="submit"
              className="slds-button slds-button_neutral slds-button_stretch"
              onClick={cmp.handleClear}
              label="Clear"
              title="Clear explanation"
            >
              Clear
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PsPatternExplain;
