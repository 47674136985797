import { useEffect, useRef, useState } from "react";

import { RECORD_COLUMNS } from "./components/Helper";
import Record from "../../helpers/recordLayer";
import "./PsSuggestionList.css";
import Event from "../../helpers/event";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import useWindowSize from "../../hooks/useWindowSize";

const PsSuggestionList = (props) => {
  const [cmpState, setCmpState] = useState({
    maxRecords: props.maxRecords || 12,
    changeView: true,
    showLoadMore: false,
    suggestions: [],
    loading: true,

    //
    orderDirection: "asc",

    // <!-- interface -->
    recordLabel: "Suggestion",
    recordLabelPlural: "Suggestions",
    title: "",
    header: "", //type="Aura.Component[]"/> <!-- extra content at top-->
    showTitle: props.showTitle === undefined ? true : props.showTitle,
    showFooter: true,
    tagLine: props.tagLine || "",
    emptyLine: "No suggestions available",
    recordModule: "relate",
    recordObject: "suggestion",
    gridComponent: "PsSuggestionTile", //<!-- name of the grid component -->
    cardActions: props.cardActions || "", // type="Aura.Component[]"/>
    emptyCallToAction: props.emptyCallToAction || "", // type="Aura.Component[]"/>
    gridItems: "", // type="Aura.Component[]"/>
    viewOptions: [
      { label: "Table", value: "table" },
      { label: "Grid", value: "grid" },
    ],

    showEdit: false, // <!-- whether to show the 'Edit' button -->
    view: props.view || "grid", //<!-- table vs grid -->
    itemView: "grid", // <!-- send to each grid item -->
    isDragMode: false,
    footer: "", // type="Aura.Component[]"/> <!-- add modal dialogs and other extra content -->

    draggedStart: "",
    draggedIndex: "",

    // <!-- querying -->
    parentId: props.parentId || "",
    parentPrefix: "",
    queryFilter: props.queryFilter || {}, //does't do antything, because it is set in useEffect
    orderBy: props.orderBy || "name",

    lastValue: "",
    lastId: "",

    hasMore: true,

    // <!-- records -->
    mode: props.mode || "init", //<!-- init, empty, view, error -->
    recordColumns: RECORD_COLUMNS,
    recordList: [],
    recordDefaultSortDirection: "desc",

    // new
    gridComponents: [],
    psRecordGridWidth: 1535,
  });

  const cmpWorking = useRef({});
  const psRecordGridRef = useRef(null);

  const { width } = useWindowSize();

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    // cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    PsRecordGrid.handlePsRecordGridWidth(cmp, psRecordGridRef);
  }, [width]);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }

    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  const cmp = {
    // --- SuggestionListController.js ---

    // init: function () {
    //   PsRecordGrid.setRecordColumns(cmp);
    // },

    afterScriptsLoaded: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      try {
        var maxRecords = cmp.get("maxRecords");
        var numRecords = !maxRecords
          ? 0
          : Math.max(cmp.get("recordList").length, maxRecords);
        PsRecordGrid.reset(cmp);
        PsRecordGrid.getRecords(cmp, numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    //   handleReset: function(cmp, event, helper) {
    //     try {
    //         var numRecords = cmp.get('v.maxRecords');
    //         helper.reset(cmp);
    //         helper.getRecords(cmp, numRecords);
    //     } catch (err) {
    //         console.error(err.stack);
    //     }
    // },

    // handleLoadMore: function(cmp, event, helper) {
    //     try {
    //         var numRecords = cmp.get('v.maxRecords');
    //         helper.getRecords(cmp, numRecords);
    //     } catch (err) {
    //         console.error(err.stack);
    //     }
    // },

    handleRecordRowAction: function (row) {
      try {
        const parentId = null;
        // TEMP idWithoutIndex:
        const rowId = row.id.substring(0, row.id.lastIndexOf("-"));

        switch (row.action.name) {
          case "question":
            // send two navigation events; one for KeyId and DataRoleId
            // the first one has source='change' to prevent changing the url already, which would break the 'back' button functionality
            this.notifyNavigation(parentId, "store", "key", rowId, "change");
            this.notifyNavigation(
              parentId,
              "core",
              "dataRole",
              (row.dataRole || {}).id,
              "grid"
            );
            break;
          case "key":
            this.notifyNavigation(parentId, "store", "key", rowId, "grid");
            break;
          case "dataRole":
            this.notifyNavigation(
              parentId,
              "core",
              "dataRole",
              (row.dataRole || {}).id,
              "grid"
            );
            break;
          default:
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- SuggestionListHelper.js ---

    RECORD_COLUMNS: RECORD_COLUMNS,

    parseResponse: function (response) {
      return response.map(
        ({ id, name, container, dataRole, relevance, question }) => ({
          id,
          name,
          source: container.source.name,
          container: container.name,
          dataRoleName: dataRole.name,
          dataRole: dataRole,
          relevance,
          question,
          questionPlain: Record.removeMarkup(question),
          questionMarkup: Record.markupToHtml(question),
          title: this.getTitle(id, name, container),
        })
      );
    },

    getTitle: function (id, name, container) {
      var source = container.source || {};
      var breadcrumb = [
        { id: source.id, name: source.name },
        { id: container.id, name: container.name },
        { id, name },
      ];
      return breadcrumb
        .map((item) => item.name)
        .filter((item) => item != null)
        .join("->");
    },

    notifyNavigation: function (parentId, module, object, id) {
      let navigationEvent = Event.createEvent("navigation");
      navigationEvent = {
        ...navigationEvent,
        parentId,
        module,
        obj: object,
        id,
        source: "grid",
      };
      props.childToParent(navigationEvent);
    },

    // --- New functions ---
    get: (key) => {
      if (props[key]) return props[key];

      return cmpWorking.current[key];
    },

    set: (key, value) => {
      let newValue = value;
      // TEMP To avoid key conflicts and provide unique identifiers for each item:
      if (key === "recordList" && value?.length > 0) {
        newValue = value.map((record, index) => ({
          ...record,
          id: `${record.id}-${index}`,
        }));
      }

      cmpWorking.current[key] = newValue;
      setCmpState((prev) => ({ ...prev, [key]: newValue }));
    },

    render: () => {
      setCmpState({ ...cmpWorking.current });
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    childToParent: (event) => {
      handleEvent(event);
    },

    footer: function (cmpState) {
      return (
        <div>
          {!cmpState.loading && cmpState.mode === "view" && (
            <div>
              {cmpState.hasMore && (
                <div>
                  {/* <ui:message class="slds-m-around_x-small" title="" severity="info" closable="false">
                    Don't see what you are looking for? Adjust filters, or edit suggested graphs.
                  </ui:message>             */}
                </div>
              )}
              {!cmpState.hasMore && (
                <div>
                  {/* <ui:message class="slds-m-around_x-small" title="" severity="info" closable="false">
                      Don't see what you are looking for? Adjust filters, edit suggested graphs, or use <a onclick="{!c.handleGoToSearch}">Search</a> to build your own graphs.
                  </ui:message> */}
                </div>
              )}
            </div>
          )}
        </div>
      );
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },
  };

  const handleEvent = (event) => {
    let stopPropagation = false;

    if (!stopPropagation) {
      props.childToParent(event);
    }
  };

  return (
    <div
      ref={psRecordGridRef}
      style={{
        height:
          cmpState.showEmptyCallToAction && cmpState.mode === "empty"
            ? "auto"
            : "100%",
      }}
    >
      {PsRecordGrid.render(cmp, cmpState)}
    </div>
  );
};

export default PsSuggestionList;
