import { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";

import { Chart } from "chart.js";
import "chartjs-chart-matrix";
import * as ChartGeo from "chartjs-chart-geo";
import "chartjs-plugin-stacked100";
import Card from "@salesforce/design-system-react/components/card";
import Spinner from "@salesforce/design-system-react/components/spinner";
import Record from "../../helpers/recordLayer.js";
import Event from "../../helpers/event.js";
import { Icon } from "@salesforce/design-system-react";

import "./PsPatternChart.css";

import HeaderActionsIcons from "./components/HeaderActionsIcons.js";
import PlotOptions from "./components/PlotOptions.js";
import Filters from "./components/Filters.js";
import Details from "./components/Details.js";
import Description from "./components/Description.js";
import {
  CARTESIAN_CHART_TYPES,
  CIRCULAR_CHART_TYPES,
  GEO_CHART_TYPES,
} from "../../constants/index.js";
import PatternEdit from "./components/PatternEdit.js";
import PatternSave from "./components/PatternSave.js";
import ToastComponent from "../toast-component/index.js";
import { cleanInput } from "./components/Helper.js";
import DataTable from "./components/DataTable.js";
import { formattedBlueText, toastErrorMessage } from "../../helpers/index.js";
import useAccountSettingsContext from "../../context/useAccountSettingsContext.js";
import PsErrorBoundary from "../ps-error-boundary/PsErrorBoundary.js";
import RecordConstants from "../../constants/RecordConstants.js";

// function handleLikeClick() {}
// function onAxisZoneMouseMove() {}
// function onAxisZoneMouseLeave() {}
// function onTooltipClick() {}
// function toggleSection() {}

const chartAxesDetailsExample = {
  xAxis: {},
  yAxis: {},
  legend: {},
};

// Props:
// <aura:attribute name="view" access="public" type="String" default="grid"/>     <!-- 'grid', 'details', 'drag', 'embeddedCompact', embeddedDetails' -->

function PsPatternChart(props) {
  // const [searchParams, setSearchParams] = useSearchParams();
  const [cmpState, setCmpState] = useState({
    // <!-- parameters-->
    patternDescription: "",
    //PROPS >  view" access="public" type="String" default="grid"/>     <!-- 'grid', 'details', 'drag', 'embeddedCompact', embeddedDetails' -->
    //PROPS? record: {},
    order: 0,
    componentContextFields: {}, // <!-- input values from embedded component -->

    // <!-- record -->
    processed: "",
    plotJSON: "",
    plotData: {},

    // <!-- UI -->
    loading: true,
    mode: "init", //<!-- init, new, view, edit, error -->
    errorType: "",
    hasActiveFilters: true,
    droppedOutliers: true,
    droppedMissing: true,
    namePlain: "",
    nameMarkup: "",
    title: "",
    relevance: 0,
    relevanceType: "relevanceType",
    showDetails: false,
    showFilters: true,

    plotTypeOptions: [],
    plotTypeSelected: "bar",
    showPlotTypes: false,

    showStackCheckbox: false,
    stackCheckbox: false,

    showStack100Checkbox: false,
    stack100Checkbox: false,

    showSortCheckbox: false,
    sortCheckbox: false,
    selectedAxis: {},
    saveModalIsOpen: false,
    editModalIsOpen: false,

    dataTableKeyField: "", // <!-- default to empty string to prevent error with keyFiels not valid in datatable -->
    dataTableColumns: [],
    dataTableRecords: [],

    // <!-- axes tooltips -->
    chartAxesInputJSON: "",
    chartAxesDetails: chartAxesDetailsExample, //TEMP
    tooltipsSet: false,
    //////
    showSectionDescription: true,
    showSectionPlotOptions: true,
    showSectionFilters: true,
    showSectionDetails: false,

    isNewRecords: false, // Indicates whether to enable tooltip for new records after saving
  });
  const { account, hasGenerativeAIAccess } = useAccountSettingsContext();

  const emptyToastState = {
    variant: "",
    heading: "",
    details: "",
  };
  const [toastState, setToastState] = useState(emptyToastState);

  const cmpWorking = useRef({});

  // const [chartState, setChartState] = useState({});
  const cmpChart = useRef();

  const isFirstRender = useRef(true);

  // References below are used to get access to UI elements
  const chartDetailRef = useRef(null);
  const chartGridRef = useRef(null);

  const xAxisZoneRef = useRef(null);
  const yAxisZoneRef = useRef(null);
  const legendZoneRef = useRef(null);
  const xAxisZoneTooltipRef = useRef(null);
  const yAxisZoneTooltipRef = useRef(null);
  const legendZoneTooltipRef = useRef(null);

  const chartAxesInputJSONRef = useRef(null);

  //init - only runs first time
  useEffect(() => {
    cmpWorking.current = { ...cmpState };

    //do some init stuff
    cmpWorking.current.pattern = props.pattern;
    onRender();
    if (props.record) {
      getRecord(false);
    }
  }, []);

  // Memoizes `generativeAIExplainEnabled` to recompute only when `account` changes, optimizing performance.
  const generativeAIExplainEnabled = useMemo(() => {
    if (!account) return false;
    const { DATA } = RecordConstants.ACCOUNT_SETTINGS_GENERATIVE_AI;
    const setGenAI = account?.settings?.generativeAI;
    const limGenAI = account?.limits?.generativeAI;
    if (
      hasGenerativeAIAccess(setGenAI, DATA.value) &&
      hasGenerativeAIAccess(limGenAI, DATA.value) &&
      account?.settings?.enableGenerativeAIExplain
    )
      return true;
    return false;
  }, [account]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    handlePatternChange();
  }, [props.record]);

  const cmp = {
    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    checkUser: function (response) {
      if (response === "No current user") {
        props.childToParent({ type: "logout" });
      }
    },

    setToastState: function (variant, heading, details) {
      setToastState({ variant, heading, details });
    },
  };

  // handle for this, so call each time?
  const onRender = () => {
    // workaround for chart not rendering on component rerender (depends on ChartJs setting style.display, and may break for future versions in ChartJS)
    var chart = cmpWorking.current.plotJSON;
    if (!chart) {
      return;
    }

    var chartElement;

    if (props.view === "details") {
      chartElement = chartDetailRef.current;
    } else {
      chartElement = chartGridRef.current;
    }
    if (!chartElement) {
      return;
    }

    if (!chartElement.style.display) {
      var timer = setTimeout(() => {
        // rerender in separate thread, otherwise the screen may freeze while plotting the patterns
        chart = JSON.parse(chart);
        plotChart(chart);
      }, 0);
    }
  };

  const toggleSection = (item) => {
    setCmpState((prevCmpState) => ({
      ...prevCmpState,
      [item]: !prevCmpState[item],
    }));
  };

  const toggleEditModal = () => {
    cmp.set("editModalIsOpen", !cmpWorking.current.editModalIsOpen);
  };

  // const toggleModal = (modalToggle) => {
  //   cmp.set(modalToggle, !cmp.get(modalToggle));
  // };

  //handler for change to record
  const handlePatternChange = () => {
    resetPattern();
    getRecord(false);
  };

  const handleCloseClick = () => {
    try {
      var event = Event.createEvent("dataCompEvent");
      event.data = { action: "close" };
      props.childToParent(event);
    } catch (err) {
      console.error(err.stack);
    }
  };

  // button in gridview, to navigate to detailedView - handled in ExploreTab by dataCompChange
  const handleViewDetails = () => {
    if (props.itemView === "drag") {
      return;
    }

    var pattern = cmpState.processed || props.record;
    var event = Event.createEvent("dataCompEvent");
    event.data = { action: "viewDetails", pattern };
    props.childToParent(event);
  };

  const handleDeletePattern = () => {
    var event = Event.createEvent("dataCompEvent");
    event.data = {
      action: "gridItemDeleted",
      order: cmpState.order,
      record: cmpState.processed,
    };
    props.childToParent(event);
  };

  const plotTypeOnChange = (e) => {
    cmp.set("plotTypeSelected", e.target.value);
    changePlotType();
  };

  const stackCheckboxOnChange = () => {
    cmp.set("stackCheckbox", !cmpState.stackCheckbox);
    changeStack();
  };

  const stack100CheckboxOnChange = () => {
    cmp.set("stack100Checkbox", !cmpState.stack100Checkbox);
    changeStack100();
  };

  const sortCheckboxOnChange = () => {
    cmp.set("sortCheckbox", !cmpState.sortCheckbox);
    changeSortAxis();
    changePlotType();
  };

  const handleLikeClick = () => {
    cmpWorking.current = { ...cmpState };

    markPatternLikedAndAddToFolder("liked");

    setCmpState({ ...cmpWorking.current });
  };

  const handleDislikeClick = () => {
    cmpWorking.current = { ...cmpState };

    dislikedPattern();

    setCmpState({ ...cmpWorking.current });
  };

  //called form HeaderActionsIcons > save icon
  const handleSaveClick = () => {
    cmpWorking.current = { ...cmpState };
    // handlePatternChange();
    cmpWorking.current.saveModalIsOpen = true;
    setCmpState({ ...cmpWorking.current });
  };

  const handleExpandClick = () => {
    var pattern = cmpState.processed || props.record;
    if (!pattern) {
      return;
    }
    var patternId = pattern.id;
    if (!patternId) {
      return;
    }

    var event = Event.createEvent("dataCompEvent");
    event.data = { action: "expand", patternId };
    props.childToParent(event);
  };

  const handleDataCompEvent = function (event) {
    try {
      cmpWorking.current = {
        ...cmpState,
      };
      var data = event.data;

      if (data.action === "update") {
        var pattern = data.pattern;
        var inplace = data.inplace;
        cmpWorking.current.processed = pattern;
        getRecord(inplace);
      }

      setCmpState({ ...cmpWorking.current });
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleEvent = (event) => {
    if (event.type === "dataCompEvent") {
      handleDataCompEvent(event);
    } else if (event.type === "logout") {
      props.childToParent(event);
    }
  };

  const onAxisZoneMouseMove = (event) => {
    cmpWorking.current = { ...cmpState };

    if (cmpWorking.current.tooltipsSet) {
      setTooltips();
    }
    var axisZone = event.target.id;
    var tooltipElement;
    if (axisZone === "xAxisZone") {
      tooltipElement = xAxisZoneTooltipRef.current;
    } else if (axisZone === "yAxisZone") {
      tooltipElement = yAxisZoneTooltipRef.current;
    } else if (axisZone === "legendZone") {
      tooltipElement = legendZoneTooltipRef.current;
    }

    if (props.view !== "details") {
      if (!tooltipElement) {
        console.log("tooltipElement not found");
      }
    }

    if (!tooltipElement) return;
    tooltipElement.style.visibility = "visible";

    setCmpState({ ...cmpWorking.current });
  };

  const onAxisZoneMouseLeave = (event) => {
    var tooltipElement;
    if (event.target.id === "xAxisZone") {
      tooltipElement = xAxisZoneTooltipRef.current;
    } else if (event.target.id === "yAxisZone") {
      tooltipElement = yAxisZoneTooltipRef.current;
    } else if (event.target.id === "legendZone") {
      tooltipElement = legendZoneTooltipRef.current;
    }

    if (!tooltipElement) {
      return;
    }
    tooltipElement.style.visibility = "hidden";
  };
  const onTooltipClick = (event) => {
    if (props.view === "details" || props.view === "embeddedDetails") {
      var chartAxesDetails = cmp.get("chartAxesDetails");
      if (event.target.id === "xAxisZone") {
        cmp.set("selectedAxis", chartAxesDetails.xAxis);
      } else if (event.target.id === "yAxisZone") {
        cmp.set("selectedAxis", chartAxesDetails.yAxis);
      } else if (event.target.id === "legendZone") {
        cmp.set("selectedAxis", chartAxesDetails.legend);
      }
      cmp.set("editModalIsOpen", true);
    }
  };

  //handler for change to chartAxesInputJSON
  //AfterDraw is called many times for a single chart. By using onChange, we only respond to actual range changes
  const handleChartAxesInputChange = () => {
    setChartAxesAreas();
  };

  const handleHeaderActionClick = (selected) => {
    const inputId = selected.name;
    const labelString = selected.label;

    try {
      if (inputId) {
        cmp.set("selectedAxis", { inputId, labelString });
        cmp.set("editModalIsOpen", true);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const setColumnWidths = (widths) => {
    var pattern = cmp.get("processed") || cmp.get("record");
    if (!pattern.inputs || !pattern.inputs.length) {
      return;
    }
    var idInputs = pattern.inputs.reduce((obj, item) => {
      obj[item.id || item.floatingId] = item;
      return obj;
    }, {});
    var columns = cmp.get("dataTableColumns");
    var colnum = 0;
    columns.forEach((col) => {
      let input = idInputs[col.inputId];
      // NOTE: each input Node can only have a single width; when a data table is broken down by an XND slot, all the width will be stored on the VAL node, and after reloading the pattern all columns for that VAL node will have the width of the last label value column.
      if (input) {
        input.parameters = Object.assign(input.parameters || {}, {
          columnWidth: widths[colnum],
        });
      }
      //if (input) { input.options = Object.assign(input.options || {}, {columnWidth: widths[colnum]}); }  // IMPROVEMENT: should be set on 'options' instead of 'parameters' (after implementing this in backend), because changing 'parameters' changes the hash
      colnum++;
    });
    cmp.set("processed", pattern); // the underlying input Nodes are changed directly; setting the pattern activates any onchange triggers
    // this.getRecord(false);   // actually, there is no need to re-render everything, because we only changed the plot options

    // Just added for webapp
    columns = columns.map((col, index) => ({
      ...col,
      columnWidth: widths[index],
    }));
    cmp.set("dataTableColumns", columns);
  };

  //   handleTableResize : function (cmp, event, helper) {
  //     try {
  //         let userTriggered = event.getParam('isUserTriggered');
  //         if (userTriggered) {
  //             let widths = event.getParam('columnWidths');
  //             helper.setColumnWidths(cmp, widths);
  //         }
  //     } catch (err) {
  //         console.error(err.stack);
  //     }
  // },

  const handleTableResize = (widths) => {
    try {
      setColumnWidths(widths);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const setMode = (mode) => {
    cmpWorking.current.loading = false;
    if (mode === "view") {
      cmpWorking.current.mode = mode;
      Record.setElementAttribute("ViewModeButton", "disabled", false);
    } else if (mode === "error") {
      cmpWorking.current.mode = mode;
    }
    setCmpState({ ...cmpWorking.current });
  };

  const setLoading = () => {
    cmp.set("loading", true);
  };

  const setError = (type = null) => {
    cmpWorking.current.loading = true;
    cmpWorking.current.errorType = type;

    // reset plot data
    cmpWorking.current.plotJSON = null;
    setMode("error");
  };

  const setPlotOptions = (options) => {
    var pattern = cmpWorking.current.processed || props.record;
    if (!pattern) {
      return;
    }
    if (!pattern.options) {
      pattern.options = {};
    }
    Object.assign(pattern.options, options);
    cmpWorking.current.processed = pattern;
  };

  //get pattern and plot
  const getRecord = (inplace) => {
    setToastState(emptyToastState);

    // When this save received an error, it made the tooltip unusable again.
    // resetChartAxesAndTooltipAreas();

    // error if empty pattern
    var pattern = cmpWorking.current.processed || props.record;

    if (!pattern) {
      resetPattern();
      setError();
      return;
    } else {
      // make first-level copy, so changing attributes doesn't change the original, potentially causing unexpected updates in other components
      pattern = Object.assign({}, pattern);
      replacePreset(pattern);
      parseText(pattern); // set available text so it is available immediately, and even if the pattern can't be plotted
    }

    // the plot API uses the stored record instead of the specified 'inputs' if an 'id' is specified, so remove the 'id' if inputs are specified already
    var patternId = pattern.id;
    var userDescription = pattern.userDescription; // this is injected from the saved Salesforce record, and not returned from the plot API

    var onSuccess = function (response) {
      var record = response[0];
      try {
        // plot API doesn't return a patternId, so set the original patternId back, so it can be used for navigation
        record.pattern.id = patternId;
        record.pattern.userDescription = userDescription;
        cmpWorking.current.processed = record.pattern;
        cmp.set("isNewRecords", true);
        parseText(record.pattern);
        processAxes(record);
        parseOptions(record.options);
        parseData(record.data);
        processChart(record.chart);
      } catch (err) {
        console.error(err.stack);
        setError();
        setCmpState({ ...cmpWorking.current });
      } finally {
        if (cmpState.editModalIsOpen) {
          cmp.set("editModalIsOpen", false);
        }
      }
    };

    var onError = function (response) {
      cmp.checkUser(response);
      cmp.setToastState("error", "Error", toastErrorMessage(response));
      cmp.set("loading", false);
      cmpWorking.current = { ...cmpState };
      // set the original 'v.record' to 'v.processed', so Filters can be parsed
      if (!cmpWorking.current.processed) {
        cmpWorking.current.processed = props.record;
      }

      if (!cmpState.editModalIsOpen) {
        setError(response.type);
      }

      setCmpState({ ...cmpWorking.current });
    };

    if (!inplace) {
      if (pattern.inputs) {
        pattern.id = null;
      }
      setLoading();
      Record.getRecord(
        "plot",
        "pattern",
        "",
        "",
        JSON.stringify(pattern),
        "PUT",
        onSuccess,
        onError
      );
      return;
    }
  };

  //Replace dynamic presets, such as logged in user id or embedded record value, before sending pattern to backend to retrieve plot
  const replacePreset = (pattern) => {
    var componentContextFields =
      cmpWorking.current.componentContextFields || {};

    var nodes = Record.flatten(pattern, "inputs");
    nodes.forEach((node) => {
      if (
        node.filter &&
        node.filter.settings &&
        node.filter.settings.alias === "PresetText"
      ) {
        if (node.filter.settings.preset === "EmbeddedRecordValue") {
          let embeddedRecordFieldValue =
            componentContextFields["embeddedRecordFieldValue"];
          if (embeddedRecordFieldValue) {
            node.filter.settings.values = [embeddedRecordFieldValue];
          } else {
            node.filter.settings.values = null;
          }
        } else if (node.filter.settings.preset === "LoggedInUserValue") {
          let userRecordFieldValue =
            componentContextFields["userRecordFieldValue"];
          if (userRecordFieldValue) {
            node.filter.settings.values = [userRecordFieldValue];
          } else {
            node.filter.settings.values = null;
          }
        } else if (node.filter.settings.preset === "LoggedInUserId") {
          // TODO, this was SF only
          let userId = "123"; // $A.get("$SObjectType.CurrentUser.Id");
          node.filter.settings.values = [userId];
        }
      }
    });
  };

  const resetPattern = () => {
    cmp.set("plotJSON", null);
    cmp.set("processed", null);
    cmp.set("namePlain", null);
    cmp.set("nameMarkup", null);
    cmp.set("title", null);
    cmp.set("relevance", null);
    cmp.set("relevanceType", null);
    cmp.set("showDetails", false);
    cmp.set("showFilters", false);
    cmp.set("patternDescription", null);
  };

  const parseText = (pattern) => {
    // parse data from pattern returned by the plot API
    var containerName = ((pattern.key || {}).container || {}).name || "";
    var sourceName =
      (((pattern.key || {}).container || {}).source || {}).name || "";
    var relevanceIntrinsic = pattern.relevanceIntrinsic;
    var relevance =
      relevanceIntrinsic != null ? relevanceIntrinsic : pattern.relevance;
    var relevanceType =
      relevanceIntrinsic != null
        ? "Modeled"
        : pattern.relevance != null
        ? "Expected"
        : null;
    var showDetails = Boolean(relevanceType);
    var showFilters = Boolean(pattern.type !== "SQL");

    cmp.set("namePlain", Record.removeMarkup(pattern.name));
    cmp.set("nameMarkup", Record.markupToHtml(pattern.name));
    cmp.set("title", sourceName + ": " + containerName);
    cmp.set("relevance", relevance);
    cmp.set("relevanceType", relevanceType);
    cmp.set("showDetails", showDetails);
    cmp.set("showFilters", showFilters);
    cmp.set("patternDescription", pattern.userDescription);
  };

  // Populate chartAxesDetails with axis information from chart and find axis key where relevant
  const processAxes = (record) => {
    try {
      var chart = record.chart;
      var pattern = record.pattern;
      if (!chart || !pattern) {
        return;
      }

      var chartAxesDetails = { plotType: chart.type };

      if (
        CARTESIAN_CHART_TYPES.includes(chart.type) ||
        CIRCULAR_CHART_TYPES.includes(chart.type)
      ) {
        chartAxesDetails.xAxis = {
          name: "xAxis",
          labelString: chart.options.scales.xAxes[0].scaleLabel.labelString,
          fulltext: chart.options.scales.xAxes[0].scaleLabel.fulltext,
          inputId: chart.options.scales.xAxes[0].scaleLabel.inputId,
          display: true,
        };
        chartAxesDetails.yAxis = {
          name: "yAxis",
          labelString: chart.options.scales.yAxes[0].scaleLabel.labelString,
          fulltext: chart.options.scales.yAxes[0].scaleLabel.fulltext,
          inputId: chart.options.scales.yAxes[0].scaleLabel.inputId,
          display: true,
        };
        if (chart.options.legend && chart.options.legend.display) {
          chartAxesDetails.legend = {
            name: "legend",
            labelString: chart.options.legend.title.text,
            fulltext: chart.options.legend.title.fulltext,
            inputId: chart.options.legend.title.inputId,
            display: true,
          };
        }
        if (chart.type === "radar") {
          chartAxesDetails.xAxis.display = false;
        }
        if (CIRCULAR_CHART_TYPES.includes(chart.type)) {
          if (chart.data.labels) {
            chartAxesDetails.xAxis.labelCount = chart.data.labels.length;
            let maxLabelWidth = -1;
            chart.data.labels.forEach((label) => {
              let width = getTextWidth(label, "Arial");
              if (width > maxLabelWidth) maxLabelWidth = width;
            });
            chartAxesDetails.xAxis.maxLabelWidth = maxLabelWidth;
          }
        }
      } else if (GEO_CHART_TYPES.includes(chart.type)) {
        //REVIEW: handle title as xAxis?
        if (chart.options.title) {
          chartAxesDetails.xAxis = {
            name: "xAxis",
            //labelString: chart.options.title.text[0], //REVIEW with LP, why is this a list? -> because it could be multiple lines of text, so do this instead:
            labelString: chart.options.title.text.join("\n"), // NOTE: don't just take the first item, instead
            fulltext: chart.options.title.fulltext,
            inputId: chart.options.title.inputId,
            display: true,
          };
        }
        if (chart.options.geo && chart.options.geo.colorScale.legend) {
          chartAxesDetails.legend = {
            name: "legend",
            labelString: chart.options.geo.colorScale.legend.title.text,
            fulltext: chart.options.geo.colorScale.legend.title.fulltext,
            inputId: chart.options.geo.colorScale.legend.title.inputId,
            display: true,
          };
        }
      }
      cmpWorking.current.chartAxesDetails = chartAxesDetails;
      // setCmpState((prevCmpState) => ({
      //   ...prevCmpState,
      //   chartAxesDetails,
      // }));
    } catch (err) {
      console.error("processAxes failed: " + err.message);
    }
  };

  const getTextWidth = (text, font) => {
    const canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  };

  const parseOptions = (options) => {
    var plotType = options.plotType;
    var plotTypes = options.plotTypes || {};
    var plotTypesOptions = [];
    for (let [pType, pOptions] of Object.entries(plotTypes)) {
      if (plotTypes.hasOwnProperty(pType) && pOptions.enabled) {
        plotTypesOptions.push({
          value: pType,
          label: pOptions.name,
          selected: pType === plotType,
          disabled: true,
        });
      }
    }
    cmpWorking.current.plotTypeOptions = plotTypesOptions;
    cmpWorking.current.plotTypeSelected = plotType;
    cmpWorking.current.showPlotTypes = plotTypesOptions.length;

    cmpWorking.current.stackCheckbox = options.stack;
    cmpWorking.current.showStackCheckbox = options.enableStack;

    cmpWorking.current.stack100Checkbox = options.stackToHundred;
    cmpWorking.current.showStack100Checkbox = options.enableStackToHundred;

    cmpWorking.current.sortCheckbox = options.sortAxis === "xAxes";
    cmpWorking.current.showSortCheckbox = (options.sortAxes || []).includes(
      "xAxes"
    );

    cmpWorking.current.hasActiveFilters = options.hasActiveFilters;
    cmpWorking.current.droppedOutliers = options.droppedOutliers;
    cmpWorking.current.droppedMissing = options.droppedMissing;
    // setCmpState((prevCmpState) => ({
    //   ...prevCmpState,
    //   plotTypeOptions: plotTypesOptions,
    //   plotTypeSelected: plotType,
    //   showPlotTypes: plotTypesOptions.length,
    //   stackCheckbox: options.stack,
    //   showStackCheckbox: options.enableStack,
    //   stack100Checkbox: options.stackToHundred,
    //   showStack100Checkbox: options.enableStackToHundred,
    //   sortCheckbox: options.sortAxis === "xAxes",
    //   showSortCheckbox: (options.sortAxes || []).includes("xAxes"),
    //   droppedMissing: options.droppedMissing,
    //   droppedOutliers: options.droppedOutliers,
    //   hasActiveFilters: options.hasActiveFilters,
    // }));
  };

  const parseData = (data) => {
    cmpWorking.current.plotData = data;
    setCmpState((prev) => ({ ...prev, plotData: data }));
  };

  function color(colorString, alpha = 1) {
    const rgbValues = colorString.match(/\d+/g);

    if (!rgbValues || rgbValues.length < 3) {
      throw new Error(
        "Invalid color string format. Please provide a valid RGB color string."
      );
    }

    const red = parseInt(rgbValues[0]);
    const green = parseInt(rgbValues[1]);
    const blue = parseInt(rgbValues[2]);

    if (isNaN(red) || isNaN(green) || isNaN(blue)) {
      throw new Error("Invalid color values. Please provide valid RGB values.");
    }

    const validAlpha = Math.min(1, Math.max(0, parseFloat(alpha)));

    return `rgba(${red}, ${green}, ${blue}, ${validAlpha})`;
  }

  /* Prepare chart input - Some charts require functions within the chart object.
As these are not included in the plot data from Deep Sigma, the functions are added here. */
  const processChart = (chart) => {
    if (!chart) {
      setError("EmptyPlotError");
      return;
    }

    // only allow interaction with the plot in detailed view
    if (props.view !== "details") {
      if (chart.options && chart.options.legend) {
        chart.options.legend.onClick = null;
      }
    }

    //   chart.plugins = [{
    //     beforeInit: function(chart, options) {
    //       test();
    //     }
    // }]

    // var self = this;
    //Retrieve and store axes information - used later for tooltips
    chart.plugins = [
      {
        afterDraw: (chartObject) => {
          setChartAxisInput(chart, chartObject);
        },
      },
    ];

    // initialize
    resetChart();
    cmp.set("plotJSON", null);
    cmp.set("dataTableKeyField", "");
    cmp.set("dataTableColumns", []);
    cmp.set("dataTableRecords", []);

    if (chart.type === "table" || chart.type === "data") {
      // map to Lightning table format and add actions
      var data = chart.data;
      var levels = (data.levels || []).map((v) => ({
        label: v.name,
        name: v.inputId,
        checked: false,
      }));
      var detail_view =
        cmp.get("view") === "details" || cmp.get("view") === "embeddedDetails";
      data.columns.forEach((v) =>
        Object.assign(v, {
          fieldName: v.column,
          label: v.value || v.name,
          hideDefaultActions: true,
          initialWidth: detail_view ? v.initialWidth : null,
          actions: [{ label: v.name, name: v.inputId, checked: false }].concat(
            v.role === "values" ? levels : []
          ),
        })
      );

      cmp.set("dataTableKeyField", data.key);
      cmp.set("dataTableColumns", data.columns);
      cmp.set("dataTableRecords", data.datasets[0]);

      // don't cache and plot
      setMode("view");
      return;
    } else if (chart.type === "scatter") {
      //code below can be used to add meaningful tooltips to scatter dots
      if (chart.additionalData != undefined) {
        //tooltips for scatter chart
        chart.options.tooltips.callbacks = {
          //tooltipItems vs item!!
          title: function (tooltipItems, data) {
            var index = tooltipItems[0].index;
            var datasetIndex = tooltipItems[0].datasetIndex;
            var dataset = data.datasets[datasetIndex];
            var datasetItem = dataset.data[index];
            var dot = chart.additionalData[datasetIndex].data[index];

            return dot.name;
          },
          beforeLabel: function (tooltipItem, data) {
            return "Xlabel " + ": " + tooltipItem.xLabel;
          },
          label: function (tooltipItem, data) {
            return (
              data.datasets[tooltipItem.datasetIndex].label +
              ": " +
              tooltipItem.yLabel
            );
          },
          afterLabel: function (tooltipItem, data) {
            return "***** Text below label *****";
          },
        };
      }
    } else if (chart.type === "matrix") {
      if (chart.additionalData !== undefined) {
        chart.data.datasets[0].borderColor = function (ctx) {
          var value = ctx.dataset.data[ctx.dataIndex].v;
          var alpha = value / value || 0;
          return color("rgb(128, 128, 128)", alpha);
        };

        chart.data.datasets[0].backgroundColor = function (ctx) {
          var value = ctx.dataset.data[ctx.dataIndex].v;
          var alpha =
            0.1 +
              (0.9 * (value - chart.additionalData.min_value)) /
                chart.additionalData.ran_value || 0;
          return color(chart.additionalData.rgbColor, alpha);
        };

        chart.data.datasets[0].width = function (ctx) {
          var a = ctx.chart.chartArea;
          return (a.right - a.left) / (chart.additionalData.nrows * 1.1);
        };
        chart.data.datasets[0].height = function (ctx) {
          var a = ctx.chart.chartArea;
          return (a.bottom - a.top) / (chart.additionalData.ncols * 1.1);
        };
        chart.options.tooltips.callbacks = {
          title: function (item) {
            var xLabelIndex = item[0].xLabel - 1;
            var xLabel = chart?.additionalData?.main_labels?.[xLabelIndex];
            return xLabel || "";
          },
          label: function (item, data) {
            var yLabelIndex = item.yLabel - 1;
            var yLabel = chart?.additionalData?.legend_labels?.[yLabelIndex];
            var v = data.datasets[item.datasetIndex].data[item.index];
            return [`${yLabel}: ${v.v}`];
          },
        };
        chart.options.scales.xAxes[0].ticks.callback = function (
          value,
          index,
          values
        ) {
          return chart.additionalData.main_labels[value - 1];
        };
        chart.options.scales.xAxes[0].ticks.afterBuildTicks = function (
          scale,
          ticks
        ) {
          return ticks.slice(1, chart.additionalData.nrows + 1);
        };
        chart.options.scales.yAxes[0].ticks.callback = function (
          value,
          index,
          values
        ) {
          return chart.additionalData.legend_labels[value - 1];
        };
        chart.options.scales.yAxes[0].ticks.afterBuildTicks = function (
          scale,
          ticks
        ) {
          return ticks.slice(1, chart.additionalData.ncols + 1);
        };
      }
    } else if (chart.type === "choropleth") {
      var dataset = chart.data.datasets[0];
      var outline = dataset.outline; //location name
      // TODO: if this runs a 2nd time, dataset.outline has already been replaced by geodata, so it no longer contains a country name. Just load based on available data?
      //       Why is the chart/canvas disappearing while these objects remain unchanged?

      let pathInput = "/assets/maps/chart_geo_";
      var countrySettings = {
        "united kingdom": ["gbreer", ""],
        brazil: ["bra", "BR."],
        usa: ["usa", ""],
      }; // IMPROVEMENT: specify in constants file
      var countrySetting = countrySettings[outline] || "";
      var countryCode = countrySetting[0];

      if (countrySetting) {
        if (countryCode === "world") {
          pathInput += "world";
        } else {
          pathInput += "countries/" + countryCode + ".topo.json";
        }

        fetch(pathInput)
          .then((response) => response.json())
          .then((responseParsed) => {
            // var responseParsed = JSON.parse(req.response);
            var geoData = ChartGeo.topojson.feature(
              responseParsed,
              responseParsed.objects[countryCode]
            ).features;
            // create lookup tables
            var geoLookup = geoData.reduce((obj, item) => {
              obj[item.id] = item;
              return obj;
            }, {});
            var geoNames = geoData.reduce((obj, item) => {
              obj[item.id] = item ? item.properties.name : null;
              return obj;
            }, {});

            // map outlines and labels
            dataset.outline = geoData;
            //dataset.data contains the items returned by the backend, they have feature (e.g. state code) and value (value to display on map)
            dataset.data.forEach((item) => {
              item.feature =
                geoLookup[countrySetting[1] + item.feature.toUpperCase()];
            });
            chart.data.labels = chart.data.labels.map(
              (item) => geoNames[countrySetting[1] + item.toUpperCase()] || item
            );
            chart.options.responsive = true;

            cacheAndPlot(chart);
          });

        return; //TODO review
      }
    }
    cacheAndPlot(chart);
  };

  //Store information on axis range that is only available in afterDraw function. This is used later to set the axis zones and tooltips
  const setChartAxisInput = (chart, chartObject) => {
    try {
      var chartAxesInput = {
        chartType: chart.type,
        xAxis: {},
        yAxis: {},
      };

      if (CARTESIAN_CHART_TYPES.includes(chart.type)) {
        var xAxis = chartObject.scales["x-axis-0"];
        var yAxis = chartObject.scales["y-axis-0"];
        if (chart.type === "scatter") {
          xAxis = chartObject.scales["x-axis-1"];
          yAxis = chartObject.scales["y-axis-1"];
        }

        if (xAxis) {
          chartAxesInput.xAxis = { left: xAxis.left, right: xAxis.right };
        }
        if (yAxis) {
          chartAxesInput.yAxis = { top: yAxis.top, bottom: yAxis.bottom };
        }
      }

      var chartAxesInputJSON = JSON.stringify(chartAxesInput);
      cmp.set("chartAxesInputJSON", chartAxesInputJSON);

      if (
        chartAxesInputJSONRef.current !== chartAxesInputJSON ||
        cmp.get("isNewRecords")
      ) {
        handleChartAxesInputChange();
        cmp.set("isNewRecords", false);
      }
      chartAxesInputJSONRef.current = chartAxesInputJSON;
    } catch (err) {
      console.error("setChartAxisInput failed: " + err.message);
    }
  };

  const changePlotType = () => {
    var plotType = cmpWorking.current.plotTypeSelected;
    setPlotOptions({ plotType });
    getRecord(false);
  };

  const changeStack = () => {
    var stack = cmpWorking.current.stackCheckbox;
    var options = { stack };
    options.stackToHundred = false;
    setPlotOptions(options);
    getRecord(false);
  };

  const changeStack100 = () => {
    var stackToHundred = cmpWorking.current.stack100Checkbox;
    var options = { stackToHundred };
    cmpWorking.current.stackCheckbox = true;
    options.stack = true;
    options.plugins = {
      stacked100: { enable: true },
    };
    setPlotOptions(options);
    getRecord(false);
  };

  const changeSortAxis = () => {
    var sortAxis = cmpWorking.current.sortCheckbox;
    var options = { sortAxis: sortAxis ? "xAxes" : null };
    setPlotOptions(options);
    getRecord(false);
  };

  const cacheAndPlot = (chart) => {
    cmpWorking.current.plotJSON = JSON.stringify(chart);
    plotChart(chart);
  };

  const resetChart = () => {
    var chartElement;
    if (props.view === "details") {
      chartElement = chartDetailRef.current;
    } else {
      chartElement = chartGridRef.current;
    }
    if (!chartElement) {
      return;
    }
    try {
      if (chartElement && cmpChart.current) {
        cmpChart.current.destroy();
      }
    } catch (err) {
      console.error("resetChart err:", err);
    }
  };

  const plotChart = (chart) => {
    try {
      setMode("view");

      var chartElement;
      if (props.view === "details") {
        chartElement = chartDetailRef.current;
      } else {
        chartElement = chartGridRef.current;
      }
      if (!chartElement) {
        return;
      }

      resetChart();
      cmpChart.current = new Chart(chartElement.getContext("2d"), chart);
    } catch (err) {
      console.error("plotChart err:", err);
    }

    setCmpState({ ...cmpWorking.current }); //update state here, because choropleth runs async, so it doesnt' come back to init flow
  };

  const showLikedPatternError = () => {
    setToastState({
      heading: "Liked Pattern",
      details: "Failed to mark pattern as liked",
      variant: "error",
    });
  };

  const showDislikedPatternError = () => {
    setToastState({
      heading: "Disliked Pattern",
      details: "Failed to mark pattern as disliked",
      variant: "error",
    });
  };

  const markPatternLikedAndAddToFolder = () => {
    try {
      var onSuccess = function (response) {
        if (response && response.length > 0) {
          const folder = response[0];
          likedPattern(folder.id);
        } else {
          createLikedFolderAndAddPattern();
        }
      };

      var onError = function (response) {
        cmp.checkUser(response);
        showLikedPatternError();
      };

      Record.getRecords(
        "relate",
        "folder",
        { name: "Liked" },
        onSuccess,
        onError
      );
    } catch (err) {
      console.error(err.stack);
      showLikedPatternError();
    }
  };

  //this is used by like button
  //TODO We now have save code in two places, here and on PatternSave. need a single function
  const savePattern = (folderId) => {
    var parentPattern = cmp.get("processed");

    // Creating a deep copy of parentPattern to avoid modifying cmpWorking.current.processed.
    // The cmp.get("processed") returns a reference to cmpWorking.current.processed,
    // and since cleanInput modifies nested keys, we use a deep copy to prevent affecting the original object.
    const deepCopyParentPattern = JSON.parse(JSON.stringify(parentPattern));

    var {
      id,
      floatingId,
      computed,
      relevance,
      relevanceIntrinsic,
      custom,
      ...pattern
    } = deepCopyParentPattern;
    cleanInput(pattern);
    pattern.description = "";
    pattern.folderId = folderId;

    const patternList = [pattern];

    var onSuccess = function (response) {
      const message = (
        <>
          Pattern bookmark {pattern.name} created in
          <Link
            to={`/SavedInsights?selected=folders_folder_${folderId}&folder=${folderId}`}
          >
            [Liked]
          </Link>
          folder
        </>
      );

      setToastState({
        heading: "Liked Pattern",
        details: message,
        variant: "success",
      });
    };
    var onError = function (response) {
      cmp.checkUser(response);
      showLikedPatternError();
    };

    Record.createRecord(
      "relate",
      "pattern",
      JSON.stringify(patternList),
      onSuccess,
      onError
    );
  };

  const createLikedFolderAndAddPattern = () => {
    try {
      var onSuccess = function (response) {
        const insertedFolder = response[0];
        if (insertedFolder?.id) {
          savePattern(insertedFolder.id);
        } else {
          showLikedPatternError();
        }
      };

      var onError = function (response) {
        cmp.checkUser(response);
        cmp.setToastState("error", "Error", toastErrorMessage(response));
      };

      Record.createRecord(
        "relate",
        "folder",
        JSON.stringify([
          {
            name: "Liked",
            description: "",
          },
        ]),
        onSuccess,
        onError
      );
    } catch (err) {
      console.error(err.stack);
    }
  };

  const likedPattern = (folderId) => {
    try {
      const pattern = cmpWorking.current.processed || props.record || {};
      const patternId = pattern.id;

      var onSuccess = function (response) {
        const likedPattern = response[0];
        if (likedPattern?.id === patternId) {
          savePattern(folderId);
        } else {
          showLikedPatternError();
        }
      };

      var onError = function (response) {
        cmp.checkUser(response);
        showLikedPatternError();
      };

      Record.updateRecord(
        "relate",
        "pattern",
        JSON.stringify({
          relevanceExternal: 1,
        }),
        patternId,
        onSuccess,
        onError
      );
    } catch (err) {
      console.error(err.stack);
      showLikedPatternError();
    }
  };

  const dislikedPattern = () => {
    try {
      const pattern = cmpWorking.current.processed || props.record || {};
      const patternId = pattern.id;

      var onSuccess = function (response) {
        const dislikedPattern = response[0];
        if (dislikedPattern?.id === patternId) {
          setToastState({
            heading: "Disliked Pattern",
            details: "Pattern marked as disliked",
            variant: "success",
          });
        } else {
          showDislikedPatternError();
        }
      };

      var onError = function (response) {
        cmp.checkUser(response);
        showDislikedPatternError();
      };

      Record.updateRecord(
        "relate",
        "pattern",
        JSON.stringify({
          relevanceExternal: -1,
        }),
        patternId,
        onSuccess,
        onError
      );
    } catch (err) {
      console.error(err.stack);
      showDislikedPatternError();
    }
  };

  const resetChartAxesAndTooltipAreas = () => {
    try {
      cmpWorking.current.chartAxesInputJSON = "";
      cmpWorking.current.tooltipsSet = false;
      cmpWorking.current.chartAxesDetails = null;

      resetAxis("xAxis");
      resetAxis("yAxis");
      resetAxis("legend");
    } catch (err) {
      console.error("resetChartAxesAndTooltipAreas failed: " + err.message);
    }
  };

  const resetAxis = (axisName) => {
    try {
      var axisZone = axisName + "Zone";
      var axisZoneElement;
      if (axisZone === "xAxisZone") {
        axisZoneElement = xAxisZoneRef.current;
      } else if (axisZone === "yAxisZone") {
        axisZoneElement = yAxisZoneRef.current;
      } else if (axisZone === "legendZone") {
        axisZoneElement = legendZoneRef.current;
      }

      // let axisZoneElement = document.getElementById(axisName + "Zone");
      if (!axisZoneElement) return;
      axisZoneElement.style.left = 0 + "px";
      axisZoneElement.style.top = 0 + "px";
      axisZoneElement.style.width = 0 + "px";
      axisZoneElement.style.height = 0 + "px";
      axisZoneElement.style.visibility = "hidden";

      var axisZoneTooltip = axisName + "ZoneTooltip";
      var tooltipElement;
      if (axisZoneTooltip === "xAxisZoneTooltip") {
        tooltipElement = xAxisZoneTooltipRef.current;
      } else if (axisZoneTooltip === "yAxisZoneTooltip") {
        tooltipElement = yAxisZoneTooltipRef.current;
      } else if (axisZoneTooltip === "legendZoneTooltip") {
        tooltipElement = legendZoneTooltipRef.current;
      }

      // let tooltipElement = document.getElementById(axisName + "ZoneTooltip");
      if (!tooltipElement) return;
      tooltipElement.style.left = 0 + "px";
      tooltipElement.style.top = 0 + "px";
      tooltipElement.classList.remove("tooltip-nubbin_left");
      tooltipElement.classList.remove("tooltip-nubbin_right");
      tooltipElement.classList.remove("tooltip-nubbin_top");
      tooltipElement.classList.remove("tooltip-nubbin_bottom");
    } catch (err) {
      console.error("resetAxis failed: " + err.message);
    }
  };

  const setChartAxesAreas = () => {
    try {
      var chartAxesInputJSON = cmpWorking.current.chartAxesInputJSON;
      if (!chartAxesInputJSON) return;
      var chartAxesInput = JSON.parse(chartAxesInputJSON);
      var chartAxesDetails = cmpWorking.current.chartAxesDetails;
      var spaceAboveChart = 107; //107 in SF

      var chartElement;
      if (props.view === "details") {
        chartElement = chartDetailRef.current;
      } else {
        chartElement = chartGridRef.current;
      }

      if (!chartElement) return;
      var rect = chartElement.getBoundingClientRect();
      var chartVisible = Boolean(rect.top);

      if (
        CARTESIAN_CHART_TYPES.includes(chartAxesInput.chartType) ||
        CIRCULAR_CHART_TYPES.includes(chartAxesInput.chartType)
      ) {
        if (CARTESIAN_CHART_TYPES.includes(chartAxesInput.chartType)) {
          var canvasHeight =
            props.view !== "details" && props.view !== "embeddedDetails"
              ? 300
              : 400;

          //xAxis tooltipZone
          chartAxesDetails.xAxis.left = chartAxesInput.xAxis.left;
          chartAxesDetails.xAxis.top = chartAxesInput.yAxis.bottom;
          chartAxesDetails.xAxis.width =
            chartAxesInput.xAxis.right - chartAxesInput.xAxis.left;
          chartAxesDetails.xAxis.height =
            canvasHeight - chartAxesInput.yAxis.bottom; //Math.round(rect.bottom - (rect.top + chartAxesInput.yAxis.bottom));
          if (props.view !== "details" && props.view !== "embeddedDetails") {
            chartAxesDetails.xAxis.top += spaceAboveChart;
          }

          //yAxis tooltipZone
          chartAxesDetails.yAxis.left = 0;
          chartAxesDetails.yAxis.top = chartAxesInput.yAxis.top;
          chartAxesDetails.yAxis.width = chartAxesInput.xAxis.left;
          chartAxesDetails.yAxis.height =
            chartAxesInput.yAxis.bottom - chartAxesInput.yAxis.top;

          if (chartAxesDetails.legend) {
            //legend tooltipZone
            chartAxesDetails.legend.left =
              chartAxesInput.xAxis.left +
              (chartAxesInput.xAxis.right - chartAxesInput.xAxis.left) / 2 -
              120;
            chartAxesDetails.legend.top = 0;
            chartAxesDetails.legend.width = 200;
            chartAxesDetails.legend.height = 25;
            if (props.view !== "details" && props.view !== "embeddedDetails") {
              chartAxesDetails.legend.top += spaceAboveChart;
            }
          }
        } else if (CIRCULAR_CHART_TYPES.includes(chartAxesInput.chartType)) {
          if (props.view === "details" || props.view === "embeddedDetails") {
            //xAxis tooltipZone
            chartAxesDetails.xAxis.left =
              570 - chartAxesDetails.xAxis.maxLabelWidth;
            chartAxesDetails.xAxis.width =
              chartAxesDetails.xAxis.maxLabelWidth + 60;
            let height = 16.7 * chartAxesDetails.xAxis.labelCount;
            chartAxesDetails.xAxis.top = 217 - height / 2;
            chartAxesDetails.xAxis.height = height;

            //yAxis tooltipZone
            chartAxesDetails.yAxis.left = 230;
            chartAxesDetails.yAxis.top = 4;
            chartAxesDetails.yAxis.width = 140;
            chartAxesDetails.yAxis.height = 26;
          } else {
            //xAxis tooltipZone
            chartAxesDetails.xAxis.left =
              410 - chartAxesDetails.xAxis.maxLabelWidth;
            chartAxesDetails.xAxis.width =
              chartAxesDetails.xAxis.maxLabelWidth + 60;
            let height = 16.7 * chartAxesDetails.xAxis.labelCount;
            chartAxesDetails.xAxis.top = 275 - height / 2;
            chartAxesDetails.xAxis.height = height;

            //yAxis tooltipZone
            chartAxesDetails.yAxis.left = 180;
            chartAxesDetails.yAxis.top = 4;
            chartAxesDetails.yAxis.width = 130;
            chartAxesDetails.yAxis.height = 18;
          }
        }
        if (props.view !== "details" && props.view !== "embeddedDetails") {
          //In grid view, it is counting from top of box instead of top of chart
          chartAxesDetails.yAxis.top += spaceAboveChart;
        }
      } else if (GEO_CHART_TYPES.includes(chartAxesInput.chartType)) {
        if (props.view === "details" || props.view === "embeddedDetails") {
          //xAxis tooltipZone
          chartAxesDetails.xAxis.left = 230;
          chartAxesDetails.xAxis.top = 365;
          chartAxesDetails.xAxis.width = 200;
          chartAxesDetails.xAxis.height = 40;

          //legend tooltipZone
          chartAxesDetails.legend.left = 260;
          chartAxesDetails.legend.top = 4;
          chartAxesDetails.legend.width = 160;
          chartAxesDetails.legend.height = 26;
        } else {
          //xAxis tooltipZone
          chartAxesDetails.xAxis.left = 140;
          chartAxesDetails.xAxis.top = 365;
          chartAxesDetails.xAxis.width = 200;
          chartAxesDetails.xAxis.height = 40;

          //legend tooltipZone
          chartAxesDetails.legend.left = 170;
          chartAxesDetails.legend.top = 4 + spaceAboveChart;
          chartAxesDetails.legend.width = 160;
          chartAxesDetails.legend.height = 26;
        }
      }
      cmpWorking.current.chartAxesDetails = chartAxesDetails;

      drawAxis(chartAxesDetails.xAxis);
      drawAxis(chartAxesDetails.yAxis);
      drawAxis(chartAxesDetails.legend);
      if (chartVisible) setTooltips();
    } catch (err) {
      console.error("setChartAxesAreas failed: " + err.message);
    }
  };

  const drawAxis = (chartAxisDetails) => {
    if (!chartAxisDetails) return;
    if (!chartAxisDetails.display) return;

    var axisZone = chartAxisDetails.name + "Zone";
    var axisZoneElement;
    if (axisZone === "xAxisZone") {
      axisZoneElement = xAxisZoneRef.current;
    } else if (axisZone === "yAxisZone") {
      axisZoneElement = yAxisZoneRef.current;
    } else if (axisZone === "legendZone") {
      axisZoneElement = legendZoneRef.current;
    }
    if (!axisZoneElement) return;

    axisZoneElement.style.left = chartAxisDetails.left + "px";
    axisZoneElement.style.top = chartAxisDetails.top + "px";
    axisZoneElement.style.width = chartAxisDetails.width + "px";
    axisZoneElement.style.height = chartAxisDetails.height + "px";

    axisZoneElement.style.visibility = "visible";
  };

  const setTooltips = () => {
    try {
      var chartAxesInputJSON = cmpWorking.current.chartAxesInputJSON;
      if (!chartAxesInputJSON) return;
      var chartAxesInput = JSON.parse(chartAxesInputJSON);
      var chartAxesDetails = cmpWorking.current.chartAxesDetails;
      var spaceAboveChart = 107;

      if (
        CARTESIAN_CHART_TYPES.includes(chartAxesInput.chartType) ||
        CIRCULAR_CHART_TYPES.includes(chartAxesInput.chartType)
      ) {
        if (CARTESIAN_CHART_TYPES.includes(chartAxesInput.chartType)) {
          //xAxis tooltip position
          let xAxisTooltipElement = xAxisZoneTooltipRef.current;
          xAxisTooltipElement.style["max-width"] =
            chartAxesDetails.xAxis.width + "px";
          let xAxisMiddle =
            chartAxesDetails.xAxis.left + chartAxesDetails.xAxis.width / 2;
          xAxisTooltipElement.style.left =
            xAxisMiddle - xAxisTooltipElement.offsetWidth / 2 + "px";
          let chartBottom =
            chartAxesDetails.xAxis.top + chartAxesDetails.xAxis.height;
          xAxisTooltipElement.style.top =
            chartBottom - xAxisTooltipElement.offsetHeight - 25 + "px";
          xAxisTooltipElement.classList.add("tooltip-nubbin_bottom");

          //yAxis tooltip position
          let yAxisTooltipElement = yAxisZoneTooltipRef.current;
          yAxisTooltipElement.style["max-width"] =
            chartAxesDetails.xAxis.width + "px";
          yAxisTooltipElement.style.left = "25px";
          let yAxisMiddle =
            chartAxesInput.yAxis.top + chartAxesDetails.yAxis.height / 2;
          let yAxisTooltipTop =
            yAxisMiddle - yAxisTooltipElement.offsetHeight / 2;
          if (props.view !== "details" && props.view !== "embeddedDetails") {
            yAxisTooltipTop += spaceAboveChart;
          }
          yAxisTooltipElement.style.top = yAxisTooltipTop + "px";
          yAxisTooltipElement.classList.add("tooltip-nubbin_left");

          if (chartAxesDetails.legend) {
            //legend tooltip position
            let legendTooltipElement = legendZoneTooltipRef.current;
            legendTooltipElement.style["max-width"] =
              chartAxesDetails.xAxis.width + "px";
            let legendLeft =
              xAxisMiddle - legendTooltipElement.offsetWidth / 2 - 30;
            legendTooltipElement.style.left = legendLeft + "px";
            let legendTop = 35;
            if (props.view !== "details" && props.view !== "embeddedDetails") {
              legendTop += spaceAboveChart;
            }
            legendTooltipElement.style.top = legendTop + "px";
            legendTooltipElement.classList.add("tooltip-nubbin_top");
          }
        } else if (CIRCULAR_CHART_TYPES.includes(chartAxesInput.chartType)) {
          if (props.view === "details" || props.view === "embeddedDetails") {
            //xAxis tooltip position
            let xAxisTooltipElement = xAxisZoneTooltipRef.current;
            xAxisTooltipElement.style["max-width"] = "450px";
            xAxisTooltipElement.style.left =
              chartAxesDetails.xAxis.left -
              10 -
              xAxisTooltipElement.offsetWidth +
              "px";
            xAxisTooltipElement.style.top =
              215 - xAxisTooltipElement.offsetHeight / 2 + "px";
            xAxisTooltipElement.classList.add("tooltip-nubbin_right");

            //yAxis tooltip position
            let yAxisTooltipElement = yAxisZoneTooltipRef.current;
            yAxisTooltipElement.style.left =
              300 - yAxisTooltipElement.offsetWidth / 2 + "px";
            yAxisTooltipElement.style.top = "30px";
            yAxisTooltipElement.classList.add("tooltip-nubbin_top");
          } else {
            //xAxis tooltip position
            let xAxisTooltipElement = xAxisZoneTooltipRef.current;
            xAxisTooltipElement.style["max-width"] = "320px";
            xAxisTooltipElement.style.left =
              chartAxesDetails.xAxis.left -
              10 -
              xAxisTooltipElement.offsetWidth +
              "px";
            xAxisTooltipElement.style.top =
              270 - xAxisTooltipElement.offsetHeight / 2 + "px";
            xAxisTooltipElement.classList.add("tooltip-nubbin_right");

            //yAxis tooltip position
            let yAxisTooltipElement = yAxisZoneTooltipRef.current;
            yAxisTooltipElement.style.left =
              245 - yAxisTooltipElement.offsetWidth / 2 + "px";
            yAxisTooltipElement.style.top = "137px";
            yAxisTooltipElement.classList.add("tooltip-nubbin_top");
          }
        }
      } else if (GEO_CHART_TYPES.includes(chartAxesInput.chartType)) {
        if (props.view === "details" || props.view === "embeddedDetails") {
          //xAxis tooltip position
          let xAxisTooltipElement = xAxisZoneTooltipRef.current;
          // let xAxisTooltipElement = document.getElementById(
          //   chartAxesDetails.xAxis.name + "ZoneTooltip"
          // );
          xAxisTooltipElement.style.left =
            330 - xAxisTooltipElement.offsetWidth / 2 + "px";
          xAxisTooltipElement.style.top =
            370 - xAxisTooltipElement.offsetHeight + "px";
          xAxisTooltipElement.classList.add("tooltip-nubbin_bottom");

          //legend tooltip position
          let legendTooltipElement = legendZoneTooltipRef.current;
          let legendLeft = 330 - legendTooltipElement.offsetWidth / 2;
          legendTooltipElement.style.left = legendLeft + "px";
          let legendTop = 30;
          legendTooltipElement.style.top = legendTop + "px";
          legendTooltipElement.classList.add("tooltip-nubbin_top");
        } else {
          //xAxis tooltip position
          let xAxisTooltipElement = xAxisZoneTooltipRef.current;
          xAxisTooltipElement.style.left =
            250 - xAxisTooltipElement.offsetWidth / 2 + "px";
          xAxisTooltipElement.style.top =
            380 - xAxisTooltipElement.offsetHeight + "px";
          xAxisTooltipElement.classList.add("tooltip-nubbin_bottom");

          //legend tooltip position
          let legendTooltipElement = legendZoneTooltipRef.current;
          let legendLeft = 250 - legendTooltipElement.offsetWidth / 2;
          legendTooltipElement.style.left = legendLeft + "px";
          legendTooltipElement.style.top = "138px";
          legendTooltipElement.classList.add("tooltip-nubbin_top");
        }
      }
      cmpWorking.current.tooltipsSet = true;
    } catch (err) {
      console.error("setTooltips failed: " + err.message);
    }
  };

  return (
    <div>
      <Card
        className={
          (props.view === "drag" ? "editModeBorder" : "") +
          " slds-card_boundary"
        }
        style={{
          border: props.itemView === "drag" ? "3px solid #005fb2" : null,
          height:
            cmpState.plotTypeSelected === "data" &&
            props.view !== "details" &&
            props.view !== "embeddedDetails"
              ? 445
              : "",
        }}
        heading={cmpState.title}
        headerActions={
          <div>
            {props.view === "details" && (
              <HeaderActionsIcons
                recordId={props?.record?.id}
                selectedView="details"
                handleLikeClick={handleLikeClick}
                handleDislikeClick={handleDislikeClick}
                handleSaveClick={handleSaveClick}
                handleViewDetails={handleViewDetails}
              />
            )}
            {/* {props.view === "grid" && (
              <HeaderActionsIcons
                selectedView="grid"
                handleLikeClick={handleLikeClick}
                handleDislikeClick={handleDislikeClick}
                handleSaveClick={handleSaveClick}
                handleViewDetails={handleViewDetails}
              />
            )} */}

            {/* <aura:if isTrue="{!v.view === 'drag'}" >
    <lightning:buttonIcon iconName="utility:delete" size="large" variant="bare" onclick="{! c.handleDeletePattern }" alternativeText="Delete" title="Delete" />
  </aura:if> */}
            {props.itemView === "drag" ? (
              <button
                className="slds-button slds-button_icon"
                title="Delete"
                onClick={() => handleDeletePattern()}
              >
                <svg
                  className="slds-button__icon slds-button__icon_large"
                  aria-hidden="true"
                >
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#delete"></use>
                </svg>
                <span className="slds-assistive-text">Delete</span>
              </button>
            ) : (
              props.view === "grid" && (
                <HeaderActionsIcons
                  recordId={props?.record?.id}
                  selectedView="grid"
                  handleLikeClick={handleLikeClick}
                  handleDislikeClick={handleDislikeClick}
                  handleSaveClick={handleSaveClick}
                  handleViewDetails={handleViewDetails}
                />
              )
            )}
          </div>
        }
      >
        {/* titleOfChart */}
        <div className="slds-p-left_medium slds-p-right_medium slds-p-bottom_small">
          <div
            className="slds-truncate slds-text-title"
            title={cmpState.namePlain}
          >
            {formattedBlueText(cmpState.nameMarkup)}
          </div>
        </div>
        {/* Detailed View */}
        {(props.view === "details" || props.view === "embeddedDetails") && (
          <div
            className="slds-grid slds-wrap" // style={{ maxWidth: "1024p" }}
          >
            <div
              className="slds-col slds-m-horizontal_medium slds-is-relative"
              style={{ flexGrow: "0" }}
            >
              {cmpState.mode === "error" && (
                <div
                  className="slds-illustration slds-illustration_large"
                  style={{ width: "640px", height: "400px" }}
                  aria-hidden="true"
                >
                  <img
                    src="/assets/images/illustrations/Desert.svg"
                    className="slds-illustration__svg"
                    alt=""
                  />
                  <div className="slds-text-color_weak">
                    {cmpState.errorType === "EmptyPlotError" && (
                      <div>
                        <h4 className="slds-text-heading_medium">
                          Insufficient data
                        </h4>
                        <br />
                        Refine search criteria or adjust filters.
                      </div>
                    )}
                    {cmpState.errorType !== "EmptyPlotError" && (
                      <h4 className="slds-text-heading_medium">
                        Pattern could not be loaded
                      </h4>
                    )}
                  </div>
                </div>
              )}
              <div
                className={
                  cmpState.mode === "init" ||
                  cmpState.mode === "view" ||
                  cmpState.mode === "edit"
                    ? ""
                    : "slds-hide"
                }
              >
                {(cmpState.plotTypeSelected === "table" ||
                  cmpState.plotTypeSelected === "data") &&
                  cmpState.dataTableColumns?.length > 0 && (
                    <div
                      style={{
                        overflow: "auto",
                        width: "90vw",
                        height: "400px",
                      }}
                    >
                      <DataTable
                        columns={cmpState.dataTableColumns}
                        data={cmpState.dataTableRecords}
                        onheaderaction={handleHeaderActionClick}
                        isEditable={true}
                        handleTableResize={handleTableResize}
                      />
                    </div>
                  )}
                <div
                  className={
                    cmpState.plotTypeSelected === "table" ||
                    cmpState.plotTypeSelected === "data"
                      ? "slds-hide"
                      : ""
                  }
                >
                  {/* <PsChart chartInput={chartExample2.chart} /> */}
                  <canvas
                    ref={chartDetailRef}
                    id="chart"
                    width="640"
                    height="400"
                  ></canvas>
                  <span
                    ref={xAxisZoneRef}
                    id="xAxisZone"
                    className="axisZone"
                    onMouseMove={onAxisZoneMouseMove}
                    onMouseLeave={onAxisZoneMouseLeave}
                    onClick={onTooltipClick}
                  >
                    xAxisZone
                  </span>
                  <span
                    ref={yAxisZoneRef}
                    id="yAxisZone"
                    className="axisZone"
                    onMouseMove={onAxisZoneMouseMove}
                    onMouseLeave={onAxisZoneMouseLeave}
                    onClick={onTooltipClick}
                  >
                    yAxisZone
                  </span>
                  <span
                    ref={legendZoneRef}
                    id="legendZone"
                    className="axisZone"
                    onMouseMove={onAxisZoneMouseMove}
                    onMouseLeave={onAxisZoneMouseLeave}
                    onClick={onTooltipClick}
                  >
                    legendZone
                  </span>
                  {cmpState.chartAxesDetails &&
                    cmpState.chartAxesDetails.xAxis && (
                      <span
                        ref={xAxisZoneTooltipRef}
                        // id="xAxisZoneTooltip"
                        className="tooltip"
                      >
                        <b>Axis: </b>
                        {cmpState.chartAxesDetails.xAxis.labelString}
                        <br />
                        <b>Origin: </b>
                        {cmpState.chartAxesDetails.xAxis.fulltext}
                      </span>
                    )}

                  {cmpState.chartAxesDetails &&
                    cmpState.chartAxesDetails.yAxis && (
                      <span ref={yAxisZoneTooltipRef} className="tooltip">
                        <b>Axis: </b>
                        {cmpState.chartAxesDetails.yAxis.labelString}
                        <br />
                        <b>Origin: </b>
                        {cmpState.chartAxesDetails.yAxis.fulltext}
                      </span>
                    )}
                  {cmpState.chartAxesDetails &&
                    cmpState.chartAxesDetails.legend && (
                      <span ref={legendZoneTooltipRef} className="tooltip">
                        <b>Axis: </b>
                        {cmpState.chartAxesDetails.legend.labelString}
                        <br />
                        <b>Origin: </b>
                        {cmpState.chartAxesDetails.legend.fulltext}
                      </span>
                    )}
                </div>
              </div>
            </div>
            <div
              className="slds-col slds-large-size_1-of-5 slds-m-horizontal_medium"
              style={{
                minWidth: "300px",
                maxWidth: "500px",
                flexGrow: "1",
              }}
            >
              <div className="slds-grid slds-wrap">
                {/* <!-- Generated Description --> */}
                {generativeAIExplainEnabled && (
                  <Description
                    cmpState={cmpState}
                    toggleSection={toggleSection}
                    handleEvent={handleEvent}
                    parentCmp={cmp}
                  />
                )}
                {/* <!-- Plot Options --> */}
                <PlotOptions
                  cmpState={cmpState}
                  toggleSection={toggleSection}
                  plotTypeOnChange={plotTypeOnChange}
                  sortCheckboxOnChange={sortCheckboxOnChange}
                  stack100CheckboxOnChange={stack100CheckboxOnChange}
                  stackCheckboxOnChange={stackCheckboxOnChange}
                />
                {/* <!-- Filters --> */}
                {cmpState.processed && cmpState.showFilters ? (
                  <Filters
                    cmpStateParent={cmpState}
                    toggleSection={toggleSection}
                    handleEvent={handleEvent}
                    childToParent={props.childToParent}
                  />
                ) : null}
                {/* <!-- Details --> */}
                {cmpState.showDetails && (
                  <Details cmpState={cmpState} toggleSection={toggleSection} />
                )}
              </div>
            </div>
          </div>
        )}
        {/* Grid View */}
        {props.view !== "details" && props.view !== "embeddedDetails" && (
          <div onClick={handleViewDetails}>
            {/* <div onClick="{! (v.view === 'drag') ? null : c.handleViewDetails }"> */}
            {cmpState.mode === "error" && (
              <div
                className="slds-illustration slds-illustration_large"
                style={{
                  width: "500px",
                  height: "310px",
                }}
                aria-hidden="true"
              >
                <img
                  src="/assets/images/illustrations/Desert.svg"
                  className="slds-illustration__svg"
                  alt=""
                />
                <div className="slds-text-color_weak">
                  {cmpState.errorType === "EmptyPlotError" && (
                    <div>
                      <h4 className="slds-text-heading_medium">
                        Insufficient data
                      </h4>
                      <br />
                      Refine search criteria or adjust filters.
                    </div>
                  )}
                  {cmpState.errorType !== "EmptyPlotError" && (
                    <h4 className="slds-text-heading_medium">
                      Pattern could not be loaded
                    </h4>
                  )}
                </div>
              </div>
            )}
            <div
              className={
                cmpState.mode === "init" ||
                cmpState.mode === "view" ||
                cmpState.mode === "edit"
                  ? "slds-show_inline'"
                  : "slds-hide"
              }
            >
              <div className="slds-grid slds-grid_vertical-align-start">
                {/* Added slds-p-right_medium. SF render has additional space to the right */}
                <div className="slds-col slds-p-right_medium">
                  <div
                    className={
                      cmpState.plotTypeSelected === "table" ||
                      cmpState.plotTypeSelected === "data"
                        ? "slds-p-left_small"
                        : "slds-hide"
                    }
                    style={{ width: "480px", height: "300px" }}
                  >
                    {cmpState.dataTableColumns?.length > 0 && (
                      <DataTable
                        columns={cmpState.dataTableColumns}
                        data={cmpState.dataTableRecords}
                        onheaderaction={handleHeaderActionClick}
                        isEditable={false}
                      />
                    )}
                  </div>
                  <div
                    className={
                      cmpState.plotTypeSelected === "table" ? "slds-hide" : ""
                    }
                  >
                    <canvas
                      ref={chartGridRef}
                      width="480"
                      height="300"
                      style={{
                        cursor: "pointer",
                        minWidth: "480px",
                        minHeight: "300px",
                      }}
                    ></canvas>
                    <span
                      ref={xAxisZoneRef}
                      id="xAxisZone"
                      className="axisZone"
                      style={{ cursor: "pointer" }}
                      onMouseMove={onAxisZoneMouseMove}
                      onMouseLeave={onAxisZoneMouseLeave}
                      onClick={onTooltipClick}
                    >
                      xAxisZone
                    </span>
                    <span
                      ref={yAxisZoneRef}
                      id="yAxisZone"
                      className="axisZone"
                      style={{ cursor: "pointer" }}
                      onMouseMove={onAxisZoneMouseMove}
                      onMouseLeave={onAxisZoneMouseLeave}
                      onClick={onTooltipClick}
                    >
                      yAxisZone
                    </span>
                    <span
                      ref={legendZoneRef}
                      id="legendZone"
                      className="axisZone"
                      style={{ cursor: "pointer" }}
                      onMouseMove={onAxisZoneMouseMove}
                      onMouseLeave={onAxisZoneMouseLeave}
                      onClick={onTooltipClick}
                    >
                      legendZone
                    </span>
                    {cmpState.chartAxesDetails &&
                      cmpState.chartAxesDetails.xAxis && (
                        <span ref={xAxisZoneTooltipRef} className="tooltip">
                          <b>Axis: </b>
                          {cmpState.chartAxesDetails.xAxis.labelString}
                          <br />
                          <b>Origin: </b>
                          {cmpState.chartAxesDetails.xAxis.fulltext}
                        </span>
                      )}

                    {cmpState.chartAxesDetails &&
                      cmpState.chartAxesDetails.yAxis && (
                        <span ref={yAxisZoneTooltipRef} className="tooltip">
                          <b>Axis: </b>
                          {cmpState.chartAxesDetails.yAxis.labelString}
                          <br />
                          <b>Origin: </b>
                          {cmpState.chartAxesDetails.yAxis.fulltext}
                        </span>
                      )}
                    {cmpState.chartAxesDetails &&
                      cmpState.chartAxesDetails.legend && (
                        <span ref={legendZoneTooltipRef} className="tooltip">
                          <b>Axis: </b>
                          {cmpState.chartAxesDetails.legend.labelString}
                          <br />
                          <b>Origin: </b>
                          {cmpState.chartAxesDetails.legend.fulltext}
                        </span>
                      )}
                  </div>
                </div>
                <div className="slds-col slds-grid slds-grid_vertical">
                  {/* <aura:if isTrue="{! v.hasActiveFilters }">
                            <div className="slds-col">
                                <lightning:icon iconName="utility:filterList" alternativeText="Has filters" title="Has filters" size="x-small"/>
                            </div>
                        </aura:if> */}
                  {/* <!-- indicators, re-enable once user can update -->
                        <aura:if isTrue="{! v.droppedOutliers }">
                            <div className="slds-col">
                                <lightning:icon iconName="utility:deprecate" alternativeText="Dropped outliers" title="Dropped outliers" size="x-small"/>
                            </div>
                        </aura:if>
                        <aura:if isTrue="{! v.droppedMissing }">
                            <div className="slds-col">
                                <lightning:icon iconName="utility:ban" alternativeText="Dropped missing data" title="Dropped missing data" size="x-small"/>
                            </div>
                        </aura:if> */}
                </div>
              </div>
            </div>
          </div>
        )}
        {cmpState.loading && <Spinner assistiveText={{ label: "Loading" }} />}
      </Card>
      {/* <!-- Edit Modal Window -->
      <aura:if isTrue="{!v.editModalIsOpen}">
          <section role="dialog" tabindex="-1" aria-labelledby="modal-heading-01" aria-modal="true" aria-describedby="modal-content-id-1" class="slds-modal slds-fade-in-open">
              <c:PatternEdit pattern="{!v.processed}" editModalIsOpen="{!v.editModalIsOpen}" selectedAxis="{!v.selectedAxis}" editModalTitle="Edit Axis"/>
          </section>
          <div className="slds-backdrop slds-backdrop_open"></div>
      </aura:if>

      <!-- Save Modal Window -->
      <aura:if isTrue="{!v.saveModalIsOpen}">
          <section role="dialog" tabindex="-1" aria-labelledby="modal-heading-01" aria-modal="true" aria-describedby="modal-content-id-1" class="slds-modal slds-fade-in-open">
              <c:PatternSave pattern="{!v.processed}" saveModalIsOpen="{!v.saveModalIsOpen}" />
          </section>
          <div className="slds-backdrop slds-backdrop_open"></div>
      </aura:if> */}
      {cmpState.saveModalIsOpen ? (
        <PatternSave
          parentState={cmpState}
          parentCmp={cmp}
          setToastState={setToastState}
          childToParent={props.childToParent}
        />
      ) : null}

      {/* Edit Modal Window */}
      {cmpState.editModalIsOpen ? (
        <PatternEdit
          editModalIsOpen={cmpState.editModalIsOpen}
          toggleEditModal={toggleEditModal}
          parentCmp={cmp}
          handleEvent={handleEvent}
          childToParent={props.childToParent}
        />
      ) : null}
      {toastState.details ? (
        <ToastComponent
          close={() => setToastState(emptyToastState)}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </div>
  );
}

export default (props) => (
  <PsErrorBoundary
    fallback={
      <div className="ps-pattern-chart-error-boundary">
        <Icon
          category="utility"
          name="error"
          size="small"
          className="error-icon"
          colorVariant="error"
        />
        <p>There was a problem loading this chart.</p>
        <p>
          Please try refreshing the page or contact support if the issue
          persists.
        </p>
      </div>
    }
  >
    <PsPatternChart {...props} />
  </PsErrorBoundary>
);
